import { Directive, HostListener, HostBinding, Output, Input, EventEmitter } from '@angular/core';

export class DraggableEvent {
	public static START:string = "start";
	public static CLICK:string = "click";
	public static STOP:string = "stop";
	public static DRAG:string = "drag";
	public static DISABLED:string = "disabled";

	constructor(public type:string, public data:any = null, public metadata:any = null) {
		
	}
}
export class DragSnap{
	snapping:boolean;
	x:number | boolean;
	y:number | boolean;
}
@Directive({
	selector: '[appDraggable]'
})
export class DraggableDirective {
	private _disabled = false;
	@Input() set disabled(disabled:boolean){
		this._disabled = disabled;
	}
	@Input() target: {x:number, y:number, width:number, height:number};
	@Input() snapTargets: any[];
	@Input() metadata: any;	// optional metadata

	@Output() onSnappingUpdate = new EventEmitter<DragSnap>();
	@Output() dragUpdate = new EventEmitter<DraggableEvent>();
	
	private snap : DragSnap = new DragSnap();
	private snapping : boolean;
	private snappingX : number | boolean;
	private snappingY : number | boolean;

	private dragging : boolean;
	private startPos: any = {};
	private deltaPos: any = {};
	private currentPos: any = {};
	private handlers:any = {};

	private moveStartThreshold:number = 3;	// required minimum movement to initiate dragging
	private active:boolean = false;

	constructor(){}

	@HostListener('click', ['$event'])
	onClick(event:MouseEvent) {
		if(this._disabled){
			if(this.dragUpdate)
			{
				this.dragUpdate.emit(new DraggableEvent(DraggableEvent.DISABLED, {event}, this.metadata));
			}
			return;
		}
		event.preventDefault();
		event.stopPropagation();
		if(this.dragUpdate)
		{
			this.dragUpdate.emit(new DraggableEvent(DraggableEvent.CLICK, {event}, this.metadata));
		}	
	}
	@HostListener('mousedown', ['$event'])
	onMouseDown(event:MouseEvent) {


	 	if(this._disabled) {			
			return;
		}
		event.preventDefault();
		event.stopPropagation();
		if(event.button == 1) return;
		this.getMousePos(event, this.startPos);
		this.deltaPos.x = this.target.x - this.startPos.x;
		this.deltaPos.y = this.target.y - this.startPos.y;
		this.dragging = true;

		if(!this.handlers["mousemove"]) this.handlers["mousemove"] = this.onMouseMove.bind(this);
		if(!this.handlers["mouseup"]) this.handlers["mouseup"] = this.onMouseUp.bind(this);
		window.document.addEventListener("mousemove", this.handlers["mousemove"]);
		window.document.addEventListener("mouseup", this.handlers["mouseup"]);

		if(this.dragUpdate)
		{
			this.dragUpdate.emit(new DraggableEvent(DraggableEvent.START, {event, delta:this.deltaPos}, this.metadata));
		}
	}
	@HostListener('mouseup', ['$event'])
	onMouseUp(event:MouseEvent) {
		if(this.dragUpdate)
		{
			this.dragUpdate.emit(new DraggableEvent(DraggableEvent.STOP, {event}, this.metadata));
		}

		if(this._disabled) return;
		if(event.button == 1) return;
		//event.preventDefault();
		//event.stopPropagation();

		this.active = false;
		this.dragging = false;
		if(this.snapping)
		{
			this.snapping = false;
			this.snap.snapping = false;
			this.snap.x = false;
			this.snap.y = false;
			this.onSnappingUpdate.emit(this.snap);
		}
		window.document.removeEventListener("mousemove", this.handlers["mousemove"]);
		window.document.removeEventListener("mouseup", this.handlers["mouseup"]);
	}
	private onMouseMove(event:MouseEvent) : void
	{
		if(this._disabled) return;

		this.getMousePos(event, this.currentPos);

		if(!this.active)
		{
			let dx = Math.abs(this.startPos.x - this.currentPos.x);
			let dy = Math.abs(this.startPos.y - this.currentPos.y);
			if(Math.sqrt(dx * dx + dy * dy) >= this.moveStartThreshold)
			{
				this.active = true;
			}else{
				return;
			}
		}

		this.target.x = this.deltaPos.x + this.currentPos.x;
		this.target.y = this.deltaPos.y + this.currentPos.y;

		// cache snapping state
		var snappingNow = this.snapping;
		var snappingXNow = this.snappingX;
		var snappingYNow = this.snappingY;

		// clear snapping state
		this.snapping = this.snappingX = this.snappingY = false;
		if(event.ctrlKey)
		{
			let snappingVDistance = 10;
			this.target.x = Math.round((this.deltaPos.x + this.currentPos.x)/snappingVDistance) * snappingVDistance;
			this.target.y = Math.round((this.deltaPos.y + this.currentPos.y)/snappingVDistance) * snappingVDistance;
		}else if(event.shiftKey && this.snapTargets)	// snap to other elements bounds
		{
			let maxSnappingDist : number = 15;
			let snappingDistX = 10;

			let bounds = this.getBounds(this.target, {});
			let result = {x:10, y:10, xx:null, yy:null, targetX:null, targetY:null, max:[10, 10], data:[null, null], target:[null, null]};
			for (let i = 0; i < this.snapTargets.length; i++)
			{
				const snaptarget = this.snapTargets[i];
				const snapAsset = snaptarget.current;
				if(snapAsset == this.target) continue;	// skip if the same
				
				if(isNaN(snapAsset.x) || isNaN(snapAsset.y) || isNaN(snapAsset.width) || isNaN(snapAsset.height) ) continue; // skip if does't have required values
				this.getSnapping(snapAsset, bounds, maxSnappingDist, result);
				
			}
			let snappo = this.getSnapping2(bounds, this.snapTargets.filter(target=>target.asset != this.target).map(target=>this.getBounds(target.asset, {})));
			if(snappo.x !== null)
			{
				this.snapping = true;
				this.snappingX = this.lerp(snappo.boundsX.left, snappo.boundsX.right, snappo.tx);
				this.target.x = (this.snappingX as number) - ((bounds.right - bounds.left) * snappo.x);
			}
			if(snappo.y !== null)
			{
				this.snapping = true;
				this.snappingY = this.lerp(snappo.boundsY.top, snappo.boundsY.bottom, snappo.ty);
				this.target.y = (this.snappingY as number) - ((bounds.bottom - bounds.top) * snappo.y);
			}
			if(false && result.target)//	console.log("target", result.target)
			if(false && result.target[0]){
				this.snapping = true;
				let resultW = result.target[0].width;
				let targetW = this.target.width;
				this.snappingX = result.target[0].x + (resultW * result.data[0][1]);
				this.target.x = result.target[0].x + (resultW * result.data[0][1]) - (targetW * result.data[0][0]);
			}
			if(false && result.target[1]){
				this.snapping = true;
				let resultH = result.target[1].height;
				let targetH = this.target.height;
				this.snappingY = result.target[1].y + (resultH * result.data[1][1]);
				this.target.y = result.target[1].y + (resultH * result.data[1][1]) - (targetH * result.data[1][0]);
			}
			/*
			if(result.xx)
			{
				this.target.x = result.targetX.x + (result.targetX.width * result.xx[1]) - (this.target.width * result.xx[0]);
			}
			if(result.yy)
			{
				this.target.y = result.targetY.y + (result.targetY.height * result.yy[1]) - (this.target.height * result.yy[0]);
			}*/
		}
		if(snappingNow != this.snapping || snappingXNow != this.snappingX || snappingYNow != this.snappingY)
		{
			this.snap.snapping = this.snapping;
			this.snap.x = this.snappingX;
			this.snap.y = this.snappingY;
			this.onSnappingUpdate.emit(this.snap);
		}
		if(this.dragUpdate)	this.dragUpdate.emit(new DraggableEvent(DraggableEvent.DRAG, {event}, this.metadata));
	}
	private getBounds(target, output)
	{
		//console.log("t:",target);
		
		let w = target.width;
		let h = target.height;
		//console.log("w:"+w,"| h:"+h);
		output.left = target.x;
		output.right = target.x + w;
		output.top = target.y;
		output.bottom = target.y + h;
		output.x = [target.x, target.x + w];
		output.y = [target.y, target.y + h];
		output.boom = [[target.x, target.x + w], [target.y, target.y + h]];
		return output;
	}
	private lerp(a, b, c)
	{
		return a + (b - a) * c;
	}

	private getSnapping2(bounds, targets)
	{
		// TODO return an array for each x and y snap so we can show multiple lines for each snap rather than just the first one found
		let maxSnappingDistX: number = 15;
		let maxSnappingDistY: number = 15;
		let snap = {x:null, tx:null, y:null, ty:null, boundsX:null, boundsY:null};
		let snaps = {x:null, y:null};

		let xintervals = [0, 0.5, 1];
		let yintervals = [0, 0.5, 1];
		let xtintervals = [0, 0.5, 1];
		let ytintervals = [0, 0.5, 1];
		targets.forEach(target => {
			// x
			xintervals.forEach(ix => {
				let x = this.lerp(bounds.left, bounds.right, ix);
				xtintervals.forEach(ixt => {
					let tx = this.lerp(target.left, target.right, ixt);
					let distance = Math.abs(x - tx);
					if(distance <= maxSnappingDistX) {
						maxSnappingDistX = distance;
						snap.x = ix;
						snap.tx = ixt;
						snap.boundsX = target;
					}
				})
			})
			// y
			yintervals.forEach(iy => {
				let y = this.lerp(bounds.top, bounds.bottom, iy);
				ytintervals.forEach(iyt => {
					let ty = this.lerp(target.top, target.bottom, iyt);
					let distance = Math.abs(y - ty);
					if(distance < maxSnappingDistY){
						maxSnappingDistY = distance;
						snap.y = iy;
						snap.ty = iyt;
						snap.boundsY = target;
					}
				})
			})
		});
		return snap;	
	}
	private getSnapping(target, bounds, max, result)
	{
		let targetBounds = this.getBounds(target, {});		
		for (let i = 0; i < bounds.boom.length; i++) {
			for (let j = 0; j < bounds.boom[i].length; j++) {
				for (let k = 0; k < targetBounds.boom[i].length; k++) {
					let d = Math.abs(bounds.boom[i][j] - targetBounds.boom[i][k]);
					if(d < max && d < result.max[i]) {
						result.max[i] = d;
						result.data[i] = [j, k];
						result.target[i] = target;
					}
				}
			}
		}
		/*
		for (let i = 0; i < bounds.x.length; i++) {
			for (let j = 0; j < targetBounds.x.length; j++) {
				let d = Math.abs(bounds.x[i] - targetBounds.x[j]);
				if(d < max && d < result.x) {
					result.x = d;
					result.xx = [i, j];
					result.targetX = target;
				}
			}
		}
		for (let i = 0; i < bounds.y.length; i++) {
			for (let j = 0; j < targetBounds.y.length; j++) {
				let d = Math.abs(bounds.y[i] - targetBounds.y[j]);
				if(d < max && d < result.y) {
					result.y = d;
					result.yy = [i, j];
					result.targetY = target;
				}
			}
		}*/
	}
	private getMousePos(event, pos: any) {
		if (typeof event.clientX === "number") {
			pos.x = event.clientX;
			pos.y = event.clientY;
		} else if (event.originalEvent.touches) {
			pos.x = event.originalEvent.touches[0].clientX;
			pos.y = event.originalEvent.touches[0].clientY;
		} else {
			return null;
		}
		return pos;
	};
	/*
	private addEvent(target, event, callback): void
	{
		target.addEventListener(event, callback);
		if(!this.events[target]) this.events[target] = [];
		this.events[target].push()
	}*/
}
