import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Amend } from './amends.service';
import { CrudService } from './crud.service';
import { ICreative } from 'src/app/models/creative.model'
import { map } from 'rxjs/operators';
import { ApiResponse } from './OvBaseService';
import { Asset, IAsset } from '../models/asset.model';
import { ApprovalChain } from '../models/ApprovalChain.model';
import { IWorkflow } from '../models/workflow.model';

@Injectable({
	providedIn: 'root'
})
export class CreativeService extends CrudService<ICreative, string> {
  sendEmail(creative_uuid: string, emails: string[], code:string) {
    return this._post(`${this.base}/${creative_uuid}/email`, {emails, code});
  }
	getUserPermissions(creative_uuid: string, user_uuid: any) {
		return this._get(`${this.base}/${creative_uuid}/userPermissions/${user_uuid}`);
	}
	
	constructor(protected http: HttpClient) {
		super(http, 'creative2');
	}
	/*
	getUserAction(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/userAction`);
	}
	*/
	/*
	getUserActions(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/userActions`);
	}
	*/
	/*
	getUsers(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/users`);
	}
	*/
	getUserRole(creative_id: string, user_id: any):Observable<any>{
		console.warn("not in use?");
		return;
		//return this._get(`${this.base}/${creative_id}/userRole/${user_id}`);
	}
	reset(creative_id: string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/reset`);
	}
	getState(uuid:string):Observable<any>
	{
		return this._get(`${this.base}/${uuid}/state`);
	}
	private _logSubject = new BehaviorSubject<any[]>([]);
	public log$ = this._logSubject.asObservable();
	loadLog(uuid:string)
	{
		this.getLog(uuid).subscribe((response:any) => {
			this._logSubject.next(response.data);
		});
	}
	getLog(uuid:string):Observable<any>
	{
		return this._get(`${this.base}/${uuid}/log`).pipe(
			map((response) => {
				let logs:{time:any}[] = response["data"];
				for (let i = 0; i < logs.length; i++) {
					let log = logs[i];
					// convert backend log time strings to js Date and re-format to (dd-mm-yy hh:mm:ss)
					log.time = new Date(log.time).toLocaleString().replace(new RegExp('/', "g"),'-').replace(new RegExp(',', "g"),'');
				}
				return response; 
			})
		);
		/*return this._get(`${this.base}/${uuid}/log`).pipe(
			map((response) => {
				let logs:any[] = response["data"];
				let tasks:any = {};
				for (let i = 0; i < logs.length; i++) {
					let log = logs[i];
					// temp transform
					if(log.status) log.state = log.status;
					//if(log.state) tasks[log.uuid] = log;			// removed because state was sometimes null (could be legacy issue)
					if(!log.action)	tasks[log.uuid] = log;
				}
				for (let i = 0; i < logs.length; i++) {
					let log = logs[i];
					if(!log.state && log.task_uuid){
						log.task = tasks[log.task_uuid];					
					}
				}
				// todo sort
				response["data"] = logs.sort((a, b) =>{
					return new Date(a.created_at || a.updated_at ).getTime() - new Date(b.created_at || b.updated_at ).getTime();
				});
				//console.log("transformed log", response["data"]);
				return response; 
			})
		);*/
	}
	getWorkflow(creative_uuid: string)
	{
		return this._get<ApiResponse<IWorkflow>>(`${this.base}/${creative_uuid}/workflow`);
	}
	getChain(creative_uuid: string)
	{
		return this._get<ApiResponse<ApprovalChain>>(`${this.base}/${creative_uuid}/chain`);
	}
	getChain2(creative_uuid: string)
	{
		return this._get<ApiResponse<ApprovalChain>>(`${this.base}/${creative_uuid}/chain2`);
	}
	getApprovalGroups(creative_uuid: string)
	{
		return this._get<ApiResponse<ApprovalChain>>(`${this.base}/${creative_uuid}/approvalGroups`);
	}
	private _assetsSubject = new Subject<Asset[]>();
	public assetsObservable$ = this._assetsSubject.asObservable();
	loadAssets(creative_uuid : string, params:any = null)
	{
		return this.getAssets(creative_uuid, params).subscribe((res) => {
			this._assetsSubject.next(res.data);
		});
	}
	getAssets(creative_uuid: string,params:any = null)
	{		
		return this._get<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/assets`, params).pipe(
			map((response) => {
				/*
				let assets:IAsset[] = response["data"];
				assets.forEach(asset => {
					Asset.cacheBust(asset);
				})	*/			
				return response; 
			})
		);
	}
	deleteAssets(creative_uuid: string, assets:IAsset[])
	{
		let formData = new FormData();
		let count = 0;
		for (let i = 0; i < assets.length; i++) {
			const asset:IAsset = assets[i];
			if(!asset.uuid) continue;
			formData.append(`assets[${count++}][uuid]`, asset.uuid);
		}
		//return this._delete<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/assets`, formData); // you cannot send data to a delete, only in query
		// @ts-ignore
		return this._post<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/assetsDelete`, formData);
	}
	// TODO remove this testing functionality!!
	setUserRole(creative_id: string, user_id: any, role:string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/userRole/${user_id}/${role}`, null);
	}
	setLabel(creative_id: string, label:string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/label/${label}`, null);
	}
	setFlag(creative_id: string, flag:number):Observable<any>{
		return this._post(`${this.base}/${creative_id}/flag/${flag}`, null);
	}
	setColorInfo(creative_id: string, info:{bg_color:string, bg_transparent:boolean}):Observable<any>{
		return this._post(`${this.base}/${creative_id}/colorInfo`, info);
	}
	setViewOnly(creative_id: string, viewonly:boolean):Observable<any>{
		return this._post(`${this.base}/${creative_id}/viewOnly`, viewonly);
	}
	setLayout(creative_id: string, layout:string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/layout/${layout}`, null);
	}
	saveSpecs(creative_uuid: string, specs: string) {
		return this._post(`${this.base}/${creative_uuid}/specs`, specs);
	}
	getProduction(creative_uuid: string)
	{
		return this._get<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/production`);
	}
	setProduction(creative_uuid: string, user_uuid: string)
	{
		return this._get<ApiResponse<IAsset>>(`${this.base}/${creative_uuid}/production/${user_uuid}`);
	}
	updatePublic(creative_uuid: string, pub: boolean) {
		return this._post(`${this.base}/${creative_uuid}/public`, pub);
	}
	creativeComplete(creative_id: number):Observable<any>{
		return this._post(`${this.base}/${creative_id}/complete`, null, {params:{method:"complete"}});
	}
	creativeApprove(creative_id: number):Observable<any>{
		return this._post(`${this.base}/${creative_id}/approve`);
	}
	creativeSubmitRequests(creative_id: number, amends:Amend[]):Observable<any>{
		return this._post(`${this.base}/${creative_id}/submitRequests`, amends);
	}
	getAvailableChannels(creative_id: string):Observable<any>{
		return this._get(`${this.base}/${creative_id}/availableChannels`);
	}
	copy(creative_id: string, copies:any[]):Observable<any>{
		return this._post(`${this.base}/${creative_id}/copy`, copies);
	}
	assetCheck(creative_id: string):Observable<any>{
		return this._post(`${this.base}/${creative_id}/assetCheck`);
	}
}
