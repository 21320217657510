/**
 * Audio recording flow
 * 1. check for availability of mic
```
navigator.permissions.query({name: 'microphone'}).then(function (result) {
	if (result.state == 'granted') {
	} else if (result.state == 'prompt') {
	} else if (result.state == 'denied') {
	}
	result.onchange = function () {};
});
```
 * 2. If the user chooses to make a recoring they press the mic button
 * this shoulr activate the mic but not acutal recording
 * 
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AmendState } from 'src/app/api/amends.service';
import { FormControl, Validators} from '@angular/forms';
import { ITask, Task, TaskState, TaskType } from 'src/app/models/task.model';
import { AssetVO } from 'src/app/models/asset.model';
import { dateToAge } from 'src/app/utils-date';
import { Permission } from 'src/app/models/permissions.model';
import { TaskMarkupService } from 'src/app/api/taskMarkup.service';
import { IMarker, Marker, MarkerVO } from 'src/app/models/marker.model';
import { ModelUtils } from 'src/app/utils/ModelUtils';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { Globals } from 'src/app/global';
import { Observable } from 'rxjs';
import { filter, find, map } from 'rxjs/operators';

export class TaskEvent
{

	//front-end specific
	static SELECT				: string = "select";
	static DISABLE				: string = "disable";
	static ENABLE				: string = "enable";
	static MARKER				: string = "marker";
	static MARKER_UPDATE		: string = "marker_update";
	static MARKER_EDIT			: string = "marker_edit";
	static MARKER_EDIT_CANCEL	: string = "marker_edit_cancel";
	static MARKER_CLEAR			: string = "marker_clear";
	static EDIT					: string = "edit";
	static CANCEL_EDIT			: string = "cancel_edit";
	static SAVE					: string = "save";
	static REQUEST				: string = "request";
	static SAVE_EDIT			: string = "save_edit";
	static CANCEL_NEW			: string = "cancel_new";
	//static UPDATE				: string = "update";
	
	
	//duplicated from back-end:
	static ACTION_APPROVER_APPROVE 						= "approve";
	static ACTION_APPROVER_REQUESTS_SUBMIT 				= "approver_requests_submit";
	static ACTION_PRODUCTION_BUILD_STARTED 				= "build_started";
	static ACTION_PRODUCTION_BUILD_COMPLETED 			= "build_completed"; 
	static ACTION_PRODUCTION_AMENDS_SUBMIT 				= "production_amends_submit";
	static ACTION_PRODUCTION_RECALL						= "production_recall";
	static ACTION_MODERATOR_SEND_APPROVAL				= "moderator_send_to_approval";
	static ACTION_MODERATOR_SEND_PRODUCTION 			= "moderator_send_to_production";
	static ACTION_MODERATOR_SEND_ISSUES					= "moderator_send_issues_to_approval";
	
	static ACTION_MODERATOR_AMEND_DELETE 				= "delete";
	static ACTION_APPROVER_AMEND_DELETE 				= "delete";
	
	
	static ACTION_APPROVER_AMEND_DECLINE_ACKNOWLEDGE	= "approver_amend_decline_acknowledge";
	static ACTION_APPROVER_AMEND_CONFIRM		 		= "approver_amend_confirm";//old - "complete";
	static ACTION_APPROVER_AMEND_CONFIRM_UNDO			= "approver_amend_confirm_undo";
	static ACTION_APPROVER_AMEND_DENIED_ACKNOWLEDGE		= "approver_amend_denied_acknowledge";//old - 'acknowledge_denied';
	static ACTION_APPROVER_AMEND_REJECT 				= "approver_amend_reject";//old - "done_rejected";
	static ACTION_APPROVER_AMEND_REJECT_UNDO			= "approver_amend_reject_undo";//old - "approver_amend_cancel_action";
	static ACTION_APPROVER_AMEND_QUERY_ANSWER			= "approver_amend_query_answer";//old - "answer_query";
	static ACTION_APPROVER_AMEND_QUERY_ANSWER_UNDO		= "approver_amend_query_answer_undo";
	
	static ACTION_PRODUCTION_AMEND_RECALL					= "production_amend_recall";
	static ACTION_PRODUCTION_AMEND_REJECTED_PUSHBACK		= "production_amend_rejected_pushback";
	static ACTION_PRODUCTION_AMEND_REJECTED_PUSHBACK_UNDO 	= "production_amend_rejected_pushback_undo";
	static ACTION_PRODUCTION_AMEND_ACCEPT 					= "production_amend_accept";//acknowledge late incoming amend
	static ACTION_PRODUCTION_AMEND_DECLINE 					= "production_amend_decline";
	static ACTION_PRODUCTION_AMEND_DONE 					= "production_amend_done";//may allow optional response msgs for this action in future
	static ACTION_PRODUCTION_AMEND_DONE_UNDO 				= "production_amend_done_undo";//old -  "production_amend_cancel_action"
	static ACTION_PRODUCTION_AMEND_DECLINE_UNDO 			= "production_amend_decline_undo";//old -  "production_amend_cancel_action"
	
	static ACTION_MODERATOR_AMEND_ACCEPT 					= "moderator_amend_accept";
	static ACTION_MODERATOR_AMEND_ACCEPT_UNDO 				= "moderator_amend_accept_undo";
	static ACTION_MODERATOR_AMEND_CONFIRM 					= "moderator_amend_confirm";
	static ACTION_MODERATOR_AMEND_CONFIRM_UNDO				= "moderator_amend_confirm_undo";
	static ACTION_MODERATOR_AMEND_DECLINE_ACKNOWLEDGE		= "moderator_amend_decline_acknowledge";
	static ACTION_MODERATOR_AMEND_DECLINE_ACKNOWLEDGE_UNDO 	= "moderator_amend_decline_acknowledge_undo";
	static ACTION_MODERATOR_AMEND_DECLINE_PUSHBACK 			= "moderator_amend_decline_pushback";//old - 'decline_to_confirm_pushback';
	static ACTION_MODERATOR_AMEND_DECLINED_PUSHBACK_UNDO 	= "moderator_amend_declined_pushback_undo";
	static ACTION_MODERATOR_AMEND_DENY 						= "moderator_amend_deny";//old - 'todo_to_qualify_denied';
	static ACTION_MODERATOR_AMEND_DENY_UNDO					= "moderator_amend_deny_undo";//old - 'todo_to_qualify_denied';
	static ACTION_MODERATOR_AMEND_QUERY 					= "moderator_amend_query";//old - 'todo_to_qualivfy_query';
	static ACTION_MODERATOR_AMEND_QUERY_UNDO 				= "moderator_amend_query_undo";//old - 'todo_to_qualivfy_query';
	static ACTION_MODERATOR_AMEND_RECALL					= "moderator_amend_recall";
	static ACTION_MODERATOR_AMEND_REJECT_UNDO 				= "moderator_amend_reject_undo";
	static ACTION_MODERATOR_AMEND_REJECT 					= "moderator_amend_reject";
	static ACTION_MODERATOR_AMEND_REJECTED_ACCEPT 			= "moderator_amend_rejected_accept";
	static ACTION_MODERATOR_AMEND_REJECTED_ACCEPT_UNDO 		= "moderator_amend_rejected_accept_undo";
	static ACTION_MODERATOR_AMEND_REJECTED_PUSHBACK 		= "moderator_amend_rejected_pushback";//old - 'moderator_deny_rejection';
	static ACTION_MODERATOR_AMEND_REJECTED_PUSHBACK_UNDO 	= "moderator_amend_rejected_pushback_undo";

	static ACTION_VIEWONLY_TOGGLE							= "view_only_toggle";


	static reloaderActions = [
		TaskEvent.ACTION_APPROVER_APPROVE,				//'approve',			// could change state
		TaskEvent.ACTION_APPROVER_REQUESTS_SUBMIT,		// "approver_requests_submit";

		TaskEvent.ACTION_PRODUCTION_BUILD_STARTED,		//'build_started',	// production owner change
		TaskEvent.ACTION_PRODUCTION_BUILD_COMPLETED,	// 'build_completed',	// change of creative and task states
		TaskEvent.ACTION_PRODUCTION_RECALL,				// "production_recall";
		TaskEvent.ACTION_PRODUCTION_AMEND_RECALL,		// "production_amend_recall";
		TaskEvent.ACTION_PRODUCTION_AMENDS_SUBMIT, 		// "production_amends_submit";

		//per amend actions
		/*
		TaskEvent.ACTION_PRODUCTION_AMEND_DONE,
		TaskEvent.ACTION_PRODUCTION_AMEND_DONE_UNDO,	
		TaskEvent.ACTION_PRODUCTION_AMEND_DECLINE,
		TaskEvent.ACTION_PRODUCTION_AMEND_DECLINE_UNDO,		
		*/
														// ??? 'recall_request',	// could change state

		TaskEvent.ACTION_MODERATOR_SEND_APPROVAL,		//'moderator_send_to_approval'
		TaskEvent.ACTION_MODERATOR_SEND_PRODUCTION, 	// "moderator_send_to_production";
		TaskEvent.ACTION_MODERATOR_SEND_ISSUES,
		TaskEvent.ACTION_MODERATOR_AMEND_RECALL,		// "moderator_amend_recall";
		TaskEvent.ACTION_MODERATOR_AMEND_QUERY_UNDO,

		//per amend actions
		/*
		TaskEvent.ACTION_MODERATOR_AMEND_REJECT,
		TaskEvent.ACTION_MODERATOR_AMEND_REJECT_UNDO,
		TaskEvent.ACTION_MODERATOR_AMEND_QUERY,
		TaskEvent.ACTION_MODERATOR_AMEND_DENY,
		TaskEvent.ACTION_MODERATOR_AMEND_DENY_UNDO,
		TaskEvent.ACTION_MODERATOR_AMEND_ACCEPT,
		TaskEvent.ACTION_MODERATOR_AMEND_ACCEPT_UNDO,
		*/
		

		TaskEvent.ACTION_VIEWONLY_TOGGLE,				// "view_only_toggle";
	];

	static resetToLatestVersion = [
		TaskEvent.ACTION_APPROVER_REQUESTS_SUBMIT,
		TaskEvent.ACTION_PRODUCTION_AMENDS_SUBMIT,
		TaskEvent.ACTION_MODERATOR_SEND_APPROVAL,
		TaskEvent.ACTION_MODERATOR_SEND_PRODUCTION,
	];

	static loadCreativeAssets = [
		TaskEvent.ACTION_PRODUCTION_AMENDS_SUBMIT,		//'production_done_amending',
		TaskEvent.ACTION_PRODUCTION_BUILD_COMPLETED, 	//'build_completed',
		TaskEvent.ACTION_MODERATOR_SEND_APPROVAL,		//'moderator_send_to_approval',
	];
	constructor(
		public component:TaskComponent,
		public type:string,
		public task:Task,
		public message:string = null,
		public data:any = null){}
}

export interface ITaskAttachment {
	id: number;
	uuid: string;
	task_uuid: string;
	uri: string;
	type: string;
	flag: number;
	taskfile_id: string;
}
export class TaskCaptureEvent implements ITaskAttachment
{
	id: number;
	uuid: string;
	task_uuid: string;
	uri: string;
	preview:string;
	editing:boolean = false; // cannot default to true without data in being converted
	flag: number;
	taskfile_id:string;
	constructor(public file:File, public type:'file' | 'image' | 'video' | 'audio_recording' | 'screen_recording' | 'screen_snapshot', public metadata?:any)
	{
		this.flag = 0;
	}
}
/*
export class TaskAttachment
{
	public id:number;
	public task_id:number;
	public uri:string;
	public type:string;
	public file:File;
}*/
/**
 * 
 * Task attachment limits
 * 	- 1 text comment
 *  - 1 screen recording
 * 	- 1 voice recording
 *  - 1 screenshot
 *  - unlimted generic attachments.. in future maybe limit number and size
 * 
 */
@Component({
	selector: 'app-task',
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskComponent implements OnInit, OnDestroy {

	@Input() task : Task;		// reference to the current task
	@Input() tasks : Task[];	// reference to all the tasks
	@Input() assets : AssetVO[];	// reference to all the assets in this creative
	@Input() markerVOs$ : Observable<MarkerVO[]>;
	@Input() user;
	@Input() selectedTask:Task;
	@Input() creativeState : string;
	@Input() buttonsDisabled : boolean;
	@Input() saving:boolean = false;
	@Output() onTaskSelected:EventEmitter<TaskEvent> = new EventEmitter<TaskEvent>();	
	@Output() onTaskAction:EventEmitter<TaskEvent> = new EventEmitter<TaskEvent>();	
	@Output() onTaskDisable:EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChildren('messageBox') messageBox:QueryList<HTMLTextAreaElement>;
	@ViewChildren(MatInput) textareas: QueryList<MatInput>;
	private recording: boolean = false;

	//public disabled:boolean = false;
	// exposed enum
	get AmendState() { return AmendState; }

	public assetsForm 	: FormControl;

	public editable		: boolean;
	public requestEdit	: string;

	private event		: string;
	public needsMessage : boolean;
	public message		: string;
	public wantsContentFocus:boolean = false;
	public wantsMessageFocus:boolean = false;

	private num_responses:number = 0;
	public show_responses:boolean = false;
	public show_markup:boolean = false;
	public show_attachments:boolean = false;
	public collapsed:boolean = false;
	public response_toggled: boolean = false;
	
	public attachments:TaskCaptureEvent[] = [];
	
	//public attaching_file : boolean;
	//public attaching_image : boolean;
	//public attaching_video : boolean;

	// single file
	public file : File;

	// image file
	public imagePreview	: string;
	public imageFile	: File;

	// video file
	public videoPreview	: string;
	public videoFile	: File;

	// video
	public recording_screen	: boolean;
	//public attaching	: boolean;
	//public preview		: any;
	
	// audio
	public recording_audio	: boolean;

	public capturing_screen : boolean;

	public formControlContent = new FormControl('', [Validators.required, Validators.minLength(3)] );

	// show the task log
	public log:boolean = false;
	public markerVO:MarkerVO;

	constructor(
		public cdr: ChangeDetectorRef,
		private taskMarkupService:TaskMarkupService,
		private matDialog:MatDialog,
		private ds:DialogService,
		public element:ElementRef,
		) { }
		ngOnInit(): void {
			//console.log("is this task selected", this.selectedTask == this.task)
			this.assetsForm = new FormControl();

			if(this.task.type == TaskType.AMEND)
			{
				this.collapsed = this.task.actioned;
			
				if(this.task.user_uuid == this.user.uuid) this.task.author = "Yours";
				if(this.task.state == "local")
				{
					this.wantsContentFocus = true;
					this.edit();
					/* //not used
					this.task.buttons = [[], [
						{
							"label" : "Cancel",
							"action" : TaskEvent.CANCEL_NEW,
							"tooltip" : "Edit amend",
							"type" : "basic",
							"colour" : "warn",
						},
						{
							"label" : "Save",
							"action" : TaskEvent.SUBMIT,
							"tooltip" : "Save amend",
							"type" : "flat",
							"colour" : "primary",
						}]];
						*/
					
				}
				this.show_responses = (this.task.open && this.task.responses?.length >0);
				
			}
			this.num_responses = this.task.responses?.length || 0;
			// find the marker vo that relates this task if applicable
			this.markerVOs$?.pipe(map(markerVOs => markerVOs.find(markerVO => markerVO.task.uuid == this.task.uuid))).subscribe(
				markerVO => this.markerVO = markerVO
			);
		}

	ngAfterViewInit() {

		// viewChildren is set
		//console.log("VIEW INNIT", this.textareas);
		this.textareas.forEach(el => {
			if(el.id  == "content" && this.wantsContentFocus)
			{
				el.focus();
				this.wantsContentFocus = false;
				this.cdr.detectChanges();	// <- required to avoid error "Expression has changed after it was checked."
				// https://stackoverflow.com/questions/52143052/how-to-manually-set-focus-on-mat-form-field-in-angular-6

			}
			/*
			if(el.nativeElement.id == "content" && this.wantsContentFocus)
			{
				this.wantsContentFocus = false;
				el.nativeElement.focus();
			}*/
		})
		this.textareas.changes.pipe().subscribe((r) => {
			//let elements = r.map(p => p.nativeElement);
			//console.log("ELMNTS", elements);
			r.forEach(element => {
				if(element.id == "content" && this.wantsContentFocus)
				{
					this.wantsContentFocus = false;
					element.focus();
				}
			});
		});
		this.messageBox.changes.subscribe((r) => {
			
			if(r.length && this.wantsMessageFocus) {
				r.first.nativeElement.focus();
				this.wantsMessageFocus = false;
			}
		});
	}
	ngOnChange( changes: SimpleChanges): void {
		//if(changes) console.log("changes",changes);
	}

	ngDoCheck() {
		let num_responses = this.task.responses?.length || 0;
		if(num_responses != this.num_responses)
		{
			this.num_responses = num_responses;
			if(this.task.open && !this.show_responses)
			{
				this.show_responses = true;
			}
		}
	}
	ngOnDestroy(): void {
		// remove any subscriptions
		//this.cancel();//this is bogus, fires cancel() twice 
	}
	focus()
	{
		this.textareas.forEach(el => {
			el.focus();
		})
	}
	setMarkerIn(task:Task, marker:IMarker)
	{
		let asset = this.assets.find(assetVO => assetVO.asset.uuid == marker.asset_uuid);
		if(asset)
		{
			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER_UPDATE, task, 'in'));
		}
	}
	setMarkerOut(task:Task, marker:IMarker)
	{
		let asset = this.assets.find(assetVO => assetVO.asset.uuid == marker.asset_uuid);
		if(asset)
		{
			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER_UPDATE, task, 'out'));
		}
	}
	toggleContent(type:string)
	{
		if(type == "responses")
		{
			//console.log("show_responses",this.show_responses);
			this.response_toggled = true;
			this.show_responses = !this.show_responses;
			if(this.show_responses )
			{
				this.show_attachments = false;
				this.show_markup = false;
			}
		} else if(type == "attachments")
		{
			this.show_attachments = !this.show_attachments;
			if(this.show_attachments )
			{
				this.show_responses = false;
				this.show_markup = false;
			}
		}else if(type == "markup")
		{
			this.show_markup = !this.show_markup;
			if(this.show_markup)
			{
				this.show_attachments = false;
				this.show_responses = false;
			}
		}
	}
	scrollIntoView()
	{
		(this.element.nativeElement as HTMLElement).scrollIntoView({behavior: "smooth",block:'nearest'});
	}
	setEvent(event:string):void
	{
		this.event = event;
	}
	getFilePath(uri:string)
	{
		return Globals.WORKER_URL + 'serve/' + uri;
		return Globals.BASE_API_URL + 'file/' + uri;
	}
	onAudioData(attachment:TaskCaptureEvent, data:{file:File})
	{
		if(data)
		{
			attachment.file = data.file;
			attachment.preview = URL.createObjectURL(attachment.file);
		}else {
			//?
		}
		//this.attachments.length = 0;
		//let attachment:TaskCaptureEvent = new TaskCaptureEvent(data.file, "audio_recording");
		//this.attachments.push(attachment);
	}
	onVideoData(attachment:TaskCaptureEvent,data:{file:File, metadata:any})
	{
		if(data)
		{
			attachment.file = data.file;
			attachment.preview = attachment.file ? URL.createObjectURL(attachment.file) : null;
			if(data.metadata) attachment.metadata = JSON.stringify(data.metadata);
			else attachment.metadata = null;// do we need to null this
		}else {
			//?
		}
		/*
		this.attachments.length = 0;
		let attachment:TaskCaptureEvent = new TaskCaptureEvent(data.file, "screen_recording" );
		if(data.metadata) attachment.metadata = JSON.stringify(data.metadata);
		this.attachments.push(attachment);*/
	}

	onRecordStartStop(attachment:TaskCaptureEvent,action)
	{
		this.recording = (action == "start");
	}

	// attachment
	removeAttachment(attachment)
	{
		this.task.files.splice(this.task.files.indexOf(attachment), 1);
	}

	attachImage()
	{
		//this.attaching_image = true;
		if(!this.task.files) this.task.files = [];
		let attachment = new TaskCaptureEvent(null, 'image');
		attachment.editing = true;
		this.task.files.push(attachment);
	}
	attachVideo()
	{
		//this.attaching_video = true;
		if(!this.task.files) this.task.files = [];
		let attachment = new TaskCaptureEvent(null, 'video');
		attachment.editing = true;
		this.task.files.push(attachment);
	}
	attachFile()
	{
		if(!this.task.files) this.task.files = [];
		let attachment = new TaskCaptureEvent(null, 'file');
		attachment.editing = true;
		this.task.files.push(attachment);
	}
	// audio 
	attachAudioRecording()
	{
		//this.recording_audio = true;
		if(!this.task.files) this.task.files = [];
		let attachment = new TaskCaptureEvent(null, 'audio_recording');
		attachment.editing = true;
		this.task.files.push(attachment);
	}
	// video 
	attachScreenRecording()
	{
		//this.recording_screen = true;
		//this.capturing_screen = false;
		if(!this.task.files) this.task.files = [];
		let attachment = new TaskCaptureEvent(null, 'screen_recording');
		attachment.editing = true;
		this.task.files.push(attachment);
	}
	attachScreenCapture()
	{
		//this.recording_screen = true;
		//this.capturing_screen = true;
		if(!this.task.files) this.task.files = [];
		let attachment = new TaskCaptureEvent(null, 'screen_snapshot');
		attachment.editing = true;
		this.task.files.push(attachment);
	}
	cancelEditAttachment(attachment:TaskCaptureEvent)
	{
		attachment.editing = false;
		if(attachment.file)
			attachment.file = null;
		if(attachment.preview)
		{
			URL.revokeObjectURL(attachment.preview);
			attachment.preview = null;
		}
	}
	downloadAttachment(attachment:TaskCaptureEvent) {
		if(attachment.uri)
		{
			window.open(Globals.WORKER_URL  + 'serve/' + attachment.uri + "?download=true", "_BLANK");
			return;
			//let domain =  window.location.hostname;
			//domain = (domain == 'localhost')  ? domain + ':' + window.location.port + '/api' : 'api.' + domain ;
			//let href = window.location.protocol + '//' + domain + '/file/'+attachment.uri+'?download=true';
			//window.location.href = href;//"/api/file/"+attachment.uri+"?download=true";
			//window.location.href = this.getDownloadAttachment(attachment);
			// generate a hidden iframe to trigger the download to avoid an unload event triggering as it would by setting href
			// target _blank is another possible but it leaves the tab open
			// a tag with download only works if same domain sadly not for subdomain which files are on
			var iframe = document.createElement("iframe");
			iframe.style.visibility = "hidden";
			iframe.style.width = "0";
			iframe.style.height = "0";
			iframe.style.position = "absolute";
			//iframe.addEventListener("load", (e) => console.log("loaded",))
			iframe.setAttribute("src", this.getDownloadAttachment(attachment));
			document.body.appendChild(iframe);
			setTimeout(() => {
				document.body.removeChild(iframe);
			}, 500);
		}
	}
	getDownloadAttachment(attachment:TaskCaptureEvent) {
		if(attachment.uri)
		{
			//let domain =  window.location.hostname;
			//domain = (domain == 'localhost')  ? domain + ':' + window.location.port + '/api' : 'api.' + domain ;
			//let href = window.location.protocol + '//' + domain + '/file/'+attachment.uri+'?download=true';
			//window.location.href = href;//"/api/file/"+attachment.uri+"?download=true";
			return Globals.WORKER_URL + 'serve/' + attachment.uri + "?download=true";
		}
	}
	editMarker(task:Task, marker:Marker)
	{
		//console.log("edit marker", marker);
		this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER_EDIT, this.task, null));
		//task.marker.editing = true
	}
	cancelEditMarker(task:Task, marker:Marker)
	{
		//console.log("cancel edit marker", marker);

		// reset the markers properties from cache		
		ModelUtils.resetProp(task, task.reference, 'marker');
		// Restore the now broken pointer!
		task.markerVO.marker = task.marker;
		
		//marker.editing = false;
		this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER_EDIT_CANCEL, this.task, null));		
	}
	removeMarker(task:ITask, marker:IMarker = null)
	{
		this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER_CLEAR, this.task, null));	
	}
	cancelAttachImage()
	{
		/*
		this.attaching_image = false;
		this.imageFile = null;
		if(this.imagePreview){
			URL.revokeObjectURL(this.imagePreview);
			this.imagePreview = null;
		}*/
	}

	cancelAttachVideo()
	{
		/*
		this.attaching_video = false;
		this.videoFile = null;
		if(this.videoPreview){
			URL.revokeObjectURL(this.videoPreview);
			this.videoPreview = null;
		}*/
	}
	hasFile(type:string)
	{
		switch (type) {
			case 'image':
				return this.imageFile;
			case 'video':
				return this.videoFile;	
			case 'file':
				return this.file;	
			default:
				return false;
		}
	}
	handleUpload(type:string, files:FileList)
	{
		if(type == "image")
		{
			this.imageFile = files[0];
			this.imagePreview = URL.createObjectURL(this.imageFile);
			let attachment:TaskCaptureEvent = new TaskCaptureEvent(this.imageFile, 'image');
			this.task.files.push(attachment);
		}else if(type == "video"){
			this.videoFile = files[0];
			this.videoPreview = URL.createObjectURL(this.videoFile);
			let attachment:TaskCaptureEvent = new TaskCaptureEvent(this.videoFile, 'video');
			this.task.files.push(attachment);
		}else{
			this.file = files[0];
			let attachment:TaskCaptureEvent = new TaskCaptureEvent(this.file, 'file');
			this.attachments.push(attachment);
		}
	}
	handleUpload2(attachment:TaskCaptureEvent, files:FileList)
	{
		let file:File = files[0];
		attachment.file = file;
		// create a preview if possible
		if(attachment.type == 'image' || attachment.type == 'video')
		{
			attachment.preview = URL.createObjectURL(file);
		}
	}

	cancelAttachFile()
	{
		//this.attaching_file = false;
		this.file = null;
	}
	
	addMarker()
	{
		this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER, this.task));
	}
	addMarker2(event:MouseEvent)
	{
		// TODO we need first just add a dummy marker anywhere
		var x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft; 
  		var y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop; 

		 x = event.pageX;//event.clientX + event.offsetX;
		 y = event.pageY;//event.clientY + event.offsetY;
		 var dx = event.offsetX;
		 var dy = event.offsetY;
		  let el = event.currentTarget as HTMLElement;//nativeElement;
		  while(el){
			 // x += el.offsetLeft;
			 // y += el.offsetTop;
			  el = el.parentElement;
		  }

		//x -= .offsetLeft;
		//y -= gCanvasElement.offsetTop;
		//console.log("x, y", x, y);
		//console.log(event);
		//console.log("page", event.pageX, event.pageY);
		//console.log("offset", event.offsetX, event.offsetY);
		this.onTaskAction.emit(new TaskEvent(this, TaskEvent.MARKER, this.task, JSON.stringify({x,y,dx,dy})));
	}
	// remove the marker
	clearMarker(task:ITask)
	{
		if(task.type == "approval")
		{
			// we are local so just bin it off
			task.marker = null;
		}else{
			// TODO
			// marker service delete the marker
			this.taskMarkupService.delete(task.marker.uuid).pipe().subscribe(response => {
				if(response.status == 200)
				{
					task.marker = null;
				}
			});
		}
	}


	date(item : any = null) : string {
		return dateToAge(new Date((item || this.task).created_at));
	}
	

	title() : string
	{
		let title: string = this.task.type;
		if(this.task.state && this.task.state != 'null') title += " :: " + this.task.state;
		return title;
	}
	isProduction(): boolean
	{
		return this.task.type == "production";
	}
	isAmend(): boolean
	{
		return this.task.type == "amend";
	}
	onSelect(event)
	{
		//console.log(event);
		if(this.task.type != TaskType.AMEND) return;
		let toggle = false;
		let taskClicked;
		if(toggle)
		{
			taskClicked = (this.task == this.selectedTask) ? null : this.task;
		}else {
			taskClicked = this.task;
		}
		this.onTaskSelected.emit(new TaskEvent(this, TaskEvent.SELECT, taskClicked));
	}
	
	private cache_content:string;
	//private cache_buttons:string;
	private cache_attachments:string;
	taskAction(action:string, needsMessage:boolean = false, message:string = null, button:HTMLButtonElement = null, note:string = null){
		//console.log("task action:",action);
		// if a note is present we can show the user a warning/popup first before they go ahead with the action giving them a chance to cancel
		if(note)
		{
			this.ds.openConfirm({
				title:"WARNING: approvals in progress",
				message:note,
				confirmAction: () => {
					//console.log("confirmAction");
					this.taskAction(action, needsMessage, message, button, null);
				},
				dismissAction: () => {
					//console.log("dismissAction");					
				},
				afterClosed: () => {
					//console.log("afterClosed");						
				}
			})
			return;
		}
		
		if(needsMessage){
			this.needsMessage = true;
			if(message) this.message = message;
			this.event = action;
			this.show_attachments = false;
			this.show_markup = false;
			this.show_responses = true;
			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.DISABLE, this.task));
		}else{
			if(button)
			{	
				this.task["disabled"] = true; //flag for disabling main tasks, not this specific task anymore
				
				//button.disabled = true;
			}
			if(action == TaskEvent.EDIT)
			{
				this.cache_content = this.task.content;
				//this.cache_buttons = JSON.stringify(this.task.buttons);
				this.cache_attachments = JSON.stringify(this.task.files);
				//this.task["disabled"] = false; //this is blocking the main task buttons being disabled on the emit 
				/*
				this.task.buttons = [[], [
					{
						"label" : "Cancel",
						"action" : TaskEvent.CANCEL_EDIT,
						"tooltip" : "Cancel changes",
						"type" : "basic",
						"colour" : "warn",
					},
					{
						"label" : "Save",
						"action" : TaskEvent.SAVE_EDIT,
						"tooltip" : "Save changes",
						"type" : "flat",
						"colour" : "primary",
					}]];*/
				this.editable = true;


				/*
				for (let i = 0; i < this.task.files.length; i++) {
					const attachment = this.task.files[i];
					if(attachment.type == 'image')
					{
						this.attaching_image = true;
						this.imagePreview = '/api/file/' + attachment.uri;
					}					
				}*/
				//return;
			}
			if(action == TaskEvent.CANCEL_EDIT)
			{
				// now handled is custom cancel
				/*
				this.task["disabled"] = false;
				this.editable = false;
				console.log("TASK PRE", this.task);
				ModelUtils.resetFromReference(this.task, this.task.reference);
				console.log("TASK POST", this.task);
*/
				/*
				// restore content
				this.task.content = this.cache_content;
				// restore buttons
				this.task.buttons = JSON.parse(this.cache_buttons);
				// restore attachments
				this.task.files = JSON.parse(this.cache_attachments);*/
				//return;
			}
			if(action == TaskEvent.SAVE_EDIT)
			{
				this.task["disabled"] = false;
				this.editable = false;
			}
			/* not used
			if(action == TaskEvent.SUBMIT)
			{
				// check form...
				if(!this.formControlContent.valid)
				{
					// TODO alert
					this.task["disabled"] = false;
					return;
				}
				//this.saving = true;
				this.onTaskAction.emit(new TaskEvent(this, action, this.task, this.message, {attachments:this.attachments}));
			}else{
				if(action != TaskEvent.REQUEST
					&& action != TaskEvent.EDIT
					&& action != TaskEvent.CANCEL_EDIT)
				this.saving = true;
				this.onTaskAction.emit(new TaskEvent(this, action, this.task, this.message));
			}*/

			//pass the action up to the parent creative component
			this.onTaskAction.emit(new TaskEvent(this, action, this.task, this.message));
		}
		this.onTaskDisable.emit(this.task["disabled"]);
	}
	deleteAttachment(file)
	{
		this.task.files.splice(this.task.files.indexOf(file), 1);
	}
	/**
	 * Amend functions
	 */

	// approver

	 edit()
	 {
		//edit
		/*
		this.needsMessage = true;
		this.message = this.task.content;*/
		this.editable = true;
		//this.event = TaskEvent.UPDATE; //is this needed?
	 }
	 amendRecall()
	 {
		 if(!this.needsMessage)
		 {
			 //this.event = TaskEvent.RECALL_REQUEST; //is this needed?
			 this.needsMessage = true;
			 this.message = '';
		 }else{
			 //this.onTaskAction.emit(new TaskEvent(this, TaskEvent.RECALL_REQUEST, this.task));
			 this.message = '';
			 this.needsMessage = false;
		 }
	 }


	 // production

	// validator - qualify

	// validator - confirming



	cancel()
	{
		if(this.task.state == "local")
		{
			//console.log("task cancel()");
			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.CANCEL_NEW, this.task));
			return;
		}else if(this.editable) {
			//this.task["disabled"] = false;
			this.editable = false;
			
			// TODO - move this logic into creative?
			ModelUtils.resetFromReference(this.task, this.task.reference, {exclude:['markerVO']});
			// update restored reference		
			if(this.task.markerVO)	this.task.markerVO.marker = this.task.marker;

			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.CANCEL_EDIT, this.task))
		}
		return;
		this.editable = false;

		this.task["disabled"] = false;
		this.needsMessage = false;
		this.message = '';
		this.event = null;

		// clear any markers if local (from approval task)
		if(this.task.type == "approval")
		{
			this.task.marker = null;
		}

		// stop and screen or audio recoding and bin off any attachments
		this.recording_audio = false;
		this.capturing_screen = false;
		this.recording_screen = false;

		// clear the attachments - might need to do more here
		this.attachments = [];

		//this.attaching = false;
	}
	canSave()
	{
		// we are editing, based on the changes can we save....
		if(this.task.state == "local")
		{
			if(this.recording) return false;
			return this.formControlContent.valid;
		}else {
			let changedProps = ModelUtils.isDirty(this.task, this.task.reference,
				{exclude:['buttons', 'disabled', 'markerVO', 'marker.asset_name', 'marker.editing', 'marker.drag', 'marker.offsetX', 'marker.offsetY']});
			if(changedProps)
			{
				let changes = ModelUtils.buildFromChanges<Task>(this.task, this.task.reference, changedProps);
				//console.log("changes", changes)
				return true;
			}else{
				return !this.recording;//false;
			}
		}

	}
	save()
	{
		this.saving = true;
		this.task["disabled"] = true;
		if(this.task.state == "local")
		{
			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.SAVE, this.task));
		}else {
			this.editable = false;
			this.onTaskAction.emit(new TaskEvent(this, TaskEvent.SAVE_EDIT, this.task));
		}
	}
	cancelMessage()
	{
		this.needsMessage = false;
		this.message = '';
		this.event = null;
		this.onTaskAction.emit(new TaskEvent(this, TaskEvent.ENABLE, this.task));
	}
	submitMessage(){
		if(this.event)
		{
			if(this.editable)
			{
				this.editable = false;
			}
			this.onTaskAction.emit(new TaskEvent(this, this.event, this.task, this.message));
		}
		this.event = null; 	// maybe only clear on success
		if(this.needsMessage)
		{
			this.needsMessage = false;
			this.show_responses = false;//to check
			this.message = '';
		}
	}




	
	// TODO better and unified single access system
	hasPermission(permission:string, fuzzy:boolean = false):boolean
	{
		//console.log("has perms", this.user);
		if(!this.user.permissions) return false;
		let perm = Permission.LOOKUP[permission];
		return (this.user.permissions & perm) == perm;
		/*
		for (let i = 0; i < this.user.permissions.length; i++) {
			const perm = this.user.permissions[i];
			if(!fuzzy)
			{
				if(perm.name == permission) return true;
			}else{
				if(perm.name.indexOf(permission) != -1) return true;
			}			
		}*/
		return false;
	}
	getActionString(action:any)
	{
		let message:string = action.action + " by " + action.user['name'];
		if(action.message) message = message + " \"" + action.message + "\""; // TODO proper component using <q></q> tags maybe
		if(action.creative_version != undefined) message = message + " on v" + action.creative_version;
		message += " " + this.date(action);
		return message;
	}
	getAssetName(marker:IMarker)
	{
		const assetVO = this.assets.find(assetVO => assetVO.asset.uuid == marker.asset_uuid);
		return assetVO ? assetVO.asset.name : "asset not found";
	}
	canAttach(type:string)
	{
		switch (type) {
			case 'image':
				return !this.task.files?.find(attachment => attachment.type == 'image');
				break;
			case 'video':
				return !this.task.files?.find(attachment => attachment.type == 'video');
				break;
			case 'screen_recording':
				return !this.task.files?.find(attachment => attachment.type == 'screen_recording')
				break;
			case 'audio_recording':
				return !this.task.files?.find(attachment => attachment.type == 'audio_recording')			
				break;
			case 'screen_capture':
				return !this.task.files?.find(attachment => attachment.type == 'screen_capture')	
				break;
			case 'file':
				return !this.task.files?.find(attachment => attachment.type == 'file');
				break;
			default:
				break;
		}
		return false;
	}
	attachmentType(attachment:TaskCaptureEvent):string
	{
		let type = attachment.type.split('_').join(' ');
		type = type.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
		return type;
	}
	isActive(task:Task):boolean
	{
		return task.type == TaskType.AMEND && !task.actioned;
	}
	/**
	 * Can the current user edit a task
	 * Only if you are the owner and the task has not yet been submitted
	 * @param task 
	 */
	/*
	canEdit(task:ITask):boolean
	{
		// false if not mine
		if(task.user_uuid != this.user.uuid) return false;
		// false if not local or not yet submitted (todo, unactioned)
		if(task.state != TaskState.AMEND_LOCAL)
		{
			if(task.state != TaskState.AMEND_TODO) return false;
			if(task.flag != 0 && task.flag != 4) return false;
		}
		return true;
	}*/
	isBlue(task:ITask):boolean
	{
		return task["colour"] == "blue";
		/*
		if(task.role == "moderator")
		{
			if(task.state == "todo") return true;
		}
		if(task.role == "production")
		{
			if(task.state == "done" || task.state == "declined") return true;
		}
		if(task.role == "approver" && task.user_uuid == this.user.uuid)
		{
			if(task.state == "new") return true;
		}
		return false;
		*/
	}
	isGreen(task:ITask):boolean
	{
		return task["colour"] == "green";
		/*
		if(task.role == "moderator")
		{
			if(task.state == "done" || task.state == "declined") return true;
		}
		if(task.role == "approver" && task.user_uuid == this.user.uuid)
		{
			if(task.state == "done") return true;
		}

		return false;
		*/
	}
	isRed(task:ITask):boolean
	{
		return task["colour"] == "red";
		/*
		if(task.role == "approver")
		{
			if(task.state == "local") return true;
		}
		console.log("isred", this.user);
		
		if(task.role == "production")
		{
			if(task.state == "todo") return true;
		}
		return (this.editable && this.canSave()) || false;	// this is gross - ben is sad :'(
		*/
	}
	getColour(task:ITask):string
	{
		return (task.open && task["colour"]) ?? '';
		/*
		if(this.isRed(task))
			return 'red';
		else if(this.isBlue(task))
			return 'blue';
		else if(this.isGreen(task))
			return 'green';
		return '';
		*/
	}
}
