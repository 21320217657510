<!--
    <task>
        <clickbg></clickbg>
        <header></header>
        <copy></copy>
        <task_files></task_files>
        <buttons></buttons>
        <message>
            <attachment_crap></attachment_crap>
        <message>
        <log></log>
        <markup></markup>
    </task>
-->
<div class="task"
	[class]= "getColour(task)"
    [ngClass]="{'disabled':false && task['disabled'], 'active':isActive(task), 'selected':selectedTask == task, 'shadow-underline':selectedTask == task, 'open':task.open, 'closed':!task.open, 'not-amend':task.type != 'amend'}"
    (click)="onSelect($event)" 
    >
	<!-- MAIN TASKS - just buttons -->
	<ng-container  *ngIf="task.buttons && task.type != 'amend'">
		<!-- buttons only (duplicated from amend tasks below)-->
		<ng-container *ngIf="task.buttons[0]">
			<div style="display: flex;">
				<ng-container *ngFor="let button of task.buttons[0]" [ngSwitch]="button.type">
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'flat'" class="button-only" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-flat-button [color]="button.colour">{{button.label}}</button></span>
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'stroked'" class="button-only" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" style="background-color: white;" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-stroked-button [color]="button.colour">{{button.label}}</button></span>
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'basic'" class="button-only"  [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-button [color]="button.colour">{{button.label}}</button></span>
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'icon'" class="button-only" mat-mini-fab  #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" [color]="button.colour"><mat-icon class="icon-small" >{{button.icon}}</mat-icon></button></span>
				</ng-container> 
			</div>
		</ng-container>
		<ng-container *ngIf="task.buttons[1]">
			<div style="display: flex;">
				<ng-container *ngFor="let button of task.buttons[1]" [ngSwitch]="button.type">
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'flat'" class="button-only" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-flat-button [color]="button.colour">{{button.label}}</button></span>
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'stroked'" class="button-only" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" style="background-color: white;" #butt [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-stroked-button [color]="button.colour">{{button.label}}</button></span>
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'basic'" class="button-only" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-button [color]="button.colour">{{button.label}}</button></span>
					<span [matTooltip]="button.tooltip"><button *ngSwitchCase="'icon'" class="button-only" mat-mini-fab  #butt [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" [color]="button.colour"><mat-icon class="icon-small" >{{button.icon}}</mat-icon></button></span>
				</ng-container>  
			</div>
		</ng-container>
	</ng-container>

	<!-- AMEND TASKS ONLY - full task cards -->
	<ng-container  *ngIf="task.type == 'amend'">
		<!-- ngClass --- //'blue':isBlue(task), 'green':isGreen(task), 'red':isRed(task),-->
		<div class="task-bar">
		</div>
		<div class="task-main">
			<div class="header" *ngIf="task.type == 'amend'">
				<!--<div (click)="onSelect($event)" class="clickCatch"></div>-->
				<div class="header-left">
					<span class="icon" *ngIf="false">
						<mat-icon *ngIf="task.type == 'production'">build</mat-icon>
						<mat-icon *ngIf="task.type == 'approval'">check_circle</mat-icon>
						<mat-icon *ngIf="false">error</mat-icon>
						<mat-icon *ngIf="task.type == 'amend'">warning_amber</mat-icon>
					</span>
					<span class="state" [ngClass]="[task.open ? '' : 'closed', task.label.colour]" *ngIf="task.state" [matTooltip]="task.role+':'+task.state+':'+(task.actioned ? '☑' : '☐')+(task.open ? '•' : '-') + (' flag:'+task.flag.toString())">{{task.label.label}}</span>
				</div>
				<div class="header-right" *ngIf="task.state != 'local'">
					<span class="version">v{{task.creative_version}}</span>
					<span class="author">{{task.author}}</span>
					<span class="date">{{task.created_at | dateAgo}}</span>
					<span></span>
					<!--<mat-icon class="small" style="position:relative; pointer-events:all; cursor: pointer;" (click)="log = !log" [matTooltip]="'toggle log'">article</mat-icon>-->
					<!--

						<mat-icon *ngIf="collapsed" class="small" style="position:relative; pointer-events:all; cursor: pointer;" (click)="collapsed = !collapsed" [matTooltip]="'expand amend'">expand_more</mat-icon>
						<mat-icon *ngIf="!collapsed" class="small" style="transform:rotate(180deg);position:relative; pointer-events:all; cursor: pointer;" (click)="collapsed = !collapsed" [matTooltip]="'collapse amend'">expand_more</mat-icon>
					-->
				</div>       

			</div>
			<div *ngIf="editable">
				<mat-form-field class="full-width requestTextArea" [style.fontSize]="14">
					<mat-label>Amend description</mat-label>
					<textarea #textarea id="content" [formControl]="formControlContent" class="nopad" [(ngModel)]="task.content" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
					<mat-error *ngIf="formControlContent.hasError('required')">Description is <strong>required</strong></mat-error>
					<mat-error *ngIf="formControlContent.hasError('minlength')">Description is not long enough</mat-error>
				</mat-form-field>
			</div>
			<div class="copy" *ngIf="false && task.state == 'local'">
				<!--
					<mat-form-field class="full-width">
						<input matInput [(ngModel)]="task.title" maxlength="256" placeholder="New amend title">
				</mat-form-field>
				-->
				<!--
					<div>
						<button class="basic-small" #butt click-stop-propagation [matTooltip]="'cancel'" [disabled]="false"
						(click)="1" mat-button color="warn">Cancel</button>
						<button class="basic-small" #butt click-stop-propagation [matTooltip]="'save'" [disabled]="false"
						(click)="1" mat-button color="primary">Save</button>
					</div>
				-->
			</div>
			<div class="copy row" *ngIf="!editable && task.state != 'local' && task.content && task.type =='amend'">
				<div (click)="onSelect()">
					<!--<span class="date">{{date()}}</span>-->
					<!--<span class="title">{{title()}}</span>-->
					<!--<span class="author">{{task.author}}</span>-->
				</div>
				<div class="attach_icon">
				<!-- <mat-icon>chat</mat-icon> -->
				</div>
				<div class="attach_item">
					<span class="body" [class.collapsed]="false && collapsed" *ngIf="task.content" >{{task.content}}</span>
				</div>
			</div>

			<div class="buttons" [class.transparent]="task.type == 'amend'">
				<!-- info icons -->
				<div class="info_icons" *ngIf="task.type == 'amend' && !editable">
					<!-- toggle chat responses -->
					<div *ngIf="task.responses?.length" [class.content-active]="show_responses" (click)="toggleContent('responses')">
						<mat-icon class="icon-small">chat_bubble_outline</mat-icon>
					</div>
					<!-- toggle markup -->
					<div *ngIf="task.marker" [class.content-active]="show_markup" (click)="toggleContent('markup')">
						<ng-container [ngSwitch]="task.marker.type">
							<ng-container *ngSwitchCase="'target'">
								<mat-icon class="icon-small" *ngIf="task.marker.metadata?.in == null">place</mat-icon>
								<mat-icon class="icon-small" *ngIf="task.marker.metadata?.in != null">schedule</mat-icon>
							</ng-container>
							<ng-container *ngSwitchCase="'hidden'">
								<mat-icon class="icon-small" *ngIf="task.marker.metadata?.in == null">place</mat-icon>
								<mat-icon class="icon-small" *ngIf="task.marker.metadata?.in != null">schedule</mat-icon>
							</ng-container>
							<ng-container *ngSwitchCase="'general'">
								<mat-icon class="icon-small">comment</mat-icon>
							</ng-container>
							<ng-container *ngSwitchCase="'text_change'">
								<mat-icon class="icon-small">border_color</mat-icon>
							</ng-container>
							<ng-container *ngSwitchCase="'text_delete'">
								<mat-icon class="icon-small">strikethrough_s</mat-icon>
							</ng-container>
							<ng-container *ngSwitchDefault>                        
							</ng-container>
						</ng-container>
					</div>
					<!-- toggle attachments -->
					<div *ngIf="task.files?.length" [matTooltip]="show_attachments ? 'hide attachments' : 'show attachments' " [class.content-active]="show_attachments" (click)="toggleContent('attachments')">
						<mat-icon class="icon-small">attach_file</mat-icon>
						<span style="margin-left: -6px;">{{task.files.length}}</span>
					</div>
				</div>
				<div *ngIf="task.type == 'amend' && editable && (task.buttons || task.state == 'local')">
					<button *ngIf="false" mat-icon-button [disabled]="true" click-stop-propagation click-stop-propagation>
						<mat-icon>comment</mat-icon>
					</button>
					<span [matTooltip]="task.marker ? 'disabled - already have a marker' : 'drag a marker onto an asset'">
						<button [disabled]="task.marker" mat-icon-button class="icon-button-small" (mousedown)="addMarker2($event)" click-stop-propagation click-stop-propagation>
							<mat-icon>place</mat-icon>
						</button>
					</span>
					<button mat-icon-button class="icon-button-small" [matMenuTriggerFor]="menu" matTooltip="Attach a recording or file">
						<mat-icon>attach_file</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item [disabled]="!canAttach('image')" (click)="attachImage()">
							<mat-icon>image</mat-icon>
							<span>Attach image</span>
						</button>
						<button mat-menu-item [disabled]="!canAttach('video')" (click)="attachVideo()">
							<mat-icon>videocam</mat-icon>
							<span>Attach video</span>
						</button>
						<button mat-menu-item [disabled]="!canAttach('file')" (click)="attachFile()">
							<mat-icon>attach_file</mat-icon>
							<span>Attach file</span>
						</button>
						<mat-divider></mat-divider>
						<button mat-menu-item [disabled]="!canAttach('audio_recording')" (click)="attachAudioRecording()">
							<mat-icon>mic</mat-icon>
							<span>Record Audio</span>
						</button>
						<button mat-menu-item [disabled]="!canAttach('screen_recording')" (click)="attachScreenRecording()">
							<mat-icon>ondemand_video</mat-icon>
							<span>Record Screen</span>
						</button>
						<button mat-menu-item [disabled]="!canAttach('screen_capture')" (click)="attachScreenCapture()">
							<mat-icon>screenshot_monitor</mat-icon>
							<span>Capture Screenshot</span>
						</button>

					</mat-menu>
					<!--

						<button click-stop-propagation click-stop-propagation mat-button mat-icon-button matTooltip="Capture screen" (click)="recordScreen()">
						<mat-icon>screenshot_monitor</mat-icon>
					</button>                
					<button click-stop-propagation mat-icon-button matTooltip="Record microphone" (click)="recordMic()">
						<mat-icon>mic</mat-icon>
					</button>
					<button click-stop-propagation click-stop-propagation mat-button mat-icon-button matTooltip="Attach a file" (click)="attach()">
						<mat-icon>attach_file</mat-icon>
					</button>
					-->
				</div>
				<div *ngIf="!needsMessage && (task.buttons || task.state == 'local')" class="buttonGroup">
					<!-- back end generated buttons-->
					<mat-progress-bar style="display: flex;flex-grow: 1;width: unset;" *ngIf="saving" mode="indeterminate"></mat-progress-bar>
					<div *ngIf="task.buttons && !editable" style="display: flex;">
						<ng-container *ngIf="task.buttons[0]">
							<div style="display: flex; flex-grow: 1; gap: 0.5em;">
								<ng-container *ngFor="let button of task.buttons[0]" [ngSwitch]="button.type">
									<button *ngSwitchCase="'flat'" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-flat-button [color]="button.colour">{{button.label}}<mat-icon>arrow_drop_down</mat-icon></button>
									<button *ngSwitchCase="'stroked'" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" style="background-color: white;" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-stroked-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'basic'" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'icon'" mat-mini-fab  #butt click-stop-propagation [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" [color]="button.colour"><mat-icon class="icon-small" >{{button.icon}}</mat-icon></button>
								</ng-container> 
							</div>
						</ng-container>
						<ng-container *ngIf="task.buttons[1]">
							<div style="display: flex; gap: 0.5em;">
								<ng-container *ngFor="let button of task.buttons[1]" [ngSwitch]="button.type">
									<ng-container *ngSwitchCase="'flat'">
										<div *ngIf="button.children" class="split-button">
											<button [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-flat-button [color]="button.colour">{{button.label}}</button>
											<button [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" mat-flat-button [disabled]="buttonsDisabled || button.disabled" [color]="button.colour">
												<mat-icon [matMenuTriggerFor]="taskButtonMenu">arrow_drop_down</mat-icon>
											</button>
											<mat-menu #taskButtonMenu="matMenu">
												<button mat-menu-item *ngFor="let child of button.children" [matTooltip]="child.tooltip" (click)="taskAction(child.action, child.needsMessage, child.message, butt, child.message)">
												  <span>{{child.label}}</span>
												</button>
											</mat-menu>
										</div>
										<button *ngIf="!button.children" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-flat-button [color]="button.colour">{{button.label}}</button>
									</ng-container>
									<button *ngSwitchCase="'stroked'" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" style="background-color: white;" #butt [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-stroked-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'basic'" [ngClass]="task.type == 'amend' ? 'basic-small' : 'button-medium'" #butt [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" mat-button [color]="button.colour">{{button.label}}</button>
									<button *ngSwitchCase="'icon'" mat-mini-fab  #butt [matTooltip]="button.tooltip" [disabled]="buttonsDisabled || button.disabled" (click)="taskAction(button.action, button.needsMessage, button.message, butt, button.message)" [color]="button.colour"><mat-icon class="icon-small" >{{button.icon}}</mat-icon></button>
								</ng-container>  
							</div>
						</ng-container>            
					</div>
					<ng-container *ngIf="editable">
						<div style="display: flex;justify-content: flex-end;">
							<button class="basic-small" #butt [matTooltip]="'Cancel'" [disabled]="false" (click)="cancel($event)" mat-button color="warn">Cancel</button>
							<button class="basic-small" #butt [matTooltip]="'Save'" [disabled]="!canSave()" (click)="save($event)" mat-flat-button color="primary">Save!</button>
						</div>
					</ng-container>
				</div>
				<!--<button click-stop-propagation *ngIf="needsMessage" (click)="cancel()" mat-stroked-button>cancel</button>-->
			</div>
			<div *ngIf="(editable || show_markup) && task.marker" class="panel">
				<div>
					<div *ngIf="(task.buttons || task.state == 'local') && editable">
						<button *ngIf="task.marker.uuid && !task.markerVO.editable" matTooltip="Edit this marker" (click)="editMarker(task, task.marker)" mat-stroked-button class="basic-small" color="accent">
							Edit
						</button>
						<button *ngIf="task.marker.uuid && task.markerVO.editable" matTooltip="Cancel editing this marker" (click)="cancelEditMarker(task, task.marker)" mat-stroked-button class="basic-small" color="accent">
							Cancel edit
						</button>
						<button mat-stroked-button  matTooltip="Remove this marker"  class="basic-small" color="warn" (click)="removeMarker(task, task.marker)">
							Remove
						</button>
					</div>

					<!--<span>{{assets.find((asset) => asset.uuid == task.marker.asset_uuid)}}</span>-->
					<!--

					<div style="display: flex; align-items: center;">
						<p style="flex-grow:1;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						display: inline-block;
						width: 0px;"
						>{{task.marker['asset_name'] || ''}}</p>
						<button *ngIf="needsMessage || task.type == 'approval'" click-stop-propagation [matTooltip]="'Clear markup'" (click)="clearMarker(task)" mat-icon-button><mat-icon>clear</mat-icon></button>
						</div>
					-->
					<ng-container [ngSwitch]="task.marker.type">
						<ng-container *ngSwitchCase="'target'">
							<!--

								<div class="attach_icon">
									<mat-icon>location_searching</mat-icon>
								</div>
							-->
							<div class="attach_item">
								<div>
									attached to: {{getAssetName(task.marker)}}
								</div>
								<div *ngIf="false">
									coord:{{task.marker.x0.toFixed(0)}}, {{task.marker.y0.toFixed(0)}}
								</div>
								<div *ngIf="task.marker.metadata?.page !== null && task.marker.metadata.page != -1 && (task.marker.metadata.page/task.marker.metadata.page) == 1">
									page: {{task.marker.metadata.page + 1}}
								</div>
							</div>
							<div *ngIf="task.marker.metadata" class="marker-inOut">
								<!-- NEW and EDIT marker + time -->
								<div *ngIf="task.marker.metadata.in != undefined">
									<button *ngIf="task.markerVO.editable" mat-icon-button matTooltip="click to set in point to current time" (click)="setMarkerIn(task, task.marker)">
										<mat-icon>start</mat-icon>
									</button>
									<span>in: {{task.marker.metadata.in | secondsToTime}}</span>
								</div>
								<div *ngIf="task.marker.metadata.out != undefined">
									<span>out: {{task.marker.metadata.out | secondsToTime}}</span>
									<button *ngIf="task.markerVO.editable" mat-icon-button matTooltip="click to set out point to current time" (click)="setMarkerOut(task, task.marker)">
										<mat-icon style="transform:rotate(180deg);">start</mat-icon>
									</button>
								</div>
							</div>
							<pre *ngIf="false">
								{{task.marker.metadata | json}}
							</pre>
						</ng-container>
						<ng-container *ngSwitchCase="'general'">
							General marker...
						</ng-container>
						<ng-container *ngSwitchCase="'hidden'">
							<!-- TODO can we remove the duplication by using metadata to drive not type?-->
							<div class="attach_item">
								<span>
									attached to: {{getAssetName(task.marker)}}
								</span>                   
							</div>
							<div *ngIf="task.marker.metadata" class="marker-inOut">
								<!-- TIME ONLY marker -->
								<div *ngIf="task.marker.metadata.in != undefined">
									<button mat-icon-button [disabled]="!markerVO?.editable" [matTooltip]="markerVO?.editable ? 'click to set in point at current time' : ''" (click)="setMarkerIn(task, task.marker)">
										<mat-icon>start</mat-icon>
									</button>
									<span>{{task.marker.metadata.in | secondsToTime}}</span>
								</div>
								<div *ngIf="task.marker.metadata.out != undefined">
									<span >{{task.marker.metadata.out | secondsToTime}}</span>
									<button mat-icon-button [disabled]="!markerVO?.editable" [matTooltip]="markerVO?.editable ? 'click to set out point at current time' : ''" (click)="setMarkerOut(task, task.marker)">
										<mat-icon style="transform:rotate(180deg);">start</mat-icon>
									</button>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div>
								<span>type: {{task.marker.type}}</span>
								<span [matTooltip]="task.marker.editing ? 'Change marker type' : 'Can only change type when editing'">
									<button [disabled]="!task.marker.editing" mat-icon-button class="icon-button-small" [matMenuTriggerFor]="menuMarkerType">
										<mat-icon *ngIf="task.marker.type == 'text_change'" class="icon-small">border_color</mat-icon>
										<mat-icon *ngIf="task.marker.type == 'text_delete'" class="icon-small">strikethrough_s</mat-icon>
									</button>
								</span>

								<mat-menu #menuMarkerType="matMenu">
									<button mat-menu-item [disabled]="task.marker.type == 'text_change'" (click)="task.marker.type = 'text_change'">
										<mat-icon>border_color</mat-icon>
										<span>Text change</span>
									</button>
									<button mat-menu-item [disabled]="task.marker.type == 'text_delete'" (click)="task.marker.type = 'text_delete'">
										<mat-icon>strikethrough_s</mat-icon>
										<span>Text delete</span>
									</button>
								</mat-menu>
							</div>

							<!--

								<mat-form-field appearance="fill">
									<mat-label>Marker type</mat-label>
									<mat-select [disabled]="task.id > 0 && task.editing" [(value)]="task.marker.type">
										<mat-option *ngFor="let type of ['text_change', 'text_delete']" [value]="type">
											{{type}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							-->
							<div>
								<span>
									attached to: {{getAssetName(task.marker)}}
								</span>                   
							</div>
							<div>
								<div *ngIf="task.marker.metadata?.page !== null && task.marker.metadata.page != -1 && (task.marker.metadata.page/task.marker.metadata.page) == 1">
									page: {{task.marker.metadata.page + 1}}
								</div>                  
							</div>
							<div>
								<span>
									selection: {{task.marker.metadata.text}}
								</span>                   
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'text_delete' && false">
							<!--

								<mat-form-field appearance="fill" *ngIf="needsMessage || task.type == 'approval'">
									<mat-label>Marker type</mat-label>
									<mat-select [(value)]="task.marker.type">
										<mat-option *ngFor="let type of ['text_change', 'text_delete']" [value]="type">
											{{type}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							-->
							<div class="attach_icon">
								<mat-icon>strikethrough_s</mat-icon>
							</div>
							<div class="attach_item">
								<span>
									p{{task.marker.metadata.page + 1}}:
									<br>
									{{task.marker.metadata.text}}
								</span>                   
							</div>
						</ng-container>
					</ng-container>
					<div *ngIf="false"><!-- legacy stuff-->
						<mat-form-field appearance="fill">
							<mat-label>Marker type</mat-label>
							<mat-select [(value)]="task.marker.type">
							<mat-option *ngFor="let type of ['target', 'box', 'circle']" [value]="type">
								{{type}}
							</mat-option>
							</mat-select>
						</mat-form-field>
						<br/>
						<mat-form-field class="half-width" appearance="fill">
							<label for="name">X</label>
							<input type="email" matInput [(ngModel)]="task.marker.x0">
						</mat-form-field>
						<mat-form-field class="half-width" appearance="fill">
							<label for="name">Y</label>
							<input type="email" matInput [(ngModel)]="task.marker.y0">
						</mat-form-field>
					</div>    
				</div>
			</div>
			<div *ngIf="(show_attachments || editable) && task.files?.length" >
				<!-- Create attachments section -->
				<fieldset >
					<div *ngFor="let attachment of task.files" class="attachment">
						<ng-container *ngIf="attachment.flag != 0">
							<!-- buttons -->
							<div *ngIf="editable" style="margin-bottom:0.5em">
								<button mat-stroked-button  matTooltip="Remove this attachment"  class="basic-small" color="warn" (click)="removeAttachment(attachment)">
									Remove
									<!--<mat-icon>delete</mat-icon>-->
								</button>
							</div>
							<div class="attachment-processing">
								<span>{{attachmentType(attachment)}}</span>
								<br>
								<span>Attachment processing...</span> <!-- {{attachment.flag}} -->
							</div>
						</ng-container>
						<ng-container *ngIf="attachment.flag == 0">
							<!-- editors-->
							<div *ngIf="editable">
								<!-- buttons -->
								<div style="margin-bottom:0.5em">
									<button *ngIf="attachment.uri && !attachment.editing" matTooltip="Edit this attachment" (click)="attachment.editing = true" mat-stroked-button class="basic-small" color="accent">
										Edit
									</button>
									<button *ngIf="attachment.uri && attachment.editing" matTooltip="Cancel editing this attachment" (click)="cancelEditAttachment(attachment)" mat-stroked-button class="basic-small" color="accent">
										Cancel edit
									</button>
									<button mat-stroked-button  matTooltip="Remove this attachment"  class="basic-small" color="warn" (click)="removeAttachment(attachment)">
										Remove
										<!--<mat-icon>delete</mat-icon>-->
									</button>
								</div>
								<div *ngIf="attachment.editing">
									<!-- files -->
									<ng-container *ngIf="attachment.type == 'image' || attachment.type == 'video' || attachment.type == 'file'">
										<app-file-uploader [message]="attachment.uri ? 'replace file' : 'upload file'" (onFilesDragged)="handleUpload2(attachment, $event)" [parent]="this" [multiple]="false" ></app-file-uploader>
									</ng-container>
									<!-- audio record -->
									<div *ngIf="attachment.type == 'audio_recording'">
										<app-record-audio-widget (onAudioData)="onAudioData(attachment, $event)" (onRecordStartStop)="onRecordStartStop(attachment, $event)"></app-record-audio-widget>
									</div>
									<!-- screen record -->
									<div *ngIf="attachment.type == 'screen_recording' || attachment.type == 'screen_snapshot'">
										<app-record-screen-widget [captureMode]="attachment.type == 'screen_snapshot'" (onData)="onVideoData(attachment, $event)" (onRecordStartStop)="onRecordStartStop(attachment, $event)"></app-record-screen-widget>
									</div>
								</div>
							</div>
							<!-- preview -->
							<div *ngIf="editable || show_attachments">
								<span>{{attachment.type}}</span>
								<div *ngIf="attachment.type == 'image'" style="text-align:center;">   <!-- && (attachment.preview || -->
									<!-- image preview -->
                                    <img *ngIf="attachment.preview" title="new image preview" style="max-width:100%;" [src]="attachment.preview | safe:'url'" />
                                    <!-- image saved file -->
									<img *ngIf="attachment.uri" title="saved image" style="max-width:100%;" [src]="getFilePath(attachment.uri) | safe:'url'" />
								</div>
                                <div *ngIf="attachment.type == 'screen_snapshot' && attachment.uri" style="text-align:center;">   <!-- && (attachment.preview || -->
									<!-- screen_snapshot saved file -->
                                    <img style="max-width:100%;" [src]="getFilePath(attachment.uri) | safe:'url'" />
								</div>
								<div *ngIf="attachment.type == 'video'" style="text-align:center;"> <!-- ) && (attachment.preview || -->
                                    <!-- video preview -->
									<video *ngIf="attachment.preview" title="new video preview" controls style="max-width:100%;" [src]="attachment.preview  | safe:'url'"></video>
                                    <!-- video saved file -->
									<video *ngIf="attachment.uri" title="saved video" controls style="max-width:100%;" [src]="getFilePath(attachment.uri) | safe:'url'"></video>
								</div>
                                <div *ngIf="attachment.type == 'screen_recording' && attachment.uri" style="text-align:center;"> <!-- ) && (attachment.preview || -->
									<!-- screen_recording saved file -->
                                    <video controls style="max-width:100%;" [src]="getFilePath(attachment.uri) | safe:'url'"></video>
								</div>
								<div *ngIf="attachment.type == 'file'" style="text-align:center;">
                                    <!-- new file -->
									<div *ngIf="attachment.file">
                                        <span >{{attachment.file.name}} :: {{attachment.file.size | formatBytes}}</span>
                                    </div>
                                    <!-- saved file -->
                                    <div *ngIf="attachment.uri">
                                        <span >{{attachment.uri | fileName}}</span>
                                        <button mat-icon-button (click)="downloadAttachment(attachment)">
                                            <mat-icon>download</mat-icon>
                                        </button>
										<!-- This will only work on same origin
										<a [href]="getDownloadAttachment(attachment)" download>
											<div class="attachment-download">
												<span >download {{attachment.uri | fileName}}</span>
												<mat-icon>download</mat-icon>
											</div>
										</a>
										-->
                                    </div>
								</div>
								<div *ngIf="attachment.type == 'audio_recording' && (attachment.preview || attachment.uri)" style="text-align:center;">
									<!-- audio preview -->
                                    <audio *ngIf="attachment.preview" controls [src]="attachment.preview | safe:'url'"></audio>
									<!-- audio saved file -->
									<audio *ngIf="attachment.uri" controls [src]="getFilePath(attachment.uri) | safe:'url'"></audio>
								</div>
							</div>         
						</ng-container>
							
					</div>
					<!--

						<fieldset *ngIf="attaching_image">
							<legend></legend>
							<button mat-stroked-button class="basic-small" color="warn" (click)="cancelAttachImage()">
								Remove
							</button>
							<app-file-uploader [message]="hasFile('image') ? 'replace file' : 'upload file'" (onFilesDragged)="handleUpload('image', $event)" [parent]="this" [multiple]="false" ></app-file-uploader>
							<div *ngIf="imagePreview" style="text-align:center;">
								<img style="max-width:100%;" [src]="imagePreview | safe:'url'" />
							</div>
						</fieldset>
					-->
					<!--

						<fieldset *ngIf="attaching_video">
							<legend></legend>
							<button mat-stroked-button class="basic-small" color="warn" (click)="cancelAttachVideo()">Remove</button>
							<app-file-uploader [message]="hasFile('video') ? 'replace file' : 'upload file'" (onFilesDragged)="handleUpload('video', $event)" [parent]="this" [multiple]="false" ></app-file-uploader>
							<div *ngIf="videoPreview" style="text-align:center;">
								<video controls style="max-width:100%;" [src]="videoPreview | safe:'url'"></video>
							</div>
						</fieldset>
					-->
					<!--

						<fieldset *ngIf="attaching_file">
							<legend></legend>
							<button mat-stroked-button class="basic-small" color="warn" (click)="cancelAttachFile()">Remove</button>
							<app-file-uploader [message]="hasFile('file') ? 'replace file' : 'upload file'" (onFilesDragged)="handleUpload('file', $event)" [parent]="this" [multiple]="false" ></app-file-uploader>
							<div *ngIf="file" style="text-align:center;">
								<soan>{{file.name}} :: {{file.size | formatBytes}}</soan>
							</div>
						</fieldset>
					-->
					<!--

						<fieldset *ngIf="recording_audio">
							<legend>Recording Audio <mat-icon (click)="recording_audio=false">close</mat-icon></legend>
							<button mat-stroked-button class="basic-small" color="warn" (click)="recording_audio=false">Remove</button>
							<app-record-audio-widget (onAudioData)="onAudioData($event)"></app-record-audio-widget>
						</fieldset>
					-->
					<fieldset *ngIf=" false && recording_screen">
						<!--<legend>Recording Screen <mat-icon (click)="recording_screen=false">close</mat-icon></legend>-->
						<button mat-stroked-button class="basic-small" color="warn" (click)="recording_screen=false">Remove</button>
						<app-record-screen-widget [captureMode]="capturing_screen" (onData)="onVideoData($event)"></app-record-screen-widget>
						<!--
			
							<button click-stop-propagation mat-icon-button (click)="takeScreenshot()" matTooltip="Take Screenshot">
								<mat-icon>screenshot_monitor</mat-icon>
							</button>
							<div *ngIf="preview">
								<img [src]="preview | safe:'url'">
							</div>
							<canvas #canvas id="canvas"></canvas>
							<video #video id="video" autoplay></video>
						-->
					</fieldset>
				</fieldset>
			</div>
			<!-- chat/responses -->
			<div class="panel responses clearfix" *ngIf="show_responses || needsMessage"><!--checkShowResponses()-->
				<div style="max-height:300px; overflow-y:auto;">
					<div class="response" *ngFor="let response of task.responses; last as isLast" [class.mine]="response.user_uuid == user.uuid" >
						<span class="author" >{{response.user}}</span>
						<div class="response_bubble corner-piece" [ngClass]="{'last-response':isLast}">                
							<span >{{response.message}}</span>
						</div>
					</div>
				</div>
				<div class="message" *ngIf="needsMessage">
					<!--
					<mat-form-field *ngIf="needsMessage" class="full-width">
						<input matInput [(ngModel)]="message" maxlength="256" placeholder="Reason for rejection...">
						<mat-hint align="start"><strong>Be concise and to the point!</strong></mat-hint>
						<button click-stop-propagation click-stop-propagation mat-button [disabled]="!message" matSuffix mat-icon-button aria-label="Submit" (click)="send()">
							<mat-icon>send</mat-icon>
						</button>
						<button click-stop-propagation click-stop-propagation mat-button matSuffix mat-icon-button aria-label="Submit" (click)="cancel()">
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-form-field>
					-->
					<mat-form-field class="full-width requestTextArea" [style.fontSize]="14">
						<mat-label>Write response...</mat-label>
						<textarea #textarea id="response" class="nopad" [(ngModel)]="message" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
						<!--<mat-hint align="start"><strong>Be concise and to the point!</strong></mat-hint>-->
						<!--<mat-error *ngIf="formControlContent.hasError('required')">Description is <strong>required</strong></mat-error>
						<mat-error *ngIf="formControlContent.hasError('minlength')">Description is not long enough</mat-error>-->
					</mat-form-field>        
					<div style="display: flex; justify-content: flex-end;">
						<button class="basic-small" mat-button color="warn" click-stop-propagation (click)="cancelMessage()">Cancel</button>
						<button class="basic-small" mat-flat-button color="primary" [disabled]="!message" (click)="submitMessage()">Save</button>
					</div>        
				</div>
			</div>
			<!-- attachments -->
			<div class="panel attachments" *ngIf="false && show_attachments">
				<div *ngFor="let file of task['files']" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
					<div class="row" *ngIf="file.type == 'audio_recording'" style="width: 100%; height:100%;">
						<div class="attach_icon" >
							<mat-icon>mic</mat-icon>
						</div>
						<div class="attach_item" style="background-color:#f1f3f4;">
							<audio style="min-width:100%;" controls [src]="getFilePath(file.uri) | safe:'url'"></audio>
						</div>
					</div>
					<div class="row" *ngIf="file.type == 'screen_recording'">
						<div class="attach_icon">
							<mat-icon>ondemand_video</mat-icon>
						</div>
						<div class="attach_item">
							<video style="max-width:100%;" controls [src]="getFilePath(file.uri) | safe:'url'"></video>
						</div>
					</div>
					<div class="row" *ngIf="file.type == 'image'">
						<div class="attach_icon">
							<mat-icon>image</mat-icon>
						</div>
						<div class="attach_item">
							<img style="max-width:100%;" [src]="getFilePath(file.uri) | safe:'url'"/>
						</div>
					</div>
					<div class="row" *ngIf="file.type == 'video'">
						<div class="attach_icon">
							<mat-icon>video</mat-icon>
						</div>
						<div class="attach_item">
							<video style="max-width:100%;" controls [src]="getFilePath(file.uri) | safe:'url'"></video>
						</div>
					</div>
				</div>
			</div>

			<ng-container *ngIf="!editable && !collapsed">

				<!-- hidden content -->
				<div class="actions-list" *ngIf="log">
					<ul *ngIf="task.actions?.length">
						<li *ngFor="let action of task.actions; index as i; count as count">
							<span>{{getActionString(task.actions[count-1-i])}}</span>    
						</li>
					</ul>
					<span *ngIf="!task.actions?.length">
						No task log...
					</span>
				</div>
				<div *ngIf="false">  <!--*ngIf="selectedTask == task" -->
					<div *ngIf="task.state == 'local'" >
					
						<div style="display:none;">
							<mat-form-field appearance="fill">
								<mat-label>Select effected assets</mat-label>
								<mat-select [formControl]="assetsForm" multiple>
									<!-- select all .. https://stackoverflow.com/questions/51580095/select-all-mat-option-and-deselect-all -->
								<!--<mat-option #allSelected (click)="toggleAllSelection()" [value]="null">All</mat-option>-->
								<mat-option *ngFor="let asset of assets" [value]="asset">{{assetVO.asset.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<!--<button click-stop-propagation (click)="addMarker()" mat-flat-button>Drag icon to create a marker</button>-->
						<div *ngIf="!task.marker" style="display: flex;align-items: center;">
							<span (mousedown)="addMarker2($event)" class="marker" style="margin-right:0.5em;cursor: pointer; user-select: none;"> <!--transform: translate(-12px, -12px); -->
								<mat-icon>adjust</mat-icon>
							</span>
							<span>Drag icon to create a marker</span>
						</div>

						<fieldset *ngIf="task.marker">
							<legend>Markup</legend>
						<!--<span>{{assets.find((asset) => asset.uuid == task.marker.asset_uuid)}}</span>-->
							<span>{{task.marker.asset_uuid}}</span>
							<button click-stop-propagation  (click)="task.marker = null" mat-stroked-button color="primary">clear marker</button>
							<mat-form-field appearance="fill">
								<mat-label>Marker type</mat-label>
								<mat-select [(value)]="task.marker.type">
								<mat-option *ngFor="let type of ['target', 'box', 'circle']" [value]="type">
									{{type}}
								</mat-option>
								</mat-select>
							</mat-form-field>
							<br/>
							<mat-form-field class="half-width" appearance="fill">
								<label for="name">X</label>
								<input matInput [(ngModel)]="task.marker.x0">
							</mat-form-field>
							<mat-form-field class="half-width" appearance="fill">
								<label for="name">Y</label>
								<input matInput [(ngModel)]="task.marker.y0">
							</mat-form-field>
						</fieldset>
						<div *ngIf="task.markers">
							<div *ngFor="let marker of task.markers; let i = index">
								<p>Marker {{i+1}}</p>
								<mat-form-field appearance="fill">
									<mat-label>Marker type</mat-label>
									<mat-select [(value)]="marker.type">
									<mat-option *ngFor="let type of ['target', 'box', 'circle']" [value]="type">
										{{type}}
									</mat-option>
									</mat-select>
								</mat-form-field>
								<br/>
								<mat-form-field class="half-width" appearance="fill">
									<label for="name">X</label>
									<input matInput [(ngModel)]="marker.x0">
								</mat-form-field>
								<mat-form-field class="half-width" appearance="fill">
									<label for="name">Y</label>
									<input matInput [(ngModel)]="marker.y0">
								</mat-form-field>
								<p>Asset: {{marker.asset_uuid}}</p>
							</div>

						</div>
						<div class="buttons">
							<ng-container *ngIf="true">
								<button click-stop-propagation [disabled]="!submit(true)" (click)="submit()" mat-flat-button color="primary">Submit</button>
								<button click-stop-propagation (click)="delete()" mat-flat-button color="warn">Delete</button>
							</ng-container>
						</div>
					</div>
					<ng-container *ngIf="task.state != 'local'">
						<div class="taskAssets" *ngIf="task.type == 'amend' && task.assets && task.assets.length">
							<span>applies to:</span>
							<span *ngFor="let taskAsset of task.assets; let last = last">
								{{taskAsset.name}}{{!last ? ', ' : ''}}
							</span>
						</div>
					</ng-container>
					<!--<div style="clear: right;"></div>-->
					<mat-expansion-panel class="actions" *ngIf="false && task.actions && task.actions.length">
						<mat-expansion-panel-header>
						<mat-panel-title>
							<app-task-action [action]="task.actions[task.actions.length-1]"></app-task-action> 
							<!--
							<span>{{task.actions[0].action}}</span>
							<span>{{task.actions[0].message}}</span>
							{{task.actions[0].user.name}} - {{task.actions[0].action}}
							-->
						</mat-panel-title>
						<mat-panel-description>
							<!--
							{{task.actions[0].message}}
							<span class="date">{{date(task.actions[0])}}</span>
						-->
						</mat-panel-description>
						</mat-expansion-panel-header>
						<div *ngFor="let action of task.actions; index as i; count as count">
							<app-task-action *ngIf="i != 0" [action]="task.actions[count-1-i]"></app-task-action>    
						</div>
						<!--
							
						-->
					</mat-expansion-panel>
				</div>
			</ng-container>
			<!--<mat-divider [inset]="true"></mat-divider> -->
		</div>
	</ng-container>
</div>
