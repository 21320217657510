import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { debounceTime, takeUntil, take, finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { WorkgroupService } from 'src/app/api/workgroup.service';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { GenericDialogComponent, GenericDialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { NewWorkgroupData, NewWorkgroupDialogComponent } from 'src/app/dialogs/new-workgroup-dialog/new-workgroup-dialog.component';
import { IAccount } from 'src/app/models/account.model';
import { Permission } from 'src/app/models/permissions.model';
import { IUser } from 'src/app/models/user.model';
import { Workgroup } from 'src/app/models/workgroup.model';
import { AccountService } from 'src/app/services/accounts.service';
import { AppUserService } from 'src/app/services/app-user.service';
import { PusherService } from 'src/app/services/pusher.service';
import { StringsService } from 'src/app/services/strings.service';
import { DialogService } from './../../services/dialog.service';
import { IMessage } from 'src/app/models/message';


@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BaseComponent implements OnInit, OnDestroy {

	@ViewChild('searchInput') searchInput: ElementRef;
	@ViewChild('searchInput_team') searchInput_team: ElementRef;
	@ViewChild("tabs", {static:false}) public tabs: MatTabGroup;
	public tabIndex:number = 0;

	private _unsubscribe = new Subject<boolean>();

	public uuid:string;
	public account:IAccount;
	public workgroups:Workgroup[];
	private canLoadUsers:boolean;
	public users:IUser[];
	public user:IUser;
	public userInfo:any;
	public loading:boolean = true;
	public addingWorkgroup:boolean = false;
	private errorMessage;

	public sortOptions: { label: string; sort: string; sortReverse: boolean; }[];
	public sort: string = "name";
	public sortReverse: boolean = false;

	public sort_team: string = "name";
	public sortReverse_team: boolean = false;

	public search: string;
	public showSearch: boolean = false;
	showSearch_team: boolean;
	search_team: string;
	submittingInvite: boolean;



	constructor(
		private route : ActivatedRoute,
		private location: Location,
		public api : ApiService,
		public accountService : AccountService,
		private workgroupService : WorkgroupService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public appUserService : AppUserService,
		private pusherService : PusherService,
		private ds:DialogService,
		public strings:StringsService) {
			super();
		}


	ngOnInit(): void {
		
		
		this.sortOptions = [
			{label:"name (A > Z)", sort:"name", sortReverse:false},
			{label:"name (Z > A)", sort:"name", sortReverse:true},
			{label:"newest", sort:"uuid", sortReverse:true},	
			{label:"oldest", sort:"uuid", sortReverse:false},	
		]


		this.sub = this.accountService.account$.subscribe(account => {
			this.account = account;		
			this.listen();
			this.loadWorkgroups();
			this.loadUsers();
		});
		this.sub = this.accountService.workgroups$.subscribe(workgroups => this.workgroups = workgroups);
		this.sub = this.accountService.users$.subscribe(users => {
			this.users = users;
			if(this.user)
			{
				this.user = this.users.find(u => u.uuid == this.user.uuid);
			}
		});
		
		this.sub = this.appUserService.account$.subscribe(account => {
			if(account.uuid == this.uuid) return;
			this.uuid = account.uuid;
			this.accountService.loadAccount(this.uuid);
		});
		this.sub = this.appUserService.appSuperOrAccountAdmin$.subscribe(superOrAdmin => {
			let originalValue = this.canLoadUsers;
			this.canLoadUsers = superOrAdmin;
			if(originalValue != this.canLoadUsers) this.loadUsers();			
		});

		this.sub = this.api.reinvite.pipe(takeUntil(this._unsubscribe)).subscribe(res =>
		{
			this.submittingInvite = false;
			this.snackBar.dismiss();	
			if (res.status == 422) {	// validation error
				this.snackBar.open("request failed: " + res.error.message, "", {duration:2000, panelClass:'snackBar-error'});
			}else if(res.error) {		// generic server error
				this.snackBar.open("request failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
			} else {					// ok
				let confirm:any = {
					message: 'Account activation email re-sent to: ',
					message2: res.email,
					dismiss_button_label: "OK",
					width: 'unset'
				};
				this.ds.openAlert(confirm);
			}
		});
	}
	ngAfterViewInit() : void
	{
		this.route.fragment.subscribe(value => {
			let found = this.tabs._tabs.find(item => item.textLabel == value);
			if(found)	this.tabs.selectedIndex = this.tabs._tabs.toArray().indexOf(found);
		});
	}
	onTabChange(e:MatTabChangeEvent) : void
	{
		//this.route.fragment = e.tab.textLabel;
		//this.router.navigate([this.router.url], {fragment:e.tab.textLabel});
		this.location.go(this.location.path(false) + "#" +  e.tab.textLabel);
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();		
		this.pusherService.leaveAccountChat();		
	}
	listen()
	{
		this.pusherService.init(this.api.token);

		this.pusherService.joinAccountChat(this.account.uuid);
		this.sub = this.pusherService.accountChat.messages.pipe(debounceTime(500 + ((Math.random() * 500) | 0)), takeUntil(this._unsubscribe)).subscribe(
			(message:IMessage) => {
				// ignore any messages I generate
				if(message.user == this.appUserService?.appUser.uuid) return;
				if(message.type == "workgroup" || message.metadata?.id == this.appUserService?.appUser.uuid)
				{
					this.snackBar.open(message.metadata.message, null, {duration:2000});
					this.loadWorkgroups();
				}
			});
	}

	loadWorkgroups()
	{
		this.accountService.loadWorkgroups(this.account.uuid);
		return;
		this.loading = true;
		//console.log("loading workgroups");
		this.workgroupService.findAll().pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.workgroups = response.data;
				this.loading = false;
			},
			error => this.errorMessage = <any>error
		);
	}
	loadUsers()
	{
		// load users if account loaded and admin or super
		if(this.account && this.canLoadUsers)
		{
			this.accountService.loadUsers(this.account.uuid);
		}		
	}
	addUser()
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:`Invite Account User`,
				negative:"Cancel",
				positive:"Invite",
				form:[
					{ name:"email", type:"text", placeholder:"Enter Email", value:"", validator:[Validators.required, Validators.email]},
					{ name:"name", type:"text", placeholder:"Enter Name", value:"", validator:[Validators.required]},
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				this.snackBar.open("adding user...", '', {duration:5000});
				this.accountService.addUser(this.account.uuid, result.formGroup.value).pipe(take(1), takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("user added", "", {duration:2000});
					this.loadUsers();
				});
			}
		});
	}
	clickUser(user:IUser)
	{
		this.user = user;
		this.userInfo = null;
		this.accountService.userInfo(this.account.uuid, user.uuid).pipe(take(1), takeUntil(this._unsubscribe)).subscribe(res => {
			//this.snackBar.open("account user updated", '', {duration:3000});
			this.userInfo = res.data;
			this.userInfo.forEach(workgroups => {
				workgroups.projects.forEach(project => {
					project.tasks = 0;
					project.workflows.forEach(workflow => project.tasks += workflow.tasks.length);
				});
			});
		}, error => {
			//this.snackBar.open("account user update error", '', {duration:5000, panelClass:'error'});
		})
	}
	isUserAdmin(user:IUser)
	{
		return (user.scoped_permissions & Permission.ADMIN) === Permission.ADMIN;
	}
	editUser(user:IUser)
	{
		this.clickUser(user);
	}
	editUserAdmin(user:IUser)
	{
		let isAdmin = this.isUserAdmin(user);
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:`Edit account access level for: ${user.name}`,
				
				negative:"Cancel",
				positive:"Update",
				form:[
					{name:"isAdmin", label:"Is admin?", type:"checkbox", value:isAdmin}
				],
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				let newIsAdmin:boolean = result.formGroup.value.isAdmin;
				// has data changed
				if(isAdmin != newIsAdmin)
				{
					this.snackBar.open(`updating user admin (${newIsAdmin}) ...`, '', {duration:5000});			
					let observable = newIsAdmin ? this.accountService.grantAccountAdmin(this.account.uuid, user.uuid) : this.accountService.revokeAccountAdmin(this.account.uuid, user.uuid);
					observable.pipe(take(1), takeUntil(this._unsubscribe)).subscribe(res => {
						this.snackBar.open("account user updated", '', {duration:3000});
						this.loadUsers();
					}, error => {
						this.snackBar.open("account user update error", '', {duration:5000, panelClass:'error'});
					})
				}
				return;

				/*
				this.workflowService.updateGroupName(format.workflow_uuid, format.group, result.formGroup.value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("category name updated", "", {duration:2000});
					//this.loadFormats();
					this.loadWorkflows();
				}, (error) => {
					let message = 'updating group name failed';
					if(error.error?.message) message += ': '+ error.error?.message;	
					this.snackBar.open(message, "", {duration:2000, panelClass:'snackBar-error'});
				});*/
			}
		});
	}
	reinviteUser(user){
		if(user && user.email){
			this.submittingInvite = true;
			let email:any = user.email;		
			let data:any = {
				email
			};
			this.snackBar.open("re-sending account invite...", null, {duration:2000});
			this.api.reInvite(data);
		}
	}
	addWorkgroup(e = null) : void
	{
		if(!e)
		{
			//const dialogRef = this.dialog.open(NewProjectDialogComponent, {
			const dialogRef = this.dialog.open(GenericDialogComponent, {

				data: {
					title:"Add Workgroup",
					negative:"Cancel",
					positive:"Create",
					form:[
						{ name:"name", type:"text", placeholder:"Enter Name", value:"", validator:Validators.required},
					]
				}//{territories:this.groups_vert, groups:this.groups_horiz}
			  });
			  dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
				  if(result)
				  {

					  let workgroup = new Workgroup();
					  workgroup.name = result.formGroup.value.name;
					  workgroup.account_uuid = this.account.uuid;
					  //project.name = result.va;
					  this.snackBar.open("creating workgroup...");
					  this.addingWorkgroup = true;				 
					  //this.workgroupService.create(workgroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.apiCallOnce(this.accountService.addWorkgroup(this.account.uuid, workgroup), () => {
						this.snackBar.open("workgroup created", "", {duration:2000});
						this.loadWorkgroups() 
					}, null, null, () => this.addingWorkgroup = false);
					  /*
					  this.accountService.addWorkgroup(this.account.uuid, workgroup)
					.pipe(
						finalize(() => this.addingWorkgroup = false),
						take(1),
						takeUntil(this._unsubscribe))
					.subscribe(res => {
						this.snackBar.open("workgroup created", "", {duration:2000});
						this.loadWorkgroups();
					})*/
				  }else{
					  // popup closed with no data
				  }
			  });
			}else{
				//console.log("CREATE", e)
			}

	}
/*
	addWorkgroup(e = null) : void
	{
		if(!e)
		{
			const dialogRef = this.dialog.open(NewWorkgroupDialogComponent, {
				data: {}//{territories:this.groups_vert, groups:this.groups_horiz}
				});
				dialogRef.afterClosed().subscribe((result: NewWorkgroupData) => {
					if(result)
					{
						let workgroup = new Workgroup();
						workgroup.name = result.name;
						this.snackBar.open("creating workgroup...");
						this.workgroupService.create(workgroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
							this.snackBar.open("workgroup created", "", {duration:2000});
							this.loadWorkgroups();
						});
					}else{
						// popup closed with no data
					}
				});
			}else{
				console.log("CREATE", e)
			}

	}
*/
	edit(workgroup:Workgroup, event:MouseEvent = null)
	{
		if(event)	event.stopPropagation();

		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title: "Edit Workgroup",
				positive: "Save",
				negative: "Cancel",
				form:[
					{ name:"name", type:"text", placeholder:"Enter Name", field:"name", label:"name", validator:Validators.required},
				],
				model:{
					name:workgroup.name,
				}
			}				
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let value = result.formGroup.value;
				this.snackBar.open("saving workgroup...");
				this.apiCallOnce(this.workgroupService.update(workgroup.uuid, value), () => {
					this.snackBar.open("workgroup saved", "", {duration:2000});
					this.loadWorkgroups()
				});
				/*
				this.workgroupService.update(workgroup.uuid, value).pipe(take(1), takeUntil(this._unsubscribe)).subscribe(
				res => {
					this.snackBar.open("workgroup saved", "", {duration:2000});
					this.loadWorkgroups();
				});*/
			}
		});
		return;
		const dialogRef2 = this.dialog.open(NewWorkgroupDialogComponent, {
			data: {name:workgroup.name, edit:true}
		});
		dialogRef2.afterClosed().subscribe((result: NewWorkgroupData) => {
			if(result)
			{
				//console.log("new workgroup", result);
				workgroup.name = result.name;
				this.snackBar.open("saving workgroup...");
				this.workgroupService.update(workgroup.uuid, workgroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("workgroup saved", "", {duration:2000});
					this.loadWorkgroups();
				});
			}else{
				// popup closed with no data
			}
		});
	}
	delete(workgroup:Workgroup, event:MouseEvent = null)
	{
		if(event)event.stopPropagation();
		let profileForm:FormArray = new FormArray([
			new FormControl(''),
			new FormControl(''),
		]);
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {title:"Delete Workgroup", subtitle:workgroup.name, body:"Are you sure you wish to delete this workgroup?", positive:"Delete", negative:"Cancel"
			//formArray:profileForm
			}
		});
		dialogRef.afterClosed().subscribe((result: NewWorkgroupData) => {
			if(result)
			{
				this.snackBar.open("deleting workgroup...");
				this.workgroupService.delete(workgroup.uuid).pipe(take(1), takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("workgroup deleted", "", {duration:2000});
					this.loadWorkgroups();
				}, error => {
					this.snackBar.open("workgroup delete error: "+error.error.message, "", {duration:4000, panelClass:'snackBar-error'});
				});
			}else{
				// popup closed with no data
			}
		});
	}
	selected(workgroup: Workgroup)
	{
		this.api.gotoPage(`/workgroup/${workgroup.uuid}`);
	}
	onWorkgroupSelected(e)
	{
		const workgroup:Workgroup = e.option.value as Workgroup;
		this.api.gotoPage(`/workgroup/${workgroup.uuid}`);
	}

	flipSort(e:MouseEvent)
	{
		e.stopPropagation();
		this.sortReverse = !this.sortReverse;
	}

	selectSort(option, isTeam = false){
		if(isTeam)
		{
			this.sort_team = option.sort;
			this.sortReverse_team = option.sortReverse;
		} else {
			this.sort = option.sort;
			this.sortReverse = option.sortReverse;
		}
	}
	getSortLabel(isTeam = false){
		if(isTeam)
		{
			return this.sortOptions.find(option => option.sort == this.sort_team && option.sortReverse == this.sortReverse_team).label;
		} else{
			return this.sortOptions.find(option => option.sort == this.sort && option.sortReverse == this.sortReverse).label;
		}
	}

	toggleSearch(isTeam = false){

		this.showSearch = !this.showSearch; 
		this.search='';
		if(this.showSearch){
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			  }, 16)
		}
	}
	toggleSearch_team(){
		
		this.showSearch_team = !this.showSearch_team; 
		this.search_team='';
		if(this.showSearch_team){
			setTimeout(() => {
				this.searchInput_team.nativeElement.focus();
			  }, 16)
		}
	}

	getUserIcon(user:IUser)
	{
		let icon = user['active'] ? 'radio_button_checked' : 'radio_button_unchecked';
		return {icon};
	}
}
