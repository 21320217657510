import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ApiService } from 'src/app/api/api.service';
import { AssetService } from 'src/app/api/assets.service';
import { IUpload, Upload2, UploadState, UploadsService } from 'src/app/api/uploads.service';

import { Asset, AssetState, AssetType, AssetVO } from 'src/app/models/asset.model';

import { NewAssetDialogComponent, NewAssetData} from 'src/app/dialogs/new-asset-dialog/new-asset-dialog.component';
import * as JSZip from 'jszip';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PusherService } from 'src/app/services/pusher.service';
//import * as pdfjsLib from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { MatSelectChange } from '@angular/material/select';
import { CreativeState } from 'src/app/models/creative.model';
import { DialogService } from 'src/app/services/dialog.service';
import { FileUtils, formatBytes, formatDuration } from 'src/app/utils/FileUtils';
import { ENETUNREACH } from 'constants';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TasksService } from 'src/app/api/task.service';
import { compare } from 'src/app/utils/compare';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import diff from 'microdiff';
import { Globals } from 'src/app/global';
import { DetailedAssetListComponent } from 'src/app/dialogs/detailed-asset-list/detailed-asset-list.component';
import { AssetListExportComponent } from 'src/app/dialogs/asset-list-export/asset-list-export.component';
import { AssetListImportComponent } from 'src/app/dialogs/asset-list-import/asset-list-import.component';
import { AssetMetadataService } from 'src/app/services/assetMetadata.service';
import { AssetsComponentService } from './assetsComponent.service';

/**
 * TODO
 * 	- single function to create a new asset
 */


@Component({
	selector: 'app-assets',
	templateUrl: './assets.component.html',
	styleUrls: ['./assets.component.scss']
})
export class AssetsComponent extends BaseComponent implements OnInit, OnChanges {

	public assets:AssetVO[];
	@Input() creative_uuid : string;
	@Input() layout : string;
	aspectRatio: number;
	@Input() set assetsIn(assets:AssetVO[])
	{
		//this input is also called by it's own class via assetsInChange emitter (anglular magic using 'Change' suffix for 2-way binding with parent component...obviously)
		this.assets = assets;
		//console.log("ASSET LIST CHANGE detected in assets", this.selectedAsset);
		// look for any uploading assets that are missing files
		// TODO set a flag when the creative_uuid has changed but before we get the new assets so we only have to check once
		this.assets.forEach(assetVO => {
			if(this.isFile(assetVO) && !assetVO.file)
			{
				const upload = this.isAssetUploading(assetVO);
				if(upload)
				{
					assetVO.file = upload.file;
					// what about metadata... re-process the asset
					this.processAsset(assetVO);
				}

			}
		})
		//
		if(this.selectedAsset)
		{
			const assetVO = this.assets.find(assetVO => assetVO.asset.uuid == this.selectedAsset.asset.uuid)
			//this.selectAsset.emit(null);
			//console.log("found", assetVO);
			if(assetVO)
			{
				this.selectAsset.emit(assetVO);
				//this.listAssets.selectedOptions.select();
				/*for (let j = 0; j < options.; j++) {
					const element = options[j];
					
				}*/
				if(this.listAssets)
				{
					// all this just to focus the damn list :( (didn't need the setter - couuld hav used onChanges)
					
					//this.listAssets.focus({preventScroll:false});

					this.listAssets.options.forEach(item => {
						if(item.value.uuid == assetVO.asset.uuid)
						{
							item.focus();
						}
					})
				}
			} else {
				this.selectAsset.emit(null);
			}
		}
	}
	@Output() assetsInChange : EventEmitter<AssetVO[]> =  new EventEmitter<AssetVO[]>();
	@Output() assetsSavedOrDeleted : EventEmitter<boolean> =  new EventEmitter<boolean>();
	@Output() eventDeleteAssets : EventEmitter<AssetVO> =  new EventEmitter<AssetVO>();
	@Output() onEditModeChange : EventEmitter<boolean> =  new EventEmitter<boolean>();
	
	@Input() editable : boolean;
	@Input() production : boolean;
	@Input() admin : boolean;
	@Input() state : string;
	//@Input() selectedAsset : Asset;

	@Input() selectedAsset : AssetVO;
	@Output() selectAsset : EventEmitter<AssetVO> =  new EventEmitter<AssetVO>();

	@ViewChild("listAssets") listAssets:MatSelectionList;
	public busy:number = 0;
	private busy_saving:number = 1 << 0;
	private busy_deleting:number = 1 << 1;
	private _unsubscribe = new Subject<boolean>();
	public types = [{value:AssetType.IMAGE, 		label:"Image", 			icon:'image'},
					{value:AssetType.BANNER_HTML,	label:"Html Banner",	icon:'integration_instructions'}, 
//					{value:AssetType.EMAIL,			label:"Html Email", 	icon:''				disabled:true},
					{value:AssetType.VIDEO, 		label:"Video",			icon:'videocam'},
					{value:AssetType.PDF, 			label:"PDF",			icon:'picture_as_pdf'},
					{value:AssetType.AUDIO, 		label:"Audio",			icon:'audiotrack'},
					{value:AssetType.FILE, 			label:"File",			icon:'description'},
					{value:AssetType.LINK, 			label:"HTML link",		icon:'link'},
					{value:AssetType.IFRAME, 		label:"IFrame link",	icon:'web_asset'},
					{value:AssetType.CAPTION, 		label:"Caption", 		icon:'article', 	disabled:false},
//					{value:AssetType.EGNYTE, 		label:"Egnyte link", 	disabled:true},
//					{value:AssetType.REFERENCE, 	label:"Reference", 		disabled:true},
//					{value:AssetType.THREE_D, 		label:"3D", 			disabled:true},
				];
	public typeLookup:any  = {};
	
	
	public renamedFile:string;
	public maintainRatio: boolean;
	

	renameFile()
	{
		this.renamedFile = this.getFilename(this.selectedAsset);
	}
	saveFileRename()
	{
		// todo
		this.selectedAsset.asset.uri = this.selectedAsset.asset.uri.split(this.getFilename(this.selectedAsset)).join(this.renamedFile);
		this.renamedFile = null;
		/*
		this.assetsService.renameAssetFile(this.selectedAsset, this.renamedFile)
		.pipe(takeUntil(this._unsubscribe))
		.subscribe((res) =>
		{
			this.renamedFile = null;
			this.selectedAsset.asset.uri = res.data[0].uri;
			this.snackBar.open("asset file renamed", null, {duration:3000});
		}, (err) =>
		{
			this.snackBar.open("file rename error", null, {duration:3000, panelClass:'error'});
		}
		);*/
	}
	cancelRenameFile()
	{
		this.renamedFile = null;
		if(this.selectedAsset.asset.uri) this.resetValue('uri');
	}
	isAssetSelected(assetVO:AssetVO)
	{
		return(assetVO === this.selectedAsset);
		//console.log("is selected", assetVO.asset.uuid, this.selectedAsset?.asset.uuid, this.selectedAsset == assetVO);
		if(!this.selectedAsset) return false;
		let asset = assetVO.asset;
		if(asset.uuid && this.selectedAsset.asset.uuid)
		{
			return asset.uuid === this.selectedAsset.asset.uuid
		}
		return false;//asset === this.selectedAsset;
	}
	public activeUploads:IUpload[];

	//public selectedAsset:Asset;
	public formGroup:FormGroup;
	constructor(
		public assetsComponentService:AssetsComponentService,
		public dialog: MatDialog,
		private api:ApiService,
		private tasksService:TasksService,
		public assetsService:AssetService,
		private snackBar:MatSnackBar,
		private pusher:PusherService,
		public uploadsService:UploadsService,
		private dialogService:DialogService,
		private fb:FormBuilder,
		protected assetMetadataService: AssetMetadataService,
		) {
		super();
		//let urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		let urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		var UUIDpattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
		this.formGroup = fb.group({
			url: ['', [Validators.required, Validators.pattern(urlRegex)]],
			uuid: ['', [Validators.required, Validators.minLength(36), Validators.pattern(UUIDpattern)]],
		});
 

	}
	// filter this to only listen for uploads that relate to this creative
	filterUploads = upload => upload.data?.creative?.indexOf(this.creative_uuid) !== -1 && upload.path.indexOf('/task_files/') === -1;
	filterActiveUploads = upload => (upload.data?.creative?.indexOf(this.creative_uuid) !== -1 && upload.path.indexOf('/task_files/') === -1 && (upload.state == UploadState.READY || upload.state == UploadState.STARTED)) ;
	log(...stuff)
	{
		//console.log.apply(this, stuff);
		return true
	}
	ngOnInit(): void {
		this.log("assets subbing");
		this.sub = this.assetsComponentService.assets$.subscribe(assets => {
			this.assets = assets;
		});
		this.sub = this.assetsComponentService.selectedAssets$.subscribe(asset => {
			this.selectedAsset = asset;
			this.setAspectRatio();
		});

		this.sub = this.assetsService.posts$.subscribe(posts =>{
			let keys = Object.keys(posts).filter(key => key.indexOf(`assets2/${this.creative_uuid}`));
			keys.length != 0 ? this.busy |= this.busy_saving : this.busy &= ~this.busy_saving;
		})
		// TODO investigate deletes might be better handled in the delete call rather than here
		this.sub = this.assetsService.deletes$.subscribe(deletes =>{
			let keys = Object.keys(deletes).filter(key => {
				let asset_uuid = key.split("/").pop();
				return this.assets.find(assetVO => assetVO.asset.uuid == asset_uuid);
			});
			keys.length != 0 ? this.busy |= this.busy_deleting : this.busy &= ~this.busy_deleting;
		})
		/*
		this.sub = this.assetMetadataService.assetMeta.pipe().subscribe(assetVO => {
			let target = this.assets.find(asset => asset == assetVO);
			if(target){
				if(this.selectedAsset == target){
					this.maintainRatio = true;
					this.setAspectRatio();
				}
				target.updateMetaView();
			}
		})*/
		this.sub = this.uploadsService.completed.pipe(filter(this.filterUploads)).subscribe(upload => {
			let asset_uuid = upload.data.asset;
			let assetVO = this.assets.find(assetVO => assetVO.asset.uuid == asset_uuid);
			
			//console.log("assets: upload complete", assetVO);
			
			if(assetVO)
			{
				// clear state
				assetVO.state = null;
				// clear file
				assetVO.file = null;
				// clear fileMetadata
				assetVO.fileMetadata = null;
				//console.log("assets: preview clearer", assetVO.preview);
			}
			this.updateAsset(null);
			//this.updateAsset(upload.response);
		})
		//pipe(map(uploads => uploads.filter(this.filterActiveUploads)))
		this.sub = this.uploadsService.uploads$.subscribe((uploads:IUpload[]) => {
			uploads = uploads.filter(this.filterActiveUploads);
			this.activeUploads = uploads;
		})
		this.sub = this.uploadsService.failed.pipe(filter(this.filterUploads)).subscribe((upload:IUpload) => {
			console.log("upload failed", upload);
			let assetVO = this.assets.find(assetVO => assetVO.asset.uuid == upload.data.asset);
			if(assetVO)
			{
				assetVO.state = null;
			}
		})
		this.sub = this.uploadsService.cancelled.pipe(filter(this.filterUploads)).subscribe((upload:IUpload) => {
			console.log("upload cancelled", upload);
			let assetVO = this.assets.find(assetVO => assetVO.asset.uuid == upload.data.asset);
			if(assetVO)
			{
				assetVO.state = null;
				// assetVO.asset.flag &= ~8;	// uploading to r2 flag - SKETCHY
				// would need to save this change!!
			}
			// see if all uploads canceled.. if so then reload tasks
			let count = this.uploadsService.getUploads().filter(this.filterActiveUploads).length;
			if(count == 0) this.tasksService.loadTasks(this.creative_uuid);
		})
		//this.assets = [];
		this.pusher.init(this.api.token);

		//TODO are these necessary now?
		this.pusher.getPrivateMessage().subscribe(message => {
			//console.log("PRIVATE YESS", message);
			//this.snackBar.open(message, null, {duration: 2000});
		 });
		 this.pusher.getPublicMessage().subscribe(message => {
			//console.log("PUBLIC YESS", message);
			//this.snackBar.open(message, null, {duration: 2000});
		 });

		 //map asset types lookup
		 //{[AssetType.IMAGE]:{value:AssetType.IMAGE,	label:"Image",	icon:"image",	disabled:false }},
		 this.types.forEach(type =>{
			this.typeLookup[type.value] = type;
		 })
		
		 
		/*
		this.pusher.channel.bind("App\\Events\\RealTimeMessage", data => {
			console.log("HELL YEAH", data);
		});*/
		/*
	// @ts-ignore
		window.Pusher = require('pusher-js');
		  console.log("TOKEM OF DOOM", this.api.token);
		  // @ts-ignore
			window.Echo = new Echo({
				broadcaster: 'pusher',
				key: "websocketkey",//process.env.MIX_PUSHER_APP_KEY,
				cluster: "mt1",//process.env.MIX_PUSHER_APP_CLUSTER,
				forceTLS: false,
				wsHost: window.location.hostname,
				wsPort: 8443,
				auth: {
					headers: {
						//Authorization: this.api.token,//'Bearer ' +  BEARER_TOKEN,
						Authorization: 'Bearer ' + this.api.token,// BEARER_TOKEN,
					},
				},
			});
		  // @ts-ignore
		  window.Echo.channel('events').listen('RealTimeMessage', (e) =>{
			console.log("WHYYY", e);  
			this.snackBar.open(e.message, null, {duration: 2000});
		  });
		  // @ts-ignore
		  window.Echo.channel('private-events').listen('RealTimeMessage', (e) =>{
			console.log("WHYYY", e);  
			this.snackBar.open(e.message, null, {duration: 2000});
		  });
		  // @ts-ignore
		  window.Echo.private('events').listen('RealTimeMessage', (e) =>{
			console.log("WHYYY priv", e);  
			this.snackBar.open("private: " + e.message, null, {duration: 2000});
		});
		// @ts-ignore
		window.Echo.private('private-events').listen('RealTimeMessage', (e) =>{
			console.log("WHYYY priv", e);  
			this.snackBar.open("private: " + e.message, null, {duration: 2000});
		});*/
	}
	ngAfterViewInit() {
		
	}
	ngOnChanges(changes: SimpleChanges = null): void {
		//console.log("assets changes", changes);
		if(changes.creative_uuid)
		{
			// clean up any requests
			this.log("assets unsubbing");
			this.unsub();
			// check for any uploads
			if(changes.creative_uuid.currentValue)
			{
				let uploads = this.uploadsService.getUploads();
				this.activeUploads = uploads.filter(this.filterActiveUploads);
			}
		}else if(changes.selectedAsset)
		{
			//console.log("loading meta");
			//this.assetMetaData = this.getMetadataSource();
		}
	}
	
	deleteAsset(assetVO:AssetVO = null, event:Event = null) : void {
		if(event) event.stopPropagation();
		if(!assetVO) assetVO = this.selectedAsset;
		this.eventDeleteAssets.emit(assetVO);
		return;
		this.dialogService.openGenericConfirm(
			{
				title:"Delete assets",
				body:"Are you sure you want to delete this asset.\nThis cannot be undone."
			}).subscribe(result =>{
				if(result)
				{
					if(!assetVO.asset.uuid)
					{
						this.removeAssets([assetVO]);
					}else{
						this.snackBar.open("deleting asset", "", {duration:4000});
						this.assetsService.delete(assetVO.asset.uuid).subscribe(response => {
							this.snackBar.open("asset deleted", "", {duration:2000});
							this.removeAssets(response.data.map(asset => new AssetVO(asset)));
							this.assetsSavedOrDeleted.emit(true);
						});
					}
				}
			});
	}
	/**
	 * Only delete assets that are local and those which appear in the current list to avoid accidental deletion of others assets
	 * There could be argued a case that this should be in the assets service not creative
	 */
	deleteAllAssets()
	{
		this.eventDeleteAssets.emit();
	}
	removeAssets(assetsToRemove:AssetVO[]) : void {
		for (let i = 0; i < assetsToRemove.length; i++) {
			const assetToRemove = assetsToRemove[i];
			for (let j = 0; j < this.assets.length; j++) {
				const assetVO = this.assets[j];
				if(assetToRemove == assetVO || (assetToRemove.asset.uuid != null && assetVO.asset.uuid != null && assetToRemove.asset.uuid == assetVO.asset.uuid))
				{
					this.assets.splice(j, 1);
					break;
				}					
			}				
		}
		this.selectAsset.emit(null);
		this.assets = this.assets.concat([]); // refesh that list yo
		this.assetsInChange.emit(this.assets);
	}
	hasAssetChanged(assetVO:AssetVO = null)
	{
		// has anthing in the asset changed - loop all props or predefined list
		if(!assetVO) assetVO = this.selectedAsset;
		if(!assetVO.original) return false;	// cant be changed if new
		return this.doesAssetFileNeedSave(assetVO) || assetVO.isDirty();
		/*
		if(!asset || !asset.reference) return false;
		for (const key in asset.reference) {
			if (key != 'reference' && Object.prototype.hasOwnProperty.call(asset.reference, key)) {
				if(this.hasChanged(key, asset)) return true;
			}
		}
		return false;
		*/
	}

	doesAssetFileNeedSave(assetVO:AssetVO)
	{
		if(!assetVO.file) return false;
		return !this.isAssetUploading(assetVO);
	}
	isAssetUploading(assetVO:AssetVO):IUpload|null
	{
		return this.activeUploads?.find(upload => upload.data?.asset == assetVO.asset?.uuid)
	}
	onTypeChange(event:MatSelectChange)
	{
		//console.log("TYPE change", this.selectedAsset.asset.type, event);
	}
	revert(assetVO:AssetVO = null, event:Event = null, inBatch:boolean = false)
	{
		if(event) event.stopPropagation();
		if(!assetVO) assetVO = this.selectedAsset;
		// if the assets order has been changed
		this.resetOrder(assetVO);
		assetVO.revert();
		if(!this.isAssetUploading(assetVO))
		{
			assetVO.file = null;
			assetVO.preview = null;
			assetVO.fileMetadata = null;
		}
	}
	resetOrder(assetVO:AssetVO)
	{
		if(this.hasChanged("order", assetVO))	// saved assetVO with order change
		{
			// order has changed so we need to move;
			let originalOrder = assetVO.original.order;
			assetVO.asset.order = originalOrder;
			// see if another asset has the original order and reset the order on that
			let conflictAsset = this.assets.find(assVO => assVO != assetVO && assetVO.asset.order == originalOrder);
			if(conflictAsset) this.resetOrder(conflictAsset);
		}else if(!assetVO.original && assetVO.asset.order != assetVO.order) // new asset with order change
		{
			let originalOrder = assetVO.order;
			assetVO.asset.order = originalOrder;
			let conflictAsset = this.assets.find(assVO => assVO != assetVO && assetVO.asset.order == originalOrder);
			if(conflictAsset) this.resetOrder(conflictAsset);
		}
	}

	revertAll(event:Event = null)
	{
		if(event) event.stopPropagation();
		for (let i = 0; i < this.assets.length; i++) {
			const assetVO = this.assets[i];
			this.revert(assetVO, null, true);
		}

	}
	hasChanged(prop:string, assetVO:AssetVO = null):boolean
	{
		if(!assetVO) assetVO = this.selectedAsset;
		if(!assetVO.original) return false;
		return assetVO.isPropDirty(prop);
	}
	resetValue(prop:string, assetVO:AssetVO = null):void
	{
		if(!assetVO) assetVO = this.selectedAsset;
		assetVO.revertProp(prop);

		if(this.maintainRatio){
			let asset = assetVO.asset;
			if(prop == "width"){
				this.setRatioHeight(asset, asset.width);
			} else if (prop == "height"){
				this.setRatioWidth(asset, asset.height);
			}
		}
	}

	needsSave(ignoreNew:boolean=false): boolean
	{
		// no assets
		if(!this.assets || !this.assets.length) return false;

		// any new assets?
		if(!ignoreNew)
		{
			for (let i = 0; i < this.assets.length; i++) {
				const assetVO = this.assets[i];
				if(!assetVO.asset.uuid) return true;			
			}
		}

		// any changed assets or existing and have a new file to upload
		let dirtyAsset = this.assets.find(assetVO => ((assetVO.asset.uuid && this.doesAssetFileNeedSave(assetVO)) || assetVO.isDirty()));
		if(dirtyAsset) return true;

		return false;
	}
	/**
	 * Save any new or changes assets
	 * A save will trigger a task reload if there are no uploads
	 * If there are uploads the task reload will trigger after the last upload is complete
	 * If and upload is canceled and it is the last upload it will also trigger a task load
	 * 
	 * Uploads are only triggered after a successfull save call as this ensures uuids (by extension uris) are available as upload destinations
	 */
	save(): void {
		let output = [];
		this.assets.forEach(assetVO => {
			let saveData:any = null;
			if(assetVO.asset.id < 0)
			{
				// local
				saveData = this.doesAssetFileNeedSave(assetVO) ? {...assetVO.asset, filename:assetVO.file.name} : {...assetVO.asset};	// , metadata:assetVO.fileMetadata
			} else {
				// stored
				if(this.doesAssetFileNeedSave(assetVO)) saveData = {uuid:assetVO.asset.uuid, filename:assetVO.file.name};		//, filename:assetVO.file.name, metadata:assetVO.fileMetadata	// important to signify an existing asset is being updated
				let diffs = [];
				let dirty = assetVO.isDirty(diffs);
				if(diffs.length)
				{
					if(!saveData) saveData = {};
					if(!saveData.uuid) saveData.uuid = assetVO.asset.uuid;	// important to signify an existing asset is being updated
					diffs.forEach(diff => {
						let path = diff.path;
						var length = path.length;
						if(length == 1)
						{
							saveData[path[0]] = diff.value;
						}else{
							console.warn("not implemented deep diffs yet!!")
							let i = 0;
							let target = saveData;
							while( i < length ) {
								let prop = path[i];
								i++;
							}
						}
					})
				}
			}
			if(saveData){
				assetVO.state = AssetState.SAVING;
				output.push(saveData);
			}
		});
		
		// references for later
		let newAssets = this.assets.filter(assetVO => assetVO.id < 0);
		let assetsWithFiles = this.assets.filter(assetVO => this.doesAssetFileNeedSave(assetVO));

		this.snackBar.open("saving assets", "", {duration:4000});

		// map the assetvos down to assets
		//let assets = this.assets.map(asset => assetVO.asset);
		const creative_uuid = this.creative_uuid;
		this.assetsService.save(creative_uuid, output as Asset[]).subscribe(response => {
			//console.log("asset saved", Date.now());		

			// remove any saving state
			this.assets.forEach(assetVO => assetVO.state = null);
			
			/*
			// if the current selected asset is new (no uuid) then remove it from the selection
			// TODO we can have the selected asset passed in from creative (and assets - probably)
			// then this should be able to work again and point at the updated reference
			if(this.selectedAsset && !this.selectedAsset.asset.uuid)
			{
				this.selectAsset.emit(null);
			}
			*/
			// what was send to the backend
			let sentAssets:any[] = response.request.assets;
			let returnedInput = response.data['input'];
			let assetsReturned = response.data['assets'];

			// override completely
			//let assetVOs:AssetVO[] = [];
			//assetsReturned.forEach(asset => assetVOs.push(new AssetVO(asset)));

			// merge apprach
			// loop though new assets in and try to find current ones, if found merge new into old
			// if any not found (shouldnt happen) append onto list
			assetsReturned.forEach(newAsset => {
				// find the input {id,uuid,version} that lead to this output 
				let input = returnedInput.find(input => input.uuid === newAsset.uuid );
				if(input)
				{
					//let inputUUID = input.uuid;
					//console.log("MATCH FOUND", input)
					// we have a match, let's merge!!
					// find original asset from input id
					let target = this.assets.find( assetVO => input.id < 0 ? assetVO.asset.id === input.id : input.uuid === assetVO.asset.uuid );
					if(target)
					{
						target.merge(newAsset);
					}
				}
			});

			this.snackBar.open("assets saved", "", {duration:2000});


			
			// if there are any uploads to send we need to get the correct uuid to upload it against for new assets
			// this should be in the returned uuids in the correct order
			//console.log("assetsWithFiles.length", assetsWithFiles);
			
			if(assetsWithFiles.length)
			{	
				// split into new and existing
				
				assetsWithFiles.forEach(assetVO => {
					// find the correct uuid to upload against
				//	let input = returnedInput.find(input => assetVO.asset.uuid ? assetVO.asset.uuid == input.uuid : input.id == assetVO.asset.id);
				//	let inputUUID = input.uuid;

					// new way - do we need creative uuid?
					let route = `upload/creative/${creative_uuid}/asset/chunkTest`;
					let link = `/creative/${creative_uuid}`;
				
					//this.uploadsService.add(route, {file:assetVO.file, data:{asset:input.uuid, version:input.version, creative:this.creative_uuid, metadata:assetVO.fileMetadata}}, link);
					//let uuid_creative = this.creative_uuid.split("-").shift();
					//let uuid_asset = input.uuid.split("-").shift();
					//let workerRoute = `upload/${uuid_creative}/${input.version}/${uuid_asset}`
					//let workerRoute = `upload/ran-do342354339-98763453095-453`
					let account_uuid = localStorage.getItem("account_uuid"); // TODO better way, i.e. from returned asset (target)
					let uri = `${account_uuid}/${creative_uuid}/${assetVO.asset.version}/${assetVO.asset.uuid}/${assetVO.file.name}`;
					let workerRoute = `upload/${uri}`
					//workerRoute = `upload/tmp`
					//workerRoute = workerRoute.split("-").join('');
					this.uploadsService.addWorker(workerRoute,
						{file:assetVO.file, data:{asset:assetVO.asset.uuid, version:assetVO.asset.version, creative_uuid:creative_uuid, metadata:assetVO.fileMetadata}},
						link,
						(upload) => {
							let subject = new Subject<boolean>();
							let uri = `${account_uuid}/${creative_uuid}/${assetVO.asset.version}/${assetVO.asset.uuid}/${assetVO.file.name}`;
							uri = Asset.uriEncoded(uri);
							//let metadata = {metadata:{size:assetVO.file.size}}	;//upload.response.metadata
							let metadata = {metadata:assetVO.fileMetadata};
							this.assetsService.complete(uri, upload.response.region, metadata).subscribe(res => 
								{
									const asset = res.data[0];
									const assetVO = this.assets.find(assetVO => assetVO.asset.uuid == asset.uuid);
									if(assetVO){
										assetVO.merge(asset);
										assetVO.updateCacheBust();
									}
									this.assetsComponentService.assets = this.assets;
									subject.next(true);
								}, err => subject.next(false));
							return subject.asObservable();
						});


					// we can reinstate the preview also to prevent jumping (inject them onto the new assets)
					/*
					let target = assetVOs.find( asset => asset.asset.uuid == inputUUID);
					if(target){
						target.state = AssetState.UPLOADING;
						if(assetVO.preview)
						{
							target.preview = assetVO.preview;
						}
				
						assetVO.asset.uri = target.asset.uri;
						assetVO.asset.flag = target.asset.flag;
						console.log("updating uri and flag", assetVO.asset.uri, assetVO.asset.flag);
					}*/
					
					//let source = this.assets.find( asset => asset.asset.uuid ? asset.asset.uuid == input.uuid : assetVO.asset.id == input.id);
					/*
					if(target && source?.preview)
					{
						// transfere the preview over to the source until the real one is uploaded..
						// or we could remove this and use some processing type flag						
						target.preview = source.preview;
					}*/
				});

				//console.log("uuid", returnedUuids);
				/*
				for (let i = 0; i < sentAssets.length; i++) {
					const sentAsset = sentAssets[i];
					let id:number = Number(sentAsset.id);
					
					let localAsset = this.assets.find(asset => assetVO.asset.id == id);
					console.log("local asset?", localAsset);
					
					if(localAsset?.file)
					{
						let serverAsset = returnedAssets.find(asset => assetVO.asset.uuid ==  );
	
						// new way - do we need creative uuid?
						let path = `upload/creative/${this.creative_uuid}/asset`;
						let link = `/prototypes/creative/${this.creative_uuid}`;
						let file = localAsset.file;
						this.uploadsService.add(path, {file, data:{asset:serverAsset.uuid, version:serverAsset.version}}, link);
	
						// temp remove the uri to stop the render being cached
						// if(localAsset.uri) localAsset.uri = null;
						// old way
						//uploads.push({file:localAsset.file, uuid:serverAsset.uuid});
					}			
				}*/
			}

			/*
			let foundSelected:boolean = false;
			// also update the selected asset
			if(this.selectedAsset && sentAsset.id == this.selectedAsset.asset.id)
			{
				//this.selectAsset.emit(returnedAssets[i]);
				foundSelected = true;
			}*/
			/*
			for (let i = 0; i < sentAssets.length; i++) {
				const sentAsset = sentAssets[i];
				let id:number = Number(sentAsset.id);
				if(id < 0)
				{
					let localAsset = this.assets[i];
					if(!localAsset.file) continue;
					let serverAsset = response.data[i];
					uploads.push({file:localAsset.file, uuid:serverAsset.uuid});
					
					// also update the selected asset
					if(this.selectedAsset && id == this.selectedAsset.asset.id)
					{
						this.selectAsset.emit(returnedAssets[i]);
						foundSelected = true;
					}
				}			
			}*/


			// old way
			//this.uploadsService.upload(this.creative_uuid, uploads);

			// 

			/*
			for (let i = 0; i < returnedAssets.length; i++) {
				let returnedAsset = returnedAssets[i];
				// TODO
				//Asset.cacheBust(returnedAsset)
			}*/
			//this.assets = returnedAssets;
			// update pointer to selected asset
			/*
			if(!foundSelected && this.selectedAsset)
			{
				for (let i = 0; i < this.assets.length; i++) {
					if(this.assets[i].uuid == this.selectedAsset.asset.uuid)
					{
						this.selectAsset.emit(this.assets[i]);
						break;
					}
				}
			}*/
			//console.log("assets post save change emit", assetVOs);
			
			this.assetsComponentService.assets = this.assets;
			//this.assetsInChange.emit(this.assets.concat([]));
			// TODO replace this with call to go and load

			/*
			for (let i = 0; i < this.assets.length; i++) {
				const element =  this.assets[i];
			}*/

			if(!assetsWithFiles.length){
				let reloadCreative = (newAssets?.length > 0) ? true : false;
				this.assetsSavedOrDeleted.emit(reloadCreative);			
			} 
		});
	}

	sortAssets(sort:string)
	{
		let initalOrders = this.assets.map(assetVO => assetVO.asset.order);
		switch (sort) {
			case 'reverse':
				this.assets.reverse();
				break;
			case 'width':
				this.assets.sort((a, b) => {
					if(a.asset.width && b.asset.width)
					{
						if(a.asset.width == b.asset.width)
						{
							return compare(a.asset.height, b.asset.height);
						}else {
							return compare(a.asset.width, b.asset.width);
						}
					}else {
						return 0;
					}
				});
				break;
			case 'height':
				this.assets.sort((a, b) => {
					if(a.asset.height && b.asset.height)
					{
						if(a.asset.height == b.asset.height)
						{
							return compare(a.asset.width, b.asset.width);
						}else {
							return compare(a.asset.height, b.asset.height);
						}
					}else {
						return 0;
					}
				});
				break;
			case 'area':
					this.assets.sort((a, b) => {
						if(a.asset.width && b.asset.width && a.asset.height && b.asset.height)
						{
							return compare(a.asset.width * a.asset.height, b.asset.width * b.asset.height);
						}else {
							return 0;
						}
					});
					break;
				case 'diagonal':
					this.assets.sort((a, b) => {
						if(a.asset.width && b.asset.width && a.asset.height && b.asset.height)
						{
							return compare(	Math.sqrt(a.asset.width * a.asset.width + a.asset.height * a.asset.height),
											Math.sqrt(b.asset.width * b.asset.width + b.asset.height * b.asset.height));
						}else {
							return 0;
						}
					});
					break;
			case 'name':
				this.assets.sort((a, b) => compare(a.asset.name, b.asset.name));
				break;
			default:
				break;
		}
		// update the orders
		this.assets.forEach((assetVO, index) => {				
			assetVO.asset.order = initalOrders[index];
		});
		this.assetsComponentService.assets = this.assets;		
		//this.assetsInChange.emit(this.assets);
		this.assetsSavedOrDeleted.emit();
	}
	updateAsset(assetIn:any)
	{
		//console.log("AssetIn",assetIn);
		if(assetIn?.uuid)
		{
			for (let i = 0; i < this.assets.length; i++) {
				const assetVO = this.assets[i];
				if(assetVO.asset.uuid == assetIn.uuid)
				{
					let assVO = new AssetVO(assetIn);
					this.assets[i] = assVO;
					//Asset.cacheBust(this.assets[i]);
					break;
				}
			}
		}
		this.assetsComponentService.assets = this.assets;
		//this.assetsInChange.emit(this.assets);
		// look through the uploads to see if this was the last on for this creative then reload the tasks
		// need to do this to update the production task buttons, might be able to skip this under certain condtions
		let anyUploadsLeft = false;
		this.uploadsService.getUploads().forEach(upload => {
			if(upload.path.indexOf(this.creative_uuid) === -1) return;
			if(upload.path.indexOf("/task_files/") !== -1) return;
			if(upload.state == Upload2.STATE_STARTED || upload.state == Upload2.STATE_READY)
			{
				anyUploadsLeft = true;
			}
		});
		// if no uploads left for this creative then reload the tasks
		if(!anyUploadsLeft)
		{
			//this.tempSub = this.tasksService.loadTasks(this.creative_uuid);
			this.assetsSavedOrDeleted.emit(true);
		}
	}
	sort(array:Array<any>, prop: string) {
		return array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
	}
	sortAssetsByOrder(assets:AssetVO[]) {
		let clone = [...assets];
		return clone.sort((a, b) => a.asset.order > b.asset.order ? -1 : a.asset.order === b.asset.order ? 0 : 1);
	}
	getIcon(assetVO:AssetVO):string
	{
		return this.getIconInner(assetVO.asset);
	}
	getIconInner(asset:Asset):string
	{
		switch(asset.type)
		{
			case AssetType.IMAGE: return 'image';
			case AssetType.VIDEO: return 'videocam';
			case AssetType.AUDIO: return 'audiotrack';
			case AssetType.BANNER_HTML: return 'integration_instructions';
			case AssetType.PDF: return 'picture_as_pdf';
			case AssetType.FILE: return 'description';
			case AssetType.IFRAME: return 'web_asset';
			case AssetType.LINK: return 'link';
			case AssetType.EGNYTE: return 'filter_drama';
			case AssetType.EMAIL: return 'email';
			case AssetType.THREE_D: return 'view_in_ar';
			case AssetType.REFERENCE: return 'highlight_alt';
			case AssetType.CAPTION: return 'article';
			default: return 'help_center';
		}
	}
	reorder(direction:number, check:boolean = false, event:Event): boolean
	{
		if(event) event.stopPropagation();
		if(check)
		{
			// can't sort if no selected assets or at least 2 assest
			if(!this.selectedAsset || !this.assets || this.assets.length < 2) return false;
			var min = Number.POSITIVE_INFINITY;
			var max = Number.NEGATIVE_INFINITY;
			this.assets.forEach(assetVO =>
				{
					if(assetVO.asset.order > max) max = assetVO.asset.order;
					if(assetVO.asset.order < min) min = assetVO.asset.order;
				})
			if(direction < 0 && this.selectedAsset.asset.order <= min ) return false;
			if(direction > 0 && this.selectedAsset.asset.order >= max) return false;
			//if(direction > 0 && this.assets.indexOf(this.selectedAsset) >= this.assets.length-1) return false;
			return true;
		}
		if(!this.selectedAsset) return false;

		//sort the assets array by .order
		this.assets.sort((a, b) => a.asset.order - b.asset.order);

		//then swap the selected asset's order num with it's next (up or down) neighbour's order 
		let newIndex = this.assets.indexOf(this.selectedAsset) + direction;
		if(newIndex < 0 || newIndex >= this.assets.length) return;
		
		let selectedAssetOrder = this.selectedAsset.asset.order;
		let swapAsset = this.assets[newIndex].asset;
		
		this.selectedAsset.asset.order = swapAsset.order;
		swapAsset.order = selectedAssetOrder;

		// resort
		this.assets.sort((a, b) => a.asset.order - b.asset.order);
		return true;
	}
	getHighestOrder(): number {
		let order = 0;
		for (var i = 0; i < this.assets.length; i++)
		{
			var asset = this.assets[i].asset;
			if(asset.order >= order) order = asset.order + 1;
		}
		return order;
	}
	tempUUID = -1;//new Date().getTime();
	getTempID():number{
		return this.tempUUID--;
	}
	newAsset(type:string = null): void {
		//this.selectedAsset = null;
		//this.selectAsset.emit(null);
		const asset = new Asset();
		asset.id = this.getTempID();
		asset.name = "new "+ (type);
		asset.x = 0;
		asset.y = 0;
		asset.order = this.getHighestOrder();
		asset.visible = true;
		asset.deliverable = true;
		if(type) asset.type = type;

		if(asset.type == AssetType.CAPTION)
		{
			asset.metadata = {
				align:"center",
				size:"18px",
				color:"#000000",
			};
		}
		asset.width = 300;
		asset.height = 250;
		let assetVO = new AssetVO(asset);
		assetVO.current = asset;
		assetVO.order = asset.order;	// reference to original order (only used for new assets)
		
		this.assetsComponentService.addAsset(assetVO);
		this.assetsComponentService.selectedAsset = assetVO;
		//this.selectAsset.emit(assetVO);
	}
	newAssetFromFiles(): void {
		const dialogRef = this.dialog.open(NewAssetDialogComponent, {
			data: {}
		  });
	  
		  dialogRef.afterClosed().subscribe((result: NewAssetData) => {
			//console.log('The dialog was closed', result);
			if(result.name)
			{
				const asset = new Asset();
				asset.id = this.getTempID();
				asset.name = result.name;
				asset.x = 0;
				asset.y = 0;
				asset.visible = true;
				asset.deliverable = true;
				asset.order = this.getHighestOrder();

				let assetVO = new AssetVO();
				assetVO.current = asset;
				assetVO.order = asset.order;	// reference to original order (only used for new assets)
				this.assets.push(assetVO);
				this.selectAsset.emit(assetVO);
			}else if(result.files && result.files.length)
			{
				this.onFilesDragged(result.files);
			}
		  });
		/*
		*/
	}
	onSelectAsset(event: MatSelectionListChange)//asset
	{
		this.selectAsset.emit(event.option.value);
		//if(this.selectAsset == asset) return;
		//this.selectedAsset = asset;
	}
	selectAssetOld(assetVO : AssetVO)//asset
	{
		/*
		this.selectedAsset = asset;
		if(this.selectedAsset){
			this.maintainRatio = ((this.hasNewFile() || this.hasFile()) && this.hasDimensions());
			if(this.maintainRatio){this.setAspectRatio();}
			this.assetMetaData = this.getMetadataSource();
		}*/
		this.selectAsset.emit(assetVO);
		//if(this.selectedAsset) this.assetMetaData = this.getMetadataSource();
		//if(this.selectedAsset == asset) return;
	}
	isNewAsset(assetVO : AssetVO)
	{
		if(!assetVO) return;
		return !assetVO.original;
	}

	isFile(assetVO:AssetVO = null):boolean
	{
		assetVO = assetVO ?? this.selectedAsset;
		if(!assetVO) return false;
		return Asset.isFile(assetVO.asset);
	}
	isLink(assetVO:AssetVO = null):boolean
	{
		assetVO = assetVO ?? this.selectedAsset;
		return (assetVO.asset.type == AssetType.LINK || 
				assetVO.asset.type == AssetType.IFRAME ||
				assetVO.asset.type == AssetType.EGNYTE ); 
	}
	isReference(assetVO:AssetVO = null):boolean
	{
		assetVO = assetVO ?? this.selectedAsset;
		return (assetVO.asset.type == AssetType.REFERENCE); 
	}
	
	hasDimensions(assetVO:AssetVO = null):boolean
	{
		assetVO = assetVO ?? this.selectedAsset;
		return Asset.hasDimensions(assetVO.asset); 
	}
	/**
	 * Does this asset have a file saved on the server
	 * @returns bool
	 */
	hasFile():boolean
	{
		if(!this.isFile()) return false;
		if(this.selectedAsset.asset.uri) return true;
		return false;
	}
	download()
	{
		// trigger browser to download file		
		// window.location.href = Globals.BASE_API_URL + "file/"+this.selectedAsset.asset.uri+"?download=true";
		//window.location.href = Globals.WORKER_URL + "serve/"+Asset.uriEncoded(this.selectedAsset.asset.uri)+"?download=true";
		//window.location.href = Globals.WORKER_URL + "serve/"+this.selectedAsset.asset.uri+"?download=true";
		window.open(Globals.WORKER_URL + "serve/"+Asset.uriEncoded(this.selectedAsset.asset.uri)+"?download=true", "_BLANK");
	}
	/**
	 * Get the filename of this asset's file
	 * @returns string
	 */
	getFilename(asset): string
	{
		//if(this.selectedAsset.asset.uri) return this.filenameFromPath(this.selectedAsset.asset.uri);
		if(asset.asset.uri)  return FileUtils.baseName(asset.asset.uri);
		//let filename = this.filenameFromPath(assetURI);
		return null;
	}
	/**
	 * Does this asset have a new file for upload
	 * @returns boolean
	 */
	hasNewFile():boolean
	{
		if(!this.isFile()) return false;
		return this.selectedAsset.file != null;
	}
	/**
	 * Get the filename of this asset's new file
	 * @returns string
	 */
	getNewFilename(): string
	{
		if(this.selectedAsset.file) return this.selectedAsset.file.name;
		return null;
	}
	private filenameFromPath(path:string):string
	{
		return path.split('\\').pop().split('/').pop();
	}
	async handleUpload(filesList:FileList, assetVO:AssetVO = null)
	{
		// clear any renaming
		this.cancelRenameFile();

		let files = Array.from(filesList);
		let assetToCheck = assetVO || this.selectedAsset;
		files = await this.checkFiles(files, assetToCheck);
		console.log("handleUpload: assetVO", assetVO);
		console.log("handleUpload: selectedAsset", this.selectedAsset);

		if(!files?.length) return;
		// check if file exists
		if(!this.isFilenameUnique(files[0], assetVO))
		{
			this.dialogService.openAlert({title:"Duplicate file detected", message: "Files that share a name with an existing asset are not allowed:\n" + files[0].name});
		}else{
			this.selectedAsset.file = files[0];//TODO - this looks bogus... what if no selectedAsset?
			this.processAsset(this.selectedAsset);
			this.setAspectRatio();
		}
	}
	async checkFiles(files:File[], assetVO:AssetVO = null)
	{
		//check for correct file type for asset
		if(assetVO && Asset.isFile(assetVO.asset)){
			let fileType =  this.getTypeFromFilename(files[0].name);
			let assetType =  assetVO.asset.type;
			let isAllowedType = (fileType == assetType);
			if(!isAllowedType){
				const dialogRef = this.dialog.open(GenericDialogComponent, {
					data: {
						title:"File / Asset type mismatch",
						subtitle: "The file type you are attempting to upload is not allowed for the selected asset.",//+assetVO.asset.type+".",
						body: "File type: "+fileType+"\nAsset type: "+assetType,
						positive: "Continue",
					}
				});
				return null;
			}
		}
		// check for filename bug where long file names + long file paths cause a filename issue
		let issueFiles = files.filter((file) => file.name.indexOf("~") !== -1);
		if(issueFiles.length){
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
					title:"Bad filename detected",
					subtitle: "Please check filename lengths and/or upload from a shorter path",
					positive: "Continue",
				}
			});
			return null;
		}
		const illegalChars = ['%', '#'];	//, "'",
		let illegalFiles = files.flatMap((file) => {
			let illegalsFound = [];
			illegalChars.forEach(char => {
				if(file.name.indexOf(char) !== -1) illegalsFound.push(char);
			});
			if(illegalsFound.length) return [{file, illegalChars}];
			else return [];
		});
		if(illegalFiles.length){
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
					title:"Filename(s) with illegal characters",
					subtitle: "Please check all filenames, the following characters are not allowed ('"+illegalChars.join("','")+"')",
					body: `${illegalFiles.length} of ${files.length} files not allowed:\n`+illegalFiles.map(i => i.file.name).join("\n"),
					positive: illegalFiles.length < files.length ? "Continue with allowed files" : "Ok",
					negative: illegalFiles.length < files.length ? "Cancel all" : null,
				}
			});
			let result = await dialogRef.afterClosed().toPromise();
			if (result) {
				if(illegalFiles.length == files.length) return null;
				files = files.filter(file => !illegalFiles.find(illegalFile => illegalFile.file == file));
			}else{
				return null;
			}
		}
		return files;
	}
	enableDragging(e)	// MatSlideToggleChange
	{
		this.assetsComponentService.draggable = e.checked;
		//this.onEditModeChange.emit(e.checked)
	}
	replaceFile():void	// TODO remove this as obsolete I think
	{
		const dialogRef = this.dialog.open(NewAssetDialogComponent, {
			data: {single:true}
		  });
		  dialogRef.afterClosed().subscribe((result: NewAssetData) => {
			if(result.files && result.files.length)
			{
				this.selectedAsset.file = result.files[0];
				this.processAsset(this.selectedAsset);
			}
		  });
	}
	removeNewFile():void
	{
		this.selectedAsset.file = null;
		this.selectedAsset.preview = null;
		this.selectedAsset.fileMetadata = null;	// TODO shoud this be on vo
	}
	deleteFile():void{}
	doesntMatchWidth()
	{
		//if(!this.hasFile()) return false;
		if(this.selectedAsset.file && this.selectedAsset.fileMetadata && this.selectedAsset.fileMetadata.width)
		{
			return this.selectedAsset.fileMetadata.width != this.selectedAsset.asset.width;
		}else if(this.selectedAsset.asset.metadata && this.selectedAsset.asset.metadata.width){
			return this.selectedAsset.asset.metadata.width != this.selectedAsset.asset.width;
		}
		return false;		
	}
	doesntMatchHeight()
	{
		//if(!this.hasFile()) return false;
		if(this.selectedAsset.file && this.selectedAsset.fileMetadata && this.selectedAsset.fileMetadata.height)
		{
			return this.selectedAsset.fileMetadata.height != this.selectedAsset.asset.height;
		}else if(this.selectedAsset.asset.metadata && this.selectedAsset.asset.metadata.height){
			return this.selectedAsset.asset.metadata.height != this.selectedAsset.asset.height;
		}
		return false;	
	}
	matchWidth()
	{
		/*
		if(this.selectedAsset.fileMetadata)
		{
			this.selectedAsset.asset.width = this.selectedAsset.fileMetadata.width;
		}else{
			this.selectedAsset.asset.width = this.selectedAsset.asset.metadata.width;
		}
		*/
		let value = this.selectedAsset.fileMetadata?.width || this.selectedAsset.asset.metadata.width
		this.selectedAsset.asset.width = value;
		if(this.maintainRatio){
			let asset = this.selectedAsset.asset;
			this.setRatioHeight(asset, value);
		}
	}
	matchHeight()
	{
		/*
		if(this.selectedAsset.fileMetadata)
		{
			this.selectedAsset.asset.height = this.selectedAsset.fileMetadata.height;
		}else{
			this.selectedAsset.asset.height = this.selectedAsset.asset.metadata.height;
		}
		*/
		let value = this.selectedAsset.fileMetadata?.height || this.selectedAsset.asset.metadata.height;
		this.selectedAsset.asset.height = value;
		if(this.maintainRatio){
			let asset = this.selectedAsset.asset;
			this.setRatioWidth(asset, value);
		}
	}
	displayedColumns: string[] = ['key', 'value_old', 'value_new'];
	/*
	.
	*/
	private isFilenameUnique(file:File, ignore:AssetVO = null):boolean
	{
		return this.assets?.find(assetVO => {
			if(ignore && assetVO == ignore) return false;
			return (assetVO.file?.name == file.name) || FileUtils.baseName(assetVO.asset?.uri) == file.name
		}) ? false : true;
	}
	findAssetByFilename(filename:string):AssetVO
	{
		return this.assets.find(assetVO => assetVO.file?.name == filename || FileUtils.baseName(assetVO.asset?.uri) == filename);
	}
	//onFilesDragged(files:File[], position:{x:number,y:number} = null, targetAsset:AssetVO = null):void
	async onFilesDragged(files:File[], position:{x:number,y:number} = null, targetAsset:AssetVO = null)
	{
		// state check
		if(!(this.editable && this.production && this.isCreativeInProductionState()))
		{
			return;
		}

		// TODO enable folder upload support!!! https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree

		files = await this.checkFiles(files, targetAsset);
		if(!files || files.length == 0) return;
			/*
			dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
				if (result) {
					if(illegalFiles.length < files.length)
					{
						
					}
				}
			});
			return;
			*/
		
		// process each file first (check type + validity against spec maybe) then generate a preview if possible

		//console.log("on files dragged", files);
		let enableDropPos:boolean = event && this.assets?.length != 0;

		// remove unknown types
		files = files.filter((file) => this.getTypeFromFilename(file.name));

		if(targetAsset)
		{
			//
			let asset = this.assets.find(asset => asset == targetAsset);
			if(asset)
			{
				asset.file = files[0];
				this.processAsset(asset);
			}
			return;
		}

		// check for duplicate filenames
		let duplicates = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if(!this.isFilenameUnique(file))
			{
				duplicates.push(file);
				files.splice(i--, 1);
			}
		}

		// we have duplicate what shall we do, ignore or replace
		if(duplicates.length)
		{
			let form = [];
			for (let i = 0; i < duplicates.length; i++) {
				const file = duplicates[i];
				let item = { name: i, type: "checkbox", label: file.name, value: false };
				form.push(item);
			}
			const dialogRef = this.dialog.open(GenericDialogComponent, {
				data: {
					title:"Resolve conflicing filenames",
					subtitle: "Check the box to replace the file or leave unchecked to skip it",
					negative: "Cancel",
					positive: "Continue",
					form
				}
			});
			dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
				if (result) {
					let values = result.formGroup.value; // {index:true/false}
					duplicates = duplicates.filter((file, index) => values[index]);
					if(duplicates.length)
					{
						// replace the duplicates
						duplicates.every(file => {
							let asset = this.findAssetByFilename(file.name);
							if(asset)
							{
								asset.file = file;
								this.processAsset(asset);
							}
						})
					}
					this.resolveNewFiles(files, enableDropPos, position);
				}

			});
			return;
		}
		this.resolveNewFiles(files, enableDropPos, position);

	}
	// TODO work out if replacing or just new
	resolveNewFiles(files:File[], enableDropPos:boolean, position:{x:number,y:number} = null)
	{
		let skippedFiles = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const type = this.getTypeFromFilename(file.name);
			if(!type) continue;	// TODO notify or handle this case

			if(!this.isFilenameUnique(file))
			{
				skippedFiles.push(file);
				continue;
			}

			const asset = new Asset();
			asset.id = this.getTempID();
			// position the asset based on drop point (only do this if there is one asset already)
			if(enableDropPos && position)
			{
				asset.x = position.x + (i * 20);
				asset.y = position.y + (i * 20);
			}else{
				asset.x = 0;
				asset.y = 0;
			}
			
			//const lastDot = file.name.lastIndexOf('.');
			//asset.name = lastDot == -1 ? file.name : file.name.substr(0, lastDot);
			asset.type = type;
			asset.order = this.getHighestOrder();
			asset.visible = true;
			asset.deliverable = true;
			
			let assetVO = new AssetVO(asset);
			assetVO.file = file;
			asset.order = asset.order;	// reference to original order (only used for new assets)
			this.setAssetNameToFilename(assetVO,file.name);

			let success = this.processAsset(assetVO);
			this.assets.push(assetVO);
		}
		this.assetsComponentService.assets = this.assets;
		//this.assetsInChange.emit(this.assets);
		if(this.assets.length)	this.selectAsset.emit(this.assets[this.assets.length-1]);
		if(skippedFiles.length)
		{
			let skippedCount = skippedFiles.length;
			let addedCount = files.length - skippedCount;
			let message = skippedFiles.map(file => file.name).join("\n");
			this.dialogService.openAlert({title:"Duplicate file detected", message: addedCount + " files added, " + skippedCount + " files skipped." + "\nFiles that share a name with an existing asset are not allowed:\n" + message});
		}
	}
	setAssetNameToFilename(assetVO:AssetVO, filename:string){
		assetVO.asset.name = this.getFilenameNoDot(filename);
	}
	getFilenameNoDot(assetURI:string)
	{
		if(!assetURI) return;
		let filename = FileUtils.baseName(assetURI);
		//let filename = this.filenameFromPath(assetURI);
		let lastDot = filename.lastIndexOf('.');
		return lastDot == -1 ? filename : filename.substr(0, lastDot);
	}

	showBackup(assetVO:AssetVO)
	{
		// this is a bit of a shoehorn
		let path = assetVO.asset.uri;
		if(!path) return;
		if(path.indexOf(".zip"))
		{
			
			assetVO.preview = Globals.BASE_API_URL + 'file/' + path + ".dir/backup.jpg";
		}
	}
	hideBackup(assetVO:AssetVO)
	{
		assetVO.preview = null;
	}
	// TODO 
	processAsset(assetVO: AssetVO): boolean
	{
		if(!assetVO.file) return false;

		const file = assetVO.file;
		assetVO.fileMetadata = { size:file.size };
		if(assetVO.asset.type === AssetType.IMAGE && file.type.indexOf("image/") === 0)
		{
			const img = new Image();
			assetVO.preview = URL.createObjectURL(file);
			img.addEventListener("load", (e) => {
				// update asset dimensions if asset is new
				if(!assetVO.asset.uuid)
				{
					assetVO.asset.width = img.width;
					assetVO.asset.height = img.height;
				}
				assetVO.fileMetadata.width = img.width;
				assetVO.fileMetadata.height = img.height;
				// TODO image size? 
				//tempObj.width = img.width;
				//tempObj.height = img.height;
				//this.newFile = tempObj;
				//if(callback) callback();
				this.assetMetadataService.assetMetaSubject.next(assetVO);
				this.assetsComponentService.assets = this.assets;
				//this.assetsInChange.emit(this.assets);
			});
			img.src = assetVO.preview;
		} else if(assetVO.asset.type === AssetType.AUDIO && file.type.indexOf("audio/") === 0)
		{
			var preview = true;
			var getMetadata = true;
			if(preview) assetVO.preview = URL.createObjectURL(file);
			if(getMetadata)
			{
				/*
				var mime = file.type; // store mime for later
            	var fileReader = new FileReader(); // create a FileReader				
          		fileReader.onload = function(e) { // when file has read:	//fileReader.addEventListener("load", )
					var blob = new Blob([e.target.result], {type: mime}) // create a blob of buffer
					var url = (URL || webkitURL).createObjectURL(blob); // create o-URL of blob
					var media = document.createElement("audio"); // create audio element
					media.preload = "metadata"; // preload setting
					media.addEventListener("loadedmetadata", function() { // when enough data loads
						console.log("media meta loaded", media.duration, media); // show duration
						(URL || webkitURL).revokeObjectURL(url); // clean up
					});
					media.src = url; // start media load
				};
				var chunkSizeMB = 0.75;
				var chunk = file.slice(0, Math.min(chunkSizeMB * 1000000, file.size));
				fileReader.readAsArrayBuffer(chunk); // read file object
				*/
			}

		} else if(assetVO.asset.type === AssetType.VIDEO && file.type.indexOf("video/") === 0)
		{
			var preview = true;
			var getMetadata = true;
			//console.log("getting video meta...");
			
			if(preview) assetVO.preview = URL.createObjectURL(file);
			if(getMetadata)
			{
				// The dimensions can be worked out in the asset preview
			
				/*
				var mime = file.type; // store mime for later
            	var fileReader = new FileReader(); // create a FileReader				
          		fileReader.onload = function(e) { // when file has read:	//fileReader.addEventListener("load", )
					var blob = new Blob([e.target.result], {type: mime}) // create a blob of buffer
					var url = (URL || webkitURL).createObjectURL(blob); // create o-URL of blob
					var media = document.createElement("video"); // create video element
					media.preload = "metadata"; // preload setting
					console.log("video blob loaded adding new listeners");
					media.addEventListener("canplay", function(e)
					{
						console.log("CAN PLAY EVENT");
					});
					media.addEventListener("loadeddata", function(e)
					{
						console.log("LOADED DATA EVENT");
					});
					media.addEventListener("loadedmetadata", function(event) { // when enough data loads
						console.log("media meta loaded", this, media.duration, media.videoWidth, media.videoWidth); // show duration
						asset.width = media.videoWidth || asset.width;
						asset.height = media.videoHeight || asset.height;
						(URL || webkitURL).revokeObjectURL(url); // clean up
						assetVO.fileMetadata = {}; // TODO maybe we should always clear it?
						assetVO.fileMetadata.width = asset.width;
						assetVO.fileMetadata.height = asset.height;
					});
					media.src = url; // start media load
				};
				var chunkSizeMB = 0.75;
				var chunk = file.slice(0, Math.min(chunkSizeMB * 1000000, file.size));
				fileReader.readAsArrayBuffer(chunk); // read file object
				*/
			}
		} else if(assetVO.asset.type === AssetType.PDF && file.type == 'application/pdf') {
			//console.log("PDF meta load");
			
			assetVO.preview = URL.createObjectURL(file);
			pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
			const loadingTask = pdfjsLib.getDocument(assetVO.preview)
			loadingTask.promise.then(pdf => {
			  //console.log("pdf loaded", pdf);
			  assetVO.fileMetadata.pdf = {pages:pdf.numPages};
			  pdf.getPage(1).then(page =>{
				var view = page.view;
				var dpiFix = 96/72;
				assetVO.fileMetadata.width = view[2] * dpiFix;
				assetVO.fileMetadata.height = view[3] * dpiFix;
				// if dimensions are unset then set them based on the file metadata
				// update asset dimensions if asset is new
				if(!assetVO.asset.uuid)
				{
					assetVO.asset.width = assetVO.fileMetadata.width;
					assetVO.asset.height = assetVO.fileMetadata.height;
				}
				this.assetMetadataService.assetMetaSubject.next(assetVO);
			  });
			});
		} else if(assetVO.asset.type === AssetType.BANNER_HTML &&  this.zip_types.indexOf(file.type) != -1) {

			// TODO - investigate replacing file references with local blobs for preview purposes
			// i.e. https://stackoverflow.com/questions/27304992/how-to-extract-a-zip-file-and-render-the-contents-client-side
			// could use service worker (for auth issue also) (https://clurgo.com/en/service-worker-and-static-content-authorization/)
			this.parseBanner(assetVO);

		}else if(assetVO.asset.type === AssetType.FILE){
			//
		} else {
			//
		}
		//TO DO - move this to each async file loader above to rfesh the metadat when ready
		//this.getMetadataSource(assetVO);
		// trigger a change update somehow :)
		assetVO.updateMetaView();
		//this.assetsInChange.emit(this.assets.concat([]));

		return true;
	}
	preloadMedia(){}

	async parseBanner(assetVO:AssetVO)
	{
		//console.log("parsing banner");
		
		const zip = await JSZip.loadAsync(assetVO.file).catch((err) => console.warn("error parsing zip", err));                                   // 1) read the Blob
		//console.log("zip", zip);
		if(!zip) return;
		if(!zip.files["index.html"])
		{
			this.dialogService.openAlert({
				title:"Incomplete banner",
				message: "'index.html' file not found in zip contents.\n Please replace with corrected zip. "
			});
			assetVO.file = null;
			assetVO.fileMetadata = null;
			assetVO.preview = null;
			assetVO.updateMetaView();
			return;
		}

		zip.forEach((relativePath, zipEntry) => {  // 2) print entries
			//console.log(relativePath, zipEntry)
			// parse the backup image
			if(relativePath === "backup.jpg" || relativePath === "backup.jpeg")
			{
				zip.file(relativePath)
					.async("base64") // could use a blob here (https://stackoverflow.com/questions/40028568/show-image-from-local-zip-with-jszip)
					.then(function(content) {
						assetVO.preview = "data:" + "image/jpeg" + ";base64," + content;
					});
			}
			// parse the index.html
			if(relativePath.indexOf(".html") != -1)
			{
				zip.file(relativePath)
				.async("text") // could use a blob here (https://stackoverflow.com/questions/40028568/show-image-from-local-zip-with-jszip)
				.then((content) => {
					// look for meta tags with content attribute
					//const re = /<meta.+content="(.+?)">/i; // ? makes it non-greedy

					// is flash2html?
					let re = /<meta name="generator" content="fbf_exporter:(\S+)">/i
					let found = content.match(re);
					if(found && found.length === 2)
					{
						assetVO.fileMetadata.banner = {generator:"fbf " + found[1]}; 
						re = /<meta.+content="width=(\d+?),height=(\d+?)">/i; 	// flash2html
						found = content.match(re);
						if(found && found.length === 3)								{
							assetVO.fileMetadata.width = found[1];	// group 1
							assetVO.fileMetadata.height = found[2];	// group 2
						}else{
							re = /{i:"root",w:(\d+),h:(\d+),/i; 	// flash2html (older variants)
							found = content.match(re);
							if(found && found.length === 3)								{
								assetVO.fileMetadata.width = found[1];	// group 1
								assetVO.fileMetadata.height = found[2];	// group 2
							}
						}
					} else {
						re = /<script type="text\/gwd-admetadata">(.+?)<\/script>/i;	// google web designer
						let found = content.match(re);
						if(found && found.length === 2)
						{
							let json = JSON.parse(found[1]);
							if(json && json.creativeProperties)
							{
								assetVO.fileMetadata.width = json.creativeProperties.minWidth;
								assetVO.fileMetadata.height = json.creativeProperties.minHeight;
							}
							assetVO.fileMetadata.banner = {generator:"gwd"}; 
						}else {
							re = /<script gwd-served-sizes="">\s*(.+?)\s*<\/script>/i;
							found = content.match(re);
							if(found && found.length === 2)
							{
								let json = JSON.parse(found[1]);
								if(json && json[0])
								{
									let parts = json[0].split("x");
									assetVO.fileMetadata.width = parseInt(parts[0]);
									assetVO.fileMetadata.height = parseInt(parts[1]);
								}
								assetVO.fileMetadata.banner = {generator:"gwd"}; 
							}else {
								//<meta name="ad\.size" content="width=(\d+)\W+height=(\d+)">
								re = /<meta name="ad\.size" content="\D+(\d+)\D+(\d+)">/i;
								found = content.match(re);
								if(found && found.length === 3)								{
									assetVO.fileMetadata.width = found[1];	// group 1
									assetVO.fileMetadata.height = found[2];	// group 2
									assetVO.fileMetadata.banner = {generator:"adobe-animate"};
								}
							}
						}
					} 
					// update asset dimensions if asset is new
					if(!assetVO.asset.uuid)
					{
						if(assetVO.fileMetadata?.width)
						{
							assetVO.asset.width = assetVO.fileMetadata.width;
						}
						if(assetVO.fileMetadata?.height)
						{
							assetVO.asset.height = assetVO.fileMetadata.height;
						}
					}
					this.assetMetadataService.assetMetaSubject.next(assetVO);
				});
			}
		});
	//console.log( "Error reading " + assetVO.file.name + ": " + e.message);
	}
	// https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript/680982
	getExtensionFromFilename(filename: string) : string
	{
		return /\.([^.]+)$/.exec(filename)[1];
		return /(?:\.([^.]+))?$/.exec(filename)[1];
	}
	getTypeFromFilename(filename: string) : string
	{
		const extension = this.getExtensionFromFilename(filename).toLocaleLowerCase();
		if(this.audio_extensions.indexOf(extension) != -1) return AssetType.AUDIO;
		if(this.image_extensions.indexOf(extension) != -1) return AssetType.IMAGE;
		if(this.video_extensions.indexOf(extension) != -1) return AssetType.VIDEO;
		if(this.pdf_extensions.indexOf(extension) != -1) return AssetType.PDF;
		if(this.web_extensions.indexOf(extension) != -1) return AssetType.BANNER_HTML;
		if(this.threed_extensions.indexOf(extension) != -1) return AssetType.THREE_D;
		return AssetType.FILE;
	}
	private audio_extensions = ['aac', 'flac', 'm4a', 'mid', 'mp3', 'mpa', 'wav', 'wma', "ogg"];
	private image_extensions  = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'psd', 'tga', 'tif', 'tiff', 'ai', 'eps', 'ps', 'svg', 'webp'];
	private video_extensions  = ['avi', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg','ogg','ogv','webm', 'wmv'];
	private pdf_extensions  = ['pdf'];
	private web_extensions  = ['zip', 'html'];
	private threed_extensions  = ['obj', 'gltf'];


	private zip_types = ['application/octet-stream', 'multipart/x-zip', 'application/zip', 'application/zip-compressed', 'application/x-zip-compressed'];


	getMetadata()
	{
		let metadata = '';
		if(this.selectedAsset.asset.metadata)
		{
			for (const key in this.selectedAsset.asset.metadata) {
				if(metadata.length) metadata += '\n&#13;\n\r';
				metadata += `${key}: ${this.selectedAsset.asset.metadata[key]}`;
			}
		}
		return metadata;
	}
	isCreativeInProductionState()
	{
		return	this.state == CreativeState.NEW
				|| this.state == CreativeState.BUILD
				|| this.state == CreativeState.AMENDING;
	}
	trackByAssetUUID(index:number, assetVO:AssetVO)
	{
		return assetVO.asset.uuid;
	}

	/**
	 * Show a popup that gives a full screen view of the assets
	 */
	showDetailedAssetList()
	{
		const dialogRef = this.dialog.open(DetailedAssetListComponent, {
			data: {assets:this.assets},
			maxWidth: '80vw',
			maxHeight: '80vh',
			//height: '100%',
			width: '100%',
		  });
	  
		  dialogRef.afterClosed().subscribe((result: any) => {
			if(result?.asset) this.selectAsset.emit(result);
		  });
	}

	downloadAssetList(){
		console.log("not yet implemented");
	}

	exportAssetJson()
	{
		const dialogRef = this.dialog.open(AssetListExportComponent, {
			data: {assets:this.assets},
			maxWidth: '80vw',
			maxHeight: '80vh',
			minWidth: '25%',
			//height: '100%',
			//width: '100%',
		  });
	  
		  dialogRef.afterClosed().subscribe((result: any) => {
			//console.log("export");

		  });
	}
	bulkResize()
	{
		let size = 1;
		let form = [];
		form.push( { name: "scale", type: "text", label: "Scale ie. '50'", value: 100 });
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Bulk resize",
				subtitle: "Input a scale 0 - 100 percentage, that will effect each assets viewing width and height, maintainiing aspect ratio. The original uploaded file will be unaffected.",
				negative: "Cancel",
				positive: "Continue",
				form
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if (result) {
				size = parseFloat(result.form[0].control.value);
				if(size > 1) size *= 0.01;
				if(size < 0) size = 0;
				else if(size > 1) size = 1;
				// resize those puppies
				this.assets.forEach((assetVO, index) => {
					if(this.hasDimensions(assetVO))
					{
						assetVO.asset.width = assetVO.fileMetadata ? assetVO.fileMetadata.width * size : assetVO.asset.metadata.width * size;
						assetVO.asset.height = assetVO.fileMetadata ? assetVO.fileMetadata.height * size : assetVO.asset.metadata.height * size;
					}
				});
			}
		});
		

	}
	importAssetJson()
	{
		const dialogRef = this.dialog.open(AssetListImportComponent, {
			maxWidth: '80vw',
			maxHeight: '80vh',
			minWidth:'33%',
			minHeight:'33%',
			//height: '100%',
			//width: '100%',
		  });
	  
		  dialogRef.afterClosed().subscribe((assetsToImport: any[]) => {
			//console.log("imported", assetsToImport);
			assetsToImport.forEach(asset => {
				this.importAsset(asset);
			});

		  });
	}
	importAsset(assetData:any)
	{
		const asset = new Asset();
		asset.id = this.getTempID();
		asset.name = assetData.name;
		asset.type = assetData.type;
		asset.x = assetData.x || 0;
		asset.y = assetData.y || 0;
		asset.width = assetData.width || 0;
		asset.height = assetData.height || 0;
		asset.visible = assetData.visible;
		asset.deliverable = assetData.deliverable;
		asset.order = this.getHighestOrder();
		
		let assetVO = new AssetVO();
		assetVO.current = asset;
		assetVO.order = asset.order;	// reference to original order (only used for new assets)
		this.assets.push(assetVO);
	}

	public setWidth(value: number){
		let asset = this.selectedAsset.asset;
		asset.width = Number(value);
		if(this.maintainRatio){
			this.setRatioHeight(asset, asset.width);
			//let h = value/this.aspectRatio;
			//asset.height = Math.round(h);
		} 

	}
	public setHeight(value: number){
		let asset = this.selectedAsset.asset;
		asset.height = Number(value);
		if(this.maintainRatio){
			this.setRatioWidth(asset, asset.height);
			//let w = value * this.aspectRatio;
			//asset.width = Math.round(w);
		} 
	}

	public setRatioHeight(asset, value){
		let h = value/this.aspectRatio;
		asset.height = Math.round(h);
	}
	public setRatioWidth(asset, value){
		let w = value * this.aspectRatio;
		asset.width = Math.round(w);
	}

	public toggleAspectRatio(asset){
		this.maintainRatio = !this.maintainRatio;
		this.setAspectRatio();
	}

	private setAspectRatio(){
		if(!this.maintainRatio) return false;
		let asset = this.selectedAsset.asset;
		let w = this.selectedAsset.fileMetadata?.width || this.selectedAsset.asset.metadata?.width || this.selectedAsset.asset.width;
		let h = this.selectedAsset.fileMetadata?.height || this.selectedAsset.asset.metadata?.height || this.selectedAsset.asset.height;
		this.aspectRatio = (w/h);
	}
	uploadFilter = (upload:IUpload) => upload.state == UploadState.STARTED && upload.data.creative_uuid == this.creative_uuid;
 }
