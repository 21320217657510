<mat-tab-group #tabs [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event)" animationDuration="0ms">
	
	<!-- HOME -->
	<mat-tab label="Project Home">
	
		<app-project-home 
			[project]	="project" 
			[workflows]	="workflows"
			[isAdmin]	="isAdmin()"
			(edit)		="edit($event)"
			(changeTab)	="changeTab($event)"
			>
		</app-project-home>

		<!-- TEMP 
		<p>The following informational sections will likely be present</p>
		<ul>
			<li>Project details
				<ul>
					<li>Name</li>
					<li>Path (project location)</li>
					<li>Brief</li>
					<li>Notes</li>
					<li>Owner</li>
				</ul>
			</li>
			<li>Announcements - top X unseen project level announcements</li>
			<li>Activity log - last X actions that happened on this project. i.e. Person X did action Y on creative Z</li>
			<li>Derived project status - charts/icons overview of how many assets are at which state</li>
			<li>Project people (users with access)</li>
			<li>Copy Project button</li>
		</ul>
		-->
	</mat-tab>

	<!-- =========== NOT USED  ============= -->
	 <!-- 
	<mat-tab *ngIf="false" [disabled]="!creatives" label="Creatives {{creatives ? '(' + creatives.length + ')' : ''}}">
		<div class="row">
			<div class="col-6">
				<mat-form-field class="example-form-field">
					<mat-label>Search</mat-label>
					<input matInput type="text" [(ngModel)]="search">
					<button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
					  <mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<br>
				<mat-form-field *ngIf="false" tooltip="a drop down list of creatives">
					<mat-label>{{creatives ? 'Select a creative' : 'Loading...'}}</mat-label>
					<mat-select (selectionChange)="onCreativeSelected($event)" ([value])="currentCreative">
						<mat-option [value]="creative" *ngFor="let creative of creatives | filter : search : 'name'" (onSelectionChange)="onCreativeSelected($event)">
							{{creative.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-selection-list [multiple]="false" (selectionChange)="onCreativeSelected($event)">
					<mat-list-option *ngFor="let creative of creatives | filter : search : 'name'" [value]="creative">
						<div class="group-list-item">
							<div class="group-list-title">
								<span>{{creative.name}}</span>
							</div>
							<div class="group-list-info">
								<span>[{{creative.channel.name}}]</span>
							</div>
							<button mat-icon-button><mat-icon>edit</mat-icon></button>
							<button mat-icon-button><mat-icon>delete</mat-icon></button>
						</div> 
					</mat-list-option>
				</mat-selection-list>
				<button mat-raised-button color="primary" (click)="this.addCreative()">New Creative</button>
				<hr>
				<table tooltip=" a table of creatives" mat-table [dataSource]="creatives | filter : search : 'name'" class="mat-elevation-z8" *ngIf="false">
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef> Name </th>
						<td mat-cell *matCellDef="let creative"> {{creative.name}} </td>
					</ng-container>
					<ng-container matColumnDef="created">
						<th mat-header-cell *matHeaderCellDef> Created </th>
						<td mat-cell *matCellDef="let creative"> {{creative.created_at}} </td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="['name', 'created']"></tr>
					<tr mat-row *matRowDef="let row; columns: ['name', 'created'];"></tr>       
				</table>
			</div>
		</div>
	</mat-tab>
-->
	<!-- GRID -->
	<mat-tab label="Grid">

		<div class="gridContainer" *ngIf="project">
			<app-grid #gridComponent class="grid"
				[project]="project"
				[projectChannels]="channels"
				[workflows]="workflows"
				[deliveryPackages]="deliveryPackages"
				[selectionMode]="selectionMode"
				[packageMode]="packageMode"
				(add)="add($event)"
				(edit)="edit($event)"
				(delete)="delete($event)"
				(creativeDeleted)="creativeDeleted($event)"
				(openDeliveryPackage)="openDeliveryPackage($event)"
				(packageChanged)="onPackageChanged($event)"
				(onResetApprovals)="loadWorkflows()"
			>
			</app-grid>

			<!-- grid side panel -->
			<div class="side-panel" [ngClass]="{closed:!panelOpen}" *ngIf="isAdmin()">
				<div class="sp-controls" [ngClass]="{open:panelOpen}" >
					<div class="header" *ngIf="panelOpen">
						{{["Help","Multi-select Options","Deliveries","Share"][panelTabIndex]}}
					</div>
					<div class="buttons" [ngClass]="{open:panelOpen}">
						<button mat-icon-button [matTooltip]="'Help'" (click)="openPanel('Help')"><mat-icon>question_mark</mat-icon></button>
						<button mat-icon-button [matTooltip]="'Multi-select Options'" (click)="openPanel('Options')"><mat-icon>grid_view</mat-icon></button>
						<button mat-icon-button [matTooltip]="'Deliveries'" (click)="openPanel('Deliveries')"><mat-icon>mail_outline</mat-icon>
							<span *ngIf="deliveryToAction && !savingPackage" class="packageBadge"
							[matBadge]="deliveryToAction" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></span></button>
						<button mat-icon-button [matTooltip]="'Share'" (click)="openPanel('Share')"><mat-icon>share</mat-icon>
							<span *ngIf="shareToAction && !savingPackage" class="packageBadge"
							[matBadge]="shareToAction" matBadgeSize="small" matBadgeOverlap="false" matBadgeColor="warn" matBagePosition="after"></span></button>
						<div style="border-left:1px solid #d1d1d1; padding-left: 0.25em; margin-left: 0.5em;"><button *ngIf="panelOpen" mat-icon-button [matTooltip]="'Close panel'" (click)="togglePanel()"><mat-icon>close</mat-icon></button></div>
					</div>

				</div>
				<mat-tab-group #panelTabs animationDuration="0ms" mat-align-tabs="end" *ngIf="panelOpen" xstyle="padding: 1em 0.25em;"[selectedIndex]="panelTabIndex" (selectedTabChange)="onPanelTabChange($event)">
					
					<!--<mat-tab label="Team" *ngIf="false">
						<app-project-team [project_uuid]="project_uuid" [channels]="channels" [formats]="formats" [inPanel]="true" (onProductionUsers)="onProductionUsers($event)"></app-project-team>
					</mat-tab> -->
					<mat-tab>
						<!-- HELP -->
						<div class="tab-content">
							<!--
								<div *ngIf="!creatives?.length">
									You have no creatives
								</div>
								<div *ngIf="!channels?.length">
									You have no channels
								</div>
								<div *ngIf="!(workflows[0]?.formats?.length)">
									You have no formats
								</div>
							-->
						</div>
					</mat-tab>
					<mat-tab>
						<!-- OPTIONS -->
						<div class="tab-content">	
							<div>
								<div class="sp-mainheader">items selected: {{selectedCreatives.length}}</div>
								<div [ngStyle]="{'visibility':(selectedCreatives.length > 0) ? 'hidden' : ''}"><span class="instruction">Select 1 or more items from the grid to apply bulk options</span></div>
								
								<mat-divider style="margin-bottom: 1rem;"></mat-divider>

								<div [ngClass]="{'options-disabled': selectedCreatives.length == 0}">

									<!-- bulk amend -->
								<!-- no ready yet...
									<div class="sp-subheader">Add bulk amend</div>
									<div class="panel-section" style="width:100%;" >
										<div class="sp-body">
											<div>Add same amend to each selected creative:</div>
											<div style="padding: 0.5rem 0 0 0; margin-left: -1rem;">
												<button mat-stroked-button class="small" color="primary" (click)="amendSelection()">Add amend</button>
											</div>
										</div>
									</div>
								-->
	
									<!-- colour tag -->
									<div class="sp-subheader">Colour tag</div>
									<div class="panel-section" style="width:100%;">
										<div class="sp-body">
											<app-colour-label
												[selectedColours]="selectedLabels || null" 
												(colourSelected)="colourSelected($event)" >
											</app-colour-label>
										</div>
									</div>
	
									<!--
									<app-pause-creative-widget
										[isPaused]="(selectedPaused == 0 || selectedPaused == selectedCreatives.length) ? selectedPaused : -1"
										(setPaused)="flagSelected($event)">
									</app-pause-creative-widget>
									-->
	
									<!-- re-assign production -->
									<div class="sp-subheader">Production owner</div>
									<div class="panel-section" style="width:100%;">
										<div class="sp-body">
											<app-creative-production 
												[availableProductionUsers]="commonSelectedChannelUsers" 
												[selectedProductionOwners]="uniqueProductionOwners"
												(productionSelected)="productionUserSelected($event)">
												<!--[channelAvailableProduction]="productionsUsers | callback:availableProductionUsers"-->
											</app-creative-production>
										</div>
									</div>
	
									<!-- delete -->
									<div class="sp-subheader">Delete</div>
									<div class="panel-section" style="width:100%;">
										<div class="sp-body">
											<button class="small" style="margin-left: -1.5rem;" mat-flat-button color="warn" (click)="deleteSelection()">Delete selected creatives</button>
										</div>
									</div>
								</div>

								<!-- more options...-->
									<!-- public -->
									<!-- issue flag (i.e. warning icon in bottomn left or sommat)-->
									<!-- <app-flag [selectedFlags]="selectedFlags" (flagSelected)="flagSelected($event)"></app-flag> -->
							</div>
						</div>
					</mat-tab>
					<mat-tab>
						<!-- DELIVERY PACKAGES -->
						<div class="tab-content">
							<div>
								<app-packages *ngIf="deliveryPackages" #deliveryPackagesComponent (newPackage)="newPackage($event)" (save)="savePackages($event)" (delete)="deliveryPackageDelete($event)" (packageSelected)="deliveryPackageSelected($event)" (packageChanged)="checkPackages()" [type]="'delivery'" [packages]="deliveryPackages" [selectedPackage]="selectedPackage" [workflows]="workflows" [saving]="savingPackage"></app-packages>
							</div>
						</div>
					</mat-tab>
					<mat-tab>
						<!-- SHARE PACKAGES -->
						<div class="tab-content">	
							<div>
								<app-packages *ngIf="sharePackages" #sharePackagesComponent (newPackage)="newPackage($event)" (save)="savePackages($event)" (delete)="deliveryPackageDelete($event)" (packageSelected)="deliveryPackageSelected($event)" (packageChanged)="checkPackages()" [type]="'share'" [packages]="sharePackages" [selectedPackage]="selectedPackage" [workflows]="workflows" [saving]="savingPackage"></app-packages>
							</div>
						</div>
					</mat-tab>
					<mat-tab>
						<!-- CLOSE -->
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
		<div *ngIf="false">
			<button (click)="addFormat()" mat-stroked-button>New Format</button>
			<button (click)="addChannel()" mat-stroked-button>New Channel</button>
			<button (click)="deliveryPackageCreate()" mat-stroked-button>New Delivery Group</button>
		</div>
		<div *ngIf="false">
			<mat-form-field appearance="fill">
				<mat-label>Filter Channels</mat-label>
				<mat-select [formControl]="controlChannels" multiple>
				  <mat-option *ngFor="let channel of channels" [value]="channel">{{channel.name}}</mat-option>
				</mat-select>
			  </mat-form-field>
		</div>
	</mat-tab>

	<!-- WORKFLOWS -->
	<mat-tab label="Workflows" *ngIf="isAdmin() || isSuper()">
		<app-project-workflows *ngIf="project && channels && workflows" 
			[project_uuid]="project_uuid" 
			[projectChannels]="channels" 
			[workflows]="workflows"
			[workflow]="null"
			[workgroup_uuid]="project.workgroup_uuid"
			[userAdmins]="userAdmins"
			[newWorkflowName] = "newWorkflowName"
			(add)="add($event)"
			(edit)="edit($event)"
			(delete)="delete($event)">
		</app-project-workflows>
	</mat-tab>

	<!-- =========== NOT USED ============== -->
	<mat-tab label="Team" *ngIf="false && isAdmin()">
		<app-project-team  *ngIf="project" 
			[project_uuid]="project_uuid" 
			[channels]="channels" 
			[formats]="formats" >
		</app-project-team>
	</mat-tab>

	<!-- DELIVERIES-->
	<mat-tab *ngIf="false" label="Deliveries">
		<div style="display: flex; justify-content: center; height: calc(75vh);">
			<mat-card class="card" style="width:33%; margin:10px;">
				<mat-card-header class="header">
					<mat-card-title>Delivery Packages {{currentPackages ? '(' + currentPackages.length + ')' : ''}}</mat-card-title>
					<mat-card-subtitle>List of delivery packages for this project</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content *ngIf="currentPackages">
					<app-genericlist [items]="currentPackages" [search]="search" [labelProp]="getDeliveryPackageLabel" (clickItem)="deliveryPackageSelected($event)" (editItem)="deliveryPackageEdit($event)" (deleteItem)="deliveryPackageDelete($event)"></app-genericlist>
				</mat-card-content>
				<mat-card-actions>
					<button (click)="deliveryPackageCreate()" [matTooltip]="'Add a delivery package'" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
				</mat-card-actions>
			</mat-card>
			<mat-card class="card" style="width:33%; margin:10px;">
				<mat-card-header class="header">
					<mat-card-title>Available Creatives</mat-card-title>
					<mat-card-subtitle>List of creatives available for delivery</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content *ngIf="selectedPackage">
					<div>
						<a #deliveryLink [routerLink]="'/delivery/' + selectedPackage.uuid">Click here to go to download page</a>
						<button mat-icon-button><mat-icon [cdkCopyToClipboard]="getDeliveryLink(deliveryLink)">content_copy</mat-icon></button>
					</div>
					<app-genericlist [items]="availableCreatives" [search]="search" [labelProp]="getCreativeLabel" (toggleItem)="toggleDeliveryItem($event)" ></app-genericlist>
				</mat-card-content>
				<mat-card-actions>
					<!--<button (click)="deliveryPackageCreate()" [matTooltip]="'Add a delivery package'" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>-->
				</mat-card-actions>
			</mat-card>
		</div>
	</mat-tab>

	<!-- =========== NOT USED ============== -->
	<mat-tab label="Admin" *ngIf="false">
		<div style="display: flex; justify-content: center;">
			<mat-card class="card" style="width:33%; margin:10px;">
				<mat-card-header class="header">
					<mat-card-title>Channels {{channels ? '(' + channels.length + ')' : ''}}</mat-card-title>
					<mat-card-subtitle>List of creative channels for this project</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content *ngIf="channels">
				<app-genericlist [items]="channels" [search]="search" [labelProp]="getChannelLabel" (clickItem)="channelSelected($event)" (editItem)="editChannel($event)" (deleteItem)="deleteChannel($event)"></app-genericlist>
<!-- 				<mat-list class="list-scroll">
					<mat-list-item *ngFor="let channel of sort(channels, 'order')" [value]="channel" (click)="channelSelected(channel)">
						
					--><!--<button mat-icon-button [disabled]="channel.order <= 0" (click)="moveChannel($event, channel, -1)"><mat-icon>arrow_drop_up</mat-icon></button>
							<button mat-icon-button [disabled]="channel.order >= channels.length-1" (click)="moveChannel($event, channel, 1)"><mat-icon>arrow_drop_down</mat-icon></button>
					--><!--
						<span matLine>{{channel.order}}. {{channel.name}}</span>
						<button mat-icon-button [matTooltip]="strings.getTooltip('btn_edit_channel')" (click)="editChannel(channel)"><mat-icon>edit</mat-icon></button>
						<button mat-icon-button [matTooltip]="strings.getTooltip('btn_delete_channel')" (click)="deleteChannel(channel)"><mat-icon>delete</mat-icon></button>
					</mat-list-item>
					<mat-list-item *ngIf="loadingChannels">
						<span matLine>Loading channels...</span>
					</mat-list-item>
					<mat-list-item *ngIf="!loadingChannels && channels.length == 0">
						<span matLine>No channels, please create one.</span>
					</mat-list-item>
				</mat-list> -->
				</mat-card-content>
				<!--<mat-card-footer *ngIf="!loadingChannels"></mat-card-footer>-->
					<mat-card-actions>
						<button (click)="addProjectChannel()" [matTooltip]="strings.getTooltip('btn_add_channel')" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
					</mat-card-actions>
			</mat-card>
			<div *ngIf="false">
				<mat-form-field appearance="fill">
					<mat-label>Channel Name</mat-label>
					<input #inputChannelName matInput [ngModel]="selectedChannel ? selectedChannel.name : ''" (ngModelChange)="selectedChannel && selectedChannel.name = $event">
				</mat-form-field>
				<button mat-raised-button color="primary" [disabled]="!inputChannelName.value" (click)="this.saveChannel(inputChannelName.value)">Save Channel</button>
				<button mat-raised-button color="warn" *ngIf="selectedChannel" (click)="deleteChannel()">Delete</button>
				<button mat-raised-button *ngIf="selectedChannel" (click)="selectedChannel = null">Cancel</button>
			</div>
			<mat-card class="card" style="width:33%; margin:10px;">
				<mat-card-header class="header">
					<mat-card-title>Formats</mat-card-title>
					<mat-card-subtitle>List of creative formats for this project</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content *ngIf="false">
				<!--<app-genericlist [items]="formats" [search]="search" (clickItem)="formatSelected($event)" (editItem)="editFormat($event)" (deleteItem)="deleteFormat($event)"></app-genericlist>-->

				<!-- <mat-list  class="list-scroll">
					<mat-list-item *ngFor="let format of formats" [value]="format" (click)="formatSelected(format)">
						<span matLine>{{format.name}}</span>
						<button mat-icon-button [matTooltip]="strings.getTooltip('btn_edit_format')" (click)="editFormat(format)"><mat-icon>edit</mat-icon></button>
						<button mat-icon-button [matTooltip]="strings.getTooltip('btn_delete_format')" (click)="deleteFormat(format)"><mat-icon>delete</mat-icon></button>
					</mat-list-item>
					<mat-list-item *ngIf="loadingFormats">
						<span matLine>Loading formats...</span>
					</mat-list-item>
					<mat-list-item *ngIf="!loadingFormats && formats.length == 0">
						<span matLine>No formats, please create one.</span>
					</mat-list-item>
				</mat-list> -->
				</mat-card-content>
				<!--<mat-card-footer *ngIf="!loadingChannels"></mat-card-footer>-->
				<mat-card-actions *ngIf="!loadingFormats">
					<button (click)="addFormat()" [matTooltip]="strings.getTooltip('btn_add_format')" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
				</mat-card-actions>
				<!--TODO clear selected line-->
			</mat-card>
			<div *ngIf="false">
				<h3 *ngIf="!selectedFormat">Add Format</h3>
				<h3 *ngIf="selectedFormat">Edit Format</h3>
				<mat-form-field appearance="fill">
					<mat-label>Format Name</mat-label>
					<input #inputFormatName matInput [ngModel]="selectedFormat ? selectedFormat.name : ''"  (ngModelChange)="selectedFormat && selectedFormat.name = $event">
				</mat-form-field>
				<mat-form-field appearance="fill">
					<mat-label>Format Group</mat-label>
					<input #inputFormatGroup matInput [ngModel]="selectedFormat ? selectedFormat.group : ''"  (ngModelChange)="selectedFormat && selectedFormat.group = $event">
				</mat-form-field>
				<button mat-raised-button color="primary" [disabled]="!inputFormatName.value" (click)="this.saveFormat(inputFormatName.value, inputFormatGroup.value)">Save Format</button>
				<button mat-raised-button color="warn" *ngIf="selectedFormat" (click)="deleteFormat()">Delete</button>
				<button mat-raised-button *ngIf="selectedFormat" (click)="selectedFormat = null">Cancel</button>
				<mat-selection-list  [multiple]="false" (selectionChange)="formatSelected($event)">
					<mat-list-option *ngFor="let format of formats" [value]="format">{{format.name}}{{format.group ? ' (' +  format.group +')' : ''}}</mat-list-option>
				</mat-selection-list> 
			</div>
		</div>
	</mat-tab>

	<!-- MY TASKS to be re-added in a future release-->
	<mat-tab *ngIf="false" label="My Tasks">
		<div *ngIf="tasks">
			<h2>My tasks</h2>
			<mat-list>
				<ng-container *ngFor="let task of tasks">
					<div mat-subheader>{{task.workflow}}</div>
					<!--<pre>{{task.tasks | json}}</pre>-->
					<div>Production tasks</div>
					<mat-list-item *ngFor="let task of task.productionTasks">
						<!--<mat-icon mat-list-icon>folder</mat-icon>-->
						<div mat-line>
							<a [routerLink]="'/prototypes/creative/' + task.creative_uuid">{{task.creative_name}} | {{task.channel_name}}</a>
						</div>
						<div mat-line>{{task.type}} | {{task.state}}</div>
						<div mat-line [matTooltip]=""> {{task.created_at | date}} ({{task.created_at | dateAgo}}) </div>
					</mat-list-item>
					<div>Approval tasks</div>
					<mat-list-item *ngFor="let task of task.approvalTasks">
						<!--<mat-icon mat-list-icon>folder</mat-icon>-->
						<div mat-line>
							<a [routerLink]="'/prototypes/creative/' + task.creative_uuid">{{task.creative_name}} | {{task.channel_name}}</a>
						</div>
						<div mat-line>{{task.type}} | {{task.state}}</div>
						<div mat-line [matTooltip]=""> {{task.created_at | date}} ({{task.created_at | dateAgo}}) </div>
					</mat-list-item>
					<div>Amend tasks - production</div>
					<mat-list-item *ngFor="let task of task.amendTasksProduction">
						<!--<mat-icon mat-list-icon>folder</mat-icon>-->
						<div mat-line>
							<a [routerLink]="'/prototypes/creative/' + task.creative_uuid">{{task.creative_name}} | {{task.channel_name}}</a>
						</div>
						<div mat-line>{{task.type}} | {{task.state}}</div>
						<div mat-line [matTooltip]=""> {{task.created_at | date}} ({{task.created_at | dateAgo}}) </div>
					</mat-list-item>
					<div>Amend tasks - approver</div>
					<mat-list-item *ngFor="let task of task.amendTasksApprover">
						<!--<mat-icon mat-list-icon>folder</mat-icon>-->
						<div mat-line>
							<a [routerLink]="'/prototypes/creative/' + task.creative_uuid">{{task.creative_name}} | {{task.channel_name}}</a>
						</div>
						<div mat-line>{{task.type}} | {{task.state}}</div>
						<div mat-line [matTooltip]=""> {{task.created_at | date}} ({{task.created_at | dateAgo}}) </div>
					</mat-list-item>
					<div>Amend tasks - moderator</div>
					<mat-list-item *ngFor="let task of task.amendTasksModerator">
						<!--<mat-icon mat-list-icon>folder</mat-icon>-->
						<div mat-line>
							<a [routerLink]="'/prototypes/creative/' + task.creative_uuid">{{task.creative_name}} | {{task.channel_name}}</a>
						</div>
						<div mat-line>{{task.type}} | {{task.state}}</div>
						<div mat-line [matTooltip]=""> {{task.created_at | date}} ({{task.created_at | dateAgo}}) </div>
					</mat-list-item>
				</ng-container>
				<!--

					<div mat-subheader>Folders</div>
					<mat-list-item *ngFor="let folder of folders">
						<mat-icon mat-list-icon>folder</mat-icon>
						<div mat-line>{{folder.name}}</div>
						<div mat-line> {{folder.updated | date}} </div>
					</mat-list-item>
					<mat-divider></mat-divider>
					<div mat-subheader>Notes</div>
					<mat-list-item *ngFor="let note of notes">
						<mat-icon mat-list-icon>note</mat-icon>
						<div mat-line>{{note.name}}</div>
						<div mat-line> {{note.updated | date}} </div>
					</mat-list-item>
				-->
				</mat-list>
			

		</div>

	</mat-tab>

</mat-tab-group>
<!--<mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="loader"></mat-progress-spinner>-->
