import { Component, Inject, Input, OnDestroy, OnInit, Output, EventEmitter, Optional, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
//import { Amend } from 'src/app/api/amends.service';
import { Amend, AmendsService } from 'src/app/api/amends.service';

import { ApiService } from 'src/app/api/api.service';
import { AssetService } from 'src/app/api/assets.service';
import { CreativeService } from 'src/app/api/creative.service';
import { ProjectService } from 'src/app/api/project.service';
import { Project2Service } from 'src/app/api/project2.service';
import { TasksService } from 'src/app/api/task.service';
import { ApprovalChain } from 'src/app/models/ApprovalChain.model';
import { ApprovalGroup } from 'src/app/models/ApprovalGroup.model';

import { AssetVO } from 'src/app/models/asset.model';
import { Creative, CreativeState, ICreative } from 'src/app/models/creative.model';
import { ITask, Task } from 'src/app/models/task.model';
import { IWorkflow, Workflow } from 'src/app/models/workflow.model';

import { GridComponent, INFO_DATA } from '../../grid.component';

import { Permission } from "src/app/models/permissions.model";
import { IUser } from 'src/app/models/user.model';
import { WorkflowService } from 'src/app/api/workflow.service';
import { DeliveryPackage } from 'src/app/models/deliveryPackage.model';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IProject } from 'src/app/models/project.model';
import { AppUserService } from 'src/app/services/app-user.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

	@Input() creative:any;
	@Input() grid:GridComponent;
	@Input() workflow:any;
	@Input() tasks:ITask[];
	@Input() delivery:any; 
	@Input() productionTask:ITask;

	@Output() mouseEntered : EventEmitter<any> =  new EventEmitter();
	@Output() close : EventEmitter<string> =  new EventEmitter();
	@Output() openWorkflow : EventEmitter<number> =  new EventEmitter();
	@Output() openDeliveryPackage : EventEmitter<string> =  new EventEmitter();
	@Output() onResetApprovals : EventEmitter<any> = new EventEmitter();

	private _unsubscribe = new Subject<boolean>();
	private errorMessage;


  public static EVENT_CLOSE:string = "close";
  public static EVENT_CLOSE_DELETE:string = "close_delete";



  //@Input() creative : Creative; // was an input but now injected in
 // public creative : any;//ICreative;
 // private grid: GridComponent;
 // public workflow: any;//IWorkflow;

  public chain:ApprovalChain;
  public approvalGroups:ApprovalGroup[];
  public amends:Amend[]

  public assets:AssetVO[];
  public asset:AssetVO;

  //public tasks:Task[];
  public userActions:any[];
  public user:IUser;
  private creativeState:string;
  private creative_uuid:any;

  public numGroupsApproved: number = 0;
  public totalGroups: number = 0;
  public currentGroup: ApprovalGroup;
  public percentApproved: number = 0;
  public groupLookup: any[];
  public numModerators: number = 0; 
  public moderators: IUser[] = [];
  public numModerated: number = 0; 
  public moderatorIssue: boolean = false;
  public productionUserLookup: any[] = [];
  public numProduction: number = 0;

  //public workflowUsers: IUser[];
  public channelUsers: IUser[];
  public channelProductionUsers: IUser[];

  public numPackages: number = 0;
  public deliveryState: any;
  public deliveryBlocked: boolean;
  public deliveryPackageLookup: any[] = [];
  private project: IProject;
  public isAdmin: boolean;
  public isProduction: boolean;
  public isModerator: boolean;
  public isApprover: boolean;
  private closeOnMouseOut = true;
	statusMsg: string;
	statusWarning: string;
	stateIcon: string;
	approvers: any[];
	approversToReset: any[];
	numApproved: number;
	resetApprovalsCallback: Function;
	isSuper: boolean;

  constructor(
    @Optional() @Inject(INFO_DATA) public data: any,
    private project2Service: Project2Service,
    private api:ApiService,
    private assetsService:AssetService,
    private tasksService:TasksService,
    private projectService: ProjectService,
    private creativeService: CreativeService,
    private workflowService: WorkflowService,
	private amendsService: AmendsService,
	private appUserService: AppUserService,
	private ds:DialogService,
	private dialog: MatDialog,
	private snackBar:MatSnackBar) { }
	

  ngOnDestroy(): void {
    this._unsubscribe.next(true);
    this._unsubscribe.complete();
    this._unsubscribe.unsubscribe();
  }


  ngOnInit(): void {
	//data injected only when triggered by grid rollover
	if(this.data)
	{
		this.creative = this.data.creative;
		this.grid = this.data.grid;
		this.workflow = this.data.workflow;
		this.delivery = this.data.delivery;
		this.project = this.data.grid.project;
		this.resetApprovalsCallback = this.data.resetApprovalsCallback;
		//this.workflowUsers = this.data.workflow.users;

		//console.log("info init", this.data);
		

		this.getAssets();
		this.getTasks();
		//this.getProductionInfo();
		this.getChannelUsers();
		this.getModerationInfo();
		this.analyseGroups();
		this.analyseDelivery();
		this.getUserRoles();
		this.getStatusMsg();
		this.getStateIcon();

		
		

	}
		if(!this.data) this.project = this.creative.project;

		//if(!this.data) console.log("info init", this.creative);
		//this.getUserActions();
		//console.log("INIT TASKS",this.tasks);

		//this.getChannelUsers();
		//this.getProductionInfo();
		//this.getModerationInfo();

		//this.analyseGroups();

		////this.getAmends();
		////this.getWorkflow();
		////this.getChain();
		////this.getApprovalGroups();
	
  }

  ngOnChanges(changes: SimpleChanges) {
		
	if(!changes) return;
	//console.log("NG INFO CHANGES", changes);
	if(changes.workflow && this.workflow){
		//this.getProductionInfo();
		this.getChannelUsers();
		this.getModerationInfo();
	}
	if(changes.creative && this.creative){
		//console.log("UPDATING CREATIVE");
		if(this.creative.project) this.project = this.creative.project;
		this.analyseGroups();
		this.getUserRoles();
		this.getStatusMsg();
		this.getStateIcon();
	}

	/*
console.log("INFO TASKS",this.tasks);
	if(changes.tasks){
		if(!this.tasks) this.tasks = changes.tasks.currentValue;
		for (let i = 0; i < this.tasks.length; i++) {
			let task = this.tasks[i];
			if(task.type == "production") {
				console.log("UPDATING PRODUCTION TASK");
				this.creative.production_task = task;
				this.isProduction = true;
				continue;
			}
		}
	}
*/

	if(changes.delivery)
	{
		this.analyseDelivery();
	}


  }
  clickDeliveryPackage(uuid:string)
  {
	  	this.openDeliveryPackage.emit(uuid);
  }
 
  getStateIcon()
  {
	this.stateIcon = Creative.getIcon(this.creative.state);
  }

  getStatusMsg(){
	this.statusMsg = "";
	this.statusWarning = "";
	//console.log(this.creative);
	switch(this.creative.state){
		case CreativeState.NEW: this.statusMsg = 'Production has <b>not yet started</b>.'; this.statusWarning = 'No production roles have been assigned in this workflow.';break;
		case CreativeState.BUILD: this.statusMsg = 'Production <b>in-progress</b>.'; this.statusWarning = 'No production roles have been assigned in this workflow.'; break;
		case CreativeState.QUERIED: this.statusMsg = 'Waiting for your response to amend queries ';  break;
		case CreativeState.SUBMITTED: this.statusMsg = 'You have submitted feedback for this creative. '; break;
		case CreativeState.APPROVED: this.statusMsg = 'You have approved this creative. '; break;
		case CreativeState.AMENDING:
			/* TODO: put this back - keep generic for now until can eliminate reliance on re-loading creative to get meta counts
			let prodAmendsTodo = this.creative.meta.production_totals - this.creative.meta.production_actioned;
			this.statusMsg = 'Production needs to ';
			this.statusMsg += (prodAmendsTodo > 0) ? 'complete ' + prodAmendsTodo + ' amends' : 'submit completed amends';
			*/
			//temp generic production message:
			this.statusMsg = 'Waiting for Production to resolve/submit amends';
			this.statusWarning = 'No production roles have been assigned in this workflow.'; 
			break;
		case CreativeState.QUALIFYING:
			/* TODO: put this back - keep generic for now until can eliminate reliance on re-loading creative to get meta counts
			let moodAmendsTodo = this.creative.meta.moderator_totals - this.creative.meta.moderator_actioned;
			this.statusMsg = 'Amend Moderator needs to ';
			this.statusMsg += (moodAmendsTodo > 0) ? 'respond to ' + moodAmendsTodo + ' amends' : 'submit amend responses';
			*/
			//temp generic moderator message:
			this.statusMsg = 'Waiting for Amend Moderator to resolve/submit amends';			
			break;
		//case CreativeState.CONFIRMING:this.statusMsg = '<b>'+(this.creative.meta)+'</b> completed amend(s) remaining to be confirmed by Moderator.'; this.statusWarning = 'No moderator roles have been assigned in this workflow.'; break;
		//case CreativeState.checking:this.statusMsg = '<strong>'+(this.creative.meta)+'</strong> completed amend(s) remaining to be checked by approver(s) in group <b>'{{currentGroup.name}}'</b>.'; this.statusWarning = 'No approvers have been assigned to the current approval group.'; break;
		case CreativeState.DONE:this.statusMsg = 'The creative has been <b>fully approved</b>. Ready to deliver.</span></ng-container'; break;
		case CreativeState.WARNING: this.statusWarning = 'Waiting for approval but the current approval group has no approvers assigned to it'; break;					
	}

	//Add 'watiing for'statement for certain states 
	//TODO: relies on groupInfo (generated by creative state load!)
	if(this.currentGroup){
		let waitingForCount = (this.currentGroup['total'] - (this.currentGroup['approved'] + this.currentGroup['submitted']));
		let msg2 = 'Waiting for response from <b>' + waitingForCount + '</b> ';
		switch(this.creative.state){
			case CreativeState.QUERIED: 
				waitingForCount -= 1;
				//replace beginning of msg
				msg2 = 'and <b>' + waitingForCount + '</b> ';
			
			case CreativeState.SUBMITTED:
			case CreativeState.APPROVED: msg2 += 'other '; 	
			case CreativeState.APPROVAL: 
				if(waitingForCount > 0){
					this.statusMsg += msg2 + 'approver(s) in group <b>' + this.currentGroup.name + '</b>'; 
				} else {
					this.statusWarning = 'No approvers have been assigned to the current approval group.'; 
				}
				break;
		}
	}
  }

  onMouseEnter(event)
  {
	if(this.data)
	{
		//console.log("OVER", this);
    	this.mouseEntered.emit(null);
    	//this.grid.recheckIfInMenu = true;
	}
  }
  onMouseLeave(event:MouseEvent)
  {
	// hack to stop it closing if you rollover an overlay triggered by a menu
	if((event.relatedTarget as HTMLElement)?.classList?.contains("cdk-overlay-backdrop")){
		return;
	}
	// TODO delayed close (200ms or so)
	if(this.data && this.closeOnMouseOut) 
	{
		//this.close.emit(null);
    	//return; // TODO put this back
   	 	this.grid.closeInfoPanel(null, true);
	} 
  }
  loadCreative()
	{
		// TODO lock the page
		this.creativeService.findOne(this.creative_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.creative = response["data"] as ICreative;
				//console.log("getCreative", this.creative);
				// this.amends = this.currentCreative.amends as Amend[];
				this.tasks = this.creative.tasks as Task[];
				//this.onCreativeLoaded();
			},
			error => this.errorMessage = <any>error
		);
	}
	/*//NOT USED
	resetCreative()
	{
		this.snackBar.open("resetting creative...", '', {duration:5000});
		this.creativeService.reset(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.snackBar.open("creative reset...", '', {duration:5000});
				this.close.emit(InfoComponent.EVENT_CLOSE_DELETE);
			},
			error => this.errorMessage = <any>error
		);
	}*/
	deleteCreative()
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Delete Creative - " + this.creative.format.name + " (" + this.creative.channel.name + ")",
				//subtitle:this.creative.format.name,
				body:`All assets and actions relating to this creative slot will be deleted. 

				Are you sure you want to delete this creative?`,
				positive:"Delete",
				negative:"Cancel"}
		  });
		  dialogRef.afterClosed().subscribe((result: any) => {
			if(result)
			{
				//do this at grid level because we toast this panel before the repsonse comes back
				this.close.emit(this.creative);//.uuid
				/*
				this.snackBar.open("deleting creative...", '', {duration:5000});
				this.creativeService.delete(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
					(response) => {
						this.snackBar.open("creative deleted...", '', {duration:5000});
						this.close.emit(InfoComponent.EVENT_CLOSE_DELETE);
					},
					error => this.errorMessage = <any>error
				);
				*/
			}
		  });
	}
	assetCheck()
	{
		this.snackBar.open("checking assets...", '', {duration:5000});
		this.creativeService.assetCheck(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.snackBar.open("assets checked...", '', {duration:5000});
			},
			error => this.errorMessage = <any>error
		);
	}
	/*
  getUserActions()
  {
    this.creativeService.getUserActions(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.userActions = response["data"].filter(action  => action.message != "no action required");
			},
			error => this.errorMessage = <any>error
		);
  }
  */
  getAssets()
	{
		this.creativeService.getAssets(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.assets = response.data.map((asset) => new AssetVO(asset));
				if(this.assets.length) this.asset = this.assets[0];
				
			},
			error => this.errorMessage = <any>error
		);
	}
	// we could inject this..
	getWorkflow()
	{
		/*
		this.creativeService.getWorkflow(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.workflow = response.data[0] ?? null;
				if(this.workflow) Workflow.decorate(this.workflow);
			},
			error => this.errorMessage = <any>error
		);*/
		this.workflowService.findOne(this.creative.workflow_uuid.toString()).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.workflow = response.data.length ? response.data[0] : null;
				Workflow.decorate(this.workflow);
				// GROSS
				//this.getProductionInfo();
				this.getChannelUsers();
				this.getTasks();
				//this.projectChannel = this.workflow.channels.find(channel => channel.id == this.creative.channel_id).project_channel_id;
				
			},
			error => this.errorMessage = <any>error
		);
	}
	getChain()
	{
		this.creativeService.getChain(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.chain = response.data.length ? response.data[0] : null;
			},
			error => this.errorMessage = <any>error
		);
		// test
		this.creativeService.getChain2(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				
			},
			error => this.errorMessage = <any>error
		);
	}

	
	/*
	getApprovalGroups()
	{
		this.creativeService.getApprovalGroups(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.approvalGroups = response.data;
			},
			error => this.errorMessage = <any>error
		);
	}*/
	viewCreative(event)
	{
		// TODO should this be an event or just smash that link
		
	}
  nextAsset(direction)
  {
    if(!this.assets || this.assets.length <= 1) return;
    let index = this.assets.indexOf(this.asset);
    index += direction;
    index %= (this.assets.length);
    if(index < 0) index += this.assets.length;
    this.asset = this.assets[index];
  }
	getTasks()
	{
		//this.getCreativeState();
		//this.getUserAction();
		//console.log("getting TASKS");
		this.tasksService.getTasks(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.tasks = response.data as Task[];
				// TODO this sucks better to just reload the creative.. but I am hot
				this.creative.production_task = this.tasks.find((task) => task.type == "production");
				//console.log("TASKS", this.tasks);
			},
			error => this.errorMessage = <any>error
		);
	}
	getCreativeState()
	{
		//NO LONGER USED
		this.creativeService.getState(this.creative_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.creativeState = response["data"][0];
			},
			error => this.errorMessage = <any>error
		);
	}

/*
  onCreativeLoaded()
	{
		this.getCurrentUserPermissions();

		this.creativeService.getUserActions(this.creative.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.userActions = response["data"];
			},
			error => this.errorMessage = <any>error
		);
	//	this.getAssets();
	//	this.getTasks();

	}
	*/
	/*
  getCurrentUserPermissions()
	{
    
		this.projectService.getUserPermissions(this.currentCreative.project_id, this.user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			//console.log("\tcurrent user permissions", res);
			//this.userPermissions = res["data"];
			//this.user.permissions = this.userPermissions;
			// TODO better user permissions system
			//this.user.permissions = {creative:{[this.currentCreative.uuid]:this.userPermissions}};
		});
	}
	*/

	getChannelUsers(){
		//console.log("getting channel users...");
		/*
		this.channelUsers = this.workflow.channels.find((chan) => chan.id == this.creative.channel_id).users;
		this.channelProductionUsers = this.channelUsers.filter(this.isProduction, this);
	
		//console.log("channel: ("+this.creative.channel_id+")"+this.channelUsers);
		*/


		//static getChannelProductionUsers(creative_channel_id: number, workflow: IWorkflow, workflow_productionChannels:any = null) : IUser[]{
		this.channelProductionUsers = Workflow.getChannelProductionUsers(this.creative.channel_uuid, this.workflow,this.workflow.productionChannels);
		this.numProduction = this.channelProductionUsers.length;
		//console.log(this.channelProductionUsers);

		
	}

	getUserRoles(){
		if(this.creative.permissions){
			//console.log("getUserRoles A");
			this.isAdmin =  	(this.creative.permissions?.project || 0) & Permission.ADMIN 		? true : false;//(this.project["scoped_permissions"] & Permission.ADMIN) != 0;
			this.isProduction = (this.creative.permissions?.channel || 0) & Permission.PRODUCTION 	? true : false;//(this.creative.production_task) ? true : false;
			this.isModerator =  (this.creative.permissions?.workflow || 0) & Permission.VALIDATOR 	? true : false;//(this.project["scoped_permissions"] & Permission.VALIDATOR) != 0;
			this.isApprover =  	(this.creative.permissions?.channel || 0) & Permission.APPROVAL 	? true : false;//(this.project["scoped_permissions"] & Permission.APPROVAL) != 0;
		} else {
			//console.log("getUserRoles B");
			let channel = this.workflow.channels.find(channel => channel.uuid == this.creative.channel_uuid);
			let user = channel.users.find(user => user.uuid == this.appUserService.appUser.uuid);	
			this.isAdmin =  	(this.project["scoped_permissions"] & Permission.ADMIN) != 0;
			this.isProduction = (user && (user["scoped_permissions"] & Permission.PRODUCTION) != 0);
			this.isModerator =  (this.project["scoped_permissions"] & Permission.VALIDATOR) != 0;
			this.isApprover = 	(user && (user["scoped_permissions"] & Permission.APPROVAL) != 0);
		}
		this.isSuper = this.appUserService?.appUser.super;
			
	}
	getModerationInfo()
	{
		/*for(var mod in this.workflow.moderators)
		{
			this.numModerators += 1;
		}
		if(this.numModerators == 0) this.moderatorIssue = true;
		*/
		/*for (let i = 0; i < this.workflow['users'].length; i++)
		{
				const user = this.workflow['users'][i];
				if(Permission.hasPermission(user.scoped_permissions, Permission.VALIDATOR))
				{
					this.moderators.push(user);
				}
		}
		*/
		this.numModerators = this.workflow['moderators'].length;
	}

	analyseGroups(){
		this.totalGroups = this.creative.groupInfo.groups.length;
		let numGA = 0;
		let currentGrp = null;
		let numApprovers = 0;
		this.numApproved = 0;
		let pcntApproved = 0;
		this.approvers = [];
		//let numSubmitted = 0;
		//group.done and .active is handled by the back-end, so just do the counts now, dont modify group info
		for (let i = 0; i < this.creative.groupInfo.groups.length; i++) {
			let grp = this.creative.groupInfo.groups[i];
			//grp.done = false;
			//get approver info
			numApprovers += grp.total;
			this.numApproved += grp.approved;

			for (let u = 0; u < grp.users.length; u++ )
			this.approvers.push(grp.users[u]);
			//pcntApproved = Math.floor((numApproved/numApprovers)*100) || 0;
			//count fully approved
			//if (grp.total > 0 && grp.approved == grp.total )
			if(grp.done)
			{
				numGA ++;
				//grp.done = true;
			}/* else {
				if(!currentGrp) currentGrp = grp;
				//console.log("currentGrp "+currentGrp.name);
			}*/
		}

		//this.currentGroup = currentGrp;
		this.currentGroup = this.creative.groupInfo.groups[this.creative.groupInfo.current];
		this.numGroupsApproved = numGA;
		this.percentApproved = Math.floor((this.numApproved/numApprovers)*100) || 0;
		
		//console.log("currentGroup "+this.currentGroup.name);
	}

	analyseDelivery()
	{
		if(!this.delivery) return;
		this.deliveryPackageLookup = [];
		this.delivery.forEach(_package => {
			if(_package.package){//for grid view only
				let count = _package.count;
				let total = _package.total;
				let status = DeliveryPackage.getStatus(_package.package, count, total);
				let statusObj:any = {
					icon:DeliveryPackage.getIcon(status, total),
					class:DeliveryPackage.getClass(status),
					tooltip:(DeliveryPackage.getApprovalMessage(status, total,count)) + "\n" + DeliveryPackage.getDownloadMessage(status, total, count, _package.partialAllowed),
					warning:status == "actioned_notAllApproved" ? true : false
				};
				this.deliveryPackageLookup[_package.uuid] = statusObj;
			}
		});
	}


//	isProduction = (user):boolean => {
		/*older - not used
		if(this.workflow.productionChannels[user.uuid]){
			let hasProdChannel = (this.workflow.productionChannels[user.uuid]).find((id) => id == this.creative.channel_id);
			return hasProdChannel;
		}
		return false;
		*/

		/*old - moved to static Workflow function
		return this.productionUserLookup[user.uuid];
		*/
//	}

//	getProductionInfo(){
		/* old - moved to static Workflow function
		this.numProduction = 0;
		
		for(const [k,v] of Object.entries(this.workflow.productionChannels))
		{
			if (this.workflow.productionChannels[k].find((id) => id == this.creative.channel_id)) 
			{
				this.productionUserLookup[k] = true;
				this.numProduction++;
			}
		}
		*/
		
//	}
	setProduction(user)
	{
		this.snackBar.open("setting production user", null, {duration: 4000});
		this.creativeService.setProduction(this.creative.uuid, user.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			this.snackBar.open("production user updated", null, {duration: 2000});
			//this.production.current = response.data[0];
			//this.loadingProduction = false;
			this.getWorkflow();
		});
	}
	isProductionOwner = (user):boolean => {
		//console.log("INFO creative: "+this.creative);
		return this.creative.production_task && this.creative.production_task.user_uuid == user.uuid;
		//return this.productionTask && this.productionTask.user_uuid == user.uuid;
	}

	/*
	isModerator = (user):boolean => {
		return this.workflow.moderators[user.uuid];
	}*/

	isIndicatedProduction(user){
		switch(this.creative.state){
			case "new": 
			case "build": 
			case "amending":
				if((this.creative.production_task && this.creative.production_task.user_uuid == 'app') || this.isProductionOwner(user)) return true;
		}
		return false;
	}
	isIndicatedModerator(){
		switch(this.creative.state){
			//case "confirming":
			case "qualifying": 
				return true;
		}
		return false;
	}

	editWorkflow(workflow:IWorkflow)
	{
		this.openWorkflow.emit(workflow.id);
	}
	

	//OPTIONS
	setLabel(color:string)
	{
		// TODO - set up snackbar emitter from info panel
		if(color=='') color = 'null';
		//this._snackBar.open("Setting label", null, {duration: 4000});
		this.creativeService.setLabel(this.creative.uuid, color).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			//this._snackBar.open("label set", null, {duration: 2000});
			this.creative.label = color;
			//this.mergeResponse(response)}
		});
	}

	pauseCreative(isPaused)
	{
		// TODO - set up snackbar emitter from info panel

		let msgStart = (isPaused) ? "Pausing creative" : "Un-pausing creative";
		let msgEnd	 = (isPaused) ? "Creative paused" : "Creativ un-paused";
		//this._snackBar.open(msgStart, null, {duration: 4000});
		
		// TODO - using 'flag' prop on Creative to store 'paused' value instead
		let pauseVal = (isPaused) ? 1 : 0;
		this.creativeService.setFlag(this.creative.uuid, pauseVal).pipe(takeUntil(this._unsubscribe)).subscribe(response => {
			
			//this._snackBar.open(msgEnd, null, {duration: 2000});
			this.creative.flag = pauseVal;
			//this.mergeResponse(response)}
		});
		
	}

	resetApproval_all(){
		let approversToReset = [];
		let creativesToReset = [this.creative.uuid];
		this.approvers.forEach(approver => {
			if(approver.state == Task.APPROVAL_APPROVED){
				approversToReset.push(approver);
			};
		});

		this.confirmApprovalReset(approversToReset,creativesToReset);
	}
	resetApproval_notlatest(){
		let approversToReset = [];
		let creativesToReset = [this.creative.uuid];
		this.approvers.forEach(approver => {
			if(approver.state == Task.APPROVAL_APPROVED && approver.version != this.creative.version_latest){
				approversToReset.push(approver);
			};
		});

		this.confirmApprovalReset(approversToReset,creativesToReset);
	}

	resetApproval_user(approverUser){
		let approversToReset = [];
		let creativesToReset = [this.creative.uuid];
		if(approverUser.state == Task.APPROVAL_APPROVED){
			approversToReset.push(approverUser);
		}

		this.confirmApprovalReset(approversToReset,creativesToReset);
	}

	resetApproval_group(approvalGroup:ApprovalGroup){
		let approversToReset = [];
		let creativesToReset = [this.creative.uuid];
		approvalGroup.users.forEach(approver => {
			if(approver.state == Task.APPROVAL_APPROVED){
				approversToReset.push(approver);
			};
		});

		this.confirmApprovalReset(approversToReset,creativesToReset);
	}

	confirmApprovalReset(approversToReset:any[],creativesToReset:any[]){
		let approverNames = '';
			approversToReset.forEach(approver => {
				approverNames += approver.name + '<br/>';
			});

			let confirm:any = {
				title: 'Approval reset',
				message: (approversToReset.length > 0) ? 'Approvals will be reset for the following users:' : 'There are no matching approvals to reset',
				message2: approverNames,
				confirm_button_label: "Reset approvals",
				dismiss_button_label: (approversToReset.length > 0) ? "Cancel" : 'OK',
				confirmAction: () => {
					//post users and creative uuids to api
					if(approversToReset.length > 0){
						this.tasksService.resetApprovals(approversToReset,creativesToReset).pipe(takeUntil(this._unsubscribe)).subscribe(
							(response) => {
								//if(this.data && this.resetApprovalsCallback) {
								//	console.log("respond to reset via grid");
								//	this.resetApprovalsCallback()
								//} else {
									this.onResetApprovals.emit();
								//}
							},
							error => this.errorMessage = <any>error
						);
					}
				},
				dismissAction: () => {
					// do nothing
				}
			};
			if(approversToReset.length > 0)	this.ds.openConfirm(confirm);
			else this.ds.openAlert(confirm);
	}
}
