import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApprovalChain } from '../models/ApprovalChain.model';
import { Permission } from '../models/permissions.model';
import { IProject, Project } from '../models/project.model';
import { IProjectChannel, ProjectChannel } from '../models/projectChannel.model';
import { IUser } from '../models/user.model';
import { IWorkflow, Workflow } from '../models/workflow.model';
import { CrudService } from './crud.service';
import { ApiResponse } from './OvBaseService';
import { IChannel } from '../models/channel.model';
import { IFormat } from '../models/format.model';



@Injectable({
	providedIn: 'root'
})
export class ProjectService extends CrudService<IProject, number> {
	constructor(protected http: HttpClient) {
		super(http, 'project2');
	}

	private creativeUsersSubject:BehaviorSubject<any> = new BehaviorSubject<any>({});
	public creativeUsers = this.creativeUsersSubject.asObservable();
	public setCreativeUsers(creativeUsers:any)
	{
		this.creativeUsersSubject.next(creativeUsers);
	}

	getUserPermissions(project_id: string, user_id:string) {
		return this._get(`${this.base}/${project_id}/userPermissions/${user_id}`);
	}
	// get all the users permisions for this project and all workflows therin
	getAllUserPermissions(project_id: string, user_id:string):Observable<ApiResponse<Project>>{
		return this._get(`${this.base}/${project_id}/userAllPermissions/${user_id}`);
	}
	grantUserPermission(project_id : string, user_id:string, permission : number) {
		return this._post(`${this.base}/${project_id}/grantUserPermission/${user_id}/${permission}`);
	}
	grantUserChannel(project_id : string, user_id:string, channel_id : string, permission:string) {
		return this._post(`${this.base}/${project_id}/grantUserChannel/${user_id}/${channel_id}/${permission}`);
	}
	revokeUserChannel(project_id : string, user_id:string, channel_id : string, permission:string) {
		return this._post(`${this.base}/${project_id}/revokeUserChannel/${user_id}/${channel_id}/${permission}`);
	}
	grantUserAllChannels(project_id : string, user_id:string, permission:string) {
		return this._post(`${this.base}/${project_id}/grantUserAllChannels/${user_id}/${permission}`);
	}
	revokeUserAllChannels(project_id : string, user_id:string, permission:string) {
		return this._post(`${this.base}/${project_id}/revokeUserAllChannels/${user_id}/${permission}`);
	}
	test(project_id : string, user_id:string) {
		return this._post(`${this.base}/test/${user_id}`);
	}
	test2(project_id : string, user_id:string) {
		return this._post(`${this.base}/${project_id}/test/${user_id}`);
	}
	removeUserPermission(project_id : string, user_id:string, permission : number) {
		return this._post(`${this.base}/${project_id}/revokeUserPermission/${user_id}/${permission}`);
	}
	private _teamSubject = new BehaviorSubject<any>(null);
	public team = this._teamSubject.asObservable();
	loadTeam(project_uuid: string):void
	{
		this.getTeam(project_uuid).pipe().subscribe((res) => {
			// EMPTY as handled by subject
		});
	}
	getTeam(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/team`).pipe(map(
			(response:ApiResponse<any>) => {
				let currentUsers = response.data["users"];
				let availableUsers = response.data["available"];
				currentUsers?.sort((a, b) => a.name.localeCompare(b.name));
				availableUsers?.sort((a, b) => a.name.localeCompare(b.name));
				// update pointers to point to the same user not a duplicate
				let map = {};
				for (let i = 0; i < currentUsers.length; i++) {
					const currentUser = currentUsers[i];
					map[currentUser.uuid] = currentUser;	
					
					//check for any 'super' users that wont have been added to data["available"] but who may be an admin
					let userInAvailable = availableUsers.find(user => user.uuid === currentUser.uuid);
					if(!userInAvailable) availableUsers.push(currentUser);
				}
				for (let i = 0; i < availableUsers.length; i++) {
					const user = availableUsers[i];
					const currentUser = map[user.uuid];
					if(currentUser)
					{
						availableUsers[i] = currentUser;
					}else{
						user.scoped_permissions = 0;
					}
				}
				
				// who has access
				let users = availableUsers.filter((user) =>
					user.scoped_permissions & Permission.ACCESS
				);
				// who is admin
				let admins = users.filter((user) =>
					user.scoped_permissions & Permission.ADMIN
				);


				let team = {admins, users, availableUsers};
				this._teamSubject.next(team);
				return team;
				/*
				let projectApprovalUsers = projectUsers.filter((user) =>
					user.scoped_permissions & Permission.APPROVAL
				);
				let projectProductionUsers = projectUsers.filter((user) =>
					user.scoped_permissions & Permission.PRODUCTION
				);
				*/
			}
		));
	}
	getUsers(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/users`);
	}
	/*
	getUsers(project_uuid: string, ...permissions:string[]):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/users`, {'permissions[]':permissions});
	}*/
	/*
	getAvailableUsers(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/availableUsers`);
	}
	*/
	getCreatives(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/creatives`);
	}
	getCreative(project_uuid: string, uuids:string[]):Observable<any>{
		const params = new HttpParams({fromObject:{'uuids[]':uuids}});
		return this._get(`${this.base}/${project_uuid}/creative`, params);
	}
	private _tasksSubject = new BehaviorSubject<any[]>(null);
	public tasks = this._tasksSubject.asObservable();
	loadProjectTasks(project_uuid :string)
	{
		this.getProjectTasks(project_uuid).pipe().subscribe((res) => {
			this._tasksSubject.next(res.data);
		});
	}
	getProjectTasks(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/tasks`);
	}
	private _workflowsSubject:BehaviorSubject<IWorkflow[]> = new BehaviorSubject<IWorkflow[]>(null);
	public workflows$ = this._workflowsSubject.asObservable();
	loadWorkflows(project_uuid :string, filters:string[] = null, wrapper:(observable: Observable<any>) => Observable<any> = null )
	{
		
		if(wrapper) 
		{
			return this.getWorkflows(project_uuid, filters).pipe(wrapper).subscribe((res) => {
				//console.log("loaded workflows (wrapper)",res.data);
				this._workflowsSubject.next(res.data);
			});
		}else{
			return this.getWorkflows(project_uuid, filters).subscribe((res) => {
				//console.log("loaded workflows",res.data);
				this._workflowsSubject.next(res.data);
			});
		}
	}
	removeWorkflow(uuid:string)
	{
		let workflows:IWorkflow[] = this._workflowsSubject.value;
		if(workflows)
		{
			// find and remove
			const index = workflows.findIndex(workflow => workflow.uuid === uuid);
			if(index != -1){
				workflows.splice(index, 1);
				this._workflowsSubject.next(workflows);
			}
		}
	}
	getWorkflows(project_uuid: string, filters:string[] = null):Observable<any>{
		let params:HttpParams;
		if(filters)
		{
			params = new HttpParams();
			filters.forEach(filter => params = params.append("filters[]", filter));
		}
		return this._get(`${this.base}/${project_uuid}/workflows`, params).pipe(
			map((response:ApiResponse<IWorkflow>) => {
				let workflows:IWorkflow[] = response.data;
				
				// test for merge vs replace

				//console.log("getWorkflows",project_uuid, response.data)
				let originalWorkflows:IWorkflow[] = this._workflowsSubject.value;
				if(originalWorkflows?.length > 0 && filters)
				{
					// check if they have the same project
					if(originalWorkflows[0].project_uuid == project_uuid){

						// if we filtered on a single workflow and got none back - then we just remove and carry on
						const workflowFilter = filters.find(filter => filter.indexOf("workflows") != -1);
						if(workflowFilter && workflows.length == 0)
						{
							const workflowFilterTest = workflowFilter.match(/workflows:\[workflows.uuid:(.+)]/);
							const workflow_uuid = workflowFilterTest[1] ?? null;
							if(workflow_uuid)
							{
								// find and remove and then skip
								let originalIndex = originalWorkflows.findIndex(workflow => workflow.uuid == workflow_uuid);
								if(originalIndex != -1) originalWorkflows.splice(originalIndex, 1);
							}
						}
						// try and merge base on filters
						// filtered content so merge based on filters
						// TODO we could test the filter type to determine merge type
						const newWorkflows = [];
						originalWorkflows.forEach(workflow => {
							let updated = workflows.find(wf => wf.uuid == workflow.uuid);
							if(!updated) return;
							// ensure it has cretives (and maybe others?)
							if(!workflow.creatives) workflow.creatives = [];
							// `workflows:[workflows.uuid:${workflow_uuid}]`, `formats:[creative_formats.uuid:${format_uuid}]`, `creatives:[format_uuid:${format_uuid}]`
							// `workflows:[workflows.uuid:${message.metadata.id}]`, 'creatives', 'formats', 'channels', 'users'
							const channelFilter = filters.find(filter => filter.indexOf("channels") != -1);
							const creativeFilter = filters.find(filter => filter.indexOf("creatives") != -1);
							const formatFilter = filters.find(filter => filter.indexOf("formats") != -1);
							const userFilter = filters.find(filter => filter.indexOf("users") != -1);
							if(channelFilter) {
								if(channelFilter.indexOf(':') != -1)	this.mergeElements(workflow.channels, updated.channels);
								else workflow.channels = updated.channels;	
							}
							if(creativeFilter) {
								if(creativeFilter.indexOf(':') != -1) this.mergeElements(workflow.creatives, updated.creatives);
								else workflow.creatives = updated.creatives;
							}
							if(formatFilter) {
								if(formatFilter.indexOf(':') != -1) this.mergeElements(workflow.formats, updated.formats);
								else workflow.formats = updated.formats;
							}
							if(userFilter) {
								workflow['users'] = updated['users'];//this.mergeElements(workflow['users'], updated['users']);
							}
							if(updated.approval_groups) {
								workflow.approval_groups = updated.approval_groups;	//this.mergeElements(workflow.approval_groups, updated.approval_groups);
							}
						});
						workflows.forEach(workflow => {
							const originalMatch = originalWorkflows.find(wf => wf.uuid == workflow.uuid);
							if(!originalMatch) newWorkflows.push(workflow);
						})
						response.data = originalWorkflows.concat(newWorkflows);
						workflows = response.data;
					}
				}
				// sort
				workflows.sort((a, b) => a.name.localeCompare(b.name));
				workflows.forEach(workflow => workflow.formats.sort((a, b) => {
					if(!a.group || !b.group) return a.name.localeCompare(b.name);
					return a.group.localeCompare(b.group) || a.name.localeCompare(b.name);
				}
				));

				
				workflows.forEach(workflow => Workflow.decorate(workflow));
				return response; 
			}));
	}
	mergeElements(original:any[], updated:any[])
	{
		updated.forEach(updatedItem => {
			const foundIndex = original.findIndex(originalItem => originalItem.uuid == updatedItem.uuid);
			if(foundIndex != -1)  original[foundIndex] = updatedItem;
			else original.push(updatedItem);
		});
	}
	getFormats(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/formats`);
	}

	private _channelsSubject = new BehaviorSubject<ProjectChannel[]>(null);
	public channels$ = this._channelsSubject.asObservable();
	loadChannels(project_uuid :string)
	{
		return this.getChannels(project_uuid).pipe().subscribe((res) => {
			this._channelsSubject.next(res.data);
		});
	}
	getChannels(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/channels`);
	}
	removeChannel(uuid:string)
	{
		let channels:IProjectChannel[] = this._channelsSubject.value;
		if(channels)
		{
			// find and remove
			const index = channels.findIndex(channel => channel.uuid === uuid);
			if(index != -1){
				channels.splice(index, 1);
				this._channelsSubject.next(channels);
			}
		}
	}
	createWorkflow(project_uuid: string, name:string):Observable<any>
	{
		return this._post(`${this.base}/${project_uuid}/workflow`, {name});
	}
	createFormat(project_uuid: string, workflow_id:number, name:string, group:string = null):Observable<any>
	{
		return this._post(`${this.base}/${project_uuid}/format`, {workflow_id, name, group});
	}
	updateFormat(project_uuid: string, format:any):Observable<any>
	{
		return this._put(`${this.base}/${project_uuid}/format/${format.id}`, format);
	}
	deleteFormat(project_uuid: string, format:any):Observable<any>
	{
		return this._delete(`${this.base}/${project_uuid}/format/${format.id}`);
	}
	deleteCreatives(project_uuid: string, data: any[]) {
		return this._post(`${this.base}/${project_uuid}/creatives/delete`, data);
	}
	// could create/update be combined into one
	createChannel(project_uuid: string, name:string):Observable<any>
	{
		return this._post(`${this.base}/${project_uuid}/channel`, {name:name});
	}
	updateChannel(project_uuid: string, channel:any):Observable<any>
	{
		return this._put(`${this.base}/${project_uuid}/channel/${channel.uuid}`, channel);
	}
	/*
	updateGroupName(project_uuid: string, oldName:string, newName: string) {
		return this._post(`${this.base}/${project_uuid}/groupName`, {oldName, newName});
	}*/
	updateCreativeLabels(project_uuid: string, data: any[]) {
		return this._post(`${this.base}/${project_uuid}/updateLabels`, data);
	}
	updateCreativeFlags(project_uuid: string, data: any[]) {
		return this._post(`${this.base}/${project_uuid}/updateFlags`, data);
	}
	updateCreativeProductionUser(project_uuid: string, data: any[]) {
		return this._post(`${this.base}/${project_uuid}/updateProductionUser`, data);
	}
	deleteChannel(project_uuid: string, channel:any):Observable<any>
	{
		return this._delete(`${this.base}/${project_uuid}/channel/${channel.uuid}`);
	}
	createCreative(project_uuid: string, channel:number, format:number):Observable<any>
	{
		throw new Error("unused");
		
		return this._post(`${this.base}/${project_uuid}/creative`, {channel, format});
	}
	// approval groups
	approvalChainCreate(project_uuid: string, chain:ApprovalChain):Observable<any>{
		return this._post(`${this.base}/${project_uuid}/approvalChainCreate`, chain);
	}
	approvalChainDelete(project_uuid: string, chain_id: number):Observable<any>{
		return this._post(`${this.base}/${project_uuid}/approvalChainDelete/${chain_id}`);
	}
	private _approvalChainsSubject = new BehaviorSubject<ApprovalChain[]>(null);
	public approvalChains = this._approvalChainsSubject.asObservable();
	loadApprovalChains(project_uuid:string)
	{
		this.getApprovalChains(project_uuid).pipe().subscribe((res) => {
			this._approvalChainsSubject.next(res.data);
		});
	}
	getApprovalChains(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/approvalChains`);
	}
	getApprovalTasks(project_uuid: string):Observable<any>{
		return this._get(`${this.base}/${project_uuid}/approvalTasks`);
	}
	/*
	approvalGroupCreate(project_uuid: string):Observable<any>{
		return this._post(`${this.base}/${project_uuid}/approvalGroupCreate`);
	}
	approvalGroupDelete(project_uuid: string, group_uuid: string):Observable<any>{
		return this._post(`${this.base}/${project_uuid}/approvalGroupDelete/${group_uuid}`);
	}*/
	copy(project_uuid:string, data:{
		name:string,
		channels:boolean,
		workflows:boolean,
		users:boolean,
		formats:boolean,
		creatives:boolean,
		assets:boolean,
	  }):Observable<any>
	{
		return this._post(`${this.base}/${project_uuid}/copy`, data);
	}
	getApproverInfo(project_uuid:string, user_uuid:string, channels:number[]):Observable<any>
	{
		return this._post(`${this.base}/${project_uuid}/approverInfo/${user_uuid}`, {channels});
	}
	removeUser(project_uuid:string, user:IUser, actions:any[])
	{
		return this._post(`${this.base}/${project_uuid}/removeUser/${user.uuid}`, {actions});
	}
	loadLatestDisussions(project_id: string, creative_uuids: any[]) {
		return this._post(`${this.base}/${project_id}/getLastMessagesForCreatives`, {creatives:creative_uuids} );
	}
}
