import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { Globals } from '../global';
import { Asset, AssetVO } from '../models/asset.model';
import { IMarker, MarkerVO, MarkerOption } from '../models/marker.model';
import { FileUtils } from '../utils/FileUtils';
import { Point } from '../utils/Point';
import { BaseComponent } from '../components/base-component/base-component.component';

let API_URL = Globals.BASE_API_URL;

@Component({
	template:``,
	selector:'app-base-asset',
	styleUrls: ['./baseAsset.component.scss'],
})
export class BaseAssetComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, DoCheck {

	public scale:number = 1;
	galleryMode: boolean;
	protected defaultWidth: number = 300;
	protected defaultHeight: number = 250;
  seek(time: number) {
    return;
  }
  scrollMarkupIntoView(marker: IMarker) {
    return;
  }
  async updateMarker2(markerVO:MarkerVO)
  {
	return;
  }
  getScrollOffset(): string {
    return '0';
  }
  	@Input() parentView:string = null;
  	@Output() callback:EventEmitter<any> = new EventEmitter<any>();

	private _assetVO:AssetVO;
	@Input()
	set assetVO(value: AssetVO) {
		this._assetVO = value;
		if(!this._assetVO.asset.width || isNaN(this._assetVO.asset.width))
		{
			this._assetVO.asset.width = this.defaultWidth;
		}
		if(!this._assetVO.asset.height || isNaN(this._assetVO.asset.height))
		{
			this._assetVO.asset.height = this.defaultHeight;
		}
		this.ngOnChanges();
	  }
	  get assetVO(): AssetVO {
		return this._assetVO;
	  }
	 

	@Output() layoutChange:EventEmitter<any> = new EventEmitter<any>();
	// https://stackoverflow.com/questions/51582194/typescript-inheritance-and-injected-constructor-arguments
	//@Inject(ElementRef<HTMLDivElement>)
	//protected elRef:ElementRef<HTMLDivElement>
	constructor	() {
		super();
	}

	ngDoCheck(): void {
		//console.log("do check?", this);
	}
	ngOnChanges(changes: SimpleChanges = null): void {
		//console.log("simple changes", changes);
	}
	ngOnInit(): void { }
	ngAfterViewInit() {}
	uri()
	{
		return this.assetVO.asset?.uri ? (Asset.uriEncoded(this.assetVO.asset.uri) + this.assetVO.cacheBust) : null;
	}
	hasPreview(): boolean {
		return this.assetVO?.preview != null; 
	}
	hasPath(): boolean {
		return this.assetVO.asset?.uri != null; 
	}
	hasAnyFlag() : boolean
	{
		return this.assetVO.asset?.flag != 0; 
	}
	hasFlag(value:number) : boolean
	{
		return (this.assetVO.asset?.flag & value) != 0; 	// == value
	}
	getPath(ignorePreview:boolean = false): string {
		if(!ignorePreview && this.assetVO.preview)
		{
			return this.assetVO.preview;
		}else{
			let uri = this.uri();
			if(uri)
			{
				//return '/api/file/' + this.assetVO.reference?.uri ? this.assetVO.reference.uri : this.assetVO.uri;
				//return API_URL + 'file/' + (this.assetVO.original?.uri ? this.assetVO.original.uri : uri);
				return Globals.WORKER_URL + 'serve/' + uri;
				// API_URL + 'file/' + uri;
			}
		}
		return null;
	}
	baseName()
	{
		if(this.assetVO.file) return this.assetVO.file.name;
		else if(this.assetVO.asset.uri)	return FileUtils.baseName(this.assetVO.asset.uri);
		return null;
	}
	public canDropMarker(x:number, y:number, noSideEffect:boolean = false):number
	{
		return 1;
	}
	public isPointOutside(x:number, y:number): boolean
	{
	  if(x < 0 || y < 0 || x > this.assetVO.asset.width * this.scale || y > this.assetVO.asset.height * this.scale)
	  {
		return true;      
	  }else {
		return false;
	  }
	}
	getMarkerRenderInfo(markerVO:MarkerVO)
	{
		// abusing the offset feature of the marker
		if(this.galleryMode)
		{
			let scaledX = markerVO.marker.x0 * this.scale;
			let scaledY = markerVO.marker.y0 * this.scale;
			markerVO.offsetX = (scaledX - markerVO.marker.x0);
			markerVO.offsetY = (scaledY - markerVO.marker.y0);
		}else{
			markerVO.offsetX = markerVO.offsetY = 0;
		}
	}
	markerDrop(marker:IMarker, x:number, y:number):boolean
	{
		return false;
	}
	updateMarker(marker:IMarker, message:string)
	{

	}
	getAmendOptions(x:number, y:number, specific:boolean):Promise<MarkerOption[]>
	{
		let message = specific ? 'Add amend marker to this amend' : 'Add amend with marker to this asset';
		return new Promise((resolve, reject) => {
			resolve([new MarkerOption(message, MarkerOption.ICON_MARKER, MarkerOption.TYPE_MARKER)]);
		});
	}
	// Can be overriden by children but needs to be null here
	globalToLocal(point:Point):Point
	{
		return null;
	}
	getStyle()
	{
		if(!this.assetVO?.asset) return {};
		let asset = this.assetVO.asset;
		let styleObj = {};

		if(this.parentView == 'preview'){
			styleObj['aspect-ratio'] = asset.width+' / '+asset.height;
			styleObj['max-height'] = '100%';
			styleObj['max-width'] = '100%';
		} else {
			styleObj['width'] = asset.width + 'px';
			styleObj['height'] = asset.height + 'px';
		}

		return null;//styleObj;
	}
}
