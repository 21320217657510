<div class="login-container">
	<div class="login">
		<mat-card style="padding:1.5em;">
			<form class="login-form">
				<!-- <div class="logo"></div> -->
				<div class="oceanview" (click)="api.gotoPage('/')">
					<span>OCEANVIEW</span>
				</div>
				<div class="inputs">
					<mat-form-field>
						<input type="email" matInput id="email" class="login_input" placeholder="Email" [formControl]="emailControl">
						<mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')">
							Please enter a valid email address
						  </mat-error>
						<mat-error *ngIf="emailControl.hasError('required')">
							Email is <strong>required</strong>
						</mat-error>
					</mat-form-field>
					<mat-form-field *ngIf="!forgottonPassword">
						<input [type]="hidePassword ? 'password' : 'text'" id="password" class="login_input" matInput placeholder="Password" [formControl]="passwordControl">
						<mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
						<!--
							<mat-error *ngIf="passwordControl.hasError('minlength') && !passwordControl.hasError('required')">
								Password must be at least <strong>{{passwordControl.errors['minlength'].requiredLength}}</strong> characters long.
							</mat-error>
							<mat-error *ngIf="passwordControl.hasError('required')">
								Password is <strong>required</strong>
							</mat-error>
						-->
					</mat-form-field>
					<mat-checkbox *ngIf="!forgottonPassword" id="remember_me" style="font-size:0.75em; float: right;"
						labelPosition="before">
						Remember me
					</mat-checkbox>
				</div>
				<div class="button_wrapper">
					<!-- <p class="forgot_password" *ngIf="!forgottonPassword"><a (click)="forgotPassword()">Forgot password?</a></p> -->
					<button [disabled]="submitting || !(emailControl.valid)" *ngIf="!forgottonPassword" (click)="logUserIn()" color="green" mat-raised-button>Log In</button>
					<button class="forgot_password" *ngIf="!forgottonPassword" (click)="forgotPassword()"  mat-stroked-button color="warn">Reset password</button>
					<button *ngIf="forgottonPassword" [disabled]="submitting || !emailControl.valid" (click)="submitForgottonPassword()" color="primary" mat-raised-button>Request password reset</button>
					<button class="forgot_password" *ngIf="forgottonPassword" (click)="notForgotPassword()" mat-stroked-button color="warn">Back to login</button>
				</div>
			</form>
			<span *ngIf="error_message" class="error_message">{{error_message}}</span>
		</mat-card>
	</div>
</div>