import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../global';

let API_URL = Globals.BASE_API_URL;

@Injectable({
	providedIn: 'root'
})
export abstract class CrudService<T, ID>{

	constructor(protected http: HttpClient, protected base: string){
		this.base = API_URL + this.base.replace(/\/+$/, "");	// trim any trailing slashes
	}
	findAll(params:any = null):Observable<T[]>{
		return this._get<T[]>(this.base, params)
	}	
	findOne(id:ID, params:any = null):Observable<T>{
		return this._get<T>(`${this.base}/${id}`, params)
	}
	create(t:T):Observable<T>{
		//return this.http.post<T>(this.base, t);
		return this._post<T>(this.base, t);
	}
	update(id:ID, t:T):Observable<T>{
		return this.http.put<T>(`${this.base}/${id}`, t);
	}
	delete(id:ID):Observable<T>{
		return this.http.delete<T>(`${this.base}/${id}`);
	}
	protected _get<Type>(path:string, params:any = null):Observable<Type>
	{
		// https://angular.io/guide/http-request-data-from-server
		// https://angular.io/api/common/http/HttpClient#get
		return this.http.get<Type>(path, {params});
	}
	protected _post<Type>(path:string, t:Type | Type[] = null, params:any = null):Observable<Type>
	{
		return this.http.post<Type>(path, t, {params});
	}
	protected _put(path:string, t:T, params:any = null):Observable<T | T[]>
	{
		return this.http.put<T | T[]>(path, t, {params});
	}
	protected _delete<Type>(path:string, params:any = null):Observable<Type>
	{
		return this.http.delete<Type>(path, {params});
	}
}