<mat-dialog-content [ngClass]="{'noshadow':!data}">

	<mat-card [ngClass]="{'inGrid': data,'inPanel':!data}" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)"  ><!--  -->
		<!--<mat-card-header>
			<mat-card-title>{{creative.format.name}}</mat-card-title>
			<mat-card-subtitle>{{creative.channel.name}}</mat-card-subtitle>
		</mat-card-header>-->
		<div class="sectionHeader" *ngIf="workflow && creative && data"><span>{{workflow.name}} - {{creative.format.group}} - </span>{{creative.format.name}}<span> | </span>{{creative.channel.name}}</div>

		<mat-card-content>
			<fieldset *ngIf="data" class="section-assets clearfix" >
				<legend>Asset preview<div *ngIf="!assets" class="loader"></div></legend>
				<ng-container *ngIf="assets">
					<div style="display:flex; flex-direction:column" >
						<span *ngIf="assets && assets.length==0" class="instruction">No assets in this creative.</span>
						<!-- preview -->
						<div *ngIf="assets && assets.length>0" class="asset-preview">
							<!-- asset component-->
							<div class="asset-component">
								<!-- width and height added fix previews  -->
								
								<app-asset style="width:100%; height:100%;" [assetVO]="asset" [galleryMode]="true" [previewMode]="true"></app-asset>
								<!--
									<ng-container *ngIf="false" class="asset-component" [ngSwitch]="asset.type">
										<app-image *ngSwitchCase="'image'" [asset]="asset" [parentView]="'preview'"></app-image>
										<app-audio *ngSwitchCase="'audio'" [asset]="asset" [parentView]="'preview'"></app-audio>
										<app-video *ngSwitchCase="'video'" [asset]="asset" [parentView]="'preview'"></app-video>
										<app-pdf *ngSwitchCase="'pdf'" [asset]="asset" [parentView]="'preview'"></app-pdf>
										<app-html-banner *ngSwitchCase="'banner_html'" [asset]="asset" [parentView]="'preview'"></app-html-banner>
										<ng-container *ngSwitchDefault>No template found for asset type {{asset.type}}</ng-container>
									</ng-container>
								-->
							</div>
						</div>						
						<!-- pips -->
						<div class="preview-nav" *ngIf="assets && assets.length>1">
							<a class="prev" (click)="nextAsset(-1)">&#10094;</a>
							<div *ngIf="assets && assets.length>1" class="pagination">
								<a (click)="asset = ass" *ngFor="let ass of assets" [ngClass]="{'active':ass==asset}"></a>			
							</div>
							<a class="next" (click)="nextAsset(1)">&#10095;</a>
						</div>
	
						<!-- asset name -->
						<div *ngIf="asset" class="asset-info">
							<span class="asset-name">{{asset.asset.name}}</span>
							<span *ngIf="asset.asset.width > 0 && asset.asset.height > 0">&nbsp;({{asset.asset.width}}x{{asset.asset.height}})</span>
							<span *ngIf="!asset.asset.uri">&nbsp;(placeholder)</span>
						</div>	
						<!-- <div *ngIf="asset" class="asset-info">
							<span class="asset-name">{{assetVO.asset.name}}</span>
							<span *ngIf="assetVO.asset.width > 0 && assetVO.asset.height > 0">&nbsp;({{assetVO.asset.width}}x{{assetVO.asset.height}})</span>
							<span *ngIf="!assetVO.asset.uri">&nbsp;(placeholder)</span>
						</div>
					-->
					</div>
					
				</ng-container>
			</fieldset>
			<!-- <ul>
				<li>state - plus explanation</li>
				<li>My tasks</li>
				<li>Total open tasks</li>
				<li>ALl tasks or who needs to do the next thing </li>
				<li>(i.e. like the checkbox list but no box) ignore already actioned</li>
			</ul> -->
			<fieldset *ngIf="creative && !creative.viewonly"> <!-- && currentGroup -->
			
				<legend>Status</legend>
				<div class="section-status">
					<span *ngIf="data" class="state-all" [ngClass]="'state-'+creative.state">
						<mat-icon *ngIf="stateIcon" [ngClass]="{'red': creative.state == 'warning'}">{{stateIcon}}</mat-icon>
						<span *ngIf="creative.state != 'warning'" class="currentState">{{creative.state}}</span>
						<!-- <span *ngIf="creative.state == 'warning'" class="currentState"><mat-icon class="red">warning</mat-icon></span> -->
					</span>
					<div class="status-msgs">
						<div>
							<span [innerHTML]="statusMsg"></span><span *ngIf="creative.warning" class="instruction">{{statusWarning}}</span>			
						</div>
						<div>groups approved:<span class="big">{{numGroupsApproved}} of {{totalGroups}}</span></div>
						<div>% approvals: <span class="big">{{percentApproved}}%</span></div> 
					</div>
				</div>
			</fieldset>
			<fieldset *ngIf="workflow && creative">
				<legend>Workflow<span>: {{workflow.name}}</span></legend>
				<div>
					
					<!--<app-creative-chain [chain]="chain"></app-creative-chain>-->
					<ng-container *ngIf="!creative.viewonly">
						<div class="subHeader workflowHeader">Approval chain ({{creative.groupInfo.groups.length}} group{{creative.groupInfo.groups.length > 1 ? 's' :''}}):
							<!-- can only edit workflow if adming and on main grid -->
							<button *ngIf="isAdmin && data && grid?.isMainGrid" class="workflow-edit-btn" mat-stroked-button color="primary" mat-button (click)="editWorkflow(workflow)">Edit workflow</button> <!-- open workflow admin panel -->
						</div>
						<div class="workflowContainer">
							<app-workflow-widget class="workflowWidget" [workflow]="workflow" [groupInfo]="creative.groupInfo" [channel]="creative.channel" [state]="creative.state" [currentGroup]="currentGroup"></app-workflow-widget>
						</div>
						<button *ngIf="isAdmin" [disabled]="creative.groupInfo.groups.length == 0" style="scale: 0.7; font-size:1.2em; margin: 0 0 1em -1.55em;" mat-stroked-button color="primary" mat-button [matMenuTriggerFor]="approverResetMenu" click-stop-propagation>Reset approval for:<mat-icon>arrow_drop_down</mat-icon></button>
						<mat-menu #approverResetMenu="matMenu">
							<button (click)="resetApproval_all()" [disabled]="!numApproved" mat-menu-item>Everyone</button>
							<button (click)="resetApproval_notlatest()" [disabled]="!numApproved" mat-menu-item>Any not latest version</button>
							<mat-divider></mat-divider>
							<span class="approverMenuLabel">Individual Approver:</span>
							<button *ngFor="let approverUser of approvers" (click)="resetApproval_user(approverUser)" [disabled]="approverUser.state != 'approved'" mat-menu-item>{{approverUser.name}}</button>
							<mat-divider></mat-divider>
							<span class="approverMenuLabel">Everyone in group:</span>
							<button *ngFor="let approverGroup of creative.groupInfo.groups" (click)="resetApproval_group(approverGroup)" [disabled]="!approverGroup.approved" mat-menu-item>{{approverGroup.name}}</button>
						</mat-menu>
						<hr/>
					</ng-container>
					<!-- <div class="subHeader">Other workflow roles:</div> -->
					<div class="workflowRoles" *ngIf="isAdmin || isProduction || isModerator || isSuper">
						<div class="role-col">
							<!-- <span><mat-icon [matTooltip]="'Production'">manage_accounts</mat-icon>:</span>-->
							<div class="subHeader">Production:
								<!-- can only add new productions if none and admin and on main grid -->
								<span *ngIf="numProduction == 0 && isAdmin && grid?.isMainGrid" [matTooltip]="'add a new production user'" >
									<button mat-icon-button color="primary" (click)="editWorkflow(workflow)" ><mat-icon>add_circle_outline</mat-icon></button><!-- open workflow admin panel -->
								</span>
								<button click-stop-propagation [matMenuTriggerFor]="productionMenu" *ngIf="isAdmin && numProduction > 0 && creative.production_task" [disabled]="channelProductionUsers.length < 1 || (channelProductionUsers.length == 1 && isProductionOwner(channelProductionUsers[0]))" class="assignOwnerBtn small" mat-stroked-button color="primary" mat-button >{{creative.production_task.user_uuid && creative.production_task.user_uuid != 'app' ? 're-assign' : 'assign'}}</button>
								<mat-menu #productionMenu="matMenu">
									<button *ngFor="let productionUser of channelProductionUsers" (click)="setProduction(productionUser)" [disabled]="isProductionOwner(productionUser)" mat-menu-item>{{productionUser.name}}</button>
								</mat-menu>
							</div>
							<div class="role-users">
								<ngContainer *ngIf="isAdmin && numProduction == 0" >
									<mat-icon class="red">warning</mat-icon>
									<span class="instruction">
										<span>Add at least one Production user to this workflow.</span>
									</span>
								</ngContainer>
								
								<span [ngClass]="!isProductionOwner(user) ? 'deprioritise' : ''" *ngFor="let user of channelProductionUsers"><!--*ngFor="let user of channelUsers | callback: isProduction"-->
									<mat-icon [matTooltip]="isIndicatedProduction(user) ? 'waiting for this user\'s respsonse' : ''" class="user-indicator">{{isIndicatedProduction(user) ? 'forward' : ''}}</mat-icon>
									{{user.name}}
									<mat-icon *ngIf="isProductionOwner(user)" [matTooltip]="'current creator for \''+creative.name+'\''">star</mat-icon>
									<!-- <button *ngIf="!isProductionOwner(user)" class="assignOwnerBtn small" disabled mat-stroked-button color="primary"  mat-button>assign as owner</button>-->
								</span>
							</div>
						</div>


						<div class="role-col">
							<!--<span><mat-icon [matTooltip]="'Moderators'">admin_panel_settings</mat-icon>:</span>-->
							<div class="subHeader">Amend Moderators:
								<!-- can only add new amend managers if admin and on main grid -->
								<span *ngIf="data && isAdmin && grid?.isMainGrid" [matTooltip]="'add a new Amend Moderator'" >
									<button mat-icon-button color="primary" (click)="editWorkflow(workflow)"><mat-icon>add_circle_outline</mat-icon></button>
								</span>
							</div>
							<div class="role-users">
								<ngContainer *ngIf="moderatorIssue" >
									<mat-icon class="red" >warning</mat-icon>
									<span class="instruction"> 
										<span>An Amend Moderator is required to accept amend requests from Approvers, and confirm amended assets from Production.</span>
								<!--	<span [matTooltip]="'add a new moderator'" >
											<button mat-icon-button color="primary" (click)="editWorkflow(workflow)"><mat-icon>add_circle_outline</mat-icon></button>
										</span>
								-->
									</span>
								</ngContainer>
								<span *ngFor="let user of workflow.moderators"><mat-icon [matTooltip]="isIndicatedModerator() ? 'waiting for this user\'s respsonse' : ''" class="user-indicator">{{isIndicatedModerator() ? 'forward' : ''}}</mat-icon>{{user.name}}</span><!--channelUsers | callback: isModerator-->
							</div>
						</div>

					</div>
				</div>
			</fieldset>
			

			<fieldset *ngIf="isAdmin || isProduction || isModerator">
				<legend>Options:</legend>
				<div class="optionRow">
					<app-colour-label
						[selectedColour]="creative.label || 'null'"
						(colourSelected)="setLabel($event)">
					</app-colour-label>
				</div>
				<!--
				<div *ngIf="isAdmin" class="optionRow">
					
					<app-pause-creative-widget
						[isPaused]="creative.flag || false"
						(setPaused)="pauseCreative($event)">
					</app-pause-creative-widget>
				</div>
				-->
			</fieldset>

			<fieldset>
				<legend>Delivery status</legend>
				<ng-container *ngIf="!delivery || delivery.length == 0">
					<div class="optionRow">
						<span  class="instruction">This creative is not currently assigned to any delivery packages.</span>	
					</div>
				</ng-container>	
				<ng-container *ngIf="delivery && delivery.length >0">
					<ng-container *ngIf="delivery[0].package"><!--grid view-->
						<div *ngIf="creative.deliveryStatus && creative.deliveryStatus.className !='none'" class="delivery"> 
							<div class="fieldset-row" [ngClass]="creative.deliveryStatus.className">                                         
								<mat-icon>
									{{creative.deliveryStatus.className.indexOf('noneActive') != -1 ? 'mail_outline' : 'mail'}}
								</mat-icon>
								<div>
									<div *ngFor="let desc of creative.deliveryStatus.description">{{desc}}</div>
								</div>
							</div>
							
							<mat-divider></mat-divider>
							
							<div class="subHeader">This creative has been assigned to {{delivery.length}} package{{delivery.length>1 ? 's':''}}:</div>
							<!--<div class="optionRow" *ngFor="let package of delivery">
								<mat-icon [matTooltip]="getDeliveryPackageStatus(package).tooltip" [class]="getDeliveryPackageStatus(package).class">{{getDeliveryPackageStatus(package).icon}}</mat-icon>
								<div>{{package.name}}</div>
							</div>-->
							
							<div class="optionRow" *ngFor="let package of delivery" (click)="clickDeliveryPackage(package.package.uuid)" style="cursor:pointer">
								<mat-icon [matTooltip]="deliveryPackageLookup[package.uuid].tooltip" [class]="deliveryPackageLookup[package.uuid].class">{{deliveryPackageLookup[package.uuid].icon}}</mat-icon>
								<div >{{package.name}}</div>	
							</div>
						</div>	
					</ng-container>
					<ng-container *ngIf="!delivery[0].package"><!-- simplified for creative view-->
						<div class="subHeader">This creative has been assigned to {{delivery.length}} package{{delivery.length>1 ? 's':''}}:</div>
								
						<div class="optionRow" *ngFor="let package of delivery" ><!--(click)="clickDeliveryPackage(package.uuid)" style="cursor:pointer"-->
								<mat-icon>mail_outline</mat-icon>
								<div>{{package.name}}</div>	
						</div>
					</ng-container>
				</ng-container>
				
<!--
				<div *ngIf="!creative.deliveryStatus || creative.deliveryStatus == 'none'" class="optionRow">
					<span *ngIf="!deliveryState" class="instruction">This creative is not currently assigned to any delivery packages.</span>	
					<div *ngIf="creative.deliveryStatus" style="position:relative;">
						<div class="subHeader">This creative has been assigned to {{numPackages}} package{{numPackages>1 ? 's':''}}:
							
						</div>
						<div *ngFor="let package of delivery"><mat-icon>{{package.actioned ? 'mail':'unsubscribe'}}</mat-icon> - {{package.name}}</div>
					</div>
					<span *ngIf="!deliveryState" class="instruction">This creative is not currently assigned to any delivery packages.</span>	
				</div>
				<div *ngIf="creative.deliveryStatus && creative.deliveryStatus !='none'" class="delivery">
					<div class="fieldset-row"
						[ngClass]="creative.deliveryStatus">                                            
						<mat-icon *ngIf="creative.deliveryStatus.indexOf('noneActive') == -1">mail</mat-icon>
						<mat-icon *ngIf="creative.deliveryStatus.indexOf('noneActive') != -1">mail_outline</mat-icon>
					
						<div>
							<div>some description</div>
							<div>another description</div>
						</div>
					</div>
				</div>	
			-->
				<!--
				<div *ngIf = "deliveryBlocked" class="instruction delivered_blocked_warning">Warning: DELIVERY DOWNLOAD BLOCKED!<br/>At least 1 package is set to 'delivered' but this creative is not yet fully approved.</div>
				
				<div *ngIf="deliveryState" class="optionRow">
					<div [ngClass]="deliveryState.class" 
						[class.delivered_blocked] = "deliveryBlocked">
						<mat-icon >mail</mat-icon>
					</div>
					<div class="deliveryDesc">
						<div>{{deliveryState.desc}}</div>
					</div>
				</div>
				-->
				
			</fieldset>
			<!-- <h3>state - {{creative.state}}</h3> -->
			<!--
			<div>
				<span>Task count: {{tasks?.length || 0}}</span>
				<div class="userActions">
					<ng-container *ngIf="!userActions">Loading user actions...</ng-container>
					<ng-container *ngIf="userActions">
						<div style="display: table; width: 100%;">
							<div style="display: table-row;">
								<div style="display: table-cell;">User</div>
								<div style="display: table-cell;">Required Action</div>
							</div>
							<div *ngFor="let userAction of userActions" style="display: table-row;">
								<div style="display: table-cell;">
									{{userAction.user.name}}	userAction.user.last_name.charAt(0)
								</div>
								<div style="display: table-cell;">
									{{userAction.message}}
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			-->
		</mat-card-content>
		<mat-card-actions *ngIf="data && (isAdmin || isProduction || isModerator)">
			<div class="btnRow">			
				<!--<button mat-button>Download Creative</button>-->
				<!--<button id="btn_reset" (click)="resetCreative()" mat-button>Reset Creative</button>-->
				<button *ngIf="(isAdmin || isProduction) && grid.isMainGrid" click-stop-propagation mat-stroked-button color="warn" (click)="deleteCreative()" mat-button>Delete Creative</button>
				<button *ngIf="false && (isAdmin || isProduction)" click-stop-propagation mat-stroked-button color="primary" (click)="assetCheck()" mat-button>Asset Check</button>
				<div class="btnsRight">
					<!-- <button disabled mat-stroked-button color="primary"  mat-button>Edit workflow</button> -->
					
					<a [href]="'/creative/' + creative.uuid">
						<button mat-raised-button color="primary" >View Creative</button> <!--(click)="viewCreative($event)"-->
					</a>
				</div>
			</div>
		</mat-card-actions>
	</mat-card>
</mat-dialog-content>