import diff from "microdiff";
import { IMarker, MarkerVO } from "./marker.model";
import { ITask } from "./task.model";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { formatBitrate, formatBytes, formatDuration } from "../utils/FileUtils";

export enum AssetType
{
	THREE_D 		= '3d', 
	AUDIO			= 'audio',
	BANNER_HTML 	= 'banner_html', 
	BANNER_SIZMEK 	= 'banner_sizmek',
	CAPTION 		= 'caption',
	EGNYTE			= 'egnyte',
	EMAIL			= 'email',
	FILE			= 'file',
	IFRAME			= 'iframe',
	IMAGE			= 'image',
	LINK			= 'link',
	PDF				= 'pdf',
	REFERENCE 		= 'reference', 
	VIDEO			= 'video',
}

export enum AssetState
{
	SAVING			= 'saving',
	UPLOADING		= 'uploading',
	PROCESSING		= 'processing',
}
export enum AssetFlag
{
	UPLOADING_LOCAL   = 1 << 0,	// 1
    UPLOADING_GCS     = 1 << 1,	// 2
    UPLOADING_DO      = 1 << 2,	// 4
    UPLOADING_R2      = 1 << 3,	// 8
	UNZIPPING    = 1 << 4,	// 16
    PROCESSING    = 1 << 5,	// 32
}

export interface IAsset {
	id: number;
	uuid: string;
	account_uuid: string;

	name: string;
	type: string;

	uri: string;
	metadata: any;
	annotation: string;

	width: number;
	height: number;
	x: number;
	y: number;

	order: number;
	visible: boolean;
	deliverable: boolean;

	created_at:number;
	updated_at:number;

	markerVOs: {task:ITask, marker:IMarker, active:boolean}[];
	version:number;
	flag:number

	uriEncoded():string
}

export class DiffableVO<T> {
	original:T;	// source for diffing
	current:T;	// current to make changes too

	// TODO cache diff, or return 
	// could just return diff or null maybe
	isDirty(diffs = null):boolean {
		if(diffs){
			diffs.length = 0;
		}
		if(!this.original) return false;	// can't be dirty if no original
		let result = diff(this.original, this.current);
		if(diffs) diffs.push(...result);
		return result.length != 0;
	}
	isPropDirty(prop:String):boolean {
		let diffs = diff(this.original, this.current);
		if(!diff.length) return false;
		else return diffs.find(diff => diff.path.toString() == prop) != undefined;
	}
	revert(filter:{include?:string[], exclude?:string[]} = null)
	{
		if(!this.original) return;
		let diffs = [];
		let isDirty = this.isDirty(diffs);
		diffs.forEach(diff => {
			// TODO
			// reset values
			if(diff.type == "CHANGE")
			{
				this.current[diff.path[0]] = diff.oldValue;
			}
		});
		if(!isDirty) return;

	}
	revertProp(prop:string)
	{
		// todo check for "." syntax
		if(!this.original || this.original[prop] === undefined) return;
		this.current[prop] = JSON.parse(JSON.stringify(this.original[prop]));
	}
}
// TODO could we do this with crazy proxies hmmm
export class AssetVO extends DiffableVO<Asset>{
	private static INSTANCE:number = 0;
	id:number;
	file:File;
	fileMetadata:any;
	metadataView:any[];
	preview:string;
	order:number;
	state:string;

	markerOver:boolean;
	markerVOs: MarkerVO[];
	cacheBust:string = '';
	//uriMeta:string;

	private proxy;
	private changesSubject:Subject<{ prop: string | symbol, value: any }> = new Subject();
	public changes$:Observable<{ prop: string | symbol, value: any }> = this.changesSubject.asObservable();
	private dirty:any = {};
	private dirtySubject:BehaviorSubject<{}> = new BehaviorSubject(this.dirty);
	public dirty$:Observable<{}> = this.dirtySubject.asObservable();
	constructor(asset:Asset = null, uriMeta:string = null)
	{
		super();
		this.id = AssetVO.INSTANCE++;
		//this.cacheBust = '?r=' + (asset?.updated_at ? asset?.updated_at : Date.now());
		
		if(asset)
		{
			if(uriMeta) this.cacheBust = "?"+uriMeta;//this.cacheBust += '&' + uriMeta;
			this.current = asset;
			this.storeOriginal();
			this.updateMetaView();

			//
			this.proxy = new Proxy(this.current, {
				set:(target, prop, value, receiver) => {
					this.current[prop] = value;
					const original_value = this.original[prop];
					if(original_value === value){
						if(this.dirty[prop]){
							delete this.dirty[prop];
							this.dirtySubject.next(this.dirty);
						}
					}else{
						if(!this.dirty[prop]){
							this.dirty[prop] = true;
							this.dirtySubject.next(this.dirty);
						}
						this.changesSubject.next({prop, value});
					}
					return true;
				}
			})

			//this.changes$.subscribe(change => console.log("change", change))
			//this.dirty$.subscribe(dirty => console.log("dirty", dirty))

		}
	}
	
	public get asset() : Asset {
		return this.current;	// this.proxy
	}
	public get uriEncoded():string
	{
		// TODO
		return null;
	}
	merge(newAsset)
	{
		// check updated at and ignore if new is older
		if(this.current && this.current.updated_at > newAsset.updated_at) return;
		Object.assign(this.current, newAsset); 
		//console.log("merge hack", this.current.metadata)
		this.storeOriginal();
		this.updateMetaView();
	}
	storeOriginal()
	{
		this.original = Object.assign({}, this.current);
	}
	updateCacheBust(clear:boolean = false) {
		this.cacheBust = '' ? null : ("?cachebust=" + Date.now());
	}
	updateMetaView()
	{
		
		if(!this?.asset?.metadata && !this?.fileMetadata){
			this.metadataView = null;
			return;
		}
		
		let keys = {};
		let ignore = [];	//"pages"
		// stored metadata
		for (const key in this.asset.metadata) {
			if (this.asset.metadata.hasOwnProperty(key)) {
				if(ignore.includes(key)) continue;
				let value = this.asset.metadata[key];
				if(typeof value === "object")
				{
					for (const nestedkey in value)
					{
						if(nestedkey == "tags") continue;
						let vo = {key:key + ":" + nestedkey, old_value: value[nestedkey]};
						keys[vo.key] = vo;	
					} 
				} else {
					let vo = {key, old_value: value};
					keys[vo.key] = vo;		
				}
			}
		}
		// preview (new file) metedata
		for (const key in this?.fileMetadata) {
			if (this.fileMetadata.hasOwnProperty(key)) {
				if(ignore.includes(key)) continue;
				let value = this.fileMetadata[key];
				if(typeof value === "object")
				{
					for (const nestedkey in value)
					{
						if(nestedkey == "tags") continue;
						let vo = this.getVO(keys, key + ":" + nestedkey);
						vo['new_value'] = value[nestedkey]
					} 
				} else {
					let vo = this.getVO(keys, key);
					vo['new_value'] = value;	
				}	
			}
		}
		//if(key=="size") value = formatBytes(value);
		let order = ["size", "width", "height", "pages", "duration", "video", "audio", "pdf"];
		let data:any[] = Object.values(keys);
		// format values
		data.forEach(vo => {
			if(vo.key == 'size')
			{
				if(vo.new_value) vo.new_value = formatBytes(vo.new_value);
				if(vo.old_value) vo.old_value = formatBytes(vo.old_value);
			}else if(vo.key.indexOf(":bitrate") != -1) {
				if(vo.new_value) vo.new_value = formatBitrate(vo.new_value, 1);
				if(vo.old_value) vo.old_value = formatBitrate(vo.old_value, 1);
			}else if(vo.key == "duration") {
				if(vo.new_value) vo.new_value = formatDuration(vo.new_value);
				if(vo.old_value) vo.old_value = formatDuration(vo.old_value);
			}
		})
		data.sort((a,b) => order.indexOf(a.key) - order.indexOf(b.key) );
		//data.sort((a,b) => order.findIndex(item => a.key.indexOf(item)) - order.findIndex(item => b.key.indexOf(item)) );
		this.metadataView = data;
	}
	getVO(map:any, key:string){
		if(map[key]) return map[key];
		return map[key] = {key};
	}
}
export class Asset implements IAsset{
	static cacheBust(asset: Asset) {
		if(asset.uri && false)	//
		{
			asset.uri += '?r=' + Date.now();
		}
	}

	public id: number;
	public uuid: string;
	public account_uuid: string;

	public name: string;
	public type: string;

	public uri: string;
	public metadata: any;
	public annotation: string;

	public width: number;
	public height: number;
	public x: number;
	public y: number;

	public order: number;
	public visible: boolean;
	public deliverable: boolean;
	public flag:number;

	created_at:number;
	updated_at:number;

	//public file:File;
	//public fileMetadata:any;
	//public preview: string;

	//public reference:Asset;
	version:number;
	markerVOs: {task:ITask, marker:IMarker, active:boolean}[];

	uriEncoded():string
	{
		return Asset.uriEncoded(this.uri);	
	}
	static uriEncoded(uri:string):string
	{
		if(!uri) return null;
		let parts = uri.split('/');
		let filename = encodeURIComponent(parts.pop());
		parts.push(filename);
		return parts.join("/");
	}
	// util
	static isFile(asset: IAsset): boolean {
		return (asset.type == AssetType.AUDIO || 
			asset.type == AssetType.IMAGE || 
			asset.type == AssetType.VIDEO || 
			asset.type == AssetType.PDF || 
			asset.type == AssetType.BANNER_HTML ||
			asset.type == AssetType.EMAIL ||
			asset.type == AssetType.CAPTION ||
			asset.type == AssetType.FILE || 
			asset.type == AssetType.THREE_D
			); 
	}
	static hasDimensions(asset:IAsset):boolean
	{
		if(!asset) return false;
		return (
			asset.type == AssetType.IMAGE || 
			asset.type == AssetType.VIDEO || 
			asset.type == AssetType.PDF || 
			asset.type == AssetType.BANNER_HTML ||
			asset.type == AssetType.EMAIL ||
			asset.type == AssetType.CAPTION ||
			asset.type == AssetType.IFRAME || 
			asset.type == AssetType.REFERENCE || 
			asset.type == AssetType.THREE_D
		); 
	}
	// diffing
	public static createReferece(target:Asset):void {
		throw new Error("unused");
		//target.reference = Object.assign({}, target);
	}
	public static isPropDifferent(target:Asset, prop: string): boolean {
		throw new Error("unused");
		
		return false;
		//return target.reference[prop] !== target[prop];
	}
	public static isDirty(target:Asset): string[]
	{
		throw new Error("unused");
		let changes: string[];
		for (let prop in target) {
			if(prop == "reference") continue;
			if(prop == "markerVOs") continue;
			if (Asset.isPropDifferent(target, prop)) { if(!changes) changes = [];changes.push(prop)};
		}
		return changes;
	}
	public static resetProp(target:Asset, prop: string): boolean {
		throw new Error("unused");
		return false;
		//return target[prop] = target.reference[prop];
	}
}