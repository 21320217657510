<div *ngIf="assetVO.asset.visible" [ngStyle]="getStyle()" resizeObserver (resize)="onAssetResized($event)">
    <ng-container [ngSwitch]="assetVO.asset.type">
        <app-image #ass *ngSwitchCase="'image'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-image>
        <app-audio #ass *ngSwitchCase="'audio'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-audio>
        <app-video #ass *ngSwitchCase="'video'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-video>
        <app-pdf #ass *ngSwitchCase="'pdf'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-pdf>
        <app-iframe #ass *ngSwitchCase="'iframe'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-iframe>
        <app-email #ass *ngSwitchCase="'email'" (callback)="assetCallback($event)" [assetVO]="assetVO" [editable]="assetEditMode"></app-email>
        <app-html-banner #ass *ngSwitchCase="'banner_html'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-html-banner>
        <app-html-link #ass *ngSwitchCase="'link'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-html-link>
        <app-file #ass *ngSwitchCase="'file'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-file>
        <app-egnyte #ass *ngSwitchCase="'egnyte'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-egnyte>
        <app-threed #ass *ngSwitchCase="'3d'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-threed>
        <app-reference #ass *ngSwitchCase="'reference'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-reference>
        <app-caption #ass *ngSwitchCase="'caption'" (callback)="assetCallback($event)" [assetVO]="assetVO"></app-caption>
        <ng-container *ngSwitchDefault>
            <app-empty #ass (callback)="assetCallback($event)" [assetVO]="assetVO" [galleryMode]="galleryMode"></app-empty>
        </ng-container>
        <!--<ng-container *ngSwitchDefault><h2>No renderer found for asset type {{asset.type}}</h2></ng-container>-->
    </ng-container>
    <div class="assetClickBlock" *ngIf="assetEditMode" [class.move]="layout == 'custom'">
        <!--<mat-icon>ads_click</mat-icon>-->
        <div *ngIf="layout !== 'custom'" class="drag-warn">Cannot drag in {{layout}} layout mode!</div>
    </div>
    <div class="markupContainer" [ngStyle]="{overflow:assetVO.asset.type == 'pdf' ? 'hidden' : 'visible'}" #markup>
        <!--<ng-container [ngSwitch]="getMarker().type"></ng-container>-->
        
        <!-- track by needed or the marker was getting removed and dragging failed :( )-->
        <!--

            <div *ngFor="let marker of getMarkers(asset); trackBy: markerTrackBy" [ngClass]="{active:marker.task==selectedTask}" class="drag-target"
            (click)="onMarkerClick($event, marker.task)"
            cdkDrag [element]="element" [cdkDragDisabled]="marker.task.state != 'local' && false" (cdkDragStarted)="onMarkerDragStarted($event)" (cdkDragMoved)="onMarkerDragMoved($event, marker.marker, asset)" (cdkDragEnded)="onMarkerDragged($event, marker.marker, asset)"
            [style.transform]="getMakerTransform(marker.marker)">
            <mat-icon style="transform: translate(-12px, -12px); cursor: pointer;" [matTooltipDisabled]="marker.task.state == 'local'" [matTooltip]="marker.task.content" >location_searching</mat-icon>
            <pre style="color:red;font-size:x-small;" *ngIf="marker.marker">{{marker.marker | json}}</pre>
        </div>
    -->
    <div *ngIf="(markerVOs$ | async | callback : markerFilter) as markerVOs">
        <ng-container *ngIf="showMarkup && markerVOs.length">
            <div *ngFor="let markerVO of markerVOs" 
            #markupItem
            #markerRef
            class="markup"
            [class.active]="markerVO.active"
            [class.hidden]="markerVO.visible === false"
            [ngClass]="markerVO.task.colour"
            (mouseenter)="onMarkerMouseEnter($event, markerVO.task)"
            (mouseleave)="onMarkerMouseLeave($event, markerVO.task)"
            (click)="onMarkerClick($event, markerVO.task)"
            >
                <ng-container [ngSwitch]="markerVO.marker.type">
                    <ng-container *ngSwitchCase="'target'">
                        <div class="marker"
                        [class.selected]="markerVO.active"
                        [class.draggable]="markerVO.editable"
                        [appDraggable]="markerVO.editable" [disabled]="(markerVO.marker.id > 0 && !markerVO.editable)" [target]="dragTarget" [metadata]="{markerVO, markerRef}" (dragUpdate)="onDragUpdate($event)"
                        [style.transform]="getMakerTransform(markerVO)">
                            <mat-icon
                                [ngClass]="markerVO.task.colour"
                                [matTooltipDisabled]="markerVO.task.type == 'approval'"
                                [matTooltip]="markerVO.marker.metadata?.in != null ? '@' + markerVO.marker.metadata.in.toFixed(2)  + ' ' + markerVO.task.content : markerVO.task.content" >place</mat-icon>
                            <mat-icon *ngIf="markerVO.marker.metadata?.in != null" class="marker-extra" [ngClass]="markerVO.task.colour">schedule</mat-icon>
                            <span *ngIf="false" style="color:white">{{markerVO.marker.x0.toFixed(2)}}, {{markerVO.marker.y0.toFixed(2)}} : {{markerVO.marker.offsetX.toFixed(2)}}, {{markerVO.offsetY.toFixed(2)}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'text_change'">
                        <div class="rect rect-change" *ngFor="let rect of markerVO.marker.metadata.rects; index as i"
                        [ngStyle]="{'left.px':(rect.x * markerVO.scale) + markerVO.offsetX, 'width.px':rect.width * markerVO.scale, 'top.px':(rect.y * markerVO.scale) + markerVO.offsetY, 'height.px':rect.height * markerVO.scale}" [matTooltip]="markerVO.task.id && markerVO.task.content">
                            <span></span>
                            <!--<span>{{i}}</span>-->
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'text_delete'">
                        <div class="rect rect-delete" *ngFor="let rect of markerVO.marker.metadata.rects; index as i"
                        [ngStyle]="{'left.px':(rect.x * markerVO.scale) + markerVO.offsetX, 'width.px':rect.width * markerVO.scale, 'top.px':(rect.y * markerVO.scale) + markerVO.offsetY, 'height.px':rect.height * markerVO.scale}" [matTooltip]="markerVO.task.id && markerVO.task.content">
                            <span></span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'hidden'"></ng-container>
                    <ng-container *ngSwitchDefault>
                        <span>no default marker!</span>
                    </ng-container>
                </ng-container>
                <pre *ngIf="false && markerVO.marker" style="color:red;font-size:x-small;">{{markerVO.marker.visible ? "true" : "false"}}</pre>
                <pre *ngIf="false && markerVO.marker" style="color:red;font-size:x-small;">{{markerVO.marker | json}}</pre>
            </div>
        </ng-container>


    </div>
    <div *ngIf="false && showMarkup && assetVO.markerVOs?.length"> <!--hasMarker(asset, creative)-->
        <!--<div #markupItem *ngFor="let marker of getMarkers(asset); trackBy: markerTrackBy"    [ngClass]="markerVO.colour"-->
        <div #markupItem *ngFor="let markerVO of assetVO.markerVOs; trackBy: markerTrackBy"
        #markerRef
        class="markup"
        [class.active]="markerVO.active"
        [class.hidden]="markerVO.marker.visible"
        [ngClass]="markerVO.task.colour"
        (mouseenter)="onMarkerMouseEnter($event, markerVO.task)"
        (mouseleave)="onMarkerMouseLeave($event, markerVO.task)"
        (click)="onMarkerClick($event, markerVO.task)"
        >   <!-- [ngClass]="{active:markerVO.task==selectedTask}" -->
            <ng-container [ngSwitch]="markerVO.marker.type">
                <ng-container *ngSwitchCase="'target'">
                    <div class="marker" [class.selected]="markerVO.active"
                    [appDraggable]="markerVO.editable || true" [disabled]="false && (markerVO.marker.id > 0 && !markerVO.editable)" [target]="dragTarget" [metadata]="{markerVO, markerRef}" (dragUpdate)="onDragUpdate($event)"
                    [style.transform]="getMakerTransform(markerVO)">
                        <mat-icon style="transform: translate(-12px, -12px); cursor: pointer;"
                            [matTooltipDisabled]="markerVO.task.type == 'approval'"
                            [matTooltip]="markerVO.marker.metadata?.in != null ? '@' + markerVO.marker.metadata.in.toFixed(2)  + ' ' + markerVO.task.content : markerVO.task.content" >place</mat-icon>
                        <mat-icon *ngIf="markerVO.marker.metadata?.in != null" class="marker-extra">schedule</mat-icon>
                        <span *ngIf="false" style="color:white">{{markerVO.marker.x0.toFixed(2)}}, {{markerVO.marker.y0.toFixed(2)}} : {{markerVO.offsetX.toFixed(2)}}, {{markerVO.offsetY.toFixed(2)}}</span>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'text_change'">
                    <div class="rect" *ngFor="let rect of markerVO.marker.metadata.rects; index as i"
                    [ngStyle]="{'left.px':(rect.x * markerVO.marker.scale) + markerVO.offsetX, 'width.px':rect.width * markerVO.marker.scale, 'top.px':(rect.y * markerVO.marker.scale) + markerVO.offsetY, 'height.px':rect.height * markerVO.marker.scale}" [matTooltip]="markerVO.task.id && markerVO.task.content">
                        <span></span>
                        <!--<span>{{i}}</span>-->
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'text_delete'">
                    <div class="rect-delete" *ngFor="let rect of markerVO.marker.metadata.rects; index as i"
                    [ngStyle]="{'left.px':(rect.x * markerVO.marker.scale) + markerVO.offsetX, 'width.px':rect.width * markerVO.marker.scale, 'top.px':(rect.y * markerVO.marker.scale) + markerVO.offsetY, 'height.px':rect.height * markerVO.marker.scale}" [matTooltip]="markerVO.task.id && markerVO.task.content">
                        <span></span>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'hidden'"></ng-container>
                <ng-container *ngSwitchDefault>
                    <span>no default marker!</span>
                </ng-container>
            </ng-container>
            <pre style="color:red;font-size:x-small;" *ngIf="false && markerVO.marker">{{markerVO.marker | json}}</pre>
        </div>
    </div>
    </div>
    <div *ngIf="assetEditMode" [ngClass]="selected ? 'highlight_base highlight2' : 'highlight_base highlight'"></div>
    <div *ngIf="!assetEditMode" [ngClass]="selected ? 'highlight_base highlight2' : ''"></div>
    <div *ngIf="markerOver" [ngClass]="'highlight_base marker_over'"></div>
    <div *ngIf="fileOver" [ngClass]="'file_over'">
        Replace file?
    </div>    
    <div *ngIf="assetVO.state" class="state">
        <div *ngIf="assetVO.state == 'saving'">SAVING...</div>
        <!--

            <div *ngIf="progress > 0 && progress < 1">UPLOADING... {{progress | percentage}}%</div>
            <div *ngIf="progress == 1">PROCESSING...</div>
        -->
    </div>
    <div *ngIf="upload" class="state">
        <span>Uploading: {{upload.progress$ | async | percentage}}%</span>
        <mat-progress-spinner mode="determinate" diameter="25" strokeThickness="3" [value]="upload.progress$ | async | percentage" ></mat-progress-spinner>
    </div>
    <div class="state" *ngIf="assetVO.asset?.flag | hasFlags : AssetFlag.PROCESSING">
        <span>Processing</span>
        <mat-progress-spinner mode="indeterminate" diameter="25" strokeThickness="3"></mat-progress-spinner>
    </div>
    <div *ngIf="false && assetVO" class="assetVOid">
        <h1>{{assetVO.id}}</h1>
    </div>
</div>