import { Injectable } from '@angular/core';
import { ApiResponse, OVBaseService } from '../api/OvBaseService';
import { IAccount } from '../models/account.model';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IWorkgroup } from '../models/workgroup.model';
import { IUser } from '../models/user.model';
import { APIResponse } from '../api/amends.service';
import { Permission } from '../models/permissions.model';

@Injectable({
	providedIn: 'root'
})
export class AccountService extends OVBaseService<IAccount, string> {

	constructor(protected http: HttpClient) {
		super(http, 'accounts');
		this.account$.pipe().subscribe(account => {		
			this._accountAdminSubject.next((account.permissions & Permission.ADMIN) == Permission.ADMIN);
		});
	}
	private _accountsSubject = new BehaviorSubject<IAccount[]>(null);
	public accounts$ = this._accountsSubject.asObservable();
	loadAccounts(): void {
		this.index().pipe().subscribe((res) => {
			let accounts: IAccount[] = res.data;
			this._accountsSubject.next(accounts);
		});
	}
	private _accountSubject = new Subject<IAccount>();
	public account$ = this._accountSubject.asObservable();
	loadAccount(uuid: string): void {	
		this.findOne(uuid).pipe().subscribe((res) => {
			let account: IAccount = res.data[0] ?? null;	
			this._accountSubject.next(account);
		});
	}

	private _accountAdminSubject = new BehaviorSubject<boolean>(false);
	public accountAdmin$ = this._accountAdminSubject.asObservable();

	addUser(account_uuid: string, user: any) {
		return this._post(`${this.base}/${account_uuid}/addUser`, user);
	}
	userInfo(account_uuid: string, user_uuid: string) {
		return this._get(`${this.base}/${account_uuid}/user/${user_uuid}`);
	}
	grantAccountAdmin(account_uuid: string, user_uuid: string) {
		return this._get(`${this.base}/${account_uuid}/grantAdmin/${user_uuid}`);
	}
	revokeAccountAdmin(account_uuid: string, user_uuid: string) {
		return this._get(`${this.base}/${account_uuid}/revokeAdmin/${user_uuid}`);
	}
	addWorkgroup(account_uuid: string, workgroup: any) {
		return this._post(`${this.base}/${account_uuid}/workgroup`, workgroup);
	}
	private _workgroupsLoadingSubject = new BehaviorSubject<boolean>(false);
	public workgroupsLoading$: Observable<boolean> = this._workgroupsLoadingSubject.asObservable();
	private _workgroupsSubject = new Subject<IWorkgroup[]>();
	public workgroups$: Observable<IWorkgroup[]> = this._workgroupsSubject.asObservable();
	loadWorkgroups(account_uuid: string): void {
		this._workgroupsLoadingSubject.next(true);
		this._get(`${this.base}/${account_uuid}/workgroups`).pipe().subscribe(
			(res) => {
				this._workgroupsSubject.next(res.data as IWorkgroup[]);
			}, error => {
				// TODO
			}, () => this._workgroupsLoadingSubject.next(false));
	}

	private _usersSubject = new Subject<IUser[]>();
	public users$: Observable<IUser[]> = this._usersSubject.asObservable();
	loadUsers(account_uuid: string): Subscription {
		return this._get<ApiResponse<IUser>>(`${this.base}/${account_uuid}/users`).pipe().subscribe((res) => {
			this._usersSubject.next(res.data as IUser[]);
		});
	}
	/*
	createCreative(workflow_uuid: string, channel:number, format:number):Observable<any>
	  {
		  return this._post(`${this.base}/${workflow_uuid}/creative`, {channel, format});
	  }*/

}

