
<!--<app-file-uploader (onFilesDragged)="onFilesDragged($event)" [parent]="this" ></app-file-uploader>-->


<div class="assetPanel">
	<!-- ASSET LIST -->
	<!-- <div class="assetsList"> -->
		<div class="flex col"> <!--asset-list-container-->

			<!-- top buttons -->
			<div *ngIf="production || admin" class="flex center gap-50 split" style="min-height: 40px;">
				<!---------->
				<div>
					<!-- <span class="section-header">Assets list</span> -->
					<button *ngIf="production && isCreativeInProductionState() && editable" class="newBtn small" mat-mini-fab color="primary" [matMenuTriggerFor]="menuAssetChoice" matTooltip="Create one or many new asset layers"><mat-icon>add</mat-icon></button>
				</div>	
				<div class="flex gap-50 grow">
					<button class="smallBtn" [disabled]="busy || !needsSave()" mat-raised-button color="primary" (click)="save()" matTooltip="Save changes on all assets">Save all</button>
					<button class="smallBtn" [disabled]="busy || !needsSave(true)" mat-stroked-button color="warn" (click)="revertAll($event)" matTooltip="Revert to previous state for all assets">Revert all</button>
				</div>
				<div>
					<!-- <mat-slide-toggle *ngIf="production && isCreativeInProductionState() && editable" [disabled]="layout != 'custom'" color="primary" style="transform: scale(0.75); transform-origin: 75% 50%;" [checked]="this.assetsComponentService.draggable$ | async" (change)="enableDragging($event)" labelPosition="before">Enable dragging</mat-slide-toggle> --> 
					
					<button [matMenuTriggerFor]="menuOptionAssets" mat-icon-button matTooltip="Asset list options"><mat-icon>more_vert</mat-icon></button>
				</div>
				<!----------->
				<!-- MENUS -->
					<!-- add asset -->
					<mat-menu #menuAssetChoice="matMenu">
						<span mat-menu-item disabled>Select a format type to add:</span>
						<mat-divider></mat-divider>
						<button class="fromFilesBtn" mat-menu-item (click)="newAssetFromFiles()">New From File(s)</button>
						<button class="newSelectItem" mat-menu-item *ngFor="let type of types" (click)="newAsset(type.value)" [disabled]="type.disabled"><mat-icon>{{getIconInner({'type':type.value})}}</mat-icon>{{type.label}}</button>		
					</mat-menu>
				
					<!-- new asset tools-->
					<mat-menu #menuOptionAssets="matMenu">
						<button mat-menu-item (click)="deleteAllAssets()" [disabled]="!assets?.length || !editable || !production || !isCreativeInProductionState()"><mat-icon>delete</mat-icon>Delete all assets</button>
						<mat-divider></mat-divider>
						<button mat-menu-item [matMenuTriggerFor]="menuSortAssets" [disabled]="!assets?.length || !editable || !production || !isCreativeInProductionState()"><mat-icon>swap_vert</mat-icon>Sort assets</button>
						<mat-divider></mat-divider>
						<button mat-menu-item (click)="showDetailedAssetList()" [disabled]="!assets?.length"><mat-icon>list</mat-icon>Show detailed asset list</button>
						<button mat-menu-item (click)="downloadAssetList()" [disabled]="true || !assets?.length"><mat-icon>save_alt</mat-icon>Download all assets</button>
						<button mat-menu-item *ngIf="production" (click)="exportAssetJson()" [disabled]="!assets?.length"><mat-icon>upload</mat-icon>export asset info (json)</button>
						<button mat-menu-item *ngIf="production" (click)="importAssetJson()" [disabled]="!editable || !production || !isCreativeInProductionState()"><mat-icon>download</mat-icon>import asset info (json)</button>
						<button mat-menu-item *ngIf="production" (click)="bulkResize()" [disabled]="!assets?.length || !editable || !production || !isCreativeInProductionState()"><mat-icon>aspect_ratio</mat-icon>bulk resize assets</button>
					</mat-menu>
					<!-- sort tools-->
					<mat-menu #menuSortAssets="matMenu">
						<button mat-menu-item (click)="sortAssets('name')">Sort by name</button>
						<button mat-menu-item (click)="sortAssets('width')">Sort by width</button>
						<button mat-menu-item (click)="sortAssets('height')">Sort by height</button>
						<button mat-menu-item (click)="sortAssets('area')">Sort by area</button>
						<button mat-menu-item (click)="sortAssets('diagonal')">Sort by diagonal</button>
						<button mat-menu-item (click)="sortAssets('reverse')">Reverse order</button>
					</mat-menu>
				<!-- END MENUS -->
			</div>
			<div *ngIf="production && !isCreativeInProductionState()" class="instruction centre gap-bottom">Assets cannot be added or updated during review phases.</div>

			<!-- <mat-divider></mat-divider> -->

			<!-- assets list -->
			<div class='assets gap-bottom'>
			
				<!--<app-asset *ngFor="let asset of assets; let index = index" [attr.data-index]="index" [asset]='asset' [index]="index" (click)="selectAsset.emit(asset)"></app-asset>-->
				<mat-selection-list *ngIf="assets && assets.length"  class="assets-list" #listAssets [multiple]="false" (selectionChange)="onSelectAsset($event)">
					<!--| sort : 'asset.order' : true  -->
					<mat-list-option *ngFor="let asset of sortAssetsByOrder(assets) ; let index = index; trackBy:trackByAssetUUID"
						
						[value]="asset"
						[selected]="isAssetSelected(asset)"
						[ngClass]="{changed:hasAssetChanged(asset) || isNewAsset(asset)}"
						[class.mat-list-single-selected-option]="isAssetSelected(asset)"
						
						> <!-- selected="asset == selectedAsset"-->
						<!-- || (selectedAsset && asset.uuid == selectedAsset?.uuid)  [disabled]="!editable"-->
						
						<div class="asset-list-item">
							<div class="asset-list-title">
								<mat-icon>{{getIcon(asset)}}</mat-icon>
								<!-- <span>({{asset.asset.order}})</span> -->
								<span matLine matTooltip="{{getFilename(asset)}}" [matTooltipPosition]="'left'" xxxmatTooltip="{{asset.asset.name}} {{asset.flag ? '('+asset.flag+')' : ''}}">{{asset.asset.name}}</span>    <!--{{index}}.&nbsp;&nbsp; ({{asset.order}}) &nbsp;v{{asset.version}}-->
							</div>
							<div *ngIf="production && isCreativeInProductionState() && editable" >
								<!-- button icons: ordering -->
								<div *ngIf="isAssetSelected(asset)">
									<button mat-icon-button [disabled]="!reorder(1, true)" (click)="reorder(1, false, $event)"><mat-icon>keyboard_arrow_up</mat-icon></button>
									<button mat-icon-button [disabled]="!reorder(-1, true)" (click)="reorder(-1, false, $event)"><mat-icon>keyboard_arrow_down</mat-icon></button>
								</div>
								<!-- button icons: delete + undo -->
								<button mat-icon-button [disabled]="!isCreativeInProductionState()" [matTooltip]="'Delete single asset'" (click)="deleteAsset(asset, $event)"><mat-icon>delete</mat-icon></button>
								<button mat-icon-button [disabled]="!hasAssetChanged(asset)" (click)="revert(asset, $event)"><mat-icon>undo</mat-icon></button>
							</div>
						</div>               
					</mat-list-option>
				</mat-selection-list>
				<div *ngIf="!assets || !assets.length" class="noAssets">
					<span class="instruction">There are no assets to display.</span>
				</div>
			</div>
			
			
			<!-- STICKY BUTTONS-->
			 <!--
				<div class="flex split center gap-bottom" *ngIf="(admin || production) && editable">
					<button class="smallBtn" [disabled]="busy || !needsSave()" mat-raised-button color="primary" (click)="save()" matTooltip="Save changes on all assets">Save all</button>
					<button class="smallBtn" [disabled]="busy || !needsSave(true)" mat-stroked-button color="warn" (click)="revertAll($event)" matTooltip="Revert to previous state for all assets">Revert all</button>
				</div>
			
			 -->
		</div>
	

	<!-- SELECTED ASSET PANEL -->
	<div *ngIf="selectedAsset" [disabled]="!editable" class="selectedAssetSection">
		<!--
		<legend >Selected asset
			<span *ngIf="production && isCreativeInProductionState()" class="legendButtons">
				<button class="smallBtn" mat-icon-button *ngIf="production && hasAssetChanged()" (color)="warn" (click)="revert()"><mat-icon>undo</mat-icon></button>	
			</span>
		</legend>
		
		<div style="margin-bottom: 0 !important;"><button class="close" mat-icon-button (click)="selectAsset.emit(null)"><mat-icon>close</mat-icon></button></div>
		-->
		<mat-divider></mat-divider>
		<div class="section-header flex split">			
			<div>Selected asset</div>
			<button class="close" mat-icon-button (click)="selectAsset.emit(null)"><mat-icon>close</mat-icon></button>
		</div>

		
		<div class="panel-section">
			<!-- new/empty asset -->
			<ng-container *ngIf="(production || admin) && !(selectedAsset.asset.type && selectedAsset.asset.uri && selectedAsset.asset.uuid) && !selectedAsset.file; else savedAsset">
				<!-- TYPE -->
				 <div class="flex center split">
					 <div class="field-label">Type</div>
					 <button mat-button matSuffix [ngStyle]="{'visibility':hasChanged('type') ? '' : 'hidden'}" mat-icon-button class="red" (click)="resetValue('type')">
						 <mat-icon>undo</mat-icon>
					 </button>
				 </div>
				<mat-form-field color="primary" appearance="fill"> 
					<mat-select [ngClass]="{'red': hasChanged('type')}" (selectionChange)="onTypeChange($event)" [(ngModel)]="selectedAsset.asset.type" >  
						<mat-select-trigger class="flex center gap-25" color="primary">
							<!-- <mat-icon matPrefix>{{getIconInner({'type':selectedAsset.asset.type})}}</mat-icon>{{selectedAsset.asset.type}}-->
							<mat-icon matPrefix>{{typeLookup[selectedAsset.asset.type].icon}}</mat-icon>{{typeLookup[selectedAsset.asset.type].label}}
						</mat-select-trigger>
						<mat-option *ngFor="let type of types" [value]="type.value" [disabled]="type.disabled"><mat-icon matPrefix>{{getIconInner({'type':type.value})}}</mat-icon>{{type.label}}</mat-option>
					</mat-select>
					
				</mat-form-field>
				<!-- NAME -->
				<div class="flex center split">
					<div class="field-label">Name</div>
					<button mat-button [ngStyle]="{'visibility':hasChanged('name') ? '' : 'hidden'}" matSuffix mat-icon-button class="red" (click)="resetValue('name')">
						<mat-icon>undo</mat-icon>
					</button>
				</div>
				<mat-form-field color="primary" appearance="fill">
					<input color="primary" [disabled]="false && !(isCreativeInProductionState())" matInput [(ngModel)]="selectedAsset.asset.name" [ngClass]="{'red': hasChanged('name') }">
				</mat-form-field>
				<!-- match to file name-->
				<button *ngIf="(hasFile() || selectedAsset.file) && (production && isCreativeInProductionState())" class="basic-small" style="float: right; margin-top: -1em;" color="warn" mat-button [disabled]="(selectedAsset.file && selectedAsset.asset.name == getFilenameNoDot(getNewFilename())) || (!selectedAsset.file && selectedAsset.asset.name == getFilenameNoDot(selectedAsset.asset.uri))" (click)="selectedAsset.file ? setAssetNameToFilename(selectedAsset, getNewFilename()) : setAssetNameToFilename(selectedAsset,selectedAsset.asset.uri)">{{selectedAsset.file ? 'Match new file' : 'Match saved file'}}</button>
			</ng-container>
			<!-- saved asset -->
			<ng-template #savedAsset>
				<div class="flex center gap-25">
					<!-- <mat-icon matPrefix class="gap-bottom-25">{{getIconInner({'type':selectedAsset.asset.type})}}</mat-icon>-->
					<mat-icon matPrefix class="gap-bottom-25">{{typeLookup[selectedAsset.asset.type].icon}}</mat-icon>
					<mat-form-field color="primary" appearance="fill">
						<input color="primary" [disabled]="!(admin || production)" matInput [(ngModel)]="selectedAsset.asset.name" [ngClass]="{'red': hasChanged('name') }">
						<button mat-button [ngStyle]="{'visibility':hasChanged('name') ? '' : 'hidden'}" matSuffix mat-icon-button class="red" (click)="resetValue('name')">
							<mat-icon>undo</mat-icon>
						</button>
					</mat-form-field>
				</div>
				<button *ngIf="(hasFile() || selectedAsset.file) && ((admin || production) && isCreativeInProductionState())" class="basic-small" style="float: right; margin-top: -1em;" color="warn" mat-button [disabled]="(selectedAsset.file && selectedAsset.asset.name == getFilenameNoDot(getNewFilename())) || (!selectedAsset.file && selectedAsset.asset.name == getFilenameNoDot(selectedAsset.asset.uri))" (click)="selectedAsset.file ? setAssetNameToFilename(selectedAsset, getNewFilename()) : setAssetNameToFilename(selectedAsset,selectedAsset.asset.uri)">{{selectedAsset.file ? 'Match new file' : 'Match saved file'}}</button>
			</ng-template>

			<!--
			<mat-divider></mat-divider>
			<div class="flex gap-25">
				<div [ngStyle]="{'width': (selectedAsset.asset.type && selectedAsset.asset.uri && selectedAsset.asset.uuid) ? '3.5em' : '4.5em'}">

					<span class="field-label">Type</span>
					<div *ngIf="!admin || (selectedAsset.asset.type && selectedAsset.asset.uri && selectedAsset.asset.uuid)" class="disabledTypeTrigger off">
						<mat-icon>{{getIconInner({'type':selectedAsset.asset.type})}}</mat-icon>
					</div>
					<mat-form-field *ngIf="admin && !(selectedAsset.asset.type && selectedAsset.asset.uri && selectedAsset.asset.uuid)" appearance="fill"> 
						<mat-select class="selectedTypeTrigger" [ngClass]="{'red': hasChanged('type')}" (selectionChange)="onTypeChange($event)" [(ngModel)]="selectedAsset.asset.type" >   
							<mat-select-trigger >
								<mat-icon>{{getIconInner({'type':selectedAsset.asset.type})}}</mat-icon>
							</mat-select-trigger>
							<mat-option *ngFor="let type of types" [value]="type.value" [disabled]="type.disabled"><mat-icon>{{getIconInner({'type':type.value})}}</mat-icon>{{type.label}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div style="width: 100%;">

					<span class="field-label">Name</span>
					<mat-form-field appearance="fill">
						<input [disabled]="!production || !isCreativeInProductionState()" matInput [(ngModel)]="selectedAsset.asset.name" [ngClass]="{'red': hasChanged('name') }">
						<button mat-button *ngIf="hasChanged('name')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('name')">
							<mat-icon>undo</mat-icon>
						</button>
					</mat-form-field>
					<button *ngIf="(hasFile() || selectedAsset.file) && (production && isCreativeInProductionState())" class="basic-small" style="float: right; margin-top: -1em;" color="warn" mat-button [disabled]="(selectedAsset.file && selectedAsset.asset.name == getFilenameNoDot(getNewFilename())) || (!selectedAsset.file && selectedAsset.asset.name == getFilenameNoDot(selectedAsset.asset.uri))" (click)="selectedAsset.file ? setAssetNameToFilename(selectedAsset, getNewFilename()) : setAssetNameToFilename(selectedAsset,selectedAsset.asset.uri)">{{selectedAsset.file ? 'Match new file' : 'Match saved file'}}</button>
				</div>
			</div>
			-->

			<!-- FILE INFO -->
			<div *ngIf="isFile()" [class.disabledCursor]="isAssetUploading(selectedAsset)">
				<div class="section-subheader">File Info</div>
				<div class="panel-section" [class.disabled]="isAssetUploading(selectedAsset)">
					
					<app-file-uploader *ngIf="production && isCreativeInProductionState()"
						[message]="hasFile() ? 'replace file' : 'upload file'" 
						(onFilesDragged)="handleUpload($event, selectedAsset)" 
						[parent]="this" 
						[multiple]="false" >
					</app-file-uploader>
					<div *ngIf="hasFile()">
						<mat-form-field appearance="fill" matTooltipClass="multiline-tooltip" *ngIf="!renamedFile">
							<mat-label>Saved file {{ hasChanged('uri') ? '*' : ''}}</mat-label>
							<input matInput disabled [value]="getFilename(selectedAsset)" [matTooltip]="getFilename(selectedAsset)" [matTooltipPosition]="'left'">
							<div matSuffix style="display:flex">
								<button mat-icon-button aria-label="backup" class="icon_backup" *ngIf="selectedAsset.asset.type == 'banner_html'" [disabled]="hasNewFile()" (mouseover)="showBackup(selectedAsset)" (mouseout)="hideBackup(selectedAsset)" >
									<mat-icon>image</mat-icon>
								</button>
								<!--<button *ngIf="production" mat-icon-button aria-label="edit" (click)="replaceFile()" [disabled]="hasNewFile()">-->
								<button *ngIf="production && hasChanged('uri')" mat-icon-button aria-label="undo" (click)="resetValue('uri')">
									<mat-icon>undo</mat-icon>
								</button>
								<!-- renaming: temporarily disabled-->
								<button *ngIf="false && production && !hasNewFile() && isCreativeInProductionState()" mat-icon-button aria-label="edit" (click)="renameFile()">
									<mat-icon>edit</mat-icon>
								</button>
								<button *ngIf="production || admin" mat-icon-button aria-label="download" (click)="download()" [matTooltip]="'Download file'">
									<mat-icon>download</mat-icon>
								</button>
							</div>
						</mat-form-field>
						<mat-form-field appearance="fill" matTooltipClass="multiline-tooltip" *ngIf="renamedFile">
							<mat-label>Saved file new name</mat-label>
							<input matInput [(ngModel)]="renamedFile">
							<div matSuffix style="display:flex">
								<button *ngIf="production" mat-icon-button aria-label="save" (click)="saveFileRename()" [disabled]="renamedFile?.length < 3">
									<mat-icon>done</mat-icon>
								</button>
								<button *ngIf="production" mat-icon-button aria-label="cancel" (click)="cancelRenameFile()">
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</mat-form-field>
					</div>
					<div *ngIf="hasNewFile()" >
						<mat-form-field appearance="fill">
							<mat-label>New file</mat-label>
							<input matInput disabled [value]="getNewFilename()" class="red">
							<button matTooltip="Delete new file" matSuffix mat-icon-button aria-label="clear" (click)="removeNewFile()">
								<mat-icon>delete</mat-icon>
							</button>
						</mat-form-field>
					</div>
					<!-- METADATA -->
					<div *ngIf="selectedAsset.metadataView?.length"><!-- selectedAsset.asset.metadata || selectedAsset.fileMetadata">-->
						<table mat-table [dataSource]="selectedAsset.metadataView" class="xmat-elevation-z4">
							<!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->
							<!-- Attribute Column -->
							<ng-container matColumnDef="key">
							<th mat-header-cell *matHeaderCellDef> Property </th>
							<td mat-cell *matCellDef="let element"> {{element.key}} </td>
							</ng-container>              
							<!-- Old Value Column -->
							<ng-container matColumnDef="value_old">
							<th mat-header-cell *matHeaderCellDef> Saved file </th>
							<td mat-cell *matCellDef="let element"> {{element.old_value}} </td>
							</ng-container>
							<!-- New Value Column -->
							<ng-container matColumnDef="value_new" >
								<th mat-header-cell *matHeaderCellDef class="primary"> {{hasNewFile() ? 'New file' :''}} </th>
								<td mat-cell *matCellDef="let element" class="primary"> {{hasNewFile() ? element.new_value : ''}} </td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
					</div>
					<div *ngIf="!selectedAsset.metadataView?.length" class="instruction gap-bottom">No file assigned to asset.</div>
					<div *ngIf="selectedAsset.asset.type == 'image' && selectedAsset.asset.metadata?.pages"><!--isFile() && -->
						<h3>Detected text</h3>
						<div *ngFor="let page of selectedAsset.asset.metadata.pages">
							<div *ngFor="let block of page">
								<p>{{block.contents}}</p>
							</div>
						</div>
					</div>
					<mat-divider></mat-divider>
					<mat-checkbox [disabled]="!(production || admin)" color="primary" [(ngModel)]="selectedAsset.asset.deliverable">deliverable{{hasChanged('deliverable') ? '*' : ''}}</mat-checkbox> <!-- || !isCreativeInProductionState() -->
				</div>
			</div>

			<!-- REFERENCE INFO-->
			<div *ngIf="isReference()">
				<form [formGroup]="formGroup">
				<mat-form-field appearance="fill">
					<mat-label for="url">Reference UUID{{hasChanged('uri') ? '*' : ''}}</mat-label>
					<input [disabled]="!production || !isCreativeInProductionState()" formControlName="uuid" type="text" matInput [(ngModel)]="selectedAsset.asset.uri">
					<mat-error *ngIf="formGroup.controls['uuid'].invalid">please enter valid uuid</mat-error>
					<button mat-button *ngIf="hasChanged('uri')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('uri')">
						<mat-icon>undo</mat-icon>
					</button>
				</mat-form-field>
				</form>
			</div>

			<!-- LINK INFO-->
			<div *ngIf="isLink()">
				<form [formGroup]="formGroup">
				<mat-form-field appearance="fill">
					<mat-label for="url">Url{{hasChanged('uri') ? '*' : ''}}</mat-label>
					<input [disabled]="!production || !isCreativeInProductionState()" formControlName="url" type="url" matInput [(ngModel)]="selectedAsset.asset.uri">
					<mat-error *ngIf="formGroup.controls['url'].invalid">please enter valid url inc. protocol</mat-error>
					<button mat-button *ngIf="hasChanged('uri')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('uri')">
						<mat-icon>undo</mat-icon>
					</button>
				</mat-form-field>
				</form>
			</div>

			<!-- VIEW INFO-->
			<div>
				<div class="section-subheader">View properties</div>
				<div class="panel-section">
					<div class="instruction label-warning" [ngStyle]="{'visibility': (hasChanged('x') || hasChanged('y')) ? '' : 'hidden'}">
						<mat-icon class="label-warning" >warning</mat-icon>
						<span>Position has changed since last saved.</span>
					</div>
					<div class="view-field-container">
						<!-- X -->
						<mat-form-field class="view-field" appearance="fill">
							<mat-label class="label-header" for="name">x
								<button mat-button *ngIf="hasChanged('x')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('x')">
									<mat-icon>undo</mat-icon>
								</button>
							</mat-label >
							<input type="number" [disabled]="!production || !isCreativeInProductionState() || layout != 'custom'" matInput [(ngModel)]="selectedAsset.asset.x">
							
						</mat-form-field>
						<div class="view-field-divider empty"></div>
						<!-- Y -->
						<mat-form-field class="view-field" appearance="fill">
							<mat-label class="label-header" for="name">y
								<button mat-button *ngIf="hasChanged('y')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('y')">
									<mat-icon>undo</mat-icon>
								</button>
							</mat-label>
							<input type="number" [disabled]="!production || !isCreativeInProductionState() ||  layout != 'custom'" matInput [(ngModel)]="selectedAsset.asset.y">
						</mat-form-field>
					</div>
					<ng-container *ngIf="hasDimensions()">
						
						<div class="view-field-container gap-bottom-25">
							<!-- WIDTH -->
							<mat-form-field class="view-field" appearance="fill">
								<mat-label class="label-header" for="name">width
									<button mat-button *ngIf="hasChanged('width')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('width')">
										<mat-icon>undo</mat-icon>
									</button>
								</mat-label>
								<input type="number" [disabled]="!production || !isCreativeInProductionState()" matInput [ngModel]="selectedAsset.asset.width" (change)="setWidth($event.target.value)">
								<!--<mat-hint *ngIf="doesntMatchWidth()" align="start">Doesn't match source</mat-hint>-->
								<mat-hint *ngIf="doesntMatchWidth()" align="end"><a (click)="matchWidth()" style="cursor:pointer"><strong>match</strong></a></mat-hint>			
							</mat-form-field>
							<button *ngIf="(production || admin)" [disabled]="!isCreativeInProductionState()" class="view-field-divider" matSuffix mat-icon-button aria-label="undo" (click)="toggleAspectRatio(selectedAsset.asset)">
								<mat-icon [matTooltip]="isCreativeInProductionState() ? ('maintain aspect ratio: '+ (maintainRatio ? 'ON' : 'OFF')) : ''">{{maintainRatio ? 'link' : 'link_off'}}</mat-icon>
							</button>
							<div *ngIf="!(production || admin)" class="view-field-divider empty"></div>
							<!-- HEIGHT -->
							<mat-form-field class="view-field" appearance="fill">
								<mat-label class="label-header" for="name">height
									<button mat-button *ngIf="hasChanged('height')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('height')">
										<mat-icon>undo</mat-icon>
									</button>
								</mat-label>
								<input type="number" [disabled]="!production || !isCreativeInProductionState()" matInput [ngModel]="selectedAsset.asset.height" (change)="setHeight($event.target.value)">
								<!--<mat-hint *ngIf="doesntMatchHeight()" align="start">Doesn't match source</mat-hint>-->
								<mat-hint *ngIf="doesntMatchHeight()" align="end"><a (click)="matchHeight()" style="cursor:pointer"><strong>match</strong></a></mat-hint>
							</mat-form-field>
						</div>
						<div class="instruction label-warning" *ngIf="doesntMatchHeight() || doesntMatchWidth() || (hasChanged('width') || hasChanged('height'))">
							<mat-icon class="label-warning" >warning</mat-icon>
							<div style="flex-shrink:1">Asset dimensions do not match file or have changed sinced last save.</div>
						</div>
					</ng-container>

					<mat-divider></mat-divider>
					<mat-checkbox [disabled]="!(production || admin)" color="primary" [(ngModel)]="selectedAsset.asset.visible">visible{{hasChanged('visible') ? '*' : ''}}</mat-checkbox> <!--  || !isCreativeInProductionState() -->


					<!-- annotation 
					<mat-form-field appearance="fill">
						<mat-label>Annotation{{hasChanged('annotation') ? '*' : ''}}</mat-label>
						<textarea [disabled]="!(production || admin)" matInput [(ngModel)]="selectedAsset.asset.annotation"></textarea>
						<button mat-button *ngIf="(production || admin) && hasChanged('annotation')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('annotation')">
							<mat-icon>undo</mat-icon>
						</button>
					</mat-form-field>
					-->
				</div>
			</div>
		</div>
		<div *ngIf="false && editable">
			<button class="smallBtn" style="font-size: 0.8em; margin: 0.5em; float:right;" mat-flat-button [disabled]="!production || !isCreativeInProductionState()" color="warn" [matTooltip]="'Delete selected asset'" (click)="deleteAsset(selectedAsset, $event)">Delete asset</button>
		</div>
	</div>


</div>

