import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeliveryPackage, IDeliveryPackage } from '../models/deliveryPackage.model';
import { Project2 } from '../models/project.model';
import { OVBaseService } from './OvBaseService';



@Injectable({
	providedIn: 'root'
})
export class Project2Service extends OVBaseService<Project2, string> {
	
	constructor(protected http: HttpClient) {
		super(http, 'project2');
	}
	getUserPermissions(project_id: string, user_id:string) {
		return this._get(`${this.base}/${project_id}/userPermissions/${user_id}`);
	}
	grantUserPermission(project_id : string, user_id:string, permission : string) {
		return this._post(`${this.base}/${project_id}/grantUserPermission/${user_id}/${permission}`);
	}
	removeUserPermission(project_id : string, user_id:string, permission : string) {
		return this._post(`${this.base}/${project_id}/revokeUserPermission/${user_id}/${permission}`);
	}
	getUsers(project_id: string):Observable<any>{
		return this._get(`${this.base}/${project_id}/users`);
	}
	// TODO can we fix the typing
	private _packagesSubject = new BehaviorSubject<any>(null);
	public packagesObservable = this._packagesSubject.asObservable();
	loadPackages(project_uuid: string):void
	{
		this.getPackages(project_uuid).pipe().subscribe((res) => {
			// process packages
			let packages:DeliveryPackage[] = res.data;
			// could sort them here?
			packages.forEach(deliveryPackage => {
				deliveryPackage.delivery_items_add = [];
				deliveryPackage.delivery_items_remove = [];

				deliveryPackage.partialAllowed = deliveryPackage.partialAllowed ? true : false;
				DeliveryPackage.createReferece(deliveryPackage);
			});
			this._packagesSubject.next(res.data);
		});
	}
	getPackages(project_id: string):Observable<any>{
		return this._get(`${this.base}/${project_id}/packages`);
	}
	updatePackages(project_uuid: string, deliveryPackages: DeliveryPackage[]) {
		let data = [];
		// loop through all packages for those with changes (name/password) or item changes
		deliveryPackages.forEach(deliveryPackage => {
			if(DeliveryPackage.isNew(deliveryPackage) || DeliveryPackage.isDirty(deliveryPackage) || deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length)
			{
				data.push(deliveryPackage);
			}
		});
		return this._post(`${this.base}/${project_uuid}/updatePackages`, {'deliveryPackages':data});
	}
	deletePackages(project_uuid: string, deliveryPackages: DeliveryPackage[])
	{
		let uuids = deliveryPackages.map(deliveryPackage => deliveryPackage.uuid);
		return this._post(`${this.base}/${project_uuid}/deletePackages`, {'uuids':uuids});
	}
	/*
	private _deliveryPackagesSubject = new BehaviorSubject<any>(null);
	public deliveryPackagesObservable = this._deliveryPackagesSubject.asObservable();
	loadDeliveryPackages(project_uuid: string):void
	{
		this.getDeliveryPackages(project_uuid).pipe().subscribe((res) => {
			// process packages
			let deliveryPackages:DeliveryPackage[] = res.data;
			deliveryPackages.forEach(deliveryPackage => {
				deliveryPackage.delivery_items_add = [];
				deliveryPackage.delivery_items_remove = [];
				DeliveryPackage.createReferece(deliveryPackage);
			});
			this._deliveryPackagesSubject.next(res.data);
		});
	}
	getDeliveryPackages(project_id: string):Observable<any>{
		return this._get(`${this.base}/${project_id}/deliveryPackages`);
	}
	updateDeliveryPackages(project_uuid: string, deliveryPackages: DeliveryPackage[]) {
		let data = [];
		// loop through all packages for those with changes (name/password) or item changes
		deliveryPackages.forEach(deliveryPackage => {
			if(DeliveryPackage.isDirty(deliveryPackage) || deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length)
			{
				data.push(deliveryPackage);
			}
		});
		return this._post(`${this.base}/${project_uuid}/updateDeliveryPackages`, {'deliveryPackages':data});
	}

	private _sharePackagesSubject = new BehaviorSubject<any>(null);
	public sharePackagesObservable = this._sharePackagesSubject.asObservable();
	loadSharePackages(project_uuid: string):void
	{
		this.getSharePackages(project_uuid).pipe().subscribe((res) => {
			// process packages
			let sharePackages:DeliveryPackage[] = res.data;
			sharePackages.forEach(sharePackage => {
				sharePackage.delivery_items_add = [];
				sharePackage.delivery_items_remove = [];
				DeliveryPackage.createReferece(sharePackage);
			});
			this._deliveryPackagesSubject.next(res.data);
		});
	}
	getSharePackages(project_id: string):Observable<any>{
		return this._get(`${this.base}/${project_id}/sharePackages`);
	}*/
}
