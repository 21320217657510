import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectService } from 'src/app/api/project.service';
import { WorkflowService } from 'src/app/api/workflow.service';
import { Channel, IChannel } from 'src/app/models/channel.model';
import { Permission } from 'src/app/models/permissions.model';
import { IUser } from 'src/app/models/user.model';
import { IWorkflow, Workflow } from 'src/app/models/workflow.model';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemoveProductionComponent } from 'src/app/dialogs/remove-production/remove-production.component';
import { ApprovalGroup, IApprovalGroup } from 'src/app/models/ApprovalGroup.model';
import { RemoveApproverComponent } from 'src/app/dialogs/remove-approver/remove-approver.component';
import { ApprovalGroupService } from 'src/app/api/approval-group-service.service';
import { IProjectChannel } from 'src/app/models/projectChannel.model';
import { WorkgroupService } from 'src/app/api/workgroup.service';
import { GenericDialogComponent } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { RemoveProjectUserComponent } from 'src/app/dialogs/remove-project-user/remove-project-user.component';
import { NoRemoveProjectadminComponent } from 'src/app/dialogs/no-remove-projectadmin/no-remove-projectadmin.component';
import { AppUserService } from 'src/app/services/app-user.service';
import { DialogService } from 'src/app/services/dialog.service';
@Component({
	selector: 'app-project-workflows',
	templateUrl: './project-workflows.component.html',
	styleUrls: ['./project-workflows.component.scss']
})
export class ProjectWorkflowsComponent implements OnInit, OnChanges {
	private _unsubscribe = new Subject<boolean>();

	@Output() add : EventEmitter<any> =  new EventEmitter();
	@Output() edit : EventEmitter<any> =  new EventEmitter();
	@Output() delete : EventEmitter<any> =  new EventEmitter();

	@Input() workgroup_uuid:string;
	@Input() project_uuid:string;
	@Input() workflows:IWorkflow[];
	@Input() projectChannels:IProjectChannel[];
	@Input() userAdmins:IUser[];
	@Input() workflow: IWorkflow;
	@Input() newWorkflowName: string;
	//@Input() availableUsers: IUser[];

	//projectChannels:IProjectChannel[];
	numProjectChannels:number = 0;
	//workflow:IWorkflow;
	availableChannels:string[] = [];
	editWorkflow:boolean = false;

	// all users available from workgroup
	availableUsers:IUser[] = [];
	
	// users that have at least one "role" in one channel in this workflow
	users:IUser[];
	projectUsers:IUser[];//TODO investigate double up here with long-winded 'users' with same info (maybe) 
	//userAdmins:IUser[] = [];

	productionUsers:IUser[] = [];
	approverUsers:IUser[] = [];
	userChannels:any;
	moderators:IUser[] = [];
	//userChannels:any[];
	
	channelFormGroup:FormGroup;
	addingProduction:boolean;
	addingApprover:boolean;
	moderatorUser:IUser;
	productionUser:IUser;
	editProductionUser:IUser;
	editApprovalUser:IUser;
	editApprovalUserUUID: string;
	approvalUser:IUser;
	approvalGroup:IApprovalGroup;
	selectedGroup:IApprovalGroup;
	groupControl: FormControl;

	disabledEditing: boolean = true;
	newWorkflowLoaded: boolean = false;
	nonApprUserNum: number = 0;
	appUserIsAdmin: boolean;


	//showChannels:boolean = false;

	constructor(		
		@Optional() public dialogRef: MatDialogRef<ProjectWorkflowsComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		public workgroupService: WorkgroupService,
		public projectService: ProjectService,
		private workflowService:WorkflowService,
		private approvalGroupService:ApprovalGroupService,
		public appUserService:AppUserService,
		private dialog: MatDialog,
		private dialogService: DialogService,
		private snackBar: MatSnackBar,
		private fb:FormBuilder,
		) { }
	ngOnInit(): void {
		if(this.dialogRef)
		{
			this.dialogRef.backdropClick().subscribe(() => {
				// Close the dialog if not editing
				if(this.editApprovalUser || this.editProductionUser)
				{
					const dialogRef = this.dialog.open(GenericDialogComponent, {
						data: {
							title:"Close Workflow",
							subtitle:this.workflow.name,
							body:"Currently editing a user, are you sure you wish to close this workflow?",
							positive:"Yes",
							negative:"Cancel"}
					  });
					  dialogRef.afterClosed().subscribe((result: any) => {
						if(result)
						{
							this.dialogRef.close();
						}
					  });
					  return;
				}
				this.dialogRef.close();
			})
		}

		//console.log("WF DATA", this.data)
		if(this.data)
		{
			this.workgroup_uuid = this.data.workgroup_uuid;
			this.project_uuid = this.data.project_uuid;
			this.projectChannels = this.data.projectChannels.sort((a, b) => a.order - b.order);
			this.numProjectChannels = this.projectChannels.length;
			this.workflows = this.data.workflows;
			this.workflow = this.data.workflow;
			this.workflow.channels.sort((a, b) => a.order - b.order);
			this.categoriseUsers(); //sorts workflow channel users into approver or production - needs this.workflow
			// //gets all the users for the entire workgroup
			this.getChannels(); //set this.availableChannels using all workflows. Could this just be project channels? (would have different ids)
		}
		this.loadUsers();
		this.workgroupService.users.pipe().subscribe((users:IUser[]) =>{
			this.availableUsers = users;
			//console.log("available users", this.availableUsers);
		});
	
		this.projectService.team.pipe().subscribe((team:any) =>{
			if(team){
				this.userAdmins = team.admins;
				this.projectUsers = team.users;
				this.appUserIsAdmin = this.isAdmin(this.appUserService.appUser);
				this.disabledEditing = !this.appUserIsAdmin;
			} 
		});
		
		
		// maybe we grab the workflows from the servie and listen to any changes
		this.projectService.workflows$.pipe().subscribe(workflows => {
			this.workflows = workflows;
			if(this.workflow)
			{
				this.workflow = this.workflows.find(workflow => workflow.uuid === this.workflow.uuid);

				//console.log("OLD WF FOUND!", this.workflow);
				this.categoriseUsers();
				
			}
			//this.mergeUsers();

			if(this.workflow && this.approvalGroup && this.approvalUser){
				this.approvalGroup = this.workflow.approval_groups.find(approvalGroup => approvalGroup.id === this.approvalGroup.id);			
				this.approvalUser = this.availableUsers.find(user => user.uuid === this.approvalUser.uuid);
				if(this.approvalUser)
				{
					this.updateChannelFormGroup(null, null);
				}
			}
			//console.log("workflows loaded", this.editApprovalUserUUID);
			
			if(this.editApprovalUserUUID && this.workflow)
			{
				// TODO this search could be improved
				let group = this.workflow.approval_groups.find(approvalGroup => approvalGroup.users.find(user => user.uuid === this.editApprovalUserUUID));
				let editApprovalUser = group?.users.find(user => user.uuid === this.editApprovalUserUUID);
				if(editApprovalUser)
				{
					this.editApproval(editApprovalUser);
				}				
				this.editApprovalUserUUID = null
			}
			if (!this.editApprovalUser) this.cancelEditors();
		});
		this.cancelEditors();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//console.log("workflow changes", changes);
		if(changes.workflows)
		{
			this.workflows = changes.workflows.currentValue;
			if(this.workflows?.length == 1){//pre-select if only 1
				this.workflow = this.workflows[0];
			}
			else if(this.newWorkflowName && !this.newWorkflowLoaded)
			{
				this.workflow = changes.workflows.currentValue.find(workflow => workflow.name == this.newWorkflowName);
				//this.delete.emit({type:"newWorkflowName", data:null});
				this.newWorkflowLoaded = true;
			}			
			else if (this.workflow) { //re-map existing workflow
				this.workflow = changes.workflows.currentValue.find(workflow => workflow.id == this.workflow.id);
			}

			if(this.workflow){
				//console.log("old workflow found", this.workflow);
				
				this.categoriseUsers();
			}	
			this.getChannels();
		}
		if(changes.workflow){
			//console.log("WORKFOOW CHANGED!!");
		}

		/*if(changes.projectChannels)
		{
			//this.projectChannels.sort((a, b) => a.order - b.order);
		}*/
		/*
		if(this.users)
		{
			this.mergeUsers();
		}*/
	}

	/*
	reloadUsers()
	{
		this.workgroupService.loadUsers(this.workgroup_uuid);
	}
	*/

	updateProjectChannels(projectChannels:IProjectChannel[])
	{
		this.projectChannels = projectChannels;
		//sort the assets array by .order
		this.projectChannels.sort((a, b) => a.order - b.order);

		this.numProjectChannels = this.projectChannels.length;
	}
	getChannels()
	{
		if(!this.workflows) return;
		this.availableChannels.length = 0;
		let channelLookup = {};
		for (let i = 0; i < this.workflows.length; i++) {
			const workflow = this.workflows[i];
			const channels = workflow.channels;
			if(channels)
			{
				for (let j = 0; j < channels.length; j++) {
					const channel = channels[j];
					if(channelLookup[channel.name] == undefined)
					{
						channelLookup[channel.name] = true; 
						this.availableChannels.push(channel.name);
					}			
				}
			}
		}
	}
	categoriseUsers()
	{
		if(!this.workflow?.channels) return;

		//sort channels forst
		this.workflow.channels.sort((a, b) => a.order - b.order);

		this.userChannels = {};
		let productionUsersLookup = {};
		this.productionUsers = [];
		let approverUsersLookup = {};
		this.approverUsers = [];
		for(let i = 0; i < this.workflow?.channels?.length; i++)
		{
			let channel = this.workflow.channels[i];
			for(let j = 0; j < channel['users'].length; j++)
			{
				let user = channel['users'][j];
				if(user.scoped_permissions & Permission.PRODUCTION)
				{
					if(!productionUsersLookup[user.uuid])
					{
						productionUsersLookup[user.uuid] = true;
						this.productionUsers.push(user);
					}
				}
				if(user.scoped_permissions & Permission.APPROVAL)
				{
					if(!approverUsersLookup[user.uuid])
					{
						approverUsersLookup[user.uuid] = true;
						this.approverUsers.push(user);
					}
				}
			}
		}

		// approval groups

		//console.log("categerize users", this.workflow);
		//console.log("categerize users", this.workflow);
		
		/*
		// clear users if any?
		for (let i = 0; i < this.workflow.approval_groups.length; i++) {
			this.workflow.approval_groups[i].users.length = 0;
		}
		let approvalChannels = this.workflow['approvalChannels'];
		for(let uuid in approvalChannels)
		{
			let user = this.approverUsers.find(u => u.uuid == uuid);
			if(!user) continue;
			let userChannels = approvalChannels[uuid];
			let groupIndex = (Object.values(userChannels)[0] as number) - 1;
			if(groupIndex < this.workflow.approval_groups.length )
			{
				this.workflow.approval_groups[groupIndex].users.push(user);
			}else{
				console.warn("group index not found:", groupIndex);				
			}
		}*/
		
		this.users = this.workflow["users"];
		this.nonApprUserNum = this.users.length + this.productionUsers.length;
		//console.log("this.users",this.users);

		/*//Moderators...should be decorated on workflow model
		for (let i = 0; i < this.workflow['users'].length; i++)
		{
				const user = this.workflow['users'][i];
				if(Permission.hasPermission(user.scoped_permissions, Permission.VALIDATOR))
				{
					this.moderators.push(user);
				}
		}*/
	}

	update(selectedWF)
	{
		//console.log("updating",event);
		//ngModel not updated from form field so passing thru selected workflow as 'selectedWF' from $event
		this.workflow = selectedWF;
		this.categoriseUsers();
		this.loadUsers();
	}
	approverMenuClosed(e)
	{
		//console.log("closed", e);		
	}
	saveWorkflowEdit(name:string)
	{
		// workflow name updated.. so save
		this.snackBar.open("updating workflow", "", { duration: 4000 });
		this.workflowService.update(this.workflow.id.toString(), {name} as IWorkflow).pipe().subscribe(res => {
			//console.log("update", res);
			this.snackBar.open("workflow updated", "", { duration: 2000 });
			this.projectService.loadWorkflows(this.project_uuid);
			this.editWorkflow = false;
		}, error => {
			this.snackBar.open("workflow update failed", "", { duration: 2000, panelClass:'snackBar-error'});
		});
	}
	newGroup(user:IUser = null)
	{
		this.cancelEditors();
		if(user)	this.approvalUser = user;

		const dialogRef = this.dialog.open(DialogNewGroup,{
			data:{workflow_uuid:this.workflow.uuid, approval_groups:this.workflow.approval_groups}
		});
		dialogRef.afterClosed().subscribe(group => {
			if(group)
			{
				//this.approvalGroup = group;
				// create the group and reloaddddd
				this.workflowService.approvalGroupCreate(this.workflow.uuid, group).pipe().subscribe(result => {
					this.snackBar.open("Approval group saved", null, {duration: 2000});
					// grab a reference to the newly created group
					if(this.approvalUser){
						this.approvalGroup = result.data[0];
						this.assignApprover(this.approvalGroup, this.approvalUser);
					}else{
						//this.projectService.loadWorkflows(this.project_uuid);
						this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'creatives']);
					}
				})
			}
		});
	}
	assignApprover(group:ApprovalGroup, user:IUser)
	{
		this.cancelEditors();
		this.disabledEditing = true;
		this.updateChannelFormGroup(null, null);
		
		this.snackBar.open("Assigning user to group", null, {duration: 2000});
		this.workflowService.assignUserToGroup(this.workflow.uuid, user.uuid, group.uuid,).pipe().subscribe(result => {
			this.snackBar.open("User assigned to group", null, {duration: 2000});
			// reload the workflows
			//this.projectService.loadWorkflows(this.project_uuid);
			this.editApprovalUserUUID = user.uuid;
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels']);
			
		}, error => {
			this.snackBar.open("Error assigning user to group", null, {duration: 2000, panelClass:'snackBar-error'});			
		}, () => {
			this.disabledEditing = false;
		});

	}
	assignApproverOld(group:ApprovalGroup, user:IUser)
	{
		throw new Error("old function");
		
		this.cancelEditors();
		this.disabledEditing = true;
		this.updateChannelFormGroup(null, null);
		
		this.approvalGroup = group;
		this.approvalUser = user;		
	}
	addWorkflow()
	{
		this.newWorkflowLoaded = false;
		this.add.emit({type:"workflow"});
	}
	deleteWorkflow(workflow:Workflow)
	{
		this.delete.emit({type:"workflow", data:workflow.uuid});
	}
	addProjectChannel()
	{
		this.add.emit({type:"project_channel"});
	}
	editProjectChannel(projectChannel:IProjectChannel)
	{
		this.edit.emit({type:"project_channel", data:projectChannel});
	}
	deleteProjectChannel(projectChannel:IProjectChannel)
	{
		this.delete.emit({type:"project_channel", data:projectChannel});
	}
	linkProjectChannel(projectChannel:IProjectChannel)
	{
		//this.add.emit({type:"project_channel", workflow:this.workflow, projectChannel});
		//this.add.emit({type:"channel", projectChannel, workflow:this.workflow});
		//let workflowChannel = this.getWorkflowChannel(projectChannel);
		this.add.emit({type:"channel", workflows:[this.workflow], projectChannel});
	}
	unlinkProjectChannel(projectChannel:IProjectChannel)
	{
		//find the workflow channel
		let workflowChannel = this.getWorkflowChannel(projectChannel);
		this.delete.emit({type:"channel", workflow:this.workflow, workflowChannel});
	}
	isProjectChannelLinked(projectChannel:IProjectChannel)
	{
		//return this.workflow.channels.find(channel => channel.project_channel_uuid == projectChannel.uuid) != null;
		return this.getWorkflowChannel(projectChannel) != null;
	}
	isProjectUser(user:IUser)
	{
		return this.projectUsers.find(u => u.uuid == user.uuid) ? true : false;
	}
	getWorkflowChannel(projectChannel:IProjectChannel){
		return this.workflow.channels?.find(channel => channel.project_channel_uuid == projectChannel.uuid);
	}
	loadUsers()
	{
		// Do we need to do this often?
		// unlikely, it could probably be triggered by a realtime vnt rather than on changes here
		this.workgroupService.loadUsers(this.workgroup_uuid);
		this.workflow = this.workflow;
		this.cancelEditors();
		return;
		/*
		this.workflowService.getUsers(this.workflow.id).pipe().subscribe(result => {
			console.log("results", result);
			this.availableUsers = result.data["availableUsers"];
			this.users = result.data["users"];

			this.mergeUsers();
		});
		*/
		
	}
	/*mergeUsers()
	{
		return;
		if(!this.users) return; // cannot merge them if they are not present
		// we have the users now put them in the correct approval groups
		// first empty
		if(!this.workflow) return;
		for (let i = 0; i < this.workflow.approval_groups.length; i++) {
			this.workflow.approval_groups[i].users.length = 0;
		}
		for (let i = 0; i < this.users.length; i++) {
			const user = this.users[i];
			let data:number = user["data"];
			if(data)
			{
				this.workflow.approval_groups[data-1].users.push(user);
			}				
		}
	}
	*/
	isModerator2 = (user):boolean =>
	{
		//return user['isModerator'];
		return (Permission.hasPermission(user.scoped_permissions, Permission.VALIDATOR));
	}
	isModerator = (user):boolean =>
	{
		//return user['isModerator'];
		return user.moderatorFor.find(workflow => workflow.id == this.workflow.id) != undefined;
	}
	// check if an active user is production by seeing if they have a production permission on any of their channels
	isProduction = (user):boolean =>
	{
		// user + channel_id + permission
		// user + channel_ids + permissions
		// channels -> users + permissions

		//let channels = user.channels;//this.userChannels[user.uuid];
		let userChannels = user.channels;
		for(const id in userChannels)
		{
			if(userChannels[id] & Permission.PRODUCTION) return true;
		}
		/*
		for (let i = 0; i < channels.length; i++) {
			const element = channels[i];
			if(element.uuid == user.uuid && element.scoped_permissions & Permission.PRODUCTION) return true;
		}*/
		return false;
	}
	isApprover = (user):boolean =>
	{
		for(const id in user.channels)
			if(user.channels[id] & Permission.APPROVAL) return true;
		return false;
	}
	hasAccessToAllChannels(user, permission)
	{
		let perm:number = Permission.LOOKUP[permission];
	
		let channels = this.workflow.channels;
		for (let i = 0; i < channels.length; i++) {
			let channel = this.workflow.channels[i];
			let foundUser = channel['users'].find(u => u.uuid == user.uuid);
			if(!foundUser) return false;
			if((foundUser.scoped_permissions & perm) == 0)
				return false;
		}
		return true;
		let userChannels = this.getUserChannels(user);

		for (let i = 0; i < this.workflow.channels.length; i++) {
			const channel = this.workflow.channels[i];
			const userChannel = userChannels[channel.id];
			if(!userChannel) return false;
			if((userChannel & perm) == 0) return false;
		}
		return true;
	}
	hasAccessToChannel(user_in, channel_in, permission)
	{
		let perm:number = Permission.LOOKUP[permission];
	
		let channels = this.workflow.channels;
		for (let i = 0; i < channels.length; i++) {
			let channel = this.workflow.channels[i];
			if(channel.id != channel_in.id) continue;
			let foundUser = channel['users'].find(u => u.uuid == user_in.uuid);
			if(!foundUser) return false;
			if((foundUser.scoped_permissions & perm) == 0)
				return false;
		}
		return true;

		let userChannels = this.getUserChannels(user_in);
		return ((userChannels[channel_in.id] ?? 0) & perm) == perm;
		/*
		for (let i = 0; i < this.users.length; i++) {
			const element = this.users[i];
			if(element.uuid == user.uuid && element["scope_id"] == channel.id && element.scoped_permissions & perm) return true;
		}*/
		return false;
	}
	toggleAccessToChannel(event:MatCheckboxChange, user:IUser, channel:IChannel, permission:string)
	{
		if(event.checked)
		{
			this.snackBar.open("granting access to channel", "", { duration: 4000 });
			this.workflowService.grantUserChannel(this.workflow.id, user.uuid, channel.uuid, Permission.LOOKUP[permission]).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("access granted", "", { duration: 2000 });
				this.loadUsers();				
			});
		}else{
			if(Permission.LOOKUP[permission] == Permission.PRODUCTION)
			{
				this.removeProductionFromChannels(user, [channel]);
			}else if(Permission.LOOKUP[permission] == Permission.APPROVAL)
			{
				
				this.dialog.open(RemoveApproverComponent, {
					data:{
						project_uuid:this.workflow.id,
						user_name:user.name,
						user_uuid:user.uuid,
						channels:[channel.id]}
				});
			}
		}
	}
	removeProduction(user:IUser)
	{
		let userProductionChannels = [];
		/*
		for(let key in user["channels"])
		{
			if(user["channels"][key] & Permission.PRODUCTION)
			{
				userProductionChannels.push(this.workflow.channels.find(channel => channel.id == parseInt(key)));
			}
		}*/
		for(let key in this.workflow['productionChannels'][user.uuid])
		{
			userProductionChannels.push(this.workflow.channels.find(channel => channel.uuid == key));
		}
		userProductionChannels = this.workflow['productionChannels'][user.uuid].map(id => this.workflow.channels.find(channel => channel.uuid == id))
		this.removeProductionFromChannels(user, userProductionChannels);
	}
	/**
	 * Info we want easy access to
	 * for a given users what channels do they have permissions on, ideally a quick lookup
	 * for a given channel what users have permissions on it
	 * 
	 */
	removeProductionFromChannels(user:IUser, channels:IChannel[])
	{
		// get other producion users per channel
		let others:IUser[][] = [];
		for (let index = 0; index < channels.length; index++) {
			const channel = channels[index];
			let otherProductionUsers:IUser[] = [];
			for (let i = 0; i < channel['users'].length; i++) {
				const otherUser = channel['users'][i];
				// skip if same user
				if(otherUser.uuid == user.uuid) continue;
				// see if user has production in this channel
				if(otherUser.scoped_permissions & Permission.PRODUCTION) otherProductionUsers.push(otherUser);
			}
			others.push(otherProductionUsers);
		}

		const dialogRef = this.dialog.open(RemoveProductionComponent, {
			data:{workflow_id:this.workflow.uuid, otherProductionUsers:others, user_name:user.name, user_uuid:user.uuid, channels}
		});
		dialogRef.afterClosed().subscribe(result => {
			//console.log("AFTER REMOV DIALOG CLOSE", result);
			this.snackBar.open("Production user updates saved", null, {duration: 2000});
			if(result)
			{
				//console.log("remove production:",result.msg);
				// TODO
			}
			//this.loadUsers();
			//this.projectService.loadTeam(this.project_uuid);
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels']);
		});
	}
	// remove approver from all channels and group
	removeApprover(user:IUser)
	{
		let channelsToRemove = [];
		/*
		for(let key in user["channels"])
		{
			if(user["channels"][key] & Permission.APPROVAL)
			{
				channelsToRemove.push(this.workflow.channels.find(channel => channel.id == parseInt(key)));
			}
		}
		*/
		for(let key in this.workflow['approvalChannels'][user.uuid])
		{
			channelsToRemove.push(this.workflow.channels.find(channel => channel.uuid == key));
		}
		this.removeApprovalFromChannels(user, channelsToRemove, true);
	}
	removeApprovalFromChannels(user:IUser, channels:IChannel[], andGroup:boolean = false)
	{
		const dialogRef = this.dialog.open(RemoveApproverComponent, {
			data:{
				workflow:this.workflow,
				user_name:user.name,
				user_uuid:user.uuid,
				channels,
				andGroup
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			//console.log("AFTER REMOV DIALOG CLOSE", result);
			this.snackBar.open("Approver updates saved", null, {duration: 2000});
			if(result)
			{
				// TODO
				this.approvalGroup = result;
			}else{
				this.approvalGroup = null;
			}
			//this.projectService.loadWorkflows(this.project_uuid);
			//this.projectService.loadTeam(this.project_uuid);
			//this.loadUsers();
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'creatives']);
		});
	}
	updateProductionUser()
	{

	}
	updateChannelFormGroup(user:IUser, permission:string)
	{
		let data = {};		
		for(var i = 0; i < this.workflow.channels.length; i++)
		{
			if(user)	data[this.workflow.channels[i].id] = this.hasAccessToChannel(user, this.workflow.channels[i], permission);
			else		data[this.workflow.channels[i].id] = false;
		}
		this.channelFormGroup = this.fb.group({
			channels:this.fb.group(data)
		});
	}
	isFormDifferentFromUser(user:IUser, permission:string)
	{
		for(var i = 0; i < this.workflow.channels.length; i++)
		{
			let channel = this.workflow.channels[i];
			// channel is different
			if(this.channelFormGroup.value.channels[channel.id] != this.hasAccessToChannel(user, channel, permission))
				return true;
		}
		// form matches user
		return false;
	}
	addProjectUser(user:IUser)
	{
		this.snackBar.open("Adding project user...", "", {duration: 5000});
		this.projectService.grantUserPermission(
			this.project_uuid,
			user.uuid,
			Permission.ACCESS
		).pipe(takeUntil(this._unsubscribe)).subscribe(result => {
			this.snackBar.open("User added", "", { duration: 2000 });
			this.projectService.loadTeam(this.project_uuid);
		}, error => {
			console.error(error);
		});
	}

	isAdmin(user):boolean
	{
		return this?.userAdmins.find(u => u.uuid == user.uuid) != undefined;
	}
	isSuper()
	{
		return (this.appUserService.appUser.super); 
	}
	canBeAdmin = (user):boolean =>
	{
		// is the user an admin aldready or not
		return !this.isAdmin(user);
	}
	setAdmin(user:IUser)
	{
		this.snackBar.open("Adding admin user...", "", {duration: 5000});
		this.projectService.grantUserPermission(
			this.project_uuid,
			user.uuid,
			Permission.ADMIN
		).pipe(takeUntil(this._unsubscribe)).subscribe(result => {
			this.snackBar.open("Admin added", "", { duration: 2000 });
			this.projectService.loadTeam(this.project_uuid);
		}, error => {
			console.error(error);
		});
	}
	/**
	 * Remove a user from an admin role in this project
	 * Will also remove them for any moderator in any child workflows
	 * @param user The user to remover from being admin
	 */
	async removeAdmin(user:IUser)
	{
		if(!this.appUserIsAdmin)	return;			// only admins can call this
		if(this.userAdmins.length == 1) return; // cannot remove if only one
		if(user.uuid == this.appUserService.appUser.uuid)
		{
			const result = await this.dialogService.openGenericConfirm({title:"Are you sure you want to remove yourself"}).toPromise();
			if(!result) return;
		}
		this.snackBar.open(`removing admin: ${user.name}`, null, {duration: 2000});
		this.projectService.removeUserPermission(this.project_uuid, user.uuid, Permission.ADMIN).pipe().subscribe(response => {
			this.reload();
			this.snackBar.open(`removed admin: ${user.name}`, null, {duration: 2000});
		});
	}
	removeProjectUser(user:IUser, removeAdminOnly:boolean = false){
		//this.snackBar.open("remove project user NOT IMPLEMENTED", "", { duration: 2000, panelClass:'snackBar-error'});
		this.snackBar.open("removing user " + user.name + " from project", null, {duration: 2000});
		// fetch all the project data on this user and decide what action needs to be taken
		this.projectService.getAllUserPermissions(this.project_uuid,user.uuid).pipe().subscribe(res => {
			//console.log("all user perms", res);
			let data:any = res.data[0];
			//console.log("removeProjUser data",data);
			//first check if removed user is not the only admin in project
			let admins = data.users.filter(projecUser => projecUser.scoped_permissions & Permission.ADMIN);
			let userIsAdmin = (user.scoped_permissions & Permission.ADMIN) != 0;

			if(admins.length == 1 && userIsAdmin)
			{
				this.onRemoveSoleAdminPopup(user, data);
				return;
			}
			// if no access to anything in workflow - safe to remove (unless is the only admin)
			if(!data.workflows.length)
			{		
				//if((data.perms[0].permissions & Permission.ADMIN) == 0)
				if(admins.length > 1 || !userIsAdmin)
				{
					this.projectService.removeUser(this.project_uuid, user, []).pipe().subscribe(res =>
					{
						this.snackBar.open("user removed", null, {duration: 2000});
						this.reload();
						
					});
					return;
				}
			}

			//if user is Admin or has any workflow roles
			const dialogRef = this.dialog.open(RemoveProjectUserComponent, {
				data:{user:user, project:res.data[0], workflows:this.workflows, removeAdminOnly},
				//position:{top:'10px'}
			});
			
			dialogRef.afterClosed().subscribe(result => {
				//console.log("AFTER REMOV DIALOG CLOSE", result)
				this.reload();
				
			});
		}, error => {
			console.log("all user perms error", error);
		});
	}

	reload(){
		//this.loadUsers();
		this.projectService.loadTeam(this.project_uuid);
		this.projectService.loadWorkflows(this.project_uuid); 
	}
	onRemoveSoleAdminPopup(user,project){
		const dialogRef = this.dialog.open(NoRemoveProjectadminComponent, {
			data:{user, project}
		});
	}

	/* now admin only removal goes thru removeProjectUser() function so that Amend Manager role can be re-assigned if present
	removeAdmin(user:IUser)
	{
		
		this.projectService.getAllUserPermissions(this.project_uuid,user.uuid).pipe().subscribe(res => {
			//console.log("all user perms", res);
			let data:any = res.data[0];
			console.log("removeProjUser data",data);
			//first check if removed user is not the only admin in project
			let admins = data.users.filter(projecUser => projecUser.scoped_permissions & Permission.ADMIN);
			let userIsAdmin = (user.scoped_permissions & Permission.ADMIN) != 0;
			if(admins.length == 1 && userIsAdmin)
			{
				this.onRemoveSoleAdminPopup(user,data);
				return;
			}

			this.snackBar.open("Removing admin user...", "", {duration: 5000});
			this.projectService.removeUserPermission(
				this.project_uuid,
				user.uuid,
				Permission.ADMIN
			).pipe(takeUntil(this._unsubscribe)).subscribe(result => {
				this.snackBar.open("Admin removed", "", { duration: 2000 });
				this.projectService.loadTeam(this.project_uuid);
			}, error => {
				console.error(error);
			});
			
		}, error => {
			console.log("all user perms error", error);
		});
		
	}
	*/
	canBeAmendManager = (user):boolean =>
	{
		// is the user an admin
		let found = this.userAdmins.find(u => u.uuid == user.uuid);
		if(!found) return false;
		found = this.users.find(u => u.uuid == user.uuid);
		if(!found) return true;
		return !this.isModerator2(found);
	}
	canBeProduction = (user):boolean =>
	{
		return this.workflow['productionChannels'][user.uuid] == undefined;
		// is the user not a current user
		let found = this.users.find(u => u.uuid == user.uuid);
		// if not found then we are available
		if(!found) return true;
		return !this.isProduction(found);
	}
	setModerator(user:IUser)
	{
		this.cancelEditors();
		this.disabledEditing = true;
		this.moderatorUser = user;
	}
	removeModerator(user:IUser)
	{
		this.snackBar.open("Removing moderator...", "", {duration: 5000});
		this.workflowService.removeValidator(this.workflow.uuid, user).pipe().subscribe(result => {
			this.snackBar.open("Moderator removed", "", { duration: 2000 });
			this.moderatorUser = null;

			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'creatives','users']);
		});
	}
	setProduction(user:IUser)
	{
		this.cancelEditors();
		this.disabledEditing = true;
		this.productionUser = user;
		this.updateChannelFormGroup(null, null);
	}
	setApproval(user:IUser)
	{
		this.approvalUser = user;
		this.updateChannelFormGroup(null, null);
		// dialog to configure approval group
		const dialogRef = this.dialog.open(DialogChooseGroup, {
			data:{workflow_id:this.workflow.id, approval_groups:this.workflow.approval_groups}
		});
		dialogRef.afterClosed().subscribe(group => {
			if(group)
			{
				// if a group was chosen we need to send off to api to add user
				this.approvalGroup = group;
			}
			this.snackBar.open("Approver updates saved", null, {duration: 2000});
		});
	}
	/*
	addProduction()
	{
		this.addingProduction = true;
		this.updateChannelFormGroup(null, null);
	}
	*/
	editProduction(user:IUser)
	{
		this.cancelEditors();
		this.disabledEditing = true;
		// generate form data
		this.updateChannelFormGroup(user, 'production');
		this.editProductionUser = user;
	}
	editApproval(user:IUser)
	{
		this.cancelEditors();
		this.disabledEditing = true;
		// generate form data
		this.updateChannelFormGroup(user, 'approval');

		// set approval user for edit
		this.editApprovalUser = user;
	}

	cancelEditors()
	{
		if(this.appUserIsAdmin) {
			this.editProductionUser = null;
			this.editApprovalUser = null;
			this.approvalUser = null;
			this.approvalGroup = null;
			this.productionUser = null;
			this.moderatorUser = null;
			this.disabledEditing = false;
		}

	}
	cancelEditProduction()
	{
		//console.log(this.channelFormGroup);
		this.editProductionUser = null;
	}
	cancelEditApproval()
	{
		//console.log(this.channelFormGroup);
		this.editApprovalUser = null;
	}
	saveAmendManagerUser(user)
	{
		// TODO

		this.snackBar.open("Adding moderator...", "", { duration: 2000 });
		this.workflowService.setValidator(this.workflow.uuid, user).pipe().subscribe(result => { //,this.moderatorUser)
			this.snackBar.open("Moderator added", "", { duration: 2000 });
			this.moderatorUser = null;
			// load project team?
			//this.loadUsers();

			// request workflows
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'creatives','users']);

		});
	}
	canSaveEditProduction()
	{
		// are there any changes to the control		
		return this.isFormDifferentFromUser(this.editProductionUser, "production");
	}
	saveEditProduction()
	{
		this.snackBar.open("Saving production user...", null, {duration: 2000});
		this.saveEdit(this.editProductionUser, Permission.PRODUCTION);
		this.editProductionUser = null;
		this.snackBar.open("Production user saved", null, {duration: 2000});
	}
	canSaveEditApproval(group:ApprovalGroup)
	{
		// are there any changes to the control		
		return this.isFormDifferentFromUser(this.editApprovalUser, "approval");
	}
	saveEditApproval(group:ApprovalGroup)
	{
		// we need the group to enable the backend to apply the correct group order to the new permission row
		this.saveEdit(this.editApprovalUser, Permission.APPROVAL, group);
		this.editApprovalUser = null
	}
	canSaveNewApproval()
	{
		return this.formGroupToChannelIds().length > 0;
	}
	saveNewApproval()
	{
		//console.log("saveNewApproval");
		// send all permissions to backend including group id or name and order if new
		// backend will create the group if required and then the user perms will be sorted after
		let channel_ids:number[] = this.formGroupToChannelIds();
		// TODO replace this!!
		let channel_uuids:string[] = [];
		this.workflow.channels.forEach(channel => {
			if(channel_ids.includes(channel.id))
			{
				channel_uuids.push(channel.uuid);
			}
		});

		this.snackBar.open("Saving approver...", null, {duration: 4000});

		this.workflowService.grantUserChannels(this.workflow.uuid, this.approvalUser.uuid, Permission.APPROVAL, channel_uuids).pipe().subscribe(result => {//this.approvalGroup
			this.approvalUser = null;
			this.approvalGroup = null;
			this.snackBar.open("Approver saved", null, {duration: 2000});
			this.loadUsers();
			this.projectService.loadWorkflows(this.project_uuid);
			// also need to load approval groups
		});
	}
	cancelNewApproval()
	{
		this.approvalUser = null;
		this.approvalGroup = null;
	}
	saveEdit(user:IUser, permission:number, group:ApprovalGroup = null)
	{

		//console.log("saveEdit");
		// step 1 is diff the form values with the original values
		// step 2 is to grant any new access if required
		// step 3 is to run the remove proudction wizzard on any channels to be removed

		let channelsToGrant:IChannel[] = [];
		let channelsToRevoke:IChannel[] = [];

		let userChannels = this.getUserChannels(user);

		// loop through form and check values
		let formChannels = this.channelFormGroup.value.channels;
		for(let key in formChannels)
		{
			let value = formChannels[key];
			let channel = this.workflow.channels.find(channel => channel.id == parseInt(key));
			if(value === true) {
				// channel selected, was it originally
				if((userChannels[channel.id] & permission) != permission)
					channelsToGrant.push(channel);
			} else if (value === false) {
				// channel deselected, was it originally
				if((userChannels[channel.id] & permission) == permission)
					channelsToRevoke.push(channel);
			}
		}

		// if any grants apply those first then go on and do the revokes
		if(channelsToGrant.length)
		{
			this.workflowService.grantUserChannels(this.workflow.uuid, user.uuid, permission, Channel.getChannelIds(channelsToGrant)).pipe().subscribe(result => { //, group
				// remove channels
				if(channelsToRevoke.length){
					if(permission == Permission.PRODUCTION)	this.removeProductionFromChannels(user, channelsToRevoke);
					else if(permission == Permission.APPROVAL)	this.removeApprovalFromChannels(user, channelsToRevoke);
				}
				else {
					this.snackBar.open("User role updates saved", null, {duration: 2000});
					//this.loadUsers();
					//this.projectService.loadWorkflows(this.project_uuid);
					this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'creatives']);
					
				}
			})
		}else{
			// remove channels
			if(channelsToRevoke.length){
				if(permission == Permission.PRODUCTION)	this.removeProductionFromChannels(user, channelsToRevoke);
				else if(permission == Permission.APPROVAL)	this.removeApprovalFromChannels(user, channelsToRevoke);
			}
		}
	}
	/**
	 * Util function to get users channel permissions
	 */
	getUserChannels(user:IUser)
	{
		let channels = this.workflow.channels;
		let result = {};
		for (let i = 0; i < channels.length; i++) {
			let channel = this.workflow.channels[i];
			let u;
			if(u = channel['users'].find(u => u.uuid == user.uuid))
				result[channel.id] = u.scoped_permissions;
		}
		return result;
		//return user["channels"] || this.users.find(u => u.uuid == user.uuid)["channels"];
	}
	isChannelFormAllTrue()
	{
		return Object.values(this.channelFormGroup.value.channels).every(element => element === true);
	}
	toggleEditProductionAccessToAllChannels()
	{
		let allTrue = this.isChannelFormAllTrue();
		let channels = {};
		for(let key in this.channelFormGroup.value.channels)
		{
			channels[key] = allTrue ? false : true;
		}
		let value = {channels};

		//console.log("setting value", value);
		this.channelFormGroup.setValue(value);
		//console.log("value set");
	}
	formGroupToChannelIds()
	{
		let channel_ids:number[] = [];
		for(let channelId in this.channelFormGroup.value.channels)
		{
			if(this.channelFormGroup.value.channels[channelId]) // if true (selected)
			{
				// find channel in list by name (might need to use id :S)
				//channel_ids.push(this.channels.find(channel => channel.id == channelId).id);
				channel_ids.push(parseInt(channelId));
			}
		}
		return channel_ids;
	}
	canSaveProductionUser()
	{
		return this.formGroupToChannelIds().length > 0;
	}
	saveProductionUser()
	{
		//console.log("save a production user", this.channelFormGroup.value);

		let channel_ids:number[] = this.formGroupToChannelIds();
		// TODO replace this!!
		let channel_uuids:string[] = [];
		this.workflow.channels.forEach(channel => {
			if(channel_ids.includes(channel.id))
			{
				channel_uuids.push(channel.uuid);
			}
		});
		if(channel_ids.length)
		{
			/*
			// add single channel
			this.workflowService.grantUserChannel(this.workflow.id, this.productionUser.uuid, channel.id, Permission.PRODUCTION).pipe().subscribe(result => {
				console.log("results", result);
				this.availableUsers = result.data["availableUsers"];
				this.users = result.data["users"];
			})*/
			this.snackBar.open("Saving production role...", null, {duration: 4000});
			this.workflowService.grantUserChannels(this.workflow.uuid, this.productionUser.uuid, Permission.PRODUCTION, channel_uuids).pipe().subscribe(result => {
				this.snackBar.open("Production user role updates saved", null, {duration: 2000});
				this.cancelEditors();
				////this.loadUsers();			
				//this.projectService.loadWorkflows(this.project_uuid);
				this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels']);
			})
			//this.addingProduction = false; //not used
			this.productionUser = null;
		}
	}
	canBeApprover = (user):boolean =>
	{
		// if they are in any approval group already they cannot be in more than one
		for (let i = 0; i < this.workflow.approval_groups.length; i++) {
			const group = this.workflow.approval_groups[i];
			if(group.users.find(u => user.uuid == u.uuid)) return false;
		}
		return true;
	}
	/*
	addApprover()
	{
		let data = {};
		for(var i = 0; i < this.channels.length; i++)
		{
			data[this.channels[i].name] = false;
		}
		this.channelFormGroup = this.fb.group({
			channels:this.fb.group(data)
		});

		this.addingApprover = true;
		this.selectedGroup = null;

		this.groupControl = new FormControl('', Validators.required);
	}*/
	saveApprover()
	{
		throw new Error("Old function");
		
		//console.log("save an approval user", this.channelFormGroup.value);

		let channel = null;
		for(let channelId in this.channelFormGroup.value.channels)
		{
			//console.log("channelId", channelId, this.channelFormGroup.value.channels[channelId]);
			if(this.channelFormGroup.value.channels[channelId])
			{
				channel = this.workflow.channels.find(channel => channel.id == parseInt(channelId));
				break;
			}

		}
		if(channel)
		{
			//console.log(this.groupControl.value);
			let group_id = this.groupControl.value == "new" ? 0 : this.groupControl.value.id;
			this.workflowService.grantApproverChannel(this.workflow.id, this.approvalUser.uuid, channel.id, Permission.APPROVAL, group_id).pipe().subscribe(result => {
				//console.log("results", result);
				this.snackBar.open("Approver saved", null, {duration: 2000});
				this.availableUsers = result.data["availableUsers"];
				this.users = result.data["users"];
			})
			this.addingApprover = false;
			this.approvalUser = null;
		}
	}
	moveUserApprovalGroup(user:IUser, target:ApprovalGroup)
	{
		this.snackBar.open("updating user group", "", {duration:2000});
		this.workflowService.updateUserGroup(this.workflow.uuid, user.uuid, target.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("user group updated", "", {duration:2000});
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'users', 'creatives']);
		});
	}
	moveApprovalGroup(dir:string, group:ApprovalGroup, target:ApprovalGroup)
	{
		let order = target.order;
		order += 1;  // we need to add one so it works for 0 orders as -0 == 0
		let direction = dir == 'before' ? -1 : 1;
		order *= direction;
		let update = {order};
		this.snackBar.open("updating approval group order...", '', {duration:5000});
		this.approvalGroupService.update(group.uuid, update as ApprovalGroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("group order updated", "", {duration:2000});
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`, 'creatives']);
		});
	}
	editApprovalGroup(group:IApprovalGroup)
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Edit Approval Group",
				negative:"Cancel",
				positive:"Update",
				form:[
					{ name:"name", type:"text", placeholder:"Enter Name", value:group.name, validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				if(result.formGroup.value.name != group.name)
				{
					let update = {name:result.formGroup.value.name};
					this.snackBar.open("updating approval group name...", '', {duration:5000});
					this.approvalGroupService.update(group.uuid, update as ApprovalGroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						this.snackBar.open("group name updated", "", {duration:2000});
						//this.projectService.loadWorkflows(this.project_uuid);
						this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`, 'creatives']);
					});
				}
			}
		});		
	}
	deleteApprovalGroup(group:IApprovalGroup)
	{
		this.snackBar.open("Deleting approval group...", null, {duration: 2000});
		this.approvalGroupService.delete(group.uuid).pipe().subscribe( res => {
			this.snackBar.open("Approval group deleted", null, {duration: 2000});
			// reload the workflows to reflect the chains
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`,'channels', 'creatives']);
		});
	}

	setApprovalMode(mode:string, group:IApprovalGroup){
		//console.log("set approval mode:",mode);		
		
		this.snackBar.open("updating approval mode...", "", {duration:2000});
		//save the group mode
		let update = {mode};
		this.approvalGroupService.update(group.uuid, update as ApprovalGroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("approval mode updated", "", {duration:2000});
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`, 'creatives']);
		});		
	}

	toggleUseModerator(group:IApprovalGroup){
		this.snackBar.open("updating group moderation...", "", {duration:2000});
		let update = {use_moderator: !group.use_moderator};
		//let use_moderator = !group.use_moderator;
		this.approvalGroupService.update(group.uuid, update as ApprovalGroup).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("group moderation updated", "", {duration:2000});
			//this.projectService.loadWorkflows(this.project_uuid);
			this.projectService.loadWorkflows(this.project_uuid, [`workflows:[workflows.uuid:${this.workflow.uuid}]`, 'creatives']);
		});		
	}

	editWorkflowName(workflow:IWorkflow)
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Edit Workflow name",
				negative:"Cancel",
				positive:"Update",
				form:[
					{ name:"name", type:"text", placeholder:"Enter Name", value:workflow.name, validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				if(result.formGroup.value.name != workflow.name)
				{
					let update = {name:result.formGroup.value.name};
					this.snackBar.open("updating workflow name...", '', {duration:5000});
					this.workflowService.update(workflow.uuid, update as Workflow).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						this.snackBar.open("workflow name updated", "", {duration:2000});
						this.projectService.loadWorkflows(this.project_uuid);
					}, (error:HttpErrorResponse) => {
						if(error.status == 422)
						{
							this.snackBar.open("workflow edit failed: " + error.error.message, "", {duration:2000, panelClass:'snackBar-error'});
						}else {
							this.snackBar.open("workflow edit failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
						}							
					});
				}
			}
		});		
	}

	trackByID(index:number, item:any)
	{
		return item.id;
	}

	
}


export interface DialogChooseGroupData {
	workflow_id:number,
	approval_groups:IApprovalGroup[]
}
  
@Component({
	selector: 'dialog-choose-group',
	templateUrl: './dialog-choose-group.html',
  })
export class DialogChooseGroup {
	public group:ApprovalGroup;
	public new_group:ApprovalGroup;

	constructor(
		public dialogRef: MatDialogRef<DialogChooseGroup>,
		@Inject(MAT_DIALOG_DATA) public data: DialogChooseGroupData) {
		this.new_group = new ApprovalGroup();
		this.new_group.name = "";
		this.new_group.workflow_id = this.data.workflow_id;
	}
	save()
	{
		this.dialogRef.close(this.group);
	}

}
export interface DialogNewGroupData {
	workflow_id:number,
	approval_groups:IApprovalGroup[]
}
@Component({
	selector: 'dialog-new-group',
	templateUrl: './dialog-new-group.html',
	styleUrls: ['./dialog-new-group.scss']
  })
export class DialogNewGroup {
	public group:ApprovalGroup;
	constructor(
		public dialogRef: MatDialogRef<DialogNewGroup>,
		@Inject(MAT_DIALOG_DATA) public data: DialogNewGroupData,private snackBar: MatSnackBar,) {
		this.group = new ApprovalGroup();
		this.group.name = "";
		this.group.workflow_id = this.data.workflow_id;
	}
	save()
	{
		this.snackBar.open("Saving approval group...", null, {duration: 2000});
		this.dialogRef.close(this.group);
	}
	triggerSubmit(){
		if(!this.group || this.group.name.length < 1) return;
		else{
			this.save();
		}
	}

}