import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, timer } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import { CreativeService } from 'src/app/api/creative.service';
import { ProjectService } from 'src/app/api/project.service';
import { Project2Service } from 'src/app/api/project2.service';
import { TasksService } from 'src/app/api/task.service';
import { map } from 'rxjs/operators';
import { ApprovalChainService } from 'src/app/api/approvalChain.service';
import { MatDialog } from '@angular/material/dialog';
import { NewCreativeData, NewCreativeDialogComponent } from 'src/app/dialogs/new-creative-dialog/new-creative-dialog.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { UserService } from 'src/app/api/user.service';
import { IUser } from 'src/app/models/user.model';
import { CreativeState, ICreative } from 'src/app/models/creative.model';
import { GenericDialogComponent, GenericDialogData } from 'src/app/dialogs/generic-dialog/generic-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Format, IFormat } from 'src/app/models/format.model';
import { StringsService } from 'src/app/services/strings.service';
import { DeliveryPackage, IDeliveryPackage } from 'src/app/models/deliveryPackage.model';
import { DeliveryPackageService } from 'src/app/api/delivery_package.service';
import { DeliveryItemService } from 'src/app/api/delivery_item.service';
import { DeliveryItem } from 'src/app/models/deliveryItem.model';
import { ApprovalChain } from 'src/app/models/ApprovalChain.model';
import { Permission } from 'src/app/models/permissions.model';
import { AppUserService } from 'src/app/services/app-user.service';
import { IProject, Project, Project2 } from 'src/app/models/project.model';
import { PusherService } from 'src/app/services/pusher.service';
import { IWorkflow, Workflow } from 'src/app/models/workflow.model';
import { ProjectWorkflowsComponent } from '../../project-workflows/project-workflows.component';
import { WorkflowService } from 'src/app/api/workflow.service';
import { FormatService } from 'src/app/api/format.service';
import { IProjectChannel, ProjectChannel } from 'src/app/models/projectChannel.model';
import { GenericlistComponent } from 'src/app/components/genericlist/genericlist.component';
import { PackagesComponent } from 'src/app/components/packages/packages.component';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkgroupService } from 'src/app/api/workgroup.service';
import { wrapper } from 'src/app/utils/wrapper';
import { BaseComponent } from 'src/app/components/base-component/base-component.component';
import { IMessage } from 'src/app/models/message';
import { IChannel } from 'src/app/models/channel.model';
import { CreativeSelectiontService } from './creativeSelection.service';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';

enum ProjectPanel {
	Help = 0,
	Options = 1,
	Deliveries = 2,
	Share = 3,
	Close = 4
}

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends BaseComponent implements OnInit, CanComponentDeactivate  {

	private _unsubscribe = new Subject<boolean>();
	private errorMessage:any;

	public project:IProject;

	@ViewChild("tabs", {static:false}) public tabs: MatTabGroup;
	@ViewChild("deliveryPackagesList", {static:false}) public deliveryPackagesList: GenericlistComponent;
	
	@ViewChild("deliveryPackagesComponent", {static:false}) public deliveryPackagesComponent: PackagesComponent;
	@ViewChild("sharePackagesComponent", {static:false}) public sharePackagesComponent: PackagesComponent;
	@ViewChild("panelTabs", {static:false}) public panelTabs: MatTabGroup;

	public panelOpen:boolean = false;
	//public selectionMode:boolean = false;
	public packageMode:boolean = false;
	
	public project_uuid : string;

	public creative_id:string;

	public search:string = '';

	public user;
	public userPermissions;
	public userRole;

	public creatives:ICreative[];
	public currentCreative:ICreative;

	public workflows:IWorkflow[];
	public channels:ProjectChannel[];

	public loadingChannels:boolean = false;
	public loadingFormats:boolean = false;

	public loading: number = 0;

	public selectedUser:IUser;
	public selectedUserPermissions;
	public userActions:any[];

	public controlChannels:FormControl;

	public deliveryPackages:DeliveryPackage[];
	public sharePackages:DeliveryPackage[];
	public deliveryItems:DeliveryItem[];
	public selectedPackage:DeliveryPackage;
	public availableCreatives:any[];

	//public selectionMode:boolean = false;
	public selectedCreatives: ICreative[] = [];

	public productionsUsers: IUser[];	// comes from team component
	public approvalChains: ApprovalChain[];
	tasks: any[];
	public uniqueSelectedProductionUsers: IUser[] = [];
	public commonSelectedChannelUsers: IUser[] = [];
	public uniqueProductionOwners: IUser[] = [];
	public newWorkflowName: string;

	// all users available from workgroup
	public availableUsers:IUser[] = [];

	public deliveryToAction:number = 0;
	public shareToAction:number = 0;
	newPackageName: string;
	selectionLookup: any;
	//channelProductionUsers: IUser[];
	/* chains/groups/roles */
	/*
	public chains:ApprovalChain[] = [];
	public groups:ApprovalGroup[] = [];
	public selectedChain:ApprovalChain;
	public selectedGroup:ApprovalGroup;
	*/

	constructor(
				private router: Router,
				private location: Location,
				private route: ActivatedRoute,
				private project2Service: Project2Service,
				private api:ApiService,
				private tasksService:TasksService,
				private projectService: ProjectService,
				private workflowService: WorkflowService,
				private formatService: FormatService,
				private userService: UserService,
				private creativeService: CreativeService,
				private approvalChainService: ApprovalChainService,
				private deliveryPackageService: DeliveryPackageService,
				private deliveryItemService: DeliveryItemService,
				private dialog: MatDialog,
				private snackBar: MatSnackBar,
				public appUserService:AppUserService,
				public pusherService:PusherService,
				public strings: StringsService,
				public workgroupService: WorkgroupService,
				private selectionService:CreativeSelectiontService,
				
				)
	{
		super();
	}
	@HostListener("window:beforeunload", ["event"])
	unloadHandler(event: WindowEventHandlers): boolean {
	  return this.canDeactivate() as boolean;
	}
	canDeactivate(): Observable<boolean> | boolean
	{
		//console.log("canDEACTIVATE");
		// check stuff in here...
		//if(this.selectionMode){
		var msg = null;
			if(this.deliveryPackagesComponent && this.deliveryPackagesComponent.needsSave())
			{
				//set msg
				msg = "DELIVERY"; 
				
			} 
			if(this.sharePackagesComponent && this.sharePackagesComponent.needsSave())
			{
				msg = (msg ? msg + " and " : '') + "SHARE";
			}
			//return this.deactivateWarning(`You have unsaved PACKAGE changes!`, "Are you sure you want to leave without saving?", "saving package changes");
		if(msg) return this.deactivateWarning("You have unsaved "+msg+" PACKAGE changes!", "Are you sure you want to leave without saving?", "saving package changes");

		return true;
	}
	deactivateWarning(subtitle:string, body:string, btnMsgSuffix:string = "saving"):Observable<boolean>
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Leaving so soon?",
				subtitle,
				body,
				positive:"Continue without "+btnMsgSuffix+".",
				negative:"Stay",
			}
		  });
		  let subject:Subject<boolean> = new Subject();
		  let obs = subject.asObservable();
		  dialogRef.afterClosed().subscribe((result: any) => {
			subject.next(result ? true : false);
		});
		return obs;
	}
	private _selectionMode:boolean;
	public set selectionMode(isOn: boolean) {
		this._selectionMode = isOn;
		this.selectionService.selectionMode = this._selectionMode;
	}
	public get selectionMode() {
		return this._selectionMode;
	}

	// passcode, view link, copy link, copy password, generate password, leave blank for no passcode
	// delete package.., copy package (new name or slap copy on the end)
	ngOnInit(): void
	{
		this.sub = this.route.params.subscribe(params => {
			this.project_uuid = params["uuid"];
			this.loadProject();
		});

		this.sub = this.project2Service.packagesObservable
			.pipe(takeUntil(this._unsubscribe))
			.subscribe(packages => {
				if(!packages) return;
				if(this.selectedPackage || this.newPackageName)
				{
					// re find and select
					let found = false;
					packages.forEach(_package => {
						if(_package.uuid == this.selectedPackage?.uuid || _package.name == this.newPackageName )
						{
							this.newPackageName = null;
							this.deliveryPackageSelected(_package, true);
							found = true;
						}
					});
					if(!found)
					{
						this.deliveryPackageSelected(null);
					}
				}
				//console.log("packages updated!");

				
				//TODO
				//collect any unsaved packages (no, we dont have any unsaved, as auto saved on creation)

				//merge any _add, _remove lists from existing packages
				if(!this.deliveryPackages) this.deliveryPackages = [];
				if(!this.sharePackages) this.sharePackages = [];
				let localPackages = [...this.deliveryPackages, ...this.sharePackages];
				if(localPackages.length && packages.length){
					packages.forEach(loadedPackage => {
						localPackages.find(_package => {
							if(_package.uuid == loadedPackage.uuid){
								if(_package.delivery_items_add.length) loadedPackage.delivery_items_add = _package.delivery_items_add;
								if(_package.delivery_items_remove.length) loadedPackage.delivery_items_remove = _package.delivery_items_remove;
							}
						})
					})
				}
					
				this.deliveryPackages = packages.filter((bundle) => bundle.type == 'delivery');
				this.sharePackages = packages.filter((bundle) => bundle.type == 'share');

				this.checkPackages();
				this.savingPackage = false;
				//re-inject unsaved packages
			});
/*
			this.workgroupService.users.pipe().subscribe((users:IUser[]) =>{
				this.availableUsers = users;
				//console.log("available users", this.availableUsers);
			});
			*/
			//this.workgroupService.loadUsers(this.project.workgroup_uuid)

		/*
		this.projectService.approvalChains.subscribe(chains => {
			this.approvalChains = chains;
		});*/
		this.sub = this.projectService.workflows$.subscribe((workflows) => {
			//console.log("workflows$ subscription", workflows);
			if(!workflows) return;
			/*workflows.forEach(workflow => {
				this.filterWarnings(workflow);
			});*/
			this.workflows = workflows;
			this.workflows = this.workflows.concat([]);
			this.selectionService.workflows = this.workflows;

			//this.handleWorkflows(workflows);
		});
		this.sub = this.projectService.channels$.subscribe(channels => {
			this.channels = channels;
			this.selectionService.projectChannels = this.channels;
			if(this.dialog.openDialogs[0]?.componentInstance instanceof ProjectWorkflowsComponent)
			{
				this.dialog.openDialogs[0].componentInstance.updateProjectChannels(this.channels);
			}
			if(this.workflows) this.workflows = this.workflows.concat([]);
			this.selectionService.workflows = this.workflows;
		});

		this.selectionMode = false;
		this.sub = this.selectionService.selection$.subscribe(selection => {	
			
			if(this.packageMode)
			{
				// update package contents
				//console.log("update package", selection)
				if(this.selectedPackage)
				{
					
					//initialise add.remove lists
					//this.selectedPackage.delivery_items_add = [];
					//this.selectedPackage.delivery_items_remove = [];
				
					// update the contents to match the selection
					let creative_lookup = {};
					this.workflows.forEach(wf => wf.creatives.forEach(creative => {
						creative_lookup[creative.uuid] = creative;
						creative.package = 0;//reset package prop for all creatives
					}));


					let packageLookup = {};	// saved (already in package)
					this.selectedPackage.delivery_items.forEach(item => packageLookup[item.creative_uuid] = true);
					
					// new to add
					Object.keys(selection.creative).forEach(creative_uuid => {
						//selected creative is not in the package
						if(!packageLookup[creative_uuid])
						{

							let index = this.selectedPackage.delivery_items_add.indexOf(creative_uuid);
							
							if(index == -1 && selection.creative[creative_uuid])
							{
								//add to add list if creative is not already added
								//console.log("adding to add list",this.selectedPackage);
								this.selectedPackage.delivery_items_add.push(creative_uuid);
								//if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = 2;
							} 
						} else {
							//if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = 1;
						}	

					});

					// saved to remove
					Object.keys(packageLookup).forEach(creative_uuid => {
						if(!selection.creative[creative_uuid])
						{
							let index = this.selectedPackage.delivery_items_remove.indexOf(creative_uuid);
							if(index == -1)
							{
								//console.log("adding to remove list",this.selectedPackage);
								this.selectedPackage.delivery_items_remove.push(creative_uuid);
								//if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = -1;
							}
						} 
					});

					//new to add to remove
					for(var i = 0 ; i<this.selectedPackage.delivery_items_add.length; i++){
						let creative_uuid = this.selectedPackage.delivery_items_add[i];
						if(!selection.creative[creative_uuid]) {
							this.selectedPackage.delivery_items_add.splice(i--,1);
							//if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = 0;
						}
					}

					//saved to remove to add
					for(var i = 0 ; i<this.selectedPackage.delivery_items_remove.length; i++){
						let creative_uuid = this.selectedPackage.delivery_items_remove[i];
						if(selection.creative[creative_uuid]) {
							this.selectedPackage.delivery_items_remove.splice(i--,1);
							//if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = 1;
						}
					}

					//set package props
					/*
					[class.new]="packageMode && creative.package 		== 2" 	//ORANGE 	- not in package, selected
					[class.active]="packageMode && creative.package 	== 1" 	//GREEN 	- in package, selected
					[class.remove]="packageMode && creative.package 	== -1" 	//RED 		- in package, unselected
																		== 0 	//NONE 		- not in package, not selected

					//TODO - instead of setting .package prop on each creative model, create a package state lookuo object with state num for eacg creativee uuid and provide back to grid component via input for html to decide what colour class (.active, .new, ,remove) to use
					*/
					this.selectedPackage.delivery_items.forEach(item => {
						if(creative_lookup[item.creative_uuid]) creative_lookup[item.creative_uuid].package = 1;
					})
					this.selectedPackage.delivery_items_add.forEach(creative_uuid => {
						if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = 2;
					})
					this.selectedPackage.delivery_items_remove.forEach(creative_uuid => {
						if(creative_lookup[creative_uuid]) creative_lookup[creative_uuid].package = -1;
					})

				}
				
				//console.log("selection$");
				this.checkPackages();
				
			} else {
				//store the grid selection
				this.selectionLookup = selection;	
				this.gatherSelectedCreatives();
			}
		})
	}
	filterWarnings(workflow:Workflow)
	{
		//TODO - not needed?
		workflow.creatives?.forEach(creative => {

		});
	}
	checkPackages(){
		//console.log("checkPagaes()");
		//check all packages to see if any need saving (without need for angular to continuously loop thru)
		this.deliveryToAction = 0;
		this.deliveryPackages.forEach(_package => {
			if(_package.delivery_items_add.length || _package.delivery_items_remove.length || DeliveryPackage.isDirty(_package)) this.deliveryToAction ++;
		})
		this.shareToAction = 0;
		this.sharePackages.forEach(_package => {
			if(_package.delivery_items_add.length || _package.delivery_items_remove.length || DeliveryPackage.isDirty(_package)) this.shareToAction ++;
		})
	}

	clearSelectionMode(){
		//the order of these is important!... selectionService reset must come last
		this.packageMode = false;
		this.selectionMode = false;
		this.selectionService.resetSelectionLookup();
	}
	handleWorkflows(workflows:IWorkflow[])
	{
		throw new Error("unused function");
		// set params
		//tempSubject.next(false);
		workflows.forEach(workflow => {
			let channelCache = {};
			let formatCache = {};
			workflow.channels?.forEach(channel => channelCache[channel.uuid] = channel);
			workflow.formats?.forEach(format => formatCache[format.uuid] = format);
			workflow.creatives?.forEach(creative => {
				console.log("PRJ creative warnings", creative.warnings);
				creative.channel = channelCache[creative.channel_uuid];
				creative.format = formatCache[creative.format_uuid];
				// generate warnings
				if(creative.meta)
				{
					// look for "_totals" in key if found, check key pair per role
					let warnings;
					for (const key in creative.meta) {
						if (Object.prototype.hasOwnProperty.call(creative.meta, key)) {
							
							let index = key.indexOf("_totals");
							if(index != -1)
							{
								const total = creative.meta[key];
								let prefix = key.substring(0, index);
								const actioned = creative.meta[`${prefix}_actioned`];
								if(total == actioned)
								{
									if(!warnings) warnings = [];											
									warnings.push({type:prefix, value:creative.meta[key]});
								}
							}
						}
					}
					if(warnings) {
						// find users
						warnings.forEach(warning => {
							let user;
							let type = warning.type;
							let parts = type.split("_");
							switch (parts[0]) {
								case "approver":
									let uuid = parts[1];
									warning.type = parts[0];
									creative.groupInfo.groups.some(group => {
										let groupUser = group.users.find(user => user.uuid == uuid);
										if(groupUser)
										{
											user = groupUser;
										}
										return user;
									});
									break;
								case "moderator":
									// TODO
									break;
									case "production":
									// TODO										
									break;
								default:
									break;
							}
							if(user) warning.user = user;
						})
						creative.warnings = warnings;
					}
				}
			});
			channelCache = formatCache = null;
		});
		let filter = this.workflowFilter;
		//console.log("updating workflows", workflows, filter);
		
		this.workflows = workflows;
		this.workflows = this.workflows.concat([]);
		this.selectionService.workflows = this.workflows;

					/*
			if(!filter)
			{
				// no filter so overwrite
				this.workflows = workflows;
			} else {
				// filtered content so merge based on filters
				this.workflows.forEach(workflow => {
					let updated = workflows.find(wf => wf.uuid == workflow.uuid);
					if(filter.includes("channels"))
					{
						workflow.channels = updated.channels;
					}
					if(filter.includes("creatives"))
					{
						workflow.creatives = updated.creatives;
					}
					if(filter.includes("formats"))
					{
						workflow.formats = updated.formats;
					}
				});
				this.workflows = this.workflows.concat([]);
				//this.workflows = JSON.parse(JSON.stringify(this.workflows));
			}
			*/
	}
	ngAfterViewInit() : void
	{
		this.sub = this.route.fragment.subscribe(value => {
			let found = this.tabs._tabs.find(item => item.textLabel == value);
			if(found)	this.tabs.selectedIndex = this.tabs._tabs.toArray().indexOf(found);
		});

		/* //unused
		let path = this.location.path(true);
		if(path.indexOf('#') != -1)
		{
			let parts = path.split('#');
			console.log(parts[1]);
		}*/
	}
	public selectedLabel:string;
	public selectedLabelUnique:boolean;
	
	public selectedFlag:number;
	public selectedFlagUnique:boolean;
	
	public selectedProduction:IUser;	// uuid
	public selectedProductionUnique:IUser[];
	
	public selectedLabels:any;
	public selectedPaused: any;
	public selectedFlags:any;
	public selectedProductions:any;
	public selectedChannels:any;


	gatherSelectedCreatives()
	{
		// clear
		this.selectedCreatives.length = 0;
		this.selectedLabel = '';
		this.selectedLabels = [];
		this.selectedPaused = 0;
		this.selectedLabelUnique = true;
		this.selectedFlag = -1;
		this.selectedFlagUnique = true;
		this.selectedProduction = null;
		this.selectedProductionUnique = [];

		this.selectedChannels = {};

		this.uniqueSelectedProductionUsers = [];
		this.commonSelectedChannelUsers = [];
		let allChannelProductionUsers = [];

		this.uniqueProductionOwners = [];

		// find selected creatives
		this.workflows?.forEach(workflow => {
			for (let i = 0; i < workflow.creatives?.length; i++) {
				const creative = workflow.creatives[i];
				if(this.selectionLookup?.creative[creative.uuid])//if(creative.selected)
				{
					// label (colour tag)
					if(this.selectedLabelUnique)
					{
						if(this.selectedLabel == '')
						{
							this.selectedLabel = creative.label;
						}else if(this.selectedLabel != creative.label)
						{
							this.selectedLabel = null;
							this.selectedLabelUnique = false;
						}
					}
					// flag (a.k.a 'paused')
					if(this.selectedFlagUnique)
					{
						if(this.selectedFlag == -1)
						{
							this.selectedFlag = creative.flag;
						}else if(this.selectedFlag != creative.flag)
						{
							this.selectedFlag = null;
							this.selectedFlagUnique = false;
						}
					}

					//console.log("creative prod task owner:",creative['production_task'].user_uuid);
					
					this.selectedCreatives.push(creative);
					if(this.selectedChannels[creative.channel_uuid] != undefined)
					{
						this.selectedChannels[creative.channel_uuid]++;	
					}
					else
					{
						this.selectedChannels[creative.channel_uuid]=1;

						// production users per selected channel
						let currentChannelProductionUsers = Workflow.getChannelProductionUsers(creative.channel_uuid, workflow,workflow['productionChannels'])
						allChannelProductionUsers.push(currentChannelProductionUsers);
						
						for (let c = 0; c< currentChannelProductionUsers.length; c++) {
							const user = currentChannelProductionUsers[c];
							if(this.uniqueSelectedProductionUsers.find(u => u.uuid == user.uuid) == undefined) 
							{
								this.uniqueSelectedProductionUsers.push(user);//[user.uuid] = user;
							}
						}
					}
					//selected production owner(s)
					for (let d = 0; d< this.uniqueSelectedProductionUsers.length; d++) {
						const user = this.uniqueSelectedProductionUsers[d];
						if(creative['production_task'] && creative['production_task'].user_uuid != "app")
						{
							if(creative['production_task'].user_uuid == user.uuid){
								if(this.uniqueProductionOwners.find(prodUser => prodUser.uuid == user.uuid) == undefined)
								{
									this.uniqueProductionOwners.push(user);
								}
							}
						}
					}
				}
			}
		});
		
		
		//determine common production users (across all selected channels)
		
		for (let a = 0; a< this.uniqueSelectedProductionUsers.length; a++) {
			const uuser = this.uniqueSelectedProductionUsers[a];
			let userFound = true;
			//check if each unique user is in ALL selected channel lists
			for (let b = 0; b< allChannelProductionUsers.length; b++) {
				const channelUsers = allChannelProductionUsers[b];
				if(channelUsers.find(chUser => chUser.uuid == uuser.uuid) == undefined){
					userFound = false;
					break;
				}
			}
			if(userFound) this.commonSelectedChannelUsers.push(uuser);
		}
		//console.log("common production users:",this.commonSelectedChannelUsers);
		//console.log("unique production owners:",this.uniqueProductionOwners);

		// analyse selected creatives
		this.selectedLabels = {};
		this.selectedFlags = {};
		this.selectedProductions = {};

		for (let i = 0; i < this.selectedCreatives.length; i++) {
			const selectedCreative = this.selectedCreatives[i];
			
			if(!selectedCreative.label)
			{
				selectedCreative.label = 'null';
			}
				if(this.selectedLabels[selectedCreative.label] != undefined)
					this.selectedLabels[selectedCreative.label]++;
				else
					this.selectedLabels[selectedCreative.label]=1;
			
			if(selectedCreative.flag) //using 'flag in place of 'paused'
			{
				this.selectedPaused ++;
				if(this.selectedFlags[selectedCreative.flag] != undefined)
					this.selectedFlags[selectedCreative.flag]++;
				else
					this.selectedFlags[selectedCreative.flag]=1;
			}
			if(selectedCreative["production_task"].user_uuid)
			{
				if(this.selectedProductions[selectedCreative["production_task"].user_uuid] != undefined)
					this.selectedProductions[selectedCreative["production_task"].user_uuid]++;
				else
					this.selectedProductions[selectedCreative["production_task"].user_uuid]=1;
			}
		}
	
		if(this.selectedLabel == '') this.selectedLabel = null;
		//console.log("selectedCreatives:",this.selectedCreatives);
		return this.selectedCreatives.length;
	}
	availableProductionUsers = (user:IUser) =>
	{
		if(!this.selectedChannels) return false;
		let userChannels = user["channels"];
		// loop through all the selected channels and ensure user has production permission on all
		let hasProduction = true;

		for (const channel_id in this.selectedChannels) {
			let channelFound = false;
			for (let i = 0; i < userChannels.length; i++) {
				const channel = userChannels[i];
				if(channel.id == channel_id)
				{
					channelFound = true;
					if(channel.permissions & Permission.PRODUCTION)
					{

					}else{
						hasProduction = false;
						break;
					}
				}
			}
		}
		return hasProduction;
	}
	colourSelected(colour:string)
	{
		//if(colour=='') colour = null;
		// gather uuids and send them off to update their labels
		let data = [];
		for (let i = 0; i < this.selectedCreatives.length; i++) {
			const creative = this.selectedCreatives[i];
			data.push({uuid:creative.uuid, label:colour});
		}
		this.snackBar.open("updating creative labels...", '', {duration:5000});
		this.projectService.updateCreativeLabels(this.project_uuid, data).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			for (let i = 0; i < this.selectedCreatives.length; i++) {
				const creative = this.selectedCreatives[i];
				creative.label = colour;
			}
			this.snackBar.open("creative labels updated", '', {duration:2000});
			this.gatherSelectedCreatives();
		});
	}
	flagSelected(flag:number)
	{
		// gather uuids and send them off to update their labels
		let data = [];
		for (let i = 0; i < this.selectedCreatives.length; i++) {
			const creative = this.selectedCreatives[i];
			data.push({uuid:creative.uuid, flag});
		}
		this.snackBar.open("updating creative flags...", '', {duration:5000});
		this.projectService.updateCreativeFlags(this.project_uuid, data).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			for (let i = 0; i < this.selectedCreatives.length; i++) {
				const creative = this.selectedCreatives[i];
				creative.flag = flag;
			}
			this.snackBar.open("creative flags updated", '', {duration:2000});
		});
	}
	productionUserSelected(user:IUser)
	{
		let data = [];
		for (let i = 0; i < this.selectedCreatives.length; i++) {
			const creative = this.selectedCreatives[i];
			data.push({uuid:creative.uuid, user_uuid:user ? user.uuid : null});
		}
		this.snackBar.open("updating creative production users...", '', {duration:5000});
		this.projectService.updateCreativeProductionUser(this.project_uuid, data).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			for (let i = 0; i < this.selectedCreatives.length; i++) {
				const creative = this.selectedCreatives[i];
				creative["production_task"].user_uuid = user ? user.uuid : null; // TODO app?
			}
			this.snackBar.open("creative production users updated", '', {duration:2000});
			this.gatherSelectedCreatives();
		});
	}
	debug(){

	}
	amendSelection(selection:ICreative[] = []){
		console.log("amendSelection()",selection);
	}
	deleteSelection(selection:ICreative[] = [])
	{
		// gather uuids and send them off to update their labels
		var selectedUUIDs = [];
		selection = (selection.length) ? selection :  this.selectedCreatives;
		for (let i = 0; i < selection.length; i++) {
			const creative = selection[i];
			selectedUUIDs.push({uuid:creative.uuid});
		}
		let bodyText =  "<p style='color:#940d00;'><i><b>Warning this cannot be undone!</b><br/>All data, including files and amends for the selected craetive slots will be deleted.</i></p><br/><b>Are you sure you wish to delete "+selectedUUIDs.length+" creative slots?</b><br/><br/>";
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {title:"Confirm delete", body:bodyText, positive:"Delete", negative:"Cancel",
				form:[
					{name:"confirm", placeholder:"Enter 'DELETE' (in CAPS) to confirm", type:"text", validator: [Validators.required, Validators.pattern(/DELETE/)] }
				],}
		});
		dialogRef.afterClosed().subscribe((result: any) => {
			if(result)
			{
				this.snackBar.open("deleting selected creatives...", '', {duration:5000});
				this.projectService.deleteCreatives(this.project_uuid, selectedUUIDs).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					if(res.data.length){
						this.removeCreatives(res.data.map(creative => creative.uuid));
						this.snackBar.open("selected creatives deleted", '', {duration:2000});
					}
					this.gatherSelectedCreatives();
				}, (error) => {
					this.snackBar.open("failed to delete", "", {duration:5000, panelClass:'snackBar-error'});
				});
			}else{
				  // popup closed with no data
			}
		});
	}

	onTabChange(e:MatTabChangeEvent) : void
	{
		//this.route.fragment = e.tab.textLabel;
		//this.router.navigate([this.router.url], {fragment:e.tab.textLabel});
		this.location.go(this.location.path(false) + "#" +  e.tab.textLabel);
	}

	public tabIndex:number = 0;
	
	private listening:boolean = false;

	private _creativeUsers:any = {};

	listen()
	{
		if(this.listening) return;
		this.listening = true;
		this.pusherService.init(this.api.token);
		this.pusherService.joinWorkgroupChat(this.project.workgroup_uuid);
		this.pusherService.workgroupChat.messages.pipe(takeUntil(this._unsubscribe)).subscribe(
			(message:IMessage) => {
				if(message.metadata.action == "user")
				{
					// need to tell the workflows thingy to reload users
					// TODO: this whole listen could be in the workflow pannel
					if(this.dialog.openDialogs[0]?.componentInstance instanceof ProjectWorkflowsComponent)
					{
						this.dialog.openDialogs[0].componentInstance.loadUsers();
					}
				}
			}
		);
		this.pusherService.joinProjectChat(this.project_uuid, null, null, (leaver) => {
			// someone left, we can bin them off the creative users subject
		});
		this.pusherService.projectChat.whispers.pipe().subscribe(whisper => {
			if(whisper.event == "creative")
			{
				let creative_uuid = whisper.whisper.message;
				let user = whisper.whisper.user;
				let leaving = whisper.whisper.leaving ? true : false;

				if(leaving)
				{
					// remove the user if you can find them
					let users = this._creativeUsers[creative_uuid];
					if(users){
						let index = users.findIndex((u) => u.uuid == user.uuid && u.session == user.session );						
						if(index != -1)	users.splice(index, 1);
					}
				}else {
					user.lastContact = Date.now();
					if(!this._creativeUsers[creative_uuid])
					{
						this._creativeUsers[creative_uuid] = [user];
					}else{
						let users = this._creativeUsers[creative_uuid];
						let existingUser = users.find((u) => u.uuid == user.uuid && u.session == user.session);
						if(!existingUser)
						{
							// only push if not in already!
							users.push(user);
						} else {
							// update last contact
							existingUser.lastContact = Date.now();
						}
						//this._creativeUsers[creative].push(user);
					}
				}
				this.projectService.setCreativeUsers(this._creativeUsers);
			}		
		})
		this.pusherService.users.pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				//this.activeUsers = response;
		});
		// debounce added with some slight random to prevent the server being smashed by everyone at the same time after very minor change
		// could better filter the things that trigger these events
		//debounceTime(500 + ((Math.random() * 500) | 0)), 
		this.pusherService.projectChat.messages.pipe(takeUntil(this._unsubscribe)).subscribe(
			(message:IMessage) => {
				// ignore any messages I generate
				//console.log("Project message:", message, message.user, this.appUserService?.appUser.uuid);
				if(message.user == this.appUserService?.appUser.uuid) return;
				//console.log("message", message);
				
				this.snackBar.open(message.metadata.message, null, {duration:1500});
				/*
				//types:
					creative
						delete
						create
						else (channel or workflows)?
					task

					format
					project_channel
					channel
					user
					permission
					workflow
					package
					group
					approval_group


				*/
				if(message.type == "creative")
				{

					if(message.metadata?.action == 'delete')
					{
						let workflow = this.workflows.find(workflow => workflow.uuid == message.metadata.workflow_id);
						if(workflow)
						{
							let creativeIndex = workflow.creatives.findIndex(creative => creative.uuid == message.metadata.id);
							if(creativeIndex != -1)
							{
								workflow.creatives.splice(creativeIndex, 1);
								if(this.isAdmin()) this.loadPackages();
								this.workflows = this.workflows.concat([]);
								this.selectionService.workflows = this.workflows;;
							}
						}
						return;
					}
					if(message.metadata?.action == 'create')
					{
						// check if we have the required workflow 
						const workflow_uuid = message.metadata.workflow_id;
						const workflow = this.workflows.find(workflow => workflow.uuid == workflow_uuid);
						if(this.isAdmin() || workflow){
							if(message.metadata.id) {//single creative
								if(!workflow.channels.find(channel => channel.uuid == message.metadata.channel_id)) return;
								if(!workflow.formats.find(format => format.uuid == message.metadata.format_id)) return;
								this.loadWorkflows(`workflows:[workflows.uuid:${workflow_uuid}]`, `creatives:[creative2.uuid:${message.metadata.id}]`);
							}
							else if(message.metadata.channel_id){//fill channel
								if(!workflow.channels.find(channel => channel.uuid == message.metadata.channel_id)) return
								this.loadWorkflows(`workflows:[workflows.uuid:${workflow_uuid}]`, `creatives:[channel_uuid:${message.metadata.channel_id}]`);
							}

							else if(message.metadata.format_id){//fill format
								if(!workflow.formats.find(format => format.uuid == message.metadata.format_id)) return;
								this.loadWorkflows(`workflows:[workflows.uuid:${workflow_uuid}]`, `creatives:[format_uuid:${message.metadata.format_id}]`);
							}
						}
						
						/*if(!workflow) return;

						// check multiple
						if(message.data?.length)
						{
							if(message.metadata.channel_id && !workflow.channels.find(channel => channel.uuid == message.metadata.channel_id)) return;
							if(message.metadata.format_id && !workflow.formats.find(format => format.uuid == message.metadata.format_id)) return;
							this.loadCreative(message.data);
						}else{
							// check if we have the required format and channel - if not load the lotelse{
							const channel = workflow.channels.find(channel => channel.uuid == message.metadata.channel_id);
							const format = workflow.formats.find(format => format.uuid == message.metadata.format_id);
							if(!(channel && format)){
								this.loadWorkflows();	// TODO could just load the singular one
								return;
							}
							this.loadCreative([message.metadata.id.toString()]);
						}
						*/
						return;
					}
					if(message.channel)
					{
						for (let i = 0; i < this.workflows.length; i++) {
							const workflow = this.workflows[i];
							if(workflow.channels.find(channel => channel.id == message.channel))
							{
								//this.loadCreatives();
								this.loadWorkflows();
								break;
							}
						}						
					}else{
						//this.loadCreatives();
						this.loadWorkflows();
					}
				}else if(message.type == "task")
				{
					// Task action has happened - which ones need responding too
					// Only some task actions can trigger a state change - which is the only effect, so for these we can reload a creative
					// These are the main task actions
					/*
						ACTION_APPROVER_APPROVE 			= "approve";
						ACTION_APPROVER_REQUESTS_SUBMIT 	= "approver_requests_submit";
						ACTION_PRODUCTION_BUILD_STARTED 	= "build_started";
						ACTION_PRODUCTION_BUILD_COMPLETED 	= "build_completed"; 
						ACTION_PRODUCTION_AMENDS_SUBMIT 	= "production_amends_submit";
						ACTION_MODERATOR_SEND_PRODUCTION 	= "moderator_send_to_production";
						ACTION_MODERATOR_SEND_APPROVAL		= "moderator_send_to_approval";
						ACTION_MODERATOR_SEND_ISSUES		= "moderator_send_issues_to_approval";
						ACTION_PRODUCTION_RECALL			= "production_recall";
					*/
					// this.loadCreatives();
					if(message.metadata)
					{
						/*let creative_uuid = message.metadata.creative_id;
						let targetCreative;
						// see if we can find that task and then load its creative - might be better to just send the creative's uuid in
						this.workflows.forEach(workflow => workflow.creatives?.some(creative => {
							if(creative.uuid == creative_uuid)
							{
								targetCreative = creative;
								return true;
							}
						}));
						
						if(targetCreative)
						{
							this.loadCreative(targetCreative.uuid);
							return;
						}
						*/
							const workflow = this.workflows.find(workflow => workflow.uuid == message.metadata.workflow_id);
							if(workflow)
								this.loadWorkflows(`workflows:[workflows.uuid:${workflow.uuid}]`, `creatives:[creative2.uuid:${message.metadata.creative_id}]`);
					} else {
						this.loadWorkflows();
					}
				
				}else if(message.type == "format")
				{
					if(message.metadata.action == 'create')
					{
						// Format created. Admins should reload or other users only if they have the workflow
						const format_uuid = message.metadata.id;
						const workflow_uuid = message.metadata.workflow_id;
						if(this.isAdmin() || this.workflows.find(workflow => workflow.uuid == workflow_uuid))
							this.loadWorkflows(`workflows:[workflows.uuid:${workflow_uuid}]`, `formats:[creative_formats.uuid:${format_uuid}]`);

					} else if(message.metadata.action == 'create+')
					{
						const format_uuid = message.metadata.id;
						const workflow_uuid = message.metadata.workflow_id;
						if(this.isAdmin() || this.workflows.find(workflow => workflow.uuid == workflow_uuid))
							this.loadWorkflows(`workflows:[workflows.uuid:${workflow_uuid}]`, `formats:[creative_formats.uuid:${format_uuid}]`, `creatives:[format_uuid:${format_uuid}]`);

					} else if(message.metadata.action == 'update'){
						const updatedFormat = message.data?.[0];
						if(updatedFormat)
						{
							this.updateFormat(updatedFormat);
							/*
							const workflow = this.workflows.find(workflow => workflow.uuid == updatedFormat.workflow_uuid);
							if(workflow)
							{
								
								const format = workflow.formats.find(format => format.uuid == updatedFormat.uuid);
								if(format){
									format.name = updatedFormat.name;
									format.group = updatedFormat.group;
								}
								this.workflows = this.workflows.concat([]);
								
							}*/
						}else if(message.metadata.group)
						{
							const workflow_uuid = message.metadata.workflow_id;
							const format_group = message.metadata.group;
							this.loadWorkflows(`formats:[workflow_uuid:${workflow_uuid},group:${format_group}]`);
						}
					}else if (message.metadata.action == 'delete'){
						this.removeFormat(message.metadata.id.toString(), message.metadata.workflow_id);
					}else if (message.metadata.action == 'delete_group'){
						this.removeFormats(message.metadata.group, message.metadata.workflow_id);
					}else{
						// i.e. delete or update
						this.loadWorkflows();
					}
				} else if(message.type == "project_channel")
				{
					if(message.metadata.action == 'update')
					{
						// if I am an admin reload the channels
						if(this.isAdmin())	this.loadChannels();
					}else if(message.metadata.action == 'delete'){
						this.projectService.removeChannel(message.metadata.id.toString());
					}
				} else if(message.type == "permission") {
					if(message.metadata.action == 'update')
					{
						if(message.data) {
							if(message.metadata?.user_id == this.appUserService?.appUser.uuid){
								this.loadChannels();
								//TODO - check if already have this workflow and onky add necessary filters otherwise need all the fruit
								this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.id}]`, 'creatives', 'formats', 'channels', 'users');	// can we do a where in?  `channels:[creative_channels.uuid:${message.metadata.id}]`
							} else {
								this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.id}]`, 'creatives', 'channels');
							}

						}
					}
				} else if(message.type == "channel")
				{
					console.log("channel message", message);
					if(message.metadata.action == 'update')
					{
						this.updateProjectChannel(message.data[0]);
						/*
						// name update can affect existing users (could check that the id of the channel updated is in my list)
						const channel = this.channels.find(channel => channel.id == message.metadata.id)
						if(channel)
						{
							const updatedChannel = message.data[0];
							if(updatedChannel.order == channel.order)
							{
								channel.name = updatedChannel.name;
							} else{
								this.loadChannels();
							}
						}
						*/
					}else if (message.metadata.action == 'create'){
						// new channel created via "activate"
						if(this.isAdmin()) {
							this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`, `channels:[creative_channels.uuid:${message.metadata.id}]`);
						}
					}else if (message.metadata.action == 'create+'){
						// new channel created via "activate"
						if(this.isAdmin()){
							this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`, `creatives:[channel_uuid:${message.metadata.id}]`, `channels:[creative_channels.uuid:${message.metadata.id}]`);
						}
					}else if (message.metadata.action == 'delete'){
						const workflow = this.workflows.find(workflow => workflow.uuid == message.metadata.workflow_id);
						if(workflow)
						{
							const channelIndex = workflow.channels.findIndex(channel => channel.uuid == message.metadata.id);
							if(channelIndex != -1) workflow.channels.splice(channelIndex, 1);
							this.workflows = this.workflows.concat([]);
							this.selectionService.workflows = this.workflows;
						}
					}else{
						// if I am an admin reload the channels
						if(this.isAdmin())	this.loadChannels();
					}
				}else if(message.type == "workflow")
				{
					//this.loadWorkflows();
					//this.loadChannels();
					if(message.metadata.action == "delete"){
						this.projectService.removeWorkflow(message.metadata.id.toString());
					}else if(message.metadata.action == "remove_production" || message.metadata.action == "remove_approver"){
						if(message.metadata.user_id == this.appUserService.appUser.uuid) {
							this.loadChannels();
							//this.loadWorkflows();
							this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.id}]`, 'channels', 'users', 'formats', 'creatives');
						} else {
							this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.id}]`, 'channels', 'creatives');
						}
					}else if(message.metadata.action == "update"){
						this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.id}]`, 'channels', 'users', 'formats');
					}else{
						//if(message.metadata.user == this.appUserService.appUser.uuid) this.loadChannels();
						//if(this.isAdmin()) this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.id}]`, 'channels', 'users', 'formats', 'creatives');
						this.loadChannels();
						this.loadWorkflows();
					}
				}else if(message.type == "user")
				{
					if(message.metadata.id == this.appUserService?.appUser.uuid)
					{
						// was I the target user? 
						if(message.metadata.action == "grant" || message.metadata.action == "revoke" || message.metadata.action == "removed"){
							this.loadProject();
							return;
						}

						
						
					}
					if(message.metadata.action == "moderator")
					{
						this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`, 'users', 'creatives');
						return;
					} else if(message.metadata.action == "update_approval_group")
					{
						this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`, 'channels', 'creatives');
						return;
					}
					else {
						this.loadWorkflows();
					}						


					if(this.isAdmin()){
						this.projectService.loadTeam(this.project_uuid);
					}
					/*else if(message.metadata.id == this.appUserService?.appUser.uuid){
						this.loadWorkflows();
						
						//this.loadChannels();
						//this.loadCreatives();
					} else {
						// need to load team to update messaging around missing moderators
						this.loadWorkflows('creatives','channels', 'users');
					}
					*/
				} else if(message.type == 'package'){
					if (message.metadata.action == 'delete'){
						const _package = this.deliveryPackages.find(_package => _package.uuid == message.metadata.id);
						if(_package){
							const packageIndex = this.deliveryPackages.findIndex(_packaggge => _packaggge.uuid == _package.uuid);
							if(packageIndex != -1){
								this.deliveryPackages.splice(packageIndex, 1);
								this.deliveryPackages = this.deliveryPackages.concat([]);
								this.checkPackages();
							}
						}
					}else{
						if(this.isAdmin()) this.loadPackages();
					}
				} else if(message.type == 'group'){
					//this.loadWorkflows();
					this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`,'channels', 'creatives');
				} 
				else if(message.type == 'approval_group'){
					if(message.metadata.action == "create"){
						this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`, 'creatives', 'channels');
					} else if(message.metadata.action == "update"){
						this.loadWorkflows(`workflows:[workflows.uuid:${message.metadata.workflow_id}]`, 'creatives');
					}
				} 
		});
		/*
		this.pusherService.creativeChat.messages.pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				console.log(response);
				let message:any = response["message"];
				if(message.type == "task")
				{
					// a task related change has occured (may or may not affect me)
					//this.taskEvents.push(message.message);
					//this.getTasks();
					//clearTimeout(this.taskEventTimer);
					//this.taskEventTimer = setTimeout(() => this.deleteOldTaskEvents(), 3000);
				}
				//this._snackBar.open(message.message, null, {duration: 2000});
		});*/

		// check for creative users every 10 seconds
		// if we have not heard from them for 20 seconds we bin them off
		timer(0, 10000).pipe( 
			map(() => { 
			  this.checkCreativeUsers(); // load data contains the http request 
			}),
			takeUntil(this._unsubscribe)
		  ).subscribe();
	}
	checkCreativeUsers()
	{
		// remove those that havent been heard from and request a checkin
		const now = Date.now();
		const timeout = 20;
		for (const creative_uuid in this._creativeUsers) {
			if (Object.prototype.hasOwnProperty.call(this._creativeUsers, creative_uuid)) {
				let users = this._creativeUsers[creative_uuid] as Array<any>;
				if(users.length == 0) delete this._creativeUsers[creative_uuid];
				for (let i = 0; i < users.length; i++) {
					const user = users[i];
					const delta = (now - user.lastContact) * 0.001
					if(delta > timeout)
					{
						users.splice(i--, 1);
					}
				}
			}
		}
		this.projectService.setCreativeUsers(this._creativeUsers);
		//this.pusherService.projectChat.whisper('ping');
	}
	loadProject()
	{
		this.project2Service.findOne(this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.project = res.data[0];
			this.selectionService.project = this.project;
			this.reload();

			this.listen();
		});
	}
	/* USERS */
	userAdmins:IUser[];
	loadUsers() {
		this.projectService
			.getTeam(this.project_uuid)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe((res) => {
				
				// TODO
				return;
				let currentUsers = res.data["users"];
				let availableUsers = res.data["available"];

				let map = {};
				for (let i = 0; i < currentUsers.length; i++) {
					const currentUser = currentUsers[i];
					map[currentUser.uuid] = currentUser;					
				}	

				for (let i = 0; i < availableUsers.length; i++) {
					const user = availableUsers[i];
					const currentUser = map[user.uuid];
					if(currentUser)
					{
						availableUsers[i] = currentUser;
					}else{
						user.scoped_permissions = 0;
					}
				}				
				// who has access
				let projectUsers = availableUsers.filter((user) =>
					user.scoped_permissions & Permission.ACCESS
				);
				// who is admin
				this.userAdmins = projectUsers.filter((user) =>
					user.scoped_permissions & Permission.ADMIN
				);
				/*
				let projectApprovalUsers = projectUsers.filter((user) =>
					user.scoped_permissions & Permission.APPROVAL
				);
				let projectProductionUsers = projectUsers.filter((user) =>
					user.scoped_permissions & Permission.PRODUCTION
				);
				*/
			


				//this.determineValidator();
			});
	}
	isAdmin()
	{
		if(!this.project) return false;
		return (this.project["scoped_permissions"] & Permission.ADMIN) != 0;
	}
	isSuper()
	{
		return (this.appUserService.appUser.super); 
	}
	reload() : void
	{
		this.loadUsers();
		this.loadChannels();
		this.loadWorkflows();
		//this.loadFormats();
		//this.loadCreatives();
		//this.loadProjectTasks();//re-instate for 'My Tasks' tab

		// packages - could load on tab change
		this.loadPackages();
		//this.loadSharePackages();
	}
	onProductionUsers(productionUsers:IUser[])
	{
		this.productionsUsers = productionUsers;	
	}
	edit(info:any)
	{
		switch (info.type) {
			case "group":
				this.editGroup(info.data);
				break;
			case "workflow":
				this.editWorkflow(info.data);
				break;
			case "format":
				this.editFormat(info.data);
				break;
			case "project_channel":
				this.editProjectChannel(info.data);
				break;
			case "overview":
				this.editProjectOverview(info.data);
				break;	
			default:
				break;
		}
	}
	delete(info:any)
	{
		switch (info.type) {
			case "project_channel":
				this.deleteProjectChannel(info.data);
				break;
			case "channel":
				//console.log("TODO needs implement");
				this.deleteWorkflowChannel(info.workflow, info.workflowChannel);			
				break;
			case "group":
				this.deleteGroup(info.data);
				break;
			case "format":
				this.deleteFormat(info.data);
				break;
			case "workflow":
				this.deleteWorkflow(info.data);
			/*case "newWorkflowName":
				this.newWorkflowName = null;
				*/
			default:
				break;
		}
	}
	deleteWorkflow(id:String)
	{
		let workflow = this.workflows.find(workflow => workflow.uuid == id);
		if(!workflow)
		{
			this.snackBar.open("workflow not found", "", {duration:2000, panelClass:'snackBar-error'});
			return;
		}
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {title:"Delete Workflow", subtitle:workflow.name, body:"Warning this cannot be undone! Are you sure you wish to delete this workflow?", positive:"Delete", negative:"Cancel"}
		  });
		  dialogRef.afterClosed().subscribe((result: any) => {
			if(result)
			{
				this.snackBar.open("deleting workflow...");
				this.workflowService.delete(workflow.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("workflow deleted", "", {duration:2000});
					this.projectService.removeWorkflow(res.data[0].uuid);
				}, (error) => {
					this.snackBar.open("workflow failed to delete", "", {duration:5000, panelClass:'snackBar-error'});
				});
			}else{
				  // popup closed with no data
			}
		  });
	}
	editWorkflow(id:Number)
	{
		let workflow = this.workflows.find(workflow => workflow.id == id);
		//console.log("edit workflow", workflow);
		const dialogRef = this.dialog.open(ProjectWorkflowsComponent, {
			disableClose:true, 
			data: {
				workgroup_uuid:this.project.workgroup_uuid,
				project_uuid:this.project_uuid,
				projectChannels:this.channels,
				workflow,
				workflows:this.workflows,
				//channels:this.channels,
				userAdmins:this.userAdmins,
			}
		});
		const subscribeDialog = dialogRef.componentInstance.add.subscribe((data) => {
			this.add(data);
		  })
		subscribeDialog.add(dialogRef.componentInstance.edit.subscribe((data) => {
			this.edit(data);
		  }))
		  subscribeDialog.add(dialogRef.componentInstance.delete.subscribe((data) => {
			this.delete(data);
			//i can see 'hello' from MatDialog
		  }))
		dialogRef.afterClosed().subscribe((result: any) => {
			if(result)
			{

			}
			subscribeDialog.unsubscribe();
		});
	}
	editGroup(format:IFormat)
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Edit Format Category",
				negative:"Cancel",
				positive:"Update",
				form:[
					{ name:"name", type:"text", placeholder:"category name", value:format.group, validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				this.snackBar.open("updating category name...", '', {duration:5000});
				this.workflowService.updateGroupName(format.workflow_uuid, format.group, result.formGroup.value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("category name updated", "", {duration:2000});
					//this.loadFormats();
					this.loadWorkflows(`workflows:[workflows.uuid:${format.workflow_uuid}]`,`formats`);
				}, (error) => {
					let message = 'updating group name failed';
					if(error.error?.message) message += ': '+ error.error?.message;	
					this.snackBar.open(message, "", {duration:2000, panelClass:'snackBar-error'});
				});
			}
		});
	}
	deleteGroup(format:IFormat)
	{
		this.snackBar.open("deleting category...", '', {duration:5000});
		this.workflowService.deleteGroup(format.workflow_uuid, format.group).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			if(res.error)
			{
				this.snackBar.open(res.error, "", {duration:2000, panelClass:'snackBar-error'});
			} else {
				this.snackBar.open("category deleted", "", { duration:2000 });
				//this.loadFormats();
				this.removeFormats(format.group, format.workflow_uuid);
			}
		}, (error) => {
			this.snackBar.open("category NOT deleted", "", {duration:2000, panelClass:'snackBar-error'});
		});
	}
	// formats, channels - default
	add(data:any)
	{
		let type = data.type;
		if(type == 'creative')
		{
			if(data.workflow != undefined && data.format != undefined && data.channel != undefined)
			{
				this.addCreative(data);
			}else{
				this.addCreative();
			}
		} else if(type == 'project_channel')
		{
			this.addProjectChannel();
		}else if(type == 'channel')
		{
			//console.log("ADDING CHANNEL TOP WORKFLOW");
			this.activateWorkflowChannel(data);
			//this.addWorkflowChannel(data.workflow, data.projectChannel);
		}else if(type == 'format'){
			this.addFormat(data.workflow, data.group);
		}else if(type == 'fillformat'){
			this.fillFormat(data);
		}else if(type == 'fillChannel'){
			this.fillChannel(data);
		}else if(type == 'workflow'){
			this.addWorkflow();
		}
	}
	addCreative(e = null) : void
	{
		//console.log("adding creative",  {channels:this.channels, formats:this.formats});
		if(!e)
		{
			throw new Error("Is this function used addCreative: e = null");
			
			const dialogRef = this.dialog.open(NewCreativeDialogComponent, {
				//data: {channels:this.channels, formats:this.formats}//{territories:this.groups_vert, groups:this.groups_horiz}
			  });
			  dialogRef.afterClosed().subscribe((result: NewCreativeData) => {
				  if(result)
				  {
						this.snackBar.open("creating creative...", '', {duration:5000});
					  //this.creativeService.create({channel:result.channel.id, format:result.format.id});
					  this.projectService.createCreative(this.project_uuid, result.channel.id, result.format.id).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						this.snackBar.open("creative created", "", {duration:2000});
						//this.loadCreatives();
						this.loadWorkflows();
					});
					//let newCreative = {state:'new', name:result.name, group_horiz:result.group, group_vert:result.territory};
					//this.creatives2.push(newCreative);
					//this.generateCreatives();
				}else{
					// popup closed with no data
				}
			});
		}else{
			this.snackBar.open("creating creative...", '', {duration:5000});
			this.workflowService.createCreative(e.workflow.uuid, e.channel.uuid, e.format.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("creative created", "", {duration:2000});
					//this.loadCreatives();
					//this.loadWorkflows();
					const creative = res.data[0];
					//this.loadCreative([creative.uuid]);
					this.loadWorkflows(`workflows:[workflows.uuid:${creative.workflow_uuid}]`,`creatives:[creative2.uuid:${creative.uuid}]`);
				});
			}

	}


	fillFormat(data) : void
	{
		this.snackBar.open("adding creatives...", '', {duration:5000});
		this.workflowService.fillFormat(data.workflow.uuid, data.format.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("creatives added", "", {duration:2000});

			//this.loadWorkflows();
			this.loadWorkflows(`workflows:[workflows.uuid:${res.data[0].workflow_uuid}]`, `creatives:[format_uuid:${res.data[0].uuid}]`);
		});
	}
	fillChannel(data) : void
	{
		this.snackBar.open("adding creatives...", '', {duration:5000});
		this.workflowService.fillChannel(data.workflow.uuid, data.channel.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("creatives added", "", {duration:2000});
			//this.loadWorkflows();
			this.loadWorkflows(`workflows:[workflows.uuid:${res.data[0].workflow_uuid}]`, `creatives:[channel_uuid:${res.data[0].uuid}]`);
						
		});
	}
	workflowFilter:string[];
	loadWorkflows(...filters) : void
	{
		if(!filters.length) filters = null; // will always exist so null if empty
		this.projectService.loadWorkflows(this.project_uuid, filters, wrapper(null, () => this.workflowFilter = filters));
		/*
		this.projectService.getWorkflows(this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.workflows = response["data"];
				// derive channels and formats from workflows
				
				//for (let i = 0; i < this.workflows.length; i++) {
				//	const workflow = this.workflows[i];
				//	// CANT :()
				//}
			},
			error => this.errorMessage = <any>error
		);*/
	}
	loadProjectTasks() : void
	{
		this.projectService.loadProjectTasks(this.project_uuid);
		this.projectService.tasks.subscribe(tasks => this.tasks = tasks);
	}
	loadCreative(uuids:string[]) : void
	{
		throw new Error("unused");
		//this.creativeService.findOne(uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
		this.projectService.getCreative(this.project_uuid, uuids).pipe().subscribe(
			(response) => {
				// find the workflow
				if(!response["data"] || response["data"].length == 0) return;
				let creatives = response["data"];
				//console.log("creatives", creatives);
				
				creatives.forEach(creative => {
					let workflow = this.workflows.find(workflow => workflow.uuid == creative.workflow_uuid);
					if(workflow)
					{
						// search to replace creative if not then its a fresh one to pop in
						let oldCreativeIndex = workflow.creatives.findIndex(oldCreative => oldCreative.uuid == creative.uuid);
						if(oldCreativeIndex != -1)
						{
							// replace
							workflow.creatives[oldCreativeIndex] = creative;
						}else{
							// insert
							workflow.creatives.push(creative);
						}
					}
				});
				// trigger update
				this.handleWorkflows(this.workflows);
				//this.workflows = this.workflows.concat([]);
			},
			error => this.errorMessage = <any>error
		);
		/*
		this.creativeService.findAll({project:this.project_uuid}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.creatives = response["data"];
				console.log("creatives", response);
			},
			error => this.errorMessage = <any>error
		);*/
	}
	loadCreatives() : void
	{
		throw new Error("unused");
		this.projectService.getCreatives(this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.creatives = response["data"];
			},
			error => this.errorMessage = <any>error
		);
		/*
		this.creativeService.findAll({project:this.project_uuid}).pipe(takeUntil(this._unsubscribe)).subscribe(
			(response) => {
				this.creatives = response["data"];
				console.log("creatives", response);
			},
			error => this.errorMessage = <any>error
		);*/
	}

	/*
	// TODO Had to delete this was it needed?
	saveFormat(name:string, group:string):void
	{
		if(this.selectedFormat)
		{
			// update format
			this.projectService.updateFormat(this.project_uuid, this.selectedFormat).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.loadChannels();
			});
		}else{
			// new format
			this.projectService.createFormat(this.project_uuid, name, group).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.loadFormats();
			});
		}
	}*/

	/* channels */
	channelSelected(e:any):void {
		// TODO is this needed
	}
	getChannelLabel(channel:IProjectChannel):string
	{
		//console.warn("remove this function?");
		return channel.name;
		//return channel.order + ". " + channel.name;
	}

	addProjectChannel(another:boolean = false, autoActivate:boolean = true):void
	{
		let form = [
			{ name:"name", type:"text", placeholder:"channel name (min. 2 characters)", value:"", validator:Validators.required},
			{ name:"another", type:"checkbox", label:"add another after?", value:another},
			{ type:"divider"},
			{ type:"label", label:"Automatically activate in workflow:"},
		];
		this.workflows.forEach(workflow => {
			form.push({ name:"workflow_"+workflow.uuid, type:"checkbox_list_item", label:workflow.name, value:autoActivate, })
		});
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add Channel",
				negative:"Cancel",
				positive:"Create",
				form
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				let value = result.formGroup.value;
				let selectedWorkflows = [];
				//this.projectService.createFormat(this.project_uuid, value.workflow.id, value.name, value.grou
				for(var key in value) {
					var wf_uuid = key.split("workflow_")[1];
					if(wf_uuid && value[key]) {
						let wf = this.workflows.find(workflow => {if(wf_uuid == workflow.uuid) return workflow});
						if (wf) selectedWorkflows.push(wf);
					}
				};

				//this.workflowService.createChannel(value.workflow.id, value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("creating project channel", "", {duration:2000});
				this.projectService.createChannel(this.project_uuid, value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("project channel created", "", {duration:2000});
					if( selectedWorkflows?.length ) this.activateWorkflowChannel({type:"channel", projectChannel:res.data[0], workflows:selectedWorkflows});
					if(result.formGroup.value.another)
					{
						this.addProjectChannel(true, result.formGroup.value.autoActivate);
					}
					this.loadChannels();
				}, error => {
					this.snackBar.open("error creating project channel", "", {duration:2000, panelClass:'snackBar-error'});
				});
			}
		});
	}
	activateWorkflowChannel(data, autoaddslot:boolean = false, workflowIndex = null){
		var workflowIndex = (workflowIndex == null) ? 0 : workflowIndex+1;
		if(!data.workflows[workflowIndex]) return;

		let workflow = data.workflows[workflowIndex];
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			
			data: {
				title: "Activate '"+data.projectChannel.name+"' channel in '"+ workflow.name +"' workflow",
				paginate: "(activation" + (workflowIndex+1) + " of " + data.workflows.length + ")",
				negative:"Cancel",
				positive:"Activate channel",// + ((autoaddslot) ? 'with' : 'without') + " formats",
				form:[
					//{ name:"workflow", type:"select", placeholder:"Workflow", value:workflow ?? this.workflows[0], data:this.workflows, validator:Validators.required},
					{ name:"autoaddslot", type:"checkbox", label:"automatically add grid slots for available formats in activated "+data.projectChannel.name+" workflow channel?", value:autoaddslot},
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				let value = result.formGroup.value;
				//this.projectService.createFormat(this.project_uuid, value.workflow.id, value.name, value.grou
				this.addWorkflowChannel(workflow, data.projectChannel, value.autoaddslot);
				if(workflowIndex < data.workflows.length - 1) this.activateWorkflowChannel(data, autoaddslot, workflowIndex);
			}
		});
	}
	addWorkflowChannel(workflow:IWorkflow, projectChannel:IProjectChannel, autoaddslot:boolean):void
	{
		this.snackBar.open("activating workflow channel", "", {duration:2000});
		this.workflowService.createChannel(workflow.uuid, projectChannel.uuid, autoaddslot).subscribe(res => {
			this.snackBar.open("workflow channel activated", "", {duration:2000});
			const channel:IChannel = res.data[0];
			const filter = [`workflows:[workflows.uuid:${workflow.uuid}]`,`channels:[creative_channels.project_channel_uuid:${projectChannel.uuid}]`]
			if(autoaddslot) filter.push(`creatives:[channel_uuid:${channel.uuid}]`);
			this.loadWorkflows.apply(this, filter);
		}, error => {
			this.snackBar.open("error activating workflow channel", "", {duration:2000, panelClass:'snackBar-error'});
		});
		/*
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add Channel",
				negative:"Cancel",
				positive:"Create",
				form:[
					//{ name:"workflow", type:"select", placeholder:"Workflow", value:workflow ?? this.workflows[0], data:this.workflows, validator:Validators.required},
					{ name:"name", type:"text", placeholder:"Enter Name", value:"", validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				let value = result.formGroup.value;
				//this.projectService.createFormat(this.project_uuid, value.workflow.id, value.name, value.grou

				//this.workflowService.createChannel(value.workflow.id, value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.projectService.createChannel(this.project_uuid, value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("channel created", "", {duration:2000});
					this.loadChannels();
				});
			}
		});*/
	}
	editProjectChannel(projectChannel:IProjectChannel) : void
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Edit Channel",
					negative:"Cancel",
					positive:"Save",
					form:[
						{ name:"name", type:"text", placeholder:"channel name", field:"name", label:"name",validator:Validators.required},
						{ name:"order", type:"text", placeholder:"channel order", field:"order", label:"order", validator:Validators.required},
					],
					model:JSON.parse(JSON.stringify(projectChannel))
				}
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let channel = result.model;
				channel.name = result.formGroup.value.name;
				channel.order = result.formGroup.value.order;
				this.snackBar.open("saving project channel...", '', {duration:5000});
				this.projectService.updateChannel(this.project_uuid, channel).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("project channel saved", "", {duration:2000});
					
					//this.loadChannels();
					//this.loadWorkflows();//TODO - this is a slow but workflows load required to ensure workflow channels are updated with latest channel info
					this.updateProjectChannel(res.data[0]);
				}, response => {
					let message = Object.values(response.error.errors).map(e => e.toString()).join();
					this.snackBar.open(message, "", {duration:2000, panelClass:'snackBar-error'});
				});
			}
		});	
	}

	updateProjectChannel(updatedChannel:IProjectChannel){
		const channel = this.channels.find(channel => channel.uuid == updatedChannel.uuid)
		if(!channel) return;
		
		if(updatedChannel.order == channel.order)
		{
			channel.name = updatedChannel.name;
		} else{
			this.loadChannels();
		}
	}
	moveChannel(e:MouseEvent, channel:IProjectChannel, direction:number):void
	{

		//TODO - dont think this is used anymore
		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();

		//sort the assets array by .order
		this.channels.sort((a, b) => a.order - b.order);

		//then swap the selected asset's order num with it's next (up or down) neighbour's order 
		let newIndex = this.channels.indexOf(channel) + direction;
		if(newIndex < 0 || newIndex >= this.channels.length) return;

		let selectedAssetOrder = channel.order;
		let swapChannel = this.channels[newIndex];

		channel.order = swapChannel.order;
		swapChannel.order = selectedAssetOrder;

		// resort
		this.channels.sort((a, b) => a.order - b.order);
	}
	
	getHighestChannelOrder(): number {
		let order = 0;
		for (var i = 0; i < this.channels.length; i++)
		{
			var asset = this.channels[i];
			if(asset.order >= order) order = asset.order + 1;
		}
		return order;
	}
	deleteProjectChannel(projectChannel:IProjectChannel) : void
	{
		// delete channel
		// TODO what about any ophaned data?
		this.snackBar.open("deleting project channel...", '', {duration:5000});
		this.projectService.deleteChannel(this.project_uuid, projectChannel)
		.pipe(takeUntil(this._unsubscribe))
		.subscribe(
			res => {
				if(res.error)
				{
					this.snackBar.open(res.error, "", {duration:4000, panelClass:'snackBar-error'});
				}else{
					this.snackBar.open("project channel deleted", "", {duration:2000});
					//this.loadChannels();
					//this.loadWorkflows();//TODO - this is a slow but workflows load required to ensure workflow channels are updated with latest channel info
					this.projectService.removeChannel(projectChannel.uuid);
				}
			},
			res => {
				//console.log(res);
				this.snackBar.open(res.message, "", {duration:2000, panelClass:'snackBar-error'});
			},
		);
	}
	deleteWorkflowChannel(workflow:IWorkflow, workflowChannel:IChannel) : void
	{
		// delete channel
		// TODO what about any ophaned data?
		this.snackBar.open("de-activating workflow channel...", '', {duration:5000});
		//console.log("workflowChannel", workflowChannel);
		this.workflowService.deleteChannel(workflow.uuid, workflowChannel.uuid)
		.pipe(takeUntil(this._unsubscribe))
		.subscribe(
			res => {
				if(res.error)
				{
					this.snackBar.open(res.error, "", {duration:4000, panelClass:'snackBar-error'});
				}else{
					this.snackBar.open("workflow channel de-activated", "", {duration:2000});
					const channelIndex = workflow.channels.findIndex(channel => channel.uuid == workflowChannel.uuid);//projectChannel.uuid
					if(channelIndex !=- 1) {
						workflow.channels.splice(channelIndex, 1);
						this.workflows = this.workflows.concat([]);
						this.selectionService.workflows = this.workflows;
					}
				}
			},
			res => {
				//console.log(res);
				this.snackBar.open(res.message, "", {duration:2000, panelClass:'snackBar-error'});
			},
		);
	}
	loadChannels() : void
	{
		this.projectService.loadChannels(this.project_uuid)
		return;
		this.loadingChannels = true;
		this.projectService.getChannels(this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
		//this.projectService.channels$.subscribe(res => {
			this.channels = res["data"];
			this.loadingChannels = false;
			if(this.dialog.openDialogs[0]?.componentInstance instanceof ProjectWorkflowsComponent)
			{
				this.dialog.openDialogs[0].componentInstance.updateProjectChannels(this.channels);
			}
		});
		//this.projectService.loadChannels(this.project_uuid);
	}
	removeCreatives(uuids:string[])
	{
		// workflow lookup
		const workflowLookup = {};
		const creativeLookup = {};
		this.workflows.forEach(workflow => {
			workflowLookup[workflow.uuid] = workflow;
			workflow.creatives.forEach(creative => {
				creativeLookup[creative.uuid] = creative;
			})
		});
		uuids.forEach(uuid => {
			let creative = creativeLookup[uuid];
			if(!creative) return;
			let workflow = workflowLookup[creative.workflow_uuid];
			if(!workflow) return;
			const creativeIndex = workflow.creatives.findIndex(creative => creative.uuid == uuid);
			if(creativeIndex != -1){
				workflow.creatives.splice(creativeIndex, 1);
			}
		});
		this.workflows = this.workflows.concat([]);
		this.selectionService.workflows = this.workflows;
	}
	creativeDeleted(deletedCreative:ICreative)
	{
		// this.loadWorkflows();
		const workflow = this.workflows.find(workflow => workflow.uuid == deletedCreative.workflow_uuid);
		if(workflow) {
			const creativeIndex = workflow.creatives.findIndex(creative => creative.uuid == deletedCreative.uuid);
			if(creativeIndex != -1){
				workflow.creatives.splice(creativeIndex, 1);
				this.workflows = this.workflows.concat([]);
				this.selectionService.workflows = this.workflows;
			}
		}
	}
	loadPackages(just_created_uuid:string = null)
	{
		if(this.isAdmin())
		{
			this.project2Service.loadPackages(this.project_uuid);
		}

	}
	loadDeliveryPackages(just_created_uuid:string = null)
	{
		//this.project2Service.loadDeliveryPackages(this.project_uuid);
		/*
		this.project2Service.getDeliveryPackages(this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.deliveryPackages = res["data"];
			if(just_created_uuid)
			{
				let deliveryPackage = this.deliveryPackages.find((deliveryPackage) => deliveryPackage.uuid == just_created_uuid);
				this.deliveryPackageSelected(deliveryPackage);
			}
		});*/
	}
	loadSharePackages(just_created_uuid:string = null)
	{
		//this.project2Service.loadSharePackages(this.project_uuid);
		/*
		this.project2Service.getDeliveryPackages(this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.deliveryPackages = res["data"];
			if(just_created_uuid)
			{
				let deliveryPackage = this.deliveryPackages.find((deliveryPackage) => deliveryPackage.uuid == just_created_uuid);
				this.deliveryPackageSelected(deliveryPackage);
			}
		});*/
	}
	loadDeliveryItems()
	{
		this.deliveryItemService.index(this.selectedPackage.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.deliveryItems = res["data"];
			let uuids = [];
			this.deliveryItems.forEach(deliveryItem => {
				uuids[deliveryItem.creative_uuid] = 1;
			});
			this.workflows.forEach(workflow => {
				workflow.creatives.forEach(creative => {
					creative.package = uuids[creative.uuid] ? 1 : 0;
				});
			});

			// LEGACY: get available creatives at this point
			this.availableCreatives = [];
			for (let i = 0; i < this.creatives.length; i++) {
				const creative = this.creatives[i];
				if(creative.state == CreativeState.DONE)
				{
					this.availableCreatives.push({creative, selected:this.isCreativeInPackage(creative)});
				}			
			}
		});
	}
	newDeliveryPackageFromSelection()
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add Delivery Package",
				negative:"Cancel",
				positive:"Create",
				form:[
					{ name:"name", type:"text", placeholder:"package name", value:"", validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				this.snackBar.open("creating delivery package...", '', {duration:5000});
				let deliveryPackage = result.formGroup.value;
				deliveryPackage.creatives = this.selectedCreatives.map(creative => creative.uuid);
				this.deliveryPackageService.create(deliveryPackage, this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("delivery package", "", {duration:2000});
					this.loadPackages(res.data[0].uuid);
				});
			}
		});
	}
	deliveryPackageCreate():void
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add Delivery Package",
				negative:"Cancel",
				positive:"Create",
				form:[
					{ name:"name", type:"text", placeholder:"package name", value:"", validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogComponent) => {
			if(result)
			{
				this.snackBar.open("creating delivery package...", '', {duration:5000});
				let deliveryPackage = result.formGroup.value;
				this.deliveryPackageService.create(deliveryPackage, this.project_uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("delivery package", "", {duration:2000});
					this.loadPackages(res.data[0].uuid);
				});
			}
		});
	}
	deliveryPackageEdit(deliveryPackage:DeliveryPackage):void
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Edit Delivery Package",
					negative:"Cancel",
					positive:"Save",
					form:[
						{ name:"name", type:"text", placeholder:"package name", field:"name", label:"name",validator:Validators.required},
					],
					model:deliveryPackage
				}
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let deliveryPackage = result.model;
				deliveryPackage.name = result.formGroup.value.name;
				this.snackBar.open("saving delivery package...", '', {duration:5000});
				this.deliveryPackageService.update(deliveryPackage.uuid, deliveryPackage).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("delivery package saved", "", {duration:2000});
					this.loadPackages();
				});
			}
		});	
	}
	deliveryPackageDelete(packagesIn:DeliveryPackage[])
	{
		// TODO we could delay the removal of local packages until server ones removed if any
		// bin off any packages that were local first (well, we shouldnt have any unsaved local packages, as auto saved on creation)
		let unsavedPackages = packagesIn.filter(_package => DeliveryPackage.isNew(_package));
		let savedPackages = packagesIn.filter(_package => !DeliveryPackage.isNew(_package));
		let packageType = packagesIn[0].type;
		let packageArr = (packageType == 'share') ? this.sharePackages : this.deliveryPackages;

		packageArr = packageArr.filter(_package => !unsavedPackages.find(p => p.uuid == _package.uuid));
		if(savedPackages.length == 0) return;
		if(savedPackages.length == 1)
		{
			this.snackBar.open("deleting package...", '', {duration:5000});
			this.deliveryPackageService.delete(savedPackages[0].uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("package deleted", "", {duration:2000});
				if(res.data[0])
				{
					const deleted_uuid:string = res.data[0].uuid; 
					const packageIndex = packageArr.findIndex(_package => _package.uuid == deleted_uuid);
					if(packageIndex != -1) 
					{
						//splice from the specific package type array
						packageArr.splice(packageIndex, 1);
						this.restorePackageArray(packageType, packageArr);
					}	
				}
				//this.loadPackages();
			});
		}else {
			this.snackBar.open("deleting packages...", '', {duration:5000});
			this.project2Service.deletePackages(this.project.uuid, savedPackages).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.snackBar.open("packages deleted", "", {duration:2000});
				//this.loadPackages();
				packageArr = []; //empties the specific package type array
				this.restorePackageArray(packageType, packageArr);
			});
		}
	}
	restorePackageArray(packageType, packageArr){
		if(packageType == "share"){
			this.sharePackages = packageArr;
			this.sharePackages.concat([]);
		} else if(packageType == "delivery"){
			this.deliveryPackages = packageArr;
			this.deliveryPackages.concat([]);
		}
		this.checkPackages();
	}
	openDeliveryPackage(uuid:string)
	{
		if(!this.panelOpen)
			this.panelOpen = true;

		this.panelTabIndex = ProjectPanel.Deliveries;
		let targetDeliveryPackage = this.deliveryPackages.find(deliveryPackage => deliveryPackage.uuid == uuid);
		this.deliveryPackageSelected(targetDeliveryPackage);
	}
	/*
	onPackageChanged(event:any)
	{
		let {uuid, value} = event;
		this.selectionService.selectionChange("creative", uuid)
		// HACK to trigger recompute in packages that looks for a workflows change
		//this.workflows = this.workflows.concat([]);
	}
	*/

	deliveryPackageSelected(_package:DeliveryPackage, autoUpdated:boolean = false):void {

		// clear if null or (same and not being auto updated by a remote delete)
		if(!_package || (_package == this.selectedPackage && !autoUpdated))
		{
			this.selectedPackage = null;
			this.workflows?.forEach(workflow => {
				workflow.creatives?.forEach(creative => {
					creative.package = 0;
				});
			});
			this.clearSelectionMode();
			
			
			if(this.deliveryPackagesComponent) this.deliveryPackagesComponent.selectedPackage = null;
			if(this.sharePackagesComponent) this.sharePackagesComponent.selectedPackage = null;

			return;
		}
		
		//if(_package && _package != this.selectedPackage) this.selectionService.resetSelectionLookup();
		this.packageMode = true;
		this.selectionMode = true;
		// load in available creatives and show selected against items
		this.selectedPackage = null;
		//this.loadDeliveryItems();
		let selected_uuids = {};
		let uuids = [];
		let uuids_add = [];
		let uuids_remove = [];
		_package.delivery_items.forEach(deliveryItem => {
			uuids[deliveryItem.creative_uuid] = 1;
			selected_uuids[deliveryItem.creative_uuid] = true;
		});
		_package.delivery_items_add.forEach(creative_uuid => {
			selected_uuids[creative_uuid] = true;
		});
		_package.delivery_items_remove.forEach(creative_uuid => {
			delete selected_uuids[creative_uuid];
		});

		//add to creative selection
		if(!autoUpdated){
			this.selectionService.resetSelectionLookup();
			this.selectedPackage = _package;
			this.selectionService.selectionChange("creative", Object.keys(selected_uuids));
		} 
		this.selectedPackage = _package;

		//update sibling components with the latest selected package
		if(this.selectedPackage.type == 'delivery')
			if(this.deliveryPackagesComponent) this.deliveryPackagesComponent.selectedPackage = this.selectedPackage;
		if(this.selectedPackage.type == 'share')
			if(this.sharePackagesComponent) this.sharePackagesComponent.selectedPackage = this.selectedPackage;
		
		this.deliveryPackagesComponent.setDelivOption();
	}
	isCreativeInPackage(creative:ICreative)
	{
		for (let i = 0; i < this.deliveryItems.length; i++) {
			let deliveryItem = this.deliveryItems[i];
			if(deliveryItem.creative_uuid == creative.uuid)
			{
				return true;
			}
		}
		return false;
	}
	getDeliveryPackageLabel(deliveryPackage:DeliveryPackage):string
	{
		return deliveryPackage.name;
	}
	getCreativeLabel(creativeIn:any):string
	{
		// channel + format
		let creative = creativeIn.creative;
		return creative.channel.name + " | " + creative.format.name;
	}
	toggleDeliveryItem(wrapper:any)
	{
		//console.log("TOGGLE", wrapper);
		if(wrapper.selected)
		{
			let deliveryItem = new DeliveryItem();
			deliveryItem.creative_uuid = wrapper.creative.uuid;
			this.deliveryItemService.create(deliveryItem, this.selectedPackage.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
				this.loadDeliveryItems();
			});
		}else{
			for (let i = 0; i < this.deliveryItems.length; i++) {
				let deliveryItem = this.deliveryItems[i];
				if(deliveryItem.creative_uuid == wrapper.creative.uuid)
				{
					this.deliveryItemService.delete(deliveryItem.id.toString()).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
						this.loadDeliveryItems();
					});
					break;
				}
			}
			
		}
	}
	getDeliveryLink(link:HTMLLinkElement)
	{
		return window.location.origin + `/delivery/${this.selectedPackage.uuid}`;
	}
	copyLinkToClipboard(link:HTMLLinkElement)
	{
		//const link = e.target.parentElement.querySelector("a");
		const range = document.createRange();
		range.selectNode(link);
		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
		const successful = document.execCommand('copy');
		selection.removeAllRanges();
		//this.main.endSaveNotification("Link copied.");
	}

	sort(array:Array<any>, prop: string) {
		return array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
	}

	addWorkflow():void
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add Workflow",
				negative:"Cancel",
				positive:"Create",
				form:[
					{ name:"name", type:"text", placeholder:"workflow name", value:"", validator:Validators.required}
				]
			}
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				this.snackBar.open("creating workflow...", '', {duration:5000});
				let value = result.formGroup.value;
				this.projectService.createWorkflow(this.project_uuid, value.name).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("workflow created", "", {duration:2000});
					this.newWorkflowName = value.name;
					const workflow_uuid = res.data[0].uuid;
					this.loadWorkflows(`workflows:[workflows.uuid:${workflow_uuid}]`, 'channels', 'formats', 'users');//, 'channels', 'users', 'formats', 'creatives');
				}, (error:HttpErrorResponse) => {
					if(error.status == 422)
					{
						this.snackBar.open("workflow create failed: " + error.error.message, "", {duration:2000, panelClass:'snackBar-error'});
					}else {
						this.snackBar.open("workflow create failed: server error", "", {duration:2000, panelClass:'snackBar-error'});
					}							
				});
			}
		});
	}

	getAllGroups(): string[]
	{
		if(!this.workflows) return [];
		let keys = {};
		for (let i = 0; i < this.workflows.length; i++) {
			const formats = this.workflows[i].formats;
			for (let index = 0; index < formats.length; index++) {
				const format = formats[index];
				keys[format.group] = 1;
			}
			/*
			const creatives = this.workflows[i].creatives;
			for (let index = 0; index < creatives.length; index++) {
				const format = creatives[index].format;
				keys[format.group] = 1;				
			}*/
		}
		return Object.keys(keys);
	}
	addFormat(workflow:IWorkflow = null, group:string = null, another:boolean = false, autoaddslot:boolean = false):void
	{
		if(localStorage.getItem('formatAutoAddSlot')) autoaddslot = (localStorage.getItem('formatAutoAddSlot') == 'true');
		if(workflow)	workflow = this.workflows.find(wf => wf.id == workflow.id);
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
				title:"Add Format",
				negative:"Cancel",
				positive:"Create",
			form:[
					// defaults to first workflow
					{ name:"workflow", type:"select", placeholder:"Workflow", value:workflow ?? this.workflows[0], data:this.workflows, validator:Validators.required},
					{ focus: !group, name:"group", type:"text", placeholder:"format category", value: group ?? '', validator:Validators.required, autoComplete:this.getAllGroups()},
					{ focus: group != '', name:"name", type:"text", placeholder:"format name", value:"", validator:Validators.required},
					{ name:"autoaddslot", type:"checkbox", label:"automatically add creative slots for available channels", value:autoaddslot},
					{ name:"another", type:"checkbox", label:"add another after?", value:another},
				]
			},
			autoFocus:false,
			restoreFocus:false,
		});
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				this.snackBar.open("creating format...", '', {duration:5000});
				let value = result.formGroup.value;
				this.workflowService.createFormat(value.workflow.uuid, value.name, value.group, value.autoaddslot).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("format created", "", {duration:2000});
					//this.loadFormats();
					const filter = [`workflows:[workflows.uuid:${workflow.uuid}]`,`formats:[creative_formats.uuid:${res.data[0].uuid}]`];
					if(value.autoaddslot) filter.push(`creatives:[format_uuid:${res.data[0].uuid}]`);
					
					//TODO - if no add creatives, inject the format straight in.

					if(result.formGroup.value.another)
					{
						//this was Ben's original
						//this.addFormat(workflow, result.formGroup.value.group ? result.formGroup.value.group : group, true, result.formGroup.value.autoaddslot);
						//setTimeout(() => this.loadWorkflows(), 100);
						
						//TODO: this was Adam's change, why no autoaddslot?
						this.addFormat(workflow, result.formGroup.value.group ? result.formGroup.value.group : group, true);



						setTimeout(() => this.loadWorkflows.apply(this, filter), 100);
						
					}else{
						//this.loadWorkflows();
						this.loadWorkflows.apply(this, filter);
					}
				}, (error) => {
					let message = 'creating format failed';
					if(error.error?.message) message += ': '+ error.error?.message;	
					this.snackBar.open(message, "", {duration:2000, panelClass:'snackBar-error'});
					//this.snackBar.open("creating format failed!", "", {duration:2000, panelClass:'error'});
				});

				//remember checkbox values
				//localStorage.setItem('formatAutoAddSlot', result.formGroup.value.autoaddslot.toString());
			}
		});
	}
	editFormat(format:Format) : void
	{
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Edit Format",
					negative:"Cancel",
					positive:"Save",
					form:[
						{ name:"group", type:"text", placeholder:"category name", field:"group", label:"group", validator:Validators.required, autoComplete:this.getAllGroups()},
						{ focus: true, name:"name", type:"text", placeholder:"format name", field:"name", label:"name", validator:Validators.required},
					],
					model:JSON.parse(JSON.stringify(format))
				},
				autoFocus:false,
				restoreFocus:false,
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let channel = result.model;
				channel.name = result.formGroup.value.name;
				this.snackBar.open("saving format...", '', {duration:5000});
				let value = result.formGroup.value;
				result.model.name = value.name;
				result.model.group = value.group;
				this.formatService.update(format.uuid, result.model).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open('format saved', '', {duration:2000});
					//this.loadFormats();
					//this.loadWorkflows();
					//this.loadWorkflows(`workflows:[workflows.uuid:${res.data[0].workflow_uuid}]`,`formats:[creative_formats.uuid:${res.data[0].uuid}]`);
					this.updateFormat(res.data[0]);
				}, (error) => {
					let message = 'saving format failed';
					if(error.error?.message) message += ': '+ error.error?.message;	
					this.snackBar.open(message, "", {duration:2000, panelClass:'snackBar-error'});
					//this.snackBar.open('saving format failed', '', {duration:2000, panelClass:'error'});
				});
			}
		});	
	}
	updateFormat(updatedFormat:IFormat){
		const workflow = this.workflows.find(workflow => workflow.uuid == updatedFormat.workflow_uuid);
		if(workflow)
		{
			const formatIndex = workflow.formats.findIndex(format => format.uuid == updatedFormat.uuid);
			if(formatIndex !== -1){
				//format.name = updatedFormat.name;
				//format.group = updatedFormat.group;
				// update the format
				workflow.formats[formatIndex] = updatedFormat;
				// update the creative references (not sure if we use this still)
				workflow.creatives.forEach(creative => {
					if(creative.format_uuid == updatedFormat.uuid)	creative.format = updatedFormat;
				})
				// sort - essential for correct rendering of grid groups
				workflow.formats.sort((a, b) => a.group.localeCompare(b.group) || a.name.localeCompare(b.name));
			}
			this.workflows = this.workflows.concat([]);
			this.selectionService.workflows = this.workflows;
		}
	}
	deleteFormat(format:Format) : void
	{
		// TODO what about any ophaned data?
		this.snackBar.open("deleting format...", '', {duration:5000});
		this.formatService.delete(format.uuid).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
			this.snackBar.open("format deleted", "", {duration:2000});
			//this.loadFormats();
			//this.loadWorkflows();
			this.removeFormat(res.data[0].uuid, res.data[0].workflow_uuid);
		}, (error) =>
		{
			let message = 'format NOT deleted';
			if(error.error?.message) message += ': '+ error.error?.message;	
			this.snackBar.open(message, "", {duration:2000, panelClass:'snackBar-error'});
		} );
	}
	removeFormat(format_id:string, workflow_id:string){
		const workflow = this.workflows.find(workflow => workflow.uuid == workflow_id);
		if(workflow)
		{
			const formatIndex = workflow.formats.findIndex(format => format.uuid == format_id);
			if(formatIndex != -1) 
				{
					workflow.formats.splice(formatIndex, 1);
					this.workflows = this.workflows.concat([]);
					this.selectionService.workflows = this.workflows;
				}
		}
	}
	removeFormats(format_group:string, workflow_id:string){
		const workflow = this.workflows.find(workflow => workflow.uuid == workflow_id);
		if(workflow)
		{
			workflow.formats = workflow.formats.filter(format => format.group !== format_group);
			this.workflows = this.workflows.concat([]);
			this.selectionService.workflows = this.workflows;
		}
	}
	formatSelected(format:Format):void{	//e:MatSelectionListChange
		//his.selectedFormat = {...e.options[0].value};	// clone
	}


	editProjectOverview(project:Project, event:MouseEvent = null)
	{
		if(event)event.stopPropagation();
  
		const dialogRef = this.dialog.open(GenericDialogComponent, {
			data: {
					title:"Edit Project overview",
					negative:"Cancel",
					positive:"Save",
					form:[
						{ name:"overview", type:"textarea", placeholder:"Overview text", field:"overview", label:"overview", },
					],
					ignoreEnterSubmit:true,
					model:JSON.parse(JSON.stringify(project))
					/* model:{
						overview:project.overview,
						uuid:project.uuid,
					}*/
				}
		  });
		dialogRef.afterClosed().subscribe((result: GenericDialogData) => {
			if(result)
			{
				let value = result.formGroup.value;
				let project = result.model;
				project.overview = value.overview;

				this.snackBar.open("saving overview...");
				this.project2Service.update(project.uuid, project as Project2).pipe(takeUntil(this._unsubscribe)).subscribe(res => {
					this.snackBar.open("overview saved", "", {duration:2000});
					this.loadProject();
				}, (error:HttpErrorResponse) => {
					if(error.status == 422)
					{
						this.snackBar.open("overview save failed: " + error.error.message, "", {duration:2000, panelClass:'snackBar-error'});
					}else {
						this.snackBar.open("overview savefailed: server error", "", {duration:2000, panelClass:'snackBar-error'});
					}							
				});
			}
		});	
		
		return;
	}

	onCreativeSelected(e)
	{
		//console.log("onCreativeSelected", e)
		const creative:ICreative = (e.option ? e.option.value : e.value) as ICreative;
		this.api.gotoPage(`/creative/${creative.uuid}`);	
	}	



	apiCall(call : Observable<any>, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void){
		return call.pipe(takeUntil(this._unsubscribe)).subscribe(next,	error);
	}
	
	ngOnDestroy() {
		this._unsubscribe.next(true);
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();

		// leave the project chat
		this.pusherService.leaveProjectChat(this.project_uuid);
		this.pusherService.leaveWorkgroupChat();
	}
	formatDate(timestamp, short:boolean = false)
	{
		const date = new Date(timestamp);
		// https://www.w3schools.com/jsref/jsref_tolocalestring.asp
		return date.toLocaleString();	// date.toLocaleDateString() + ", " + date.toLocaleTimeString();
	}
	togglePanel()
	{
		this.panelOpen = !this.panelOpen;
		if(!this.panelOpen)
		{
			// clear any settings
			this.clearSelectionMode();
		}
	}
	currentPackages:IDeliveryPackage[];
	panelTabIndex:number = ProjectPanel.Help;
	onPanelTabChange(e:MatTabChangeEvent)
	{
		//console.log("tab", e);
		this.panelTabIndex = e.index;
		this.updatePanelState();	
	}

	openPanel(tab:string)
	{
		this.selectionMode = false;
		this.packageMode = false;
		//make sure no packages selected before resetting, as reset will add existing to items_remove list in the selected package
		this.deliveryPackageSelected(null);
		this.selectionService.resetSelectionLookup();
		//set tab index to open
		switch (tab) {
			case 'Help':
				this.panelTabIndex = ProjectPanel.Help;
				break;
			case 'Options':
				this.panelTabIndex = ProjectPanel.Options;
				break;
			case 'Deliveries':
				this.panelTabIndex = ProjectPanel.Deliveries;
				break;
			case 'Share':
				this.panelTabIndex = ProjectPanel.Share;
				break;
			default:
				break;
		}
		this.updatePanelState();
	}
	updatePanelState()
	{
		if(this.panelTabIndex == ProjectPanel.Close)	// close
		{
			this.panelOpen = false;
			this.selectionMode = false;
			this.packageMode = false;
			this.currentPackages = [];
			return;
		}	
		if(this.panelTabIndex == ProjectPanel.Options)	// options
		{
			//this.deliveryPackageSelected(null);
			this.selectionMode = true;
			this.packageMode = false;
		}else if(this.panelTabIndex == ProjectPanel.Deliveries) // deliveries
		{
			//this.deliveryPackageSelected(null);
			this.selectionMode = false;
			//this.packageMode = true;
			this.currentPackages = [...this.deliveryPackages];
		}else if(this.panelTabIndex == ProjectPanel.Share) // share
		{
			//this.deliveryPackageSelected(null);
			this.selectionMode = false;
			this.currentPackages = [...this.sharePackages];
			//this.packageMode = true;
		}
		if(!this.panelOpen)
		{
			this.panelOpen = true;
		}
		
	}
	savingPackage:boolean = false;
	
	newPackage(_package:DeliveryPackage)
	{
		this.newPackageName = _package.name;
		//packages
		if(_package.type == "delivery"){
			this.deliveryPackages.push(_package);
			this.deliveryPackages = [...this.deliveryPackages];
		} else if(_package.type == "share"){
			this.sharePackages.push(_package);
			this.sharePackages = [...this.sharePackages];
		}
	}
	
	savePackages(packages:DeliveryPackage[])
	{
		this.savingPackage = true;
		this.snackBar.open("updating packages...", '', {duration:5000});
		// clear selection
		
		this.project2Service.updatePackages(this.project_uuid, packages).pipe(takeUntil(this._unsubscribe)).subscribe(res =>
			{
				//this.savingPackage = false;
				//loop thru packages sent and clear _add and _romve lists, ready for load merge
				packages.forEach(_package => {
					_package.delivery_items_add = [];
					_package.delivery_items_remove = [];
				})

				//console.log("savePackages",res);
				this.project2Service.loadPackages(this.project_uuid);
				this.snackBar.open("Packages saved.", '', {duration:2000});
				this.deliveryPackageSelected(null);
				//this.checkPackages();
			}, err => {
				console.warn("error updated delivery packages", err);
				this.savingPackage = false;
	
			});

		//this.projectService.
	}
	deliveryPackageNameChange(e)
	{
		e.stopPropagation();
		e.stopImmediatePropagation();
		// TODO if name is no longer in original position then sort
		this.currentPackages = [...this.currentPackages];
		//this.deliveryPackagesList.cdr.detectChanges();
	}
	deliveryPackageNeedsSave()
	{
		// no deliveryPackages
		//if(!this.deliveryPackages || !this.deliveryPackages.length) return false;

		// any new deliveryPackages?
		for (let i = 0; i < this.deliveryPackages.length; i++) {
			const deliveryPackage = this.deliveryPackages[i];
			if(!deliveryPackage.uuid) return true;			
		}

		// any changed deliveryPackages
		let anyDirty = false;
		for (let i = 0; i < this.deliveryPackages.length; i++) {
			const deliveryPackage = this.deliveryPackages[i];
			if(DeliveryPackage.isDirty(deliveryPackage) || deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length){
				anyDirty = true;
				break;
			}
		}
		return anyDirty;
	}
	revertAllDeliveryPackage()
	{
		// reset packages
		this.deliveryPackages.forEach(deliveryPackage => {
			deliveryPackage.delivery_items_add.length = 0;
			deliveryPackage.delivery_items_remove.length = 0;
			DeliveryPackage.resetProp(deliveryPackage, 'name');
		});
		// reset creatives
		this.workflows.forEach(workflow => {
			workflow.creatives.forEach(creative => {
				creative.package = 0;
			});
		});
	}
	hasChanged(property:string, deliveryPackage:DeliveryPackage = null)
	{
		if(!deliveryPackage) deliveryPackage = this.selectedPackage;
		if(property == "creatives")	//delivery_items
		{
			/*
			let uuids = this.getDeliveryPackageCreativeUUIDS();
			let selectedUuids = this.getSelectedCreatives();
			return !this.areArraysSame(uuids, selectedUuids);*/
			return deliveryPackage.delivery_items_add.length || deliveryPackage.delivery_items_remove.length;
		}
		else{
			if(!deliveryPackage.reference) return false;		// no reference so never been saved before
			return DeliveryPackage.isPropDifferent(deliveryPackage, property);
		}
	}
	areArraysSame(a:any[], b:any[], sort:boolean = true)
	{
		if(sort)
		{
			a.sort();
			b.sort();
		}
		return a.length === b.length && a.every((value, index) => value === b[index])
	}
	getDeliveryPackageCreativeUUIDS():string[]
	{
		return this?.selectedPackage.delivery_items.map(deliveryItem => deliveryItem.creative_uuid);
	}
	getSelectedCreatives()
	{
		let uuids = [];
		this.workflows.forEach(workflow => {
			workflow.creatives.forEach(creative => {
				if(creative.package == 1 || creative.package == 2)
					uuids.push(creative.uuid);
			});
		});
		return uuids;
	}
	// TODO be able to pass in a target
	resetValue(property:string)
	{
		if(property == 'creatives')
		{
			this.selectedPackage.delivery_items_add.length = 0;
			this.selectedPackage.delivery_items_remove.length = 0;

			this.workflows.forEach(workflow => {
				workflow.creatives.forEach(creative => {
					// TODO this is slow use lookup
					creative.package = this.selectedPackage.delivery_items.find(deliveryItem => deliveryItem.creative_uuid == creative.uuid) ? 1: 0;
				});
			});
		}else{
			DeliveryPackage.resetProp(this.selectedPackage, property);
		}
	}

	changeTab(info){
		this.tabIndex = info.tabIndex;
	}
}