<div class="container">
    <div class="top" >

        <!-- <div> -->
            <!-- <div class="panel-section"> -->
                <div *ngIf="packages.length > 0" class="splitRow">
					<div class="sp-mainheader">{{type == 'delivery' ? 'Delivery' : 'Share'}} package list</div><!-- {{type == 'delivery' ? 'delivery' : 'share'}} packages: -->
					<button mat-icon-button [matMenuTriggerFor]="menuPackageList" [matMenuTriggerData]="{type}"><mat-icon>more_vert</mat-icon></button>

					<mat-menu #menuPackageList="matMenu">
						<ng-template matMenuContent let-type="type">
							<button (click)="deleteAllPackages()" mat-menu-item><mat-icon class="medium">delete</mat-icon>Delete all packages</button>
						</ng-template>
					</mat-menu>
				</div>
				<ng-container *ngIf="packages.length > 0">
					<app-genericlist #currentPackagesList [listType]="'compact'" [items]="packages" [itemStyleOverride]="(!saving) ? getPackageStyle : null" [itemMeta]="getDeliveryPackageNumItems" [itemIcon]="getDeliveryStatus" [sort]="'name'" [selectedItem]="selectedPackage" [search]="search" [labelProp]="getDeliveryPackageLabel" (clickItem)="deliveryPackageSelected($event)" (linkItem)="deliveryPackageLink($event)" (copyItem)="createNewPackage($event)" (deleteItem)="deliveryPackageDelete($event)" ></app-genericlist> <!--(editItem)="deliveryPackageEdit($event)"-->	
				</ng-container>
				<ng-container *ngIf="packages.length == 0">
					<span class="instruction">There are currently no {{type == 'delivery' ? 'delivery' : 'share'}} packages set up for this project.</span> 
				</ng-container>
				<div class="list-button-group">
					<button class="newBtn" (click)="createNewPackage()" [matTooltip]="'Create a new '+type+' package'" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
					<!--<button mat-raised-button color="warn" [disabled]="!packages || packages.length == 0" (click)="deleteAllPackages()" matTooltip="Delete all packages">Delete all {{type == 'delivery' ? 'delivery' : 'share'}} packages</button>-->
				</div>
			<!-- </div> -->
        
		<!-- </div> -->
		<div *ngIf="selectedPackage" class="selectedPanel" >
			<mat-divider style="margin: 0 0 0.5em 0"></mat-divider>
			<div class="sp-mainheader splitRow">			
				<div>Selected package</div>
				<button class="close" mat-icon-button (click)="deliveryPackageSelected(null)"><mat-icon>close</mat-icon></button>
			</div>
			<div class="selectedPanelContent">
                <form [formGroup]="packageForm">
                    <!--<div>    
                        <mat-form-field appearance="fill">
                            <mat-label for="name">package name</mat-label>
                            <input matInput formControlName="name" [(ngModel)]="selectedPackage.name" (change)="deliveryPackageNameChange($event)" [ngClass]="{'red': hasChanged('name') }">
                            <mat-error *ngIf="packageForm.controls.name.errors?.required">Name is <strong>required</strong></mat-error>
                            <button mat-button *ngIf="hasChanged('name')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('name')">
                                <mat-icon>undo</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>-->
					<div class="panel-section" >
						<div class="splitRow">
							<span class="sp-subheader" style="margin-top: 0.25rem; margin-left: -0.25rem;">Package name</span>
							<button mat-button *ngIf="hasChanged('name')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('name')" color="warn">
								<mat-icon>undo</mat-icon>
							</button>
						</div>
						<input type="text" #packagename class="panel-section panel-input-text single" [(ngModel)]="selectedPackage.name" (change)="deliveryPackageNameChange($event)" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('name') }" placeholder="add a package name" rows="1" cols="49" spellcheck="false" />
					</div>
							
                    
					<!-- STATUS -->
					<ng-container *ngIf="selectedPackage.uuid && selectedPackage.type == 'delivery' "><!--  && selectedPackage.type == 'delivery'-->
						<div class="sp-subheader">Status</div>
						<div class="panel-section">
							<!--<div class="splitGroup">
								<mat-icon *ngIf="getDeliveryStatus(selectedPackage).warning" class="red">warning</mat-icon>
								<span>{{getDeliveryStatus(selectedPackage).downloadMsg}}</span>
							</div>-->
							<div class="splitGroup" style="font-size: 0.7rem;">
								<mat-icon [class]="getDeliveryStatus(selectedPackage).class">{{getDeliveryStatus(selectedPackage).icon}}</mat-icon>
								<div>
									<div>{{getDeliveryStatus(selectedPackage).approvalMsg}}</div>
									<div [class]="{'red':getDeliveryStatus(selectedPackage).warning}">{{getDeliveryStatus(selectedPackage).downloadMsg}}</div>
								</div>
							</div>
						</div>
					</ng-container>

					<!-- PACKAGE LINK PAGE SETTINGS -->
					<div class="sp-subheader">{{selectedPackage.type == 'delivery' ? 'Delivery' : 'Share'}} link page</div>
                    <div class="panel-section">
						<!-- <legend>{{selectedPackage.type == 'delivery' ? 'Delivery' : 'Share'}} link page</legend> -->
						<!--<span>ID: {{selectedPackage.uuid}}</span>--><!--<a target="_blank" [href]="'/'+ selectedPackage.type +'/' + selectedPackage.uuid">Click here</a>--> 
						<div class="splitRow" style="margin: 0 -1rem 0.5rem -1rem !important;">
                        	<button class="small" [disabled]="isNewPackage(selectedPackage)" mat-stroked-button color="primary" (click)="copyDeliveryLink()">copy page link<!--<mat-icon>content_copy</mat-icon>--></button><!--selectedPackage.isNew()-->
							<a class="small" mat-button [disabled]="isNewPackage(selectedPackage)" mat-stroked-button color="primary" [href]="'/'+ selectedPackage.type +'/' + selectedPackage.uuid" target="_blank">open {{selectedPackage.type == "delivery" ? 'delivery' : 'share'}} page<!--<mat-icon>arrow_right</mat-icon>--></a>
						</div>
						
						<mat-divider></mat-divider>

						<!-- share active toggle-->
						<ng-container *ngIf="selectedPackage.type == 'share'">
							<div class="option-label splitRow">
								<span>Activated:</span>
								<div>
									<button mat-button *ngIf="hasChanged('actioned')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('actioned')" color="warn">
										<mat-icon>undo</mat-icon>
									</button>
									<mat-checkbox style="margin-right:1em;" formControlName="actioned" color="primary" [(ngModel)]="selectedPackage.actioned" [checked]="selectedPackage.actioned == true" (change)="packageUpdated()"></mat-checkbox>
								</div>
							</div>
						</ng-container>

						<!-- download options -->
						<ng-container *ngIf="selectedPackage.type == 'delivery'">
							<div class="option-label splitRow">
								<span>Download options: </span>
								<span *ngIf="hasChanged('actioned') || hasChanged('partialAllowed')">
									<button mat-button  matSuffix mat-icon-button aria-label="undo" (click)="resetDownloadOptions()" color="warn">
										<mat-icon>undo</mat-icon>
									</button>
								</span>
								<div [matMenuTriggerFor]="delivOptionMenu" class="dropdown-trigger option-dropdown-label">
									<span class="primary">{{delivOption?.label}}</span>
									<span><mat-icon>arrow_drop_down</mat-icon></span>

									<mat-menu #delivOptionMenu="matMenu">
										<button mat-menu-item *ngFor="let option of deliveryDownloadOptions" [disabled]="option == delivOption" (click)="updateDownloadOption(option)">
											<span>{{option.label}}</span>
										</button> 
									</mat-menu>
								</div>
							</div>

							<mat-divider></mat-divider>
						</ng-container>

						<!-- folder options -->
						<ng-container *ngIf="selectedPackage.type == 'delivery'">
							<div class="option-label splitRow">
								<span>Download folder structure:</span>
								<span *ngIf="hasChanged('folderOption')">
									<button mat-button  matSuffix mat-icon-button aria-label="undo" (click)="resetFolderOptions()" color="warn">
										<mat-icon>undo</mat-icon>
									</button>
								</span>
								<div [matMenuTriggerFor]="folderOptionMenu" class="dropdown-trigger option-dropdown-label">
									<span class="primary">{{folderOption?.label}}</span>
									<span><mat-icon>arrow_drop_down</mat-icon></span>

									<mat-menu #folderOptionMenu="matMenu">
										<button mat-menu-item *ngFor="let option of deliveryFolderOptions" [disabled]="option == folderOption" (click)="updateFolderOption(option)">
											<span>{{option.label}}</span>
										</button> 
									</mat-menu>
								</div>
							</div>

							<mat-divider></mat-divider>

							<!-- folder priority -->
							<!--
							<div style="display: flex; align-items: center; font-size: 0.8em; height: 3.5em;">
								<span style="font-weight:bold; flex-grow: 1;" >Delivery folder priority:</span>
								<div style="display: flex; align-items: center;">
									<span [ngStyle]="{'opacity': selectedPackage.useFormatPath ? 0.4 : 1}" [ngClass]="{'blue': !selectedPackage.useFormatPath}">Channels</span>
									<mat-slide-toggle class="folder-switch" color="primary" [(ngModel)]="selectedPackage.useFormatPath" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
									<span [ngStyle]="{'opacity': selectedPackage.useFormatPath ? 1 : 0.4}" [ngClass]="{'blue': selectedPackage.useFormatPath}">Formats</span>
								</div>
							</div>
							<div class="note">
								<span *ngIf="!selectedPackage.useFormatPath">Workflow > channel > group > format > (files)</span>
								<span *ngIf="selectedPackage.useFormatPath">Workflow > group > format > channel > (files)</span>
							</div>

							
							<mat-divider></mat-divider>
							-->

							<!-- delivery note NOT USED-->
							<ng-container *ngIf="false">
								<mat-checkbox color="primary" (change)="toggleNote()" [checked]="noteState == 1">Add Delivery note</mat-checkbox>
								<div *ngIf="noteState == 1">
									<span class="note">This will appear on the delivery page.</span>
									<mat-form-field appearance="fill">
										<mat-label for="note" >Note</mat-label>
										<input matInput res [(ngModel)]="selectedPackage.notes" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('notes') }">
										<mat-icon matPrefix>edit_note</mat-icon>
									</mat-form-field>
								</div>
							</ng-container>

		
							<!-- delivery note -->
							<div class="option-label splitRow">
								<span>Delivery page note:</span>
								<!--<span class="note">This will appear on the delivery page.</span>-->
								<div>
									<span *ngIf="hasChanged('notes')">
										<button mat-button matSuffix mat-icon-button aria-label="undo" (click)="resetValue('notes')" color="warn">
											<mat-icon>undo</mat-icon>
										</button>
									</span>	
									<button mat-button class="small"  style="margin-right:-0.75em" [disabled]="!selectedPackage.notes" matSuffix mat-basic-button aria-label="clear" color="warn" (click)="clearProp('notes')">
										clear
									</button>	
								</div>
							</div>
							<!--
								<mat-form-field appearance="fill">
									<textarea matInput res [(ngModel)]="selectedPackage.notes" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('notes') }" placeholder="no notes" ></textarea>
									<mat-icon matPrefix>edit_note</mat-icon>
								</mat-form-field>
							-->
							<textarea #deliverynotes class="panel-section panel-input-text" [(ngModel)]="selectedPackage.notes" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('notes') }" placeholder="no notes" rows="3" cols="49" spellcheck="false"></textarea>
								
							<mat-divider></mat-divider>									
						</ng-container>
						<!-- passcode NOT USED-->
						 <ng-container *ngIf="false">

							<div class="list-button-group">
								<mat-checkbox color="primary" (change)="togglePasscode()" [checked]="passcodeState == 1">Protect with passcode</mat-checkbox>
								<button mat-button *ngIf="hasChanged('passcode')" matSuffix mat-icon-button aria-label="undo" color="warn" (click)="resetValue('passcode')">
									<mat-icon>undo</mat-icon> 
								</button>						
							</div>	
							<div matTooltip="Set an access passcode for this package" *ngIf="passcodeState == 1" >
								<mat-form-field appearance="fill">
									<mat-label for="passcode" >Passcode</mat-label>
									<input matInput res formControlName="passcode" [(ngModel)]="selectedPackage.passcode" [ngClass]="{'red': hasChanged('passcode') }">
									<mat-error *ngIf="packageForm.controls.passcode.errors?.minlength || packageForm.controls.passcode.errors?.maxlength">passcode must be<strong> 6 </strong>digits</mat-error>
									<mat-error *ngIf="packageForm.controls.passcode.errors?.pattern">passcode must only contain <strong>digits (0-9)</strong></mat-error>
									<mat-icon matPrefix>lock</mat-icon>
									<button mat-button [disabled]="selectedPackage.passcode?.length != 6" matSuffix mat-icon-button aria-label="copy" (click)="copyPasscode()">
										<mat-icon>content_copy</mat-icon>
									</button>
									<button mat-button matSuffix mat-icon-button aria-label="random" (click)="randomPasscode()">
										<mat-icon>autorenew</mat-icon>
									</button>
									<!--<button mat-button *ngIf="hasChanged('passcode')" matSuffix mat-icon-button aria-label="undo" (click)="resetValue('passcode')">
										<mat-icon>undo</mat-icon> 
									</button>-->
								</mat-form-field>
							</div>
						
						</ng-container>

						<!-- passcode -->
						<div class="option-label splitRow">
							<span>Delivery page passcode:</span>
							<!--<span class="note">This will appear on the delivery page.</span>-->
							<div>
								<span *ngIf="hasChanged('passcode')">
									<button mat-button matSuffix mat-icon-button aria-label="undo" (click)="resetValue('passcode')" color="warn">
										<mat-icon>undo</mat-icon>
									</button>
								</span>	
								<button mat-button class="small" style="margin-right:-0.75em" [disabled]="!selectedPackage.passcode" matSuffix mat-basic-button aria-label="clear" color="warn" (click)="clearProp('passcode')">
									clear
								</button>	
							</div>
						</div>

						<!--
							<div class="splitRow">
								<div>
									<input #passcode1 class="panel-section panel-input-text single digit" [(ngModel)]="partpasscode1" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" rows="1" cols="1" spellcheck="false" maxlength="1"/>
									<input #passcode2 class="panel-section panel-input-text single digit" [(ngModel)]="partpasscode1" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" rows="1" cols="1" spellcheck="false" maxlength="1"/>
									<input #passcode3 class="panel-section panel-input-text single digit" [(ngModel)]="partpasscode1" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" rows="1" cols="1" spellcheck="false" maxlength="1"/>
									<input #passcode4 class="panel-section panel-input-text single digit" [(ngModel)]="partpasscode1" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" rows="1" cols="1" spellcheck="false" maxlength="1"/>
									<input #passcode5 class="panel-section panel-input-text single digit" [(ngModel)]="partpasscode1" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" rows="1" cols="1" spellcheck="false" maxlength="1"/>
									<input #passcode6 class="panel-section panel-input-text single digit" [(ngModel)]="partpasscode1" (change)="packageUpdated()" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" rows="1" cols="1" spellcheck="false" maxlength="1"/>
								</div>
								<div>
									<button mat-button [disabled]="selectedPackage.passcode?.length != 6" matSuffix mat-icon-button aria-label="copy" (click)="copyPasscode()">
										<mat-icon>content_copy</mat-icon>
									</button>
									<button mat-button matSuffix mat-icon-button aria-label="random" (click)="randomPasscode()">
										<mat-icon>autorenew</mat-icon>
									</button>
								</div>
							</div>
						-->
						
							<mat-form-field appearance="fill">
								<input matInput res formControlName="passcode" (change)="packageUpdated()" [(ngModel)]="selectedPackage.passcode" [ngModelOptions]="{standalone: true}" [ngClass]="{'red': hasChanged('passcode') }" placeholder="no passcode">
								<mat-error *ngIf="packageForm.controls.passcode.errors?.minlength || packageForm.controls.passcode.errors?.maxlength">passcode must be<strong> 6 </strong>digits</mat-error>
								<mat-error *ngIf="packageForm.controls.passcode.errors?.pattern">passcode must only contain <strong>digits (0-9)</strong></mat-error>
								<mat-icon matPrefix>lock</mat-icon>
								<button mat-button [disabled]="selectedPackage.passcode?.length != 6" matSuffix mat-icon-button aria-label="copy" (click)="copyPasscode()">
									<mat-icon>content_copy</mat-icon>
								</button>
								<button mat-button matSuffix mat-icon-button aria-label="random" (click)="randomPasscode()">
									<mat-icon>autorenew</mat-icon>
								</button>
							</mat-form-field>
						
						
						
                    </div>

					

					<!-- CREATIVES IN PACKAGE -->
					<div class="sp-subheader">Creatives in package</div>
					<div class="panel-section">
							<div class="splitRow"><span class="colBlock saved">saved:</span><span>{{selectedPackage.delivery_items.length}}</span></div>
							<div class="splitRow"><span class="colBlock toAdd">to add:</span><span>{{selectedPackage.delivery_items_add.length > 0 ? '+':''}}{{selectedPackage.delivery_items_add.length}}</span></div>
							<div class="splitRow"><span class="colBlock toRemove">to remove:</span><span>{{selectedPackage.delivery_items_remove.length > 0 ? '-':''}}{{selectedPackage.delivery_items_remove.length}}</span></div>
							<div class="splitRow totaller">
								<span><button class="small" [ngStyle]="{'visibility':hasChanged('creatives') ? 'unset':'hidden'}" mat-stroked-button color="warn" (click)="resetValue('creatives')">undo selection</button></span>
								<span>Selection result: {{selectedPackage.delivery_items.length + selectedPackage.delivery_items_add.length - selectedPackage.delivery_items_remove.length}}</span>
							</div>
						<!--<mat-menu #packageCreativeList="matMenu">
							<button mat-menu-item *ngFor="let creative of getPackageCreativeList(selectedPackage)" style="pointer-events: none;">{{creative.format.name}}</button>			
						</mat-menu>-->
					</div>
                </form>
			</div>
		</div>
    </div>    
    <div class="bottom">
        <div class="buttons">
            <button xclass="small" [disabled]="saving || !needsSave()" mat-stroked-button color="warn" (click)="revertAllPackages()" matTooltip="Revert to previous state for all assets">Revert all changes</button>
			<button xclass="small" [disabled]="saving || !needsSave()" mat-raised-button color="primary" (click)="savePackages()" matTooltip="Save changes on all assets">Save all changes</button>         
        </div>
        <!-- <button *ngIf="production" [disabled]="!hasAssetChanged()" mat-raised-button (click)="revert()">Revert Changes</button> -->
    </div>
</div>     